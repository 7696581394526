import { makeAutoObservable } from "mobx";

export interface TagArgs {
  value: string;
}

export class Tag {
  private _value: string;

  constructor(args: TagArgs) {
    makeAutoObservable(this);

    this._value = args.value;
  }

  // getters

  get value() {
    return this._value;
  }

  // getters end
}
