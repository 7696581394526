import styled from 'styled-components';

export const StyledLoader = styled.div<{ $width?: number; $height?: number }>`
		&.loader {
			align-self: center;
			width: ${props => (props.$width ? props.$width : 16)}px;
			height: ${props => (props.$height ? props.$height : 16)}px;

			& > div.loader-spinner {
				width: 100%;
				height: 100%;
				border: 2px solid #f3f3f3;
				border-color: #abb3bb #abb3bb #abb3bb transparent;
				border-radius: 50%;
				animation: spin 1s linear infinite;
			}
		}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
