import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { calculateOffset, userHasRoles } from '@asd-stan/helpers/app-utils';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInputPopup } from '@components/form-input-popup/form-input-popup';
import { Option } from '@components/form-select/form-select';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { Field, FormikValues, useFormikContext } from 'formik';

import { DownloadFile } from '../../download-file/download-file';
import { PatentFile } from '../../patent-file/patent-file';
import { TechnicalChanges } from '../../technical-changes/technical-changes';

const limit = 6;

export const Justification = observer(() => {
	const url = window.location.href;
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<FormikValues>();

	const currentUserService = getCurrentUserService();
	const standardService = getStandardService();

	const handleLoadConsideredStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page, limit);
		const count = await standardService.getLatestStandardsList(limit, offset, {
			search: search ? search : undefined,
		});
		const consideredStandards = standardService.latestStandards
			.filter(
				standard =>
					standard.registrationNumber !== standardService.singleStandard?.registrationNumber
			)
			.map(standard => {
				return {
					label: `${standard.registrationNumber ?? ''} ${standard.form ?? ''} ${
						standard.localizedTitle ?? ''
					} ${standard.edition}`,
					value: standard.id,
				};
			});
		const hasMore = page < Math.ceil(count / limit);
		return {
			options: consideredStandards,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	useEffect(() => {
		const onLoadPage = async () => {
			standardService.getStandardList(20, 0, false);
		};

		onLoadPage();
	}, [standardService]);

	const handleChange = async (name: string, option: MultiValue<Option> | SingleValue<Option>) => {
		if (!option || !Array.isArray(option)) {
			return;
		}

		const newOption = option.map(item => {
			const splitLabel = item.label.split(' ');
			return {
				label: `${splitLabel[0]} ${splitLabel[1]} ${splitLabel[splitLabel.length - 1]}`,
				value: item.value,
			};
		});

		await setFieldValue(name, newOption);
	};

	return (
		<>
			<Field
				component={FormInputParagraph}
				name="purpose"
				title={t('standard.createNWP.justification.purpose')}
				fullWidth
				maxLength={5000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			{values.revisionId && values.revisionId.value === 'yes' && (
				<Field
					key={`${values.revisionId.value}-revision`}
					component={FormInputParagraph}
					name="revisionJustification"
					title={t('standard.createNWP.justification.revisionJustification')}
					fullWidth
					mandatory={!standardService.singleStandard?.isMigrated}
					maxLength={5000}
					useSubmitOnBlur
					showError
					tooltip={t('standard.createNWP.justification.revisionJustificationTooltip')}
					description={
						<DownloadFile file="Guidance to list the changes in the revised standards.docx" />
					}
					disableMaxLength={url.includes('draft')}
				/>
			)}
			{values.revisionId && values.revisionId.value === 'yes' && <TechnicalChanges />}
			<Field
				component={FormSelectAsyncPagination}
				name="consideredStandards"
				title={t('standard.createNWP.justification.consideredStandards')}
				fullWidth
				disabled={userHasRoles([SystemRole.EXPERT], currentUserService.userRoles!)}
				isMulti
				showCheckboxOption
				loadOptions={handleLoadConsideredStandards}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				onChange={handleChange}
				useSubmitOnChange
			/>
			<Field
				component={FormInputPopup}
				name="externalConsideredStandards"
				title={t('standard.createNWP.justification.externalConsideredStandards')}
				fullWidth
				showError
				enableCopy
				maxLength={500}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			<PatentFile />
		</>
	);
});
