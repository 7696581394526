import styled from 'styled-components';

export const StyledEditCatalogItem = styled.div`
	& form {
		width: 800px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.is-top {
			margin-top: 8px;
		}
	}

	.cover-page {
		.remove-file-btn {
			display: flex;
			justify-content: flex-end;
		}
	}
`;
