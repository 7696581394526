import {
	AttachmentResponse,
	Classification,
	Expert,
	ICSCode,
	Justification,
	Leader,
	Originator,
	Revision,
	StandardDetailedRemark,
	StandardDomain,
	StandardFile,
	StandardWorkingGroup,
	Tag,
	WGS,
} from '@asd-stan/standard/domain/standard-detailed.entity';
import { makeAutoObservable } from 'mobx';

export interface DraftDetailedArgs {
	localizedTitle: string;
	additionalInformation: {
		tags: Tag[];
		classifications: Classification[];
		icsCodes: ICSCode[];
	};
	draft: StandardFile | null;
	experts: {
		experts: Expert[];
		wgs: WGS | null;
	};
	generalData: {
		domains: StandardDomain[];
		edition: string;
		form: string;
		isRevision: boolean | null;
		isWorkingGroupNull: boolean;
		languages: string[];
		leader: Leader;
		pages: number;
		registrationNumber: string;
		revision: Revision | null;
		scope: string;
		stanNumber: string;
		standardTypes: {
			id: number;
			name: string;
		}[];
		workingGroups: StandardWorkingGroup[];
		cenWiNumber: null | string;
	};
	id: number;
	justification: Justification;
	originator: Originator;
	remarks: StandardDetailedRemark[];
	titles: {
		[key: string]: string;
	};
	attachments: Array<AttachmentResponse>;
	enData: null | {
		name: string;
		publicationDate: null | string;
		title: string;
	};
}

export class DraftDetailed {
	localizedTitle: string;
	id: number;
	draft: StandardFile | null;
	generalData: {
		stanNumber: string;
		registrationNumber: string;
		form: string;
		edition: string;
		isRevision: boolean | null;
		revision: Revision | null;
		pages: number;
		domains: StandardDomain[];
		isWorkingGroupNull: boolean;
		workingGroups: StandardWorkingGroup[];
		leader: Leader;
		languages: string[];
		standardTypes: {
			id: number;
			name: string;
		}[];
		scope: string;
		cenWiNumber: null | string;
	} | null;
	experts: {
		experts: Expert[];
		wgs: WGS | null;
	} | null;
	justification: Justification;
	additionalInformation: {
		tags: Tag[];
		classifications: Classification[];
		icsCodes: ICSCode[];
	} | null;
	originator: Originator;
	remarks: StandardDetailedRemark[];
	titles: {
		[key: string]: string;
	};
	attachments: Array<AttachmentResponse>;
	enData: {
		name: string;
		publicationDate: null | Date;
		title: string;
	};

	constructor(args: DraftDetailedArgs) {
		makeAutoObservable(this);
		this.id = args.id;
		this.draft = args.draft;
		this.generalData = args.generalData
			? {
					stanNumber: args.generalData.stanNumber,
					registrationNumber: args.generalData.registrationNumber,
					form: args.generalData.form,
					edition: args.generalData.edition,
					isRevision: args.generalData.isRevision,
					revision: args.generalData.revision,
					languages: args.generalData.languages,
					standardTypes: args.generalData.standardTypes,
					pages: args.generalData.pages,
					domains: args.generalData.domains,
					isWorkingGroupNull: args.generalData.isWorkingGroupNull,
					workingGroups: args.generalData.workingGroups,
					leader: args.generalData.leader && {
						...args.generalData.leader,
						systemRoles: args.generalData.leader.systemRoles.map(role => role.toLowerCase()),
					},
					scope: args.generalData.scope,
					cenWiNumber: args.generalData.cenWiNumber,
			  }
			: null;
		this.localizedTitle = args.localizedTitle;
		this.titles = args.titles;
		this.originator = args.originator;
		this.justification = args.justification;
		this.additionalInformation = args.additionalInformation
			? {
					tags: args.additionalInformation.tags,
					classifications: args.additionalInformation.classifications,
					icsCodes: args.additionalInformation.icsCodes,
			  }
			: null;
		this.experts = args.experts
			? {
					experts: args.experts.experts.map(expert => ({
						...expert,
						fullName: `${expert.firstName} ${expert.lastName}`,
					})),
					wgs: args.experts.wgs
						? {
								...args.experts.wgs,
								fullName: `${args.experts.wgs.firstName} ${args.experts.wgs.lastName}`,
						  }
						: null,
			  }
			: null;
		this.remarks = args.remarks;
		this.attachments = args.attachments;
		this.enData = {
			name: args.enData?.name || '',
			publicationDate: args.enData?.publicationDate ? new Date(args.enData.publicationDate) : null,
			title: args.enData?.title || '',
		};
	}
}
