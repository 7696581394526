import { useTranslation } from 'react-i18next';

import { SingleBallot } from '@asd-stan/ballot/domain/ballot.entity';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import { Field } from '../../../field';
import { StyledBallotResult } from '../../../single-ballot.styled';

export const Results = ({ ballot }: { ballot: SingleBallot }) => {
	const { t } = useTranslation();

	return (
		<InputGroup title="Results">
			<Field
				label={
					<>
						{t('ballot.singleBallot.ballotResult')}{' '}
						<StyledBallotResult $type={ballot.result.value}>
							{ballot.result.value}
						</StyledBallotResult>
					</>
				}
				timestamp={ballot.result}
			/>
			<Field
				label={t('ballot.singleBallot.comment')}
				value={ballot.resultComment.value}
				timestamp={ballot.resultComment}
				showEmptyValuePlaceholder
			/>
		</InputGroup>
	);
};
