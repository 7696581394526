import styled from 'styled-components';

export const StyledModalRoot = styled.div`
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	overflow-x: hidden;
	background-color: rgba(4, 22, 48, 0.2);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	& > div,
	& > form {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 24px;
		border-radius: 16px;
		width: fit-content;
		background-color: ${({ theme }) => theme.fontColors.white};
		margin: auto;

		& > h1 {
			color: ${({ theme }) => theme.fontColors.darkGrey};
			font-size: 32px;
			font-weight: 600;
			line-height: normal;
		}

		& > p {
			color: ${({ theme }) => theme.fontColors.lightGrey};
			margin-top: 24px;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}
	}
`;
