import styled from 'styled-components';

export const StyledButton = styled.div<{
	$secondary?: boolean;
	$fullWidth?: boolean;
	$disabled?: boolean;
	$flat?: boolean;
	$title?: boolean;
}>`
	width: ${props => (props.$fullWidth ? '100%' : 'auto')};

	& button {
		width: ${props => (props.$fullWidth ? '100%' : 'auto')};
		display: flex;
		justify-content: center;
		align-items: center;
		height: 42px;
		background-color: ${props =>
			props.$secondary ? props.theme.fontColors.white : props.theme.fontColors.accent};
		border: ${props => props.$secondary && props.theme.borders.divider};
		color: ${props =>
			props.$secondary ? props.theme.fontColors.darkGrey : props.theme.fontColors.white};
		padding: 10px 22px;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 500;
		white-space: nowrap;
		background-color: ${props => props.$disabled && `${props.theme.bgColors.lightGrey} !important`};
		background-color: ${props => props.$flat && `transparent !important`};
		border: ${props => props.$flat && `none !important`};
		color: ${props =>
			props.$flat &&
			`${
				props.$disabled ? props.theme.bgColors.lightGrey : props.theme.fontColors.accent
			} !important`};
	}

	& span {
		& > svg {
			width: 16px;
			height: 16px;
			fill: ${props =>
				props.$secondary ? props.theme.fontColors.darkGrey : props.theme.fontColors.white};
			margin-right: 6px;
			margin-top: 3px;
		}
	}

	& button:active:not(:disabled) {
		-webkit-box-shadow: ${({ theme }) => theme.button.active};
		-moz-box-shadow: ${({ theme }) => theme.button.active};
		box-shadow: ${({ theme }) => theme.button.active};
		outline: none;
		box-shadow: ${props => props.$flat && 'none !important'};
	}

	& button:hover {
		background-color: ${props =>
			props.$secondary ? props.theme.button.secondaryHover : props.theme.button.primaryHover};
		color: ${props =>
			props.$flat &&
			`${props.$disabled ? props.theme.lightGrey : props.theme.fontColors.buttonHover} !important`};
	}

	& button:disabled {
		cursor: not-allowed;
		background-color: ${({ theme }) => theme.button.disabled};
		opacity: 0.7;
		color: ${({ theme }) => theme.fontColors.darkGrey};

		&:hover {
			background-color: ${({ theme }) => theme.button.disabled};
		}
	}
`;

export const StyledBorderButton = styled(StyledButton)`
	margin-top: 15px;

	& {
		align-self: center;
	}

	& button {
		background-color: inherit;
		border: ${({ theme }) => theme.borders.buttonBorder};
		color: ${({ theme }) => theme.fontColors.accent};
		padding: 10px 20px;
	}

	& button:hover {
		background-color: inherit;
	}

	& span {
		& > svg {
			width: fit-content;
			height: fit-content;
			fill: ${({ theme }) => theme.fontColors.accent};
			margin-right: 8px;
		}
	}
`;

export const StyledAccentButton = styled(StyledButton)<{ $iconButton?: boolean }>`
	border-radius: 4px;
	width: fit-content;
	background-color: ${({ theme }) => theme.button.accent};

	& button {
		border-radius: inherit;
		padding: 8px;
		width: 100%;
		height: 100%;
		background-color: inherit;
		color: ${({ theme }) => theme.fontColors.accent};

		svg {
			margin-left: ${props => (props.$iconButton ? '0' : '10px')};
		}

		&:hover:not(:disabled) {
			background-color: ${({ theme }) => theme.bgColors.accent};

			& svg {
				stroke: white;
			}
		}
	}
`;

export const StyledButtonClose = styled.span<{ $disabled: boolean }>`
	display: flex;
	align-items: center;
	height: 100%;

	button {
		background-color: inherit;
		width: 16px;
		height: 16px;
		cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
	}
`;

export const StyledIconButton = styled.div`
	margin-left: 16px;
	width: auto;

	& button {
		width: 42px;
		height: 42px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		background-color: ${props => props.theme.fontColors.white};
		border: ${props => props.theme.borders.divider};

		&:disabled {
			opacity: 0.7;
			cursor: not-allowed;
			background-color: ${({ theme }) => theme.button.disabled};

			&:hover {
				background-color: ${({ theme }) => theme.button.disabled};
			}
		}
	}

	& > svg {
		width: 16px;
		height: 16px;
	}

	& button:hover {
		background-color: ${props => props.theme.button.secondaryHover};
	}
`;

export const StyledButtonWithSettings = styled(StyledButton)`
	display: flex;

	& button {
		border-radius: 8px 0 0 8px;

		&.settings {
			margin-left: -3px;
			padding: 8px;
			border-radius: 0 8px 8px 0;
		}
	}
`;
