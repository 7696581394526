import { css } from 'styled-components';

export const scrollStyles = css`
	&::-webkit-scrollbar {
		border-radius: 10px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.bgColors.scrollTrack};
		margin: 4px 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.fontColors.accent};
		border-radius: 8px;
	}
`;


export const inputStyles = css`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  min-height: 72px;

  label {
    min-height: 16px;
    color: ${({theme}) => theme.fontColors.darkGrey};
    font-size: 13px;
    font-weight: 500;

    span {
      color: ${({theme}) => theme.fontColors.accent};
    }

    &.disabled {
      color: ${({theme}) => theme.fontColors.lightGrey};
    }
  }
`;
