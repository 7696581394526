import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploadController, UploadedFile } from '@asd-stan/file/domain/file-upload-controller';
import { MeetingForm } from '@asd-stan/meeting/domain/meeting.entity';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { DroppedFile } from '@components/file-dropzone/dropped-files';
import { FileDropzone } from '@components/file-dropzone/file-dropzone';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInput } from '@components/form-input/form-input';
import { Field, useFormikContext } from 'formik';

export const meetingFileTypes = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg': ['.svg'],
	'application/xml': ['.xml'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'text/csv': ['.csv'],
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'image/tiff': ['.tiff'],
	'image/vnd.dwg': ['.dwg'],
	'image/bmp': ['.bmp'],
	'application/zip': ['.zip'],
	'application/vnd.ms-powerpoint': ['.ppt'],
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
};

export const meetingMaxFileSizeBytes = 20 * 1024 * 1024;

export const Files = () => {
	const { t } = useTranslation();
	const fileUploadContrallerRef = useRef<null | FileUploadController>(null);
	const { values, setFieldValue } = useFormikContext<MeetingForm>();

	if (fileUploadContrallerRef.current === null) {
		fileUploadContrallerRef.current = new FileUploadController();
	}

	return (
		<InputGroup title={t('meeting.createMeeting.files')}>
			<div style={{ width: '100%' }}>
				<Field
					name="files"
					component={FileDropzone}
					fileTypes={meetingFileTypes}
					maxSize={meetingMaxFileSizeBytes}
					controller={fileUploadContrallerRef.current}
					maxFiles={0}
					isFilePrivate
					onFilesChange={(files: UploadedFile[]) => {
						return setFieldValue(
							'files',
							files.map(file => ({
								file,
								description: values.files.find(f => f.file === file)?.description || '',
							}))
						);
					}}
					customFilesView={({
						files,
						disabled,
						handleFileDelete,
					}: {
						files: UploadedFile[];
						disabled?: boolean;
						handleFileDelete(fileToDelete: UploadedFile): void;
					}) =>
						files.map((file, index) => (
							<DroppedFile
								key={file.title + index}
								file={file}
								onDelete={() => handleFileDelete(file)}
								disabled={disabled}>
								<Field
									component={FormInputParagraph}
									name={`files[${index}].description`}
									title={t('meeting.createMeeting.fileDescription')}
									showError
									fullWidth
									maxLength={500}
								/>
							</DroppedFile>
						))
					}
				/>
			</div>
		</InputGroup>
	);
};
