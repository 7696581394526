import styled from 'styled-components';

export const StyledInputGroup = styled.div`
	width: 888px;
	max-width: 1240px;
	border-radius: 16px;
	background-color: ${({ theme }) => theme.fontColors.white};
	padding: 24px 32px;
	margin: 16px 0;

	& h4 {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
	}

	& > p {
		margin-top: 16px;
		font-size: 11px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		text-transform: uppercase;
		color: ${({ theme }) => theme.fontColors.lightGrey};
	}

	@media (min-width: 1500px) {
		width: 80%;
	}
`;

export const StyledDomainInputGroup = styled(StyledInputGroup)`
	& {
		& p {
			text-align: center;
			margin: 16px 0;
			font-size: 14px;
			font-weight: 400;
			text-transform: unset;
		}

		& .checkbox-container {
			display: flex;
			margin: 16px 0;
		}

		.add-domain-button {
			margin-top: 12px;
		}
	}
`;

export const StyledDomain = styled.div`
	& {
		& > div > p {
			margin: 16px 0 0;
			color: ${({ theme }) => theme.fontColors.lightGrey};
			font-size: 11px;
			font-weight: 500;
			line-height: 150%;
		}
	}
`;
