import React from 'react';

import {
	StandardDisplayField,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
} from './single-standard.styled';

interface Props {
	header: string;
	value?: string | number | null;
	fallbackText?: string;
}

export const SingleStandardField: React.FC<Props> = ({ header, value, fallbackText = '' }) => (
	<StandardDisplayField>
		<StandardDisplayFieldHeader>{header}</StandardDisplayFieldHeader>
		{value ? (
			<StandardDisplayFieldValue>{value}</StandardDisplayFieldValue>
		) : (
			<StandardDisplayFieldValueAbsent>{fallbackText}</StandardDisplayFieldValueAbsent>
		)}
	</StandardDisplayField>
);
