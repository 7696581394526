import { scrollStyles } from '@asd-stan/ui-kit/components/utility/shared-styles';
import styled from 'styled-components';

export const StyledTextArea = styled.div<{ $error: boolean; $fullWidth?: boolean; $disabled?: boolean; }>`
	display: flex;
	margin-top: 16px;
	flex-direction: column;
	width: ${props => (props.$fullWidth ? '100%' : '400px')};
	max-height: 232px;
	line-height: 130%;

	label {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 13px;
		font-weight: 500;

		span {
			width: 100%;
			height: 100%;
			color: ${({ theme }) => theme.fontColors.accent};
		}

		.field-hint {
			color: ${({ theme }) => theme.fontColors.lightGrey};
		}
	}

	.input-container {
		margin-top: 8px;
		padding: 12px;
		width: 100%;
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 13px;
		border-radius: 8px;
		border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};
		background-color: ${props => props.$disabled ? props.theme.bgColors.primary : 'inherit'};
		resize: none;
		overflow-y: auto;

		${scrollStyles}
	}

	.extra-input {
		border-radius: 4px;
		background-color: #ea3e533d;
	}

	[data-placeholder]:empty:before {
		content: attr(data-placeholder);
		color: ${({ theme }) => theme.fontColors.lightGrey};
	}

	.error-message {
		margin-top: 8px;
		font-size: 11px;
		font-weight: 500;
		color: ${({ theme }) => theme.fontColors.error};
	}
`;
