import React from 'react';

import { StyledHeader } from './header.styled';
import { UserPanel } from './user-panel/user-panel';
import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
// @ts-ignore
import { FooBar } from '@repo/ui/dist/index';

interface HeaderProps {
	currentUser: CurrentUser;
}

export const Header: React.FC<HeaderProps> =  ({ currentUser }) => {
	return (
		<StyledHeader>
			<UserPanel currentUser={currentUser} />
		</StyledHeader>
	);
};
