import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { calculateOffset } from '@asd-stan/helpers/app-utils';
import i18n from '@asd-stan/i18n/i18n';
import { MeetingForm, MeetingType } from '@asd-stan/meeting/domain/meeting.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInput } from '@components/form-input/form-input';
import { FormSelect } from '@components/form-select/form-select';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';

export const meetingTypeOptions: { label: string; value: MeetingType }[] = [
	{ label: i18n.t('meeting.meetingType.remote'), value: MeetingType.Remote },
	{ label: i18n.t('meeting.meetingType.physical'), value: MeetingType.Physical },
];

const limit = 6;

export const General = observer(() => {
	const { t } = useTranslation();
	const domainService = getDomainService();
	const { values, setFieldValue } = useFormikContext<MeetingForm>();
	const standardService = getStandardService();

	const domainOptions = domainService.domains.map(domain => {
		return { label: `${domain.code} ${domain.name}`, value: domain.code };
	});

	const wgOptions = values.domains
		.flatMap(({ value }) => domainService.getDomainWorkingGroups(value))
		.map(({ code, name }) => ({ label: `${code} ${name}`, value: code }));

	const handleLoadLatestStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page, limit);
		const count = await standardService.getLatestStandardsList(limit, offset, {
			childSearch: true,
			registrationNumber: search,
			isDraft: false,
		});
		const updateStandards = standardService.latestStandards.map(standard => {
			return {
				label: `${standard.registrationNumber ?? ''} ${standard.form ?? ''} ${
					standard.edition ?? ''
				} ${standard.localizedTitle}`,
				value: standard.id,
			};
		});
		const hasMore = page < Math.ceil(count / limit);
		return {
			options: updateStandards.filter(
				standard => standard.value !== standardService.singleStandard?.id
			),
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	return (
		<InputGroup title={t('meeting.createMeeting.general')}>
			<Field
				component={FormSelect}
				isMulti
				options={domainOptions}
				name="domains"
				title={t('meeting.createMeeting.domains')}
				customChange={() => {
					setFieldValue('workingGroups', []);
				}}
				showError
			/>
			<Field
				component={FormSelect}
				isMulti
				options={wgOptions}
				name="workingGroups"
				title={t('meeting.createMeeting.workingGroups')}
				showError
				disabled={values.domains.length === 0}
			/>
			<Field
				component={FormInputParagraph}
				name="title"
				title={t('meeting.createMeeting.titleField')}
				showError
				fullWidth
				mandatory
				maxLength={1000}
			/>
			<Field
				component={FormSelect}
				options={meetingTypeOptions}
				name="type"
				title={t('meeting.createMeeting.type')}
				showError
				fullWidth
				mandatory
			/>
			<Field
				component={FormDatePicker}
				name="startTime"
				label={t('meeting.createMeeting.startTime')}
				placeholder={t('meeting.createMeeting.startTimePlaceholder')}
				showError
				showTimeSelect
				onChange={(_: string, value: null | Date) => {
					if (values.endTime && value && moment(values.endTime).isBefore(value)) {
						setFieldValue('endTime', null);
					}
				}}
				mandatory
				timeIntervals={10}
			/>
			<Field
				component={FormDatePicker}
				name="endTime"
				label={t('meeting.createMeeting.endTime')}
				placeholder={t('meeting.createMeeting.endTimePlaceholder')}
				showError
				minTime={values.startTime}
				showTimeSelect
				mandatory={false}
				timeIntervals={10}
				isClearable
			/>
			<Field
				component={FormInput}
				name="link"
				title={t('meeting.createMeeting.link')}
				showError
				fullWidth
			/>
			<Field
				component={FormInputParagraph}
				name="location"
				title={t('meeting.createMeeting.location')}
				showError
				fullWidth
				mandatory
				maxLength={1000}
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="standard"
				title={t('meeting.createMeeting.standard')}
				loadOptions={handleLoadLatestStandards}
				fullWidth
				showError
			/>
		</InputGroup>
	);
});
