import { useTranslation } from 'react-i18next';

import { ConfirmEmailLayout } from '../layout/confirm-email-layout/confirm-email-layout';

export const LinkExpired: React.FC = () => {
	const { t } = useTranslation();

	return (
		<ConfirmEmailLayout>
			<h1>{t('auth.confirmEmail.linkExpiredTitle')}</h1>
			<p>{t('auth.confirmEmail.linkExpiredDescription')}</p>
		</ConfirmEmailLayout>
	);
};
