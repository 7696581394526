import 'react-datepicker/dist/react-datepicker.css';
import { Trans, useTranslation } from 'react-i18next';

import { UpdateStageForm } from '@asd-stan/standard/domain/stage-update.entity';
import { StandardStage } from '@asd-stan/standard/domain/standard-stage.entity';
import { Checkbox } from '@components/checkbox/checkbox';
import { FormCheckbox } from '@components/form-checkbox/form-checkbox';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { Field, useFormikContext } from 'formik';

import { ActiveStageOption } from '../active-stages/active-stages';

import { UserSelect } from './user-select';

import {
	DataSection,
	StageSelectorTitle,
	StageSelectorTitleBlack,
	TitleWrapper,
} from '@asd-stan/standard/components/single-standard/stage-update/additional/additional.styled';

export const Additional = ({
	newStageOptions,
	activeStageOptions,
}: {
	newStageOptions: StandardStage[];
	activeStageOptions: ActiveStageOption[];
}) => {
	const { t } = useTranslation();

	const { setFieldValue, values } = useFormikContext<UpdateStageForm>();

	const onCheckboxChange = (checked: boolean) => {
		setFieldValue('targetDate', checked ? null : new Date());
	};

	const newStages = newStageOptions.filter(({ code }) => values.stages.newStages.includes(code));
	const activeStages = activeStageOptions.filter(({ code }) =>
		values.stages.activeStages.includes(code)
	);
	const selectedStages = [...newStages, ...activeStages];

	const noTargetDate = values.targetDate === null;

	return (
		<>
			<TitleWrapper>
				<StageSelectorTitle>
					<Trans
						i18nKey="standard.singleStandard.stageUpdateModal.additional.title"
						values={{
							stagesAmount: `${selectedStages.length}`,
							stagesList: `${selectedStages.map(({ code, name }) => `${code} ${name}`).join(', ')}`,
						}}>
						<StageSelectorTitleBlack />
					</Trans>
				</StageSelectorTitle>
				<DataSection>
					<Field
						title={t('standard.singleStandard.stageUpdateModal.additional.comment')}
						component={FormInputParagraph}
						name="comment"
						placeholder={t('standard.singleStandard.stageUpdateModal.additional.enterComment')}
						maxLength={100}
					/>
					<Field
						component={FormDatePicker}
						label="Target date"
						mandatory
						minDate={new Date()}
						placeholder="Choose date"
						name="targetDate"
						disabled={noTargetDate}
						fullWidth
					/>
					<div className="checkbox">
						<Checkbox
							label={t('standard.singleStandard.stageUpdateModal.additional.noTargetDate')}
							checked={noTargetDate}
							onChange={onCheckboxChange}
						/>
					</div>
					<UserSelect />
					<Field
						component={FormCheckbox}
						label={t('standard.singleStandard.stageUpdateModal.additional.email')}
						name="notify.email"
						big
					/>
					{/* <Field
						component={FormCheckbox}
						label={t('standard.singleStandard.stageUpdateModal.additional.onScreen')}
						name="notify.onScreen"
						big
					/> */}
				</DataSection>
			</TitleWrapper>
		</>
	);
};
