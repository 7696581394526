import React, { MouseEvent, useEffect, useRef } from 'react';

import { StyledLabel } from './table-checkbox.styled';

interface TableCheckboxProps extends React.HTMLProps<HTMLInputElement> {
	indeterminate?: boolean;
}

export const TableCheckbox: React.FC<TableCheckboxProps> = ({ indeterminate, ...rest }) => {
	const ref = useRef<HTMLInputElement>(null!);

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate, rest.checked]);

	return (
		<StyledLabel onClick={(e: MouseEvent<HTMLLabelElement>) => e.stopPropagation()}>
			<input type="checkbox" ref={ref} {...rest} />
		</StyledLabel>
	);
};
