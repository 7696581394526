import { CompanyDTO } from '@asd-stan/user/api/company/company.dto';
import { CountryDTO } from '@asd-stan/user/api/country/country.dto';
import { SystemRoleDTO } from '@asd-stan/user/api/system-role.dto';
import { UserDTO } from '@asd-stan/user/api/user.dto';

import { Position } from '../domain/position.entity';

export const users: Array<UserDTO> = [
	{
		id: 1,
		userId: 1,
		firstName: 'Lowe',
		lastName: 'Beach',
		phone: '(915) 431-2935',
		email: 'lowebeach@zentury.com',
		available: true,
		appointer: null,
		appointerId: 1,
		invitedBy: null,
		invitedById: 2,
		country: {
			id: 1,
			name: 'Papua New Guinea',
		},
		company: {
			id: 1,
			name: 'Enomen',
		},
		systemRoles: [SystemRoleDTO.MC],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Mon Apr 18 1983 00:13:15 GMT+0400 (за східноєвропейським літнім часом)',
		updatedAt: 'Thu Jun 19 1980 18:40:33 GMT+0300 (за східноєвропейським стандартним часом)',
	},
	{
		id: 2,
		userId: 2,
		firstName: 'Concepcion',
		lastName: 'Munoz',
		phone: '(932) 579-3519',
		email: 'concepcionmunoz@enomen.com',
		available: false,
		appointer: null,
		appointerId: 3,
		invitedBy: null,
		invitedById: 2,
		country: {
			id: 2,
			name: 'Tunisia',
		},
		company: {
			id: 2,
			name: 'Empirica',
		},
		systemRoles: [SystemRoleDTO.ES],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Sun Feb 03 1974 03:12:45 GMT+0300 (за східноєвропейським стандартним часом)',
		updatedAt: 'Sat Aug 02 1997 23:48:11 GMT+0300 (за східноєвропейським літнім часом)',
	},
	{
		id: 3,
		userId: 3,
		firstName: 'Sofia',
		lastName: 'Madden',
		phone: '(847) 534-3008',
		email: 'sofiamadden@empirica.com',
		available: true,
		appointer: null,
		appointerId: 1,
		invitedBy: null,
		invitedById: 1,
		country: {
			id: 3,
			name: 'Georgia',
		},
		company: {
			id: 3,
			name: 'Medmex',
		},
		systemRoles: [SystemRoleDTO.EXPERT],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Mon Mar 12 2018 16:37:52 GMT+0200 (за східноєвропейським стандартним часом)',
		updatedAt: 'Sat Apr 08 1972 15:02:58 GMT+0300 (за східноєвропейським стандартним часом)',
	},
	{
		id: 4,
		userId: 4,
		firstName: 'Alma',
		lastName: 'Raymond',
		phone: '(924) 500-3719',
		email: 'almaraymond@medmex.com',
		available: false,
		appointer: null,
		appointerId: 3,
		invitedBy: null,
		invitedById: 3,
		country: {
			id: 4,
			name: 'Gabon',
		},
		company: {
			id: 4,
			name: 'Barkarama',
		},
		systemRoles: [SystemRoleDTO.TAC],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Mon Aug 15 1994 14:03:26 GMT+0300 (за східноєвропейським літнім часом)',
		updatedAt: 'Sun Aug 24 1980 23:58:50 GMT+0300 (за східноєвропейським стандартним часом)',
	},
	{
		id: 5,
		userId: 5,
		firstName: 'Mcclain',
		lastName: 'Mendez',
		phone: '(923) 574-2359',
		email: 'mcclainmendez@barkarama.com',
		available: true,
		appointer: null,
		appointerId: 2,
		invitedBy: null,
		invitedById: 3,
		country: {
			id: 5,
			name: 'Zimbabwe',
		},
		company: {
			id: 5,
			name: 'Icology',
		},
		systemRoles: [SystemRoleDTO.ADMIN],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Thu Jan 17 2013 14:06:27 GMT+0200 (за східноєвропейським стандартним часом)',
		updatedAt: 'Mon Nov 27 1978 14:26:16 GMT+0300 (за східноєвропейським стандартним часом)',
	},
	{
		id: 6,
		userId: 6,
		firstName: 'Mckee',
		lastName: 'Love',
		phone: '(831) 599-2787',
		email: 'mckeelove@icology.com',
		available: false,
		appointer: null,
		appointerId: 3,
		invitedBy: null,
		invitedById: 3,
		country: {
			id: 6,
			name: 'Guadeloupe',
		},
		company: {
			id: 6,
			name: 'Andryx',
		},
		systemRoles: [SystemRoleDTO.BOARD_MEMBER],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Tue Apr 24 2007 13:52:15 GMT+0300 (за східноєвропейським літнім часом)',
		updatedAt: 'Fri Sep 29 2017 09:18:05 GMT+0300 (за східноєвропейським літнім часом)',
	},
	{
		id: 7,
		userId: 7,
		firstName: 'Jannie',
		lastName: 'Hardy',
		phone: '(974) 535-3379',
		email: 'janniehardy@andryx.com',
		available: false,
		appointer: null,
		appointerId: 3,
		invitedBy: null,
		invitedById: 3,
		country: {
			id: 7,
			name: 'Bahrain',
		},
		company: {
			id: 7,
			name: 'Isbol',
		},
		systemRoles: [SystemRoleDTO.ES],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Tue Mar 20 2012 16:24:55 GMT+0200 (за східноєвропейським стандартним часом)',
		updatedAt: 'Mon Jul 12 2010 12:05:13 GMT+0300 (за східноєвропейським літнім часом)',
	},
	{
		id: 8,
		userId: 8,
		firstName: 'Jeri',
		lastName: 'Velez',
		phone: '(925) 520-2964',
		email: 'jerivelez@isbol.com',
		available: false,
		appointer: null,
		appointerId: 3,
		invitedBy: null,
		invitedById: 2,
		country: {
			id: 8,
			name: 'Heard and McDonald Islands',
		},
		company: {
			id: 8,
			name: 'Malathion',
		},
		systemRoles: [SystemRoleDTO.MC],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Wed Apr 22 1992 02:21:34 GMT+0300 (за східноєвропейським літнім часом)',
		updatedAt: 'Fri Oct 23 2009 23:30:32 GMT+0300 (за східноєвропейським літнім часом)',
	},
	{
		id: 9,
		userId: 9,
		firstName: 'Solis',
		lastName: 'Good',
		phone: '(925) 492-3322',
		email: 'solisgood@malathion.com',
		available: false,
		appointer: null,
		appointerId: 1,
		invitedBy: null,
		invitedById: 2,
		country: {
			id: 9,
			name: 'Niue',
		},
		company: {
			id: 9,
			name: 'Comvey',
		},
		systemRoles: [SystemRoleDTO.MC],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Mon Sep 29 1980 11:22:17 GMT+0300 (за східноєвропейським стандартним часом)',
		updatedAt: 'Fri Dec 09 2011 00:08:22 GMT+0200 (за східноєвропейським стандартним часом)',
	},
	{
		id: 10,
		userId: 10,
		firstName: 'Rachel',
		lastName: 'Burke',
		phone: '(805) 507-3910',
		email: 'rachelburke@comvey.com',
		available: true,
		appointer: null,
		appointerId: 2,
		invitedBy: null,
		invitedById: 1,
		country: {
			id: 10,
			name: 'Bosnia and Herzegovina',
		},
		company: {
			id: 10,
			name: 'Cytrek',
		},
		systemRoles: [SystemRoleDTO.MC],
		positions: [new Position({ id: 0, name: '' })],
		createdAt: 'Wed Jan 07 2015 03:03:20 GMT+0200 (за східноєвропейським стандартним часом)',
		updatedAt: 'Sun Apr 06 1975 13:16:15 GMT+0300 (за східноєвропейським стандартним часом)',
	},
];

export const countries: Array<CountryDTO> = [
	{
		id: 1,
		name: 'Tajikistan',
	},
	{
		id: 2,
		name: 'Iraq',
	},
	{
		id: 3,
		name: 'Kyrgyzstan',
	},
	{
		id: 4,
		name: 'Faroe Islands',
	},
	{
		id: 5,
		name: 'Greece',
	},
	{
		id: 6,
		name: 'Andorra',
	},
	{
		id: 7,
		name: 'Bosnia and Herzegovina',
	},
	{
		id: 8,
		name: 'Argentina',
	},
	{
		id: 9,
		name: 'Maldives',
	},
	{
		id: 10,
		name: 'Saint Kitts and Nevis',
	},
	{
		id: 11,
		name: 'Malawi',
	},
	{
		id: 12,
		name: 'Zambia',
	},
	{
		id: 13,
		name: 'El Salvador',
	},
	{
		id: 14,
		name: 'Papua New Guinea',
	},
	{
		id: 15,
		name: 'Luxembourg',
	},
	{
		id: 16,
		name: 'Madagascar',
	},
	{
		id: 17,
		name: 'Turks and Caicos Islands',
	},
	{
		id: 18,
		name: 'Brunei Darussalam',
	},
	{
		id: 19,
		name: 'Sierra Leone',
	},
	{
		id: 20,
		name: 'Tonga',
	},
	{
		id: 21,
		name: 'French Guiana',
	},
	{
		id: 22,
		name: 'Yemen',
	},
	{
		id: 23,
		name: 'Cameroon',
	},
	{
		id: 24,
		name: 'British Indian Ocean Territory',
	},
	{
		id: 25,
		name: 'Albania',
	},
	{
		id: 26,
		name: 'Sri Lanka',
	},
	{
		id: 27,
		name: 'Estonia',
	},
	{
		id: 28,
		name: 'Aruba',
	},
	{
		id: 29,
		name: 'Panama',
	},
	{
		id: 30,
		name: 'Heard and McDonald Islands',
	},
	{
		id: 31,
		name: 'Bermuda',
	},
	{
		id: 32,
		name: 'Anguilla',
	},
	{
		id: 33,
		name: 'Sudan',
	},
	{
		id: 34,
		name: 'Lithuania',
	},
	{
		id: 35,
		name: 'Dominican Republic',
	},
	{
		id: 36,
		name: 'Northern Mariana Islands',
	},
	{
		id: 37,
		name: 'Ireland',
	},
	{
		id: 38,
		name: 'Cook Islands',
	},
	{
		id: 39,
		name: 'Kiribati',
	},
	{
		id: 40,
		name: 'Equatorial Guinea',
	},
	{
		id: 41,
		name: 'Solomon Islands',
	},
	{
		id: 42,
		name: 'French Southern Territories',
	},
	{
		id: 43,
		name: 'Barbados',
	},
	{
		id: 44,
		name: 'Belgium',
	},
	{
		id: 45,
		name: 'Eritrea',
	},
	{
		id: 46,
		name: 'Taiwan',
	},
	{
		id: 47,
		name: 'Mozambique',
	},
	{
		id: 48,
		name: 'India',
	},
	{
		id: 49,
		name: 'Rwanda',
	},
	{
		id: 50,
		name: 'East Timor',
	},
	{
		id: 51,
		name: 'Philippines',
	},
	{
		id: 52,
		name: 'Ecuador',
	},
	{
		id: 53,
		name: 'Falkland Islands (Malvinas)',
	},
	{
		id: 54,
		name: 'Israel',
	},
	{
		id: 55,
		name: 'Bhutan',
	},
	{
		id: 56,
		name: 'Reunion',
	},
	{
		id: 57,
		name: 'Bouvet Island',
	},
	{
		id: 58,
		name: 'S. Georgia and S. Sandwich Isls.',
	},
	{
		id: 59,
		name: 'Malta',
	},
	{
		id: 60,
		name: 'Tunisia',
	},
	{
		id: 61,
		name: 'Switzerland',
	},
	{
		id: 62,
		name: 'Haiti',
	},
	{
		id: 63,
		name: 'Mauritius',
	},
	{
		id: 64,
		name: 'Seychelles',
	},
	{
		id: 65,
		name: 'Turkmenistan',
	},
	{
		id: 66,
		name: 'Kazakhstan',
	},
	{
		id: 67,
		name: 'Trinidad and Tobago',
	},
	{
		id: 68,
		name: 'Marshall Islands',
	},
	{
		id: 69,
		name: 'Bangladesh',
	},
	{
		id: 70,
		name: 'Tuvalu',
	},
	{
		id: 71,
		name: 'Zaire',
	},
	{
		id: 72,
		name: 'Thailand',
	},
	{
		id: 73,
		name: 'Mexico',
	},
	{
		id: 74,
		name: 'Antarctica',
	},
	{
		id: 75,
		name: 'Niger',
	},
	{
		id: 76,
		name: 'Georgia',
	},
	{
		id: 77,
		name: 'Gabon',
	},
	{
		id: 78,
		name: 'Jordan',
	},
	{
		id: 79,
		name: 'Jamaica',
	},
	{
		id: 80,
		name: 'Ukraine',
	},
	{
		id: 81,
		name: 'Virgin Islands (British)',
	},
	{
		id: 82,
		name: 'Myanmar',
	},
	{
		id: 83,
		name: 'Mauritania',
	},
	{
		id: 84,
		name: 'Peru',
	},
	{
		id: 85,
		name: 'Antigua and Barbuda',
	},
	{
		id: 86,
		name: 'Russian Federation',
	},
	{
		id: 87,
		name: 'St. Helena',
	},
	{
		id: 88,
		name: 'Saint Vincent and The Grenadines',
	},
	{
		id: 89,
		name: 'Saint Lucia',
	},
	{
		id: 90,
		name: 'Pitcairn',
	},
	{
		id: 91,
		name: 'China',
	},
	{
		id: 92,
		name: 'Poland',
	},
	{
		id: 93,
		name: 'France',
	},
	{
		id: 94,
		name: 'Western Sahara',
	},
	{
		id: 95,
		name: 'Niue',
	},
	{
		id: 96,
		name: 'Tanzania',
	},
	{
		id: 97,
		name: 'Bulgaria',
	},
	{
		id: 98,
		name: 'Czech Republic',
	},
	{
		id: 99,
		name: 'Cape Verde',
	},
	{
		id: 100,
		name: 'Algeria',
	},
];

export const companies: Array<CompanyDTO> = [
	{
		id: 1,
		name: 'Xiix',
	},
	{
		id: 2,
		name: 'Centree',
	},
	{
		id: 3,
		name: 'Datagen',
	},
	{
		id: 4,
		name: 'Krag',
	},
	{
		id: 5,
		name: 'Combot',
	},
	{
		id: 6,
		name: 'Bristo',
	},
	{
		id: 7,
		name: 'Uxmox',
	},
	{
		id: 8,
		name: 'Vetron',
	},
	{
		id: 9,
		name: 'Zentime',
	},
	{
		id: 10,
		name: 'Freakin',
	},
	{
		id: 11,
		name: 'Duflex',
	},
	{
		id: 12,
		name: 'Waterbaby',
	},
	{
		id: 13,
		name: 'Hatology',
	},
	{
		id: 14,
		name: 'Aclima',
	},
	{
		id: 15,
		name: 'Zillar',
	},
	{
		id: 16,
		name: 'Geekosis',
	},
	{
		id: 17,
		name: 'Webiotic',
	},
	{
		id: 18,
		name: 'Snips',
	},
	{
		id: 19,
		name: 'Dancity',
	},
	{
		id: 20,
		name: 'Panzent',
	},
	{
		id: 21,
		name: 'Miraclis',
	},
	{
		id: 22,
		name: 'Talkola',
	},
	{
		id: 23,
		name: 'Unisure',
	},
	{
		id: 24,
		name: 'Zyple',
	},
	{
		id: 25,
		name: 'Stockpost',
	},
	{
		id: 26,
		name: 'Playce',
	},
	{
		id: 27,
		name: 'Applidec',
	},
	{
		id: 28,
		name: 'Flumbo',
	},
	{
		id: 29,
		name: 'Zilphur',
	},
	{
		id: 30,
		name: 'Dognost',
	},
	{
		id: 31,
		name: 'Nipaz',
	},
	{
		id: 32,
		name: 'Enjola',
	},
	{
		id: 33,
		name: 'Plasmosis',
	},
	{
		id: 34,
		name: 'Zidant',
	},
	{
		id: 35,
		name: 'Eventex',
	},
	{
		id: 36,
		name: 'Comvene',
	},
	{
		id: 37,
		name: 'Halap',
	},
	{
		id: 38,
		name: 'Scenty',
	},
	{
		id: 39,
		name: 'Zinca',
	},
	{
		id: 40,
		name: 'Qualitex',
	},
	{
		id: 41,
		name: 'Quilm',
	},
	{
		id: 42,
		name: 'Animalia',
	},
	{
		id: 43,
		name: 'Genmex',
	},
	{
		id: 44,
		name: 'Nikuda',
	},
	{
		id: 45,
		name: 'Telpod',
	},
	{
		id: 46,
		name: 'Eplosion',
	},
	{
		id: 47,
		name: 'Unia',
	},
	{
		id: 48,
		name: 'Farmex',
	},
	{
		id: 49,
		name: 'Orbean',
	},
	{
		id: 50,
		name: 'Balooba',
	},
	{
		id: 51,
		name: 'Krog',
	},
	{
		id: 52,
		name: 'Comtest',
	},
	{
		id: 53,
		name: 'Ronelon',
	},
	{
		id: 54,
		name: 'Accupharm',
	},
	{
		id: 55,
		name: 'Gology',
	},
	{
		id: 56,
		name: 'Snorus',
	},
	{
		id: 57,
		name: 'Photobin',
	},
	{
		id: 58,
		name: 'Aeora',
	},
	{
		id: 59,
		name: 'Collaire',
	},
	{
		id: 60,
		name: 'Rockyard',
	},
	{
		id: 61,
		name: 'Isologix',
	},
	{
		id: 62,
		name: 'Speedbolt',
	},
	{
		id: 63,
		name: 'Concility',
	},
	{
		id: 64,
		name: 'Medcom',
	},
	{
		id: 65,
		name: 'Shepard',
	},
	{
		id: 66,
		name: 'Snowpoke',
	},
	{
		id: 67,
		name: 'Zytrac',
	},
	{
		id: 68,
		name: 'Gonkle',
	},
	{
		id: 69,
		name: 'Myopium',
	},
	{
		id: 70,
		name: 'Zillidium',
	},
	{
		id: 71,
		name: 'Codact',
	},
	{
		id: 72,
		name: 'Ultrasure',
	},
	{
		id: 73,
		name: 'Exospace',
	},
	{
		id: 74,
		name: 'Pushcart',
	},
	{
		id: 75,
		name: 'Techade',
	},
	{
		id: 76,
		name: 'Aquasure',
	},
	{
		id: 77,
		name: 'Lexicondo',
	},
	{
		id: 78,
		name: 'Marketoid',
	},
	{
		id: 79,
		name: 'Virva',
	},
	{
		id: 80,
		name: 'Velity',
	},
	{
		id: 81,
		name: 'Strozen',
	},
	{
		id: 82,
		name: 'Parleynet',
	},
	{
		id: 83,
		name: 'Exoplode',
	},
	{
		id: 84,
		name: 'Mantrix',
	},
	{
		id: 85,
		name: 'Plasmos',
	},
	{
		id: 86,
		name: 'Ronbert',
	},
	{
		id: 87,
		name: 'Xsports',
	},
	{
		id: 88,
		name: 'Kidgrease',
	},
	{
		id: 89,
		name: 'Inear',
	},
	{
		id: 90,
		name: 'Irack',
	},
	{
		id: 91,
		name: 'Duoflex',
	},
	{
		id: 92,
		name: 'Imaginart',
	},
	{
		id: 93,
		name: 'Poshome',
	},
	{
		id: 94,
		name: 'Avenetro',
	},
	{
		id: 95,
		name: 'Filodyne',
	},
	{
		id: 96,
		name: 'Nurplex',
	},
	{
		id: 97,
		name: 'Xurban',
	},
	{
		id: 98,
		name: 'Emoltra',
	},
	{
		id: 99,
		name: 'Pathways',
	},
	{
		id: 100,
		name: 'Bleendot',
	},
];
