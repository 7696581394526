import { styled } from 'styled-components';

export const StyledFormInputPassword = styled.div<{ $error: boolean }>`
	& {
		display: flex;
		margin-top: 16px;
		flex-direction: column;
		width: 400px;
		height: 72px;
		padding-bottom: 90px;

		label {
			color: ${({ theme }) => theme.fontColors.darkGrey};
			font-size: 13px;
			font-weight: 500;

			span {
				color: ${({ theme }) => theme.fontColors.accent};
			}
		}

		input {
			height: 42px;
			margin-top: 8px;
			padding: 16px;
			width: 100%;
			color: ${({ theme }) => theme.fontColors.darkGrey};
			font-size: 14px;
			border-radius: 8px;
			border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};

			&::placeholder {
				color: ${({ theme }) => theme.fontColors.lightGrey};
			}

			// Hide arrows for number inputs
			// Chrome, Safari, Edge, Opera
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			// Firefox
			&[type='number'] {
				-moz-appearance: textfield;
			}
		}
	}
`;

export const PasswordInputContainer = styled.div`
	position: relative;
`;

export const PasswordInput = styled.input`
	padding-right: 40px;
`;

export const ShowHideButton = styled.button<{ $show: boolean }>`
	position: absolute;
	right: 10px;
	top: 60%;
	transform: translateY(-50%);
	background: none;
	border: none;
	cursor: pointer;

	& > svg > path {
		width: 24px;
		height: 24px;
		fill: ${props =>
			props.$show ? props.theme.fontColors.accent : props.theme.fontColors.lightGrey};
	}

	& > svg:hover {
		& > path {
			fill: ${({ theme }) => theme.fontColors.accent};
		}
	}
`;

export const ForgotPasswordLink = styled.a`
	color: ${({ theme }) => theme.fontColors.accent} !important;
	display: inline-block;
	font-weight: 500;
	font-size: 13px;
	text-decoration: none;
	cursor: pointer;
`;
