import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import {
	isOriginatorInStandard,
	isUserParticipatingInStandardDomain,
	userHasRoles,
	userHasRolesAndParticipatesInDomain,
} from '@asd-stan/helpers/app-utils';
import { forms } from '@asd-stan/standard/domain/enums';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as SettingIcon } from '@asd-stan/ui-kit/assets/icons/dark-setting.svg';
import { ReactComponent as EditIcon } from '@asd-stan/ui-kit/assets/icons/edit.svg';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { BreadCrumbs } from '@components/bread-crumbs/bread-crumbs';
import { IconButton } from '@components/button/icon-button';
import { EmptyTableSearch } from '@components/content-table/empty-table-search';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import { TabPanel } from '@components/tabs/tab/tab-panel';
import { Tabs } from '@components/tabs/tabs';
import { UnderConstruction } from '@components/under-construction/under-construction';
import { Flex } from '@components/utility/flex';

import { ButtonGroup } from './button-group';
import { AttachmentsDraftSection } from './tabs-sections/attachments-section/attachments-draft-section';
import { SingleDraftPageTabSection } from './tabs-sections/single-standard-page-section/single-draft-page-tab-section';
import { StyledLoaderContainer } from './tabs-sections/single-standard-page-section/single-standard.styled';

import { ContentContainer } from '@components/utility/content-container.styled';

export const SingleDraftPage: React.FC = observer(() => {
	const { t } = useTranslation();
	const params = useParams();

	const navigate = useNavigate();
	const currentUserService = getCurrentUserService();
	const draftService = getDraftService();

	useEffect(() => {
		draftService.getDraftById(Number(params.id));
	}, [params.id, draftService]);

	const isEsOrDirector = userHasRoles(
		[SystemRole.ES, SystemRole.DIRECTOR],
		currentUserService.userRoles!
	);

	const tabLists = [
		{ tabKey: 'general', title: t('standard.singleStandard.general.title') },
		{
			tabKey: 'stageAndStatus',
			title: t('standard.singleStandard.stageAndStatus.title'),
		},
		{
			tabKey: 'priceHistory',
			title: t('standard.singleStandard.priceHistory.title'),
		},
		{ tabKey: 'attachments', title: t('standard.singleStandard.attachments.title') },
	];

	const getTabComponent = (key: string) => {
		if (key === 'general') {
			return <SingleDraftPageTabSection />;
		}

		if (key === 'attachments') {
			return <AttachmentsDraftSection draft={draftService.singleDraft} />;
		}

		if (key === 'priceHistory') {
			return <UnderConstruction />;
		}

		if (key === 'stageAndStatus') {
			return <UnderConstruction />;
		}
	};

	if (draftService.singleDraftNotFound) {
		return (
			<ContentContainer>
				<EmptyTableSearch emptySearchText={t('standard.standardList.emptyDraftSearchText')} />
			</ContentContainer>
		);
	}

	if (!draftService.singleDraft) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	if (
		userHasRoles([SystemRole.EXPERT], currentUserService.userRoles!) &&
		!isOriginatorInStandard(draftService.singleDraft, currentUserService.currentUser) &&
		!isUserParticipatingInStandardDomain(
			draftService.singleDraft!.generalData === null
				? []
				: [draftService.singleDraft!.generalData.domains],
			currentUserService.domainParticipations
		)
	) {
		return <Navigate to="/forbidden" replace />;
	}

	return (
		<>
			<ContentContainer>
				<BreadCrumbs />
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={`${
							draftService.singleDraft?.generalData?.form
								? forms.find(({ value }) => value === draftService.singleDraft?.generalData?.form)
										?.label ?? ''
								: ''
						} ${draftService.singleDraft?.generalData?.registrationNumber ?? ''} ${
							draftService.singleDraft?.generalData?.edition &&
							draftService.singleDraft?.generalData?.edition.length > 60
								? `${draftService.singleDraft?.generalData?.edition}`
								: draftService.singleDraft?.generalData?.edition ?? ''
						}`}
					/>
					<ButtonGroup>
						{isEsOrDirector ||
						isOriginatorInStandard(draftService.singleDraft, currentUserService.currentUser) ? (
							<IconButton disabled icon={<SettingIcon />} />
						) : null}
						{userHasRolesAndParticipatesInDomain(
							[SystemRole.DIRECTOR, SystemRole.ES],
							currentUserService.userRoles!,
							[SystemRole.MC, SystemRole.BOARD_MEMBER],
							currentUserService.domainParticipations
						) ||
						isOriginatorInStandard(draftService.singleDraft, currentUserService.currentUser) ? (
							<IconButton
								icon={<EditIcon />}
								onClick={() => navigate(`/standards/draft/edit/${params.id}`)}
							/>
						) : null}
					</ButtonGroup>
				</Flex>

				<Tabs tabLists={tabLists} isDraft>
					<Routes>
						{tabLists.map((tabList, index) => {
							if (tabList !== null) {
								return (
									<Route
										key={`${tabList.tabKey} ${index}`}
										path={tabList.tabKey}
										element={<TabPanel component={getTabComponent(tabList.tabKey)} />}
									/>
								);
							}
							return null;
						})}
					</Routes>
				</Tabs>
			</ContentContainer>
		</>
	);
});
