import { getCountryRepo } from '@asd-stan/user/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

import { Country } from './country.entity';

export class CountryService {
	private _countries: Country[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	private get _countryRepo() {
		return getCountryRepo();
	}

	public async getCountries() {
		const countries = await this._countryRepo.getCountries();

		runInAction(() => {
			this._countries = countries;
		});
	}

	public async getCountriesByCurrentCompany(id: number) {
		const countries = await this._countryRepo.getCountryByCompanyId(id);

		runInAction(() => {
			this._countries = countries;
		});
	}

	get countries(): Country[] {
		return this._countries;
	}
}
