import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { userHasDomainRoles, userHasRoles } from '@asd-stan/helpers/app-utils';

interface ProtectedRouteProps {
	redirectPath?: string;
	children?: React.ReactElement;
	restrictedRoles: string | string[];
}

export const ProtectedConfigRoute: React.FC<ProtectedRouteProps> = observer(
	({ redirectPath = '/forbidden', children, restrictedRoles }) => {
		const currentUserService = getCurrentUserService();

		let isRestricted = false;

		if (userHasRoles(restrictedRoles, currentUserService.userRoles!)) {
			isRestricted = !userHasDomainRoles(
				['WGS', 'WGC', 'Domain Secretary'],
				currentUserService.domainParticipations!
			);
		}

		if (isRestricted) {
			return <Navigate to={redirectPath} replace />;
		}

		return children ? children : <Outlet />;
	}
);
