import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as FontoIcon } from '@asd-stan/ui-kit/assets/icons/fonto-icon.svg';

import { StyledFontoButton } from './fonto-button.styled';

interface FontoButtonProps {
	editorId: number | null;
	title?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
}

export const FontoButton: React.FC<FontoButtonProps> = ({
	editorId,
	title,
	type = 'button',
	disabled,
}) => {
	const navigate = useNavigate();
	const params = useParams();
	const { accessToken }: Storage = localStorage;
	const fontoUrl: string = useMemo(
		() => `${process.env.REACT_APP_FONTO_URL!}?accessToken=${accessToken}&documentId=${editorId}`,
		[accessToken, editorId]
	)

	const onClick = () => {
		// navigate(`/standards/detailed/${params.id}/fonto`); // to disable button
		window.open(fontoUrl);
	};

	return (
		<StyledFontoButton $disabled={disabled}>
			<button type={type} title={title} onClick={onClick} disabled={disabled}>
				<FontoIcon />
			</button>
		</StyledFontoButton>
	);
};
