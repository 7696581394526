import React from 'react';

import { Tooltip } from '@asd-stan/ui-kit/components/tooltip/tooltip';

import { MarkedTextCell } from '@components/content-table/cells/marked-text-cell.styled';

interface RoleCellProps {
	roles: string[];
}

export const RoleCell: React.FC<RoleCellProps> = ({ roles }) => {
	const rolesString = roles.join(', ');

	return (
		<MarkedTextCell className="roles">
			{roles.slice(0, 2).map((role, i) => (
				<MarkedTextCell key={i}>{role}</MarkedTextCell>
			))}
			{roles.length > 2 ? (
				<span>
					<Tooltip text={rolesString} children={'...'} />
				</span>
			) : null}
		</MarkedTextCell>
	);
};
