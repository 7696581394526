import styled from 'styled-components';

export const FieldGrid = styled.div`
	display: grid;
	grid-row-gap: 16px;
	grid-column-gap: 24px;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	margin-top: 16px;
`;

export const StyledField = styled.div`
	line-height: 150%;

	.label {
		font-size: 11px;
		font-weight: 500;
		text-transform: uppercase;
		color: #abb3bb;
		margin-bottom: 4px;
	}

	.value {
		font-size: 13px;
		font-weight: 600;

		&.no-value {
			color: #abb3bb;
			font-weight: 400;
		}

		a {
			color: #0b93e3;
			text-decoration: underline;
		}
	}
`;
