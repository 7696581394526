import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { Page } from '@asd-stan/helpers/pagination-collection-controller';
import { Standard } from '@asd-stan/standard/domain/standard.entity';

import { DraftDetailed } from '../domain/draft-detailed.entity';
import { DraftCollectionFilter } from '../domain/draft.service';

import { DraftDTO } from './draft.dto';
import { makeDraftDetailedFromDraftDetailedDTO, makeDraftFromDraftDTO } from './draft.factory';

const CREATE_DRAFT = gql`
	mutation {
		createDraft {
			id
		}
	}
`;

const DRAFT_LIST = gql`
	query ($limit: Int!, $offset: Int!) {
		draftList(limit: $limit, offset: $offset) {
			id
			generalData {
				registrationNumber
				form
				edition
				cenWiNumber
			}
			titles {
				en
			}
		}
		draftCount
	}
`;

const SINGLE_DRAFT = gql`
	query ($id: Int!) {
		draft(id: $id) {
			id
			generalData {
				stanNumber
				registrationNumber
				form
				edition
				isRevision
				revision {
					id
					form
					registrationNumber
					localizedTitle
					edition
				}
				languages
				standardTypes {
					id
					name
				}
				pages
				domains {
					code
					name
				}
				isWorkingGroupNull
				workingGroups {
					code
					name
				}
				scope
				leader {
					id
					firstName
					lastName
					systemRoles
					picture {
						path
					}
				}
				cenWiNumber
			}
			titles {
				en
				de
				fr
			}
			originator {
				id
				isAsdOriginator
				isExistOriginator
				userId
				firstName
				lastName
				email
				phone
				company {
					id
					name
				}
				country {
					id
					name
				}
				positions {
					id
					name
				}
			}
			draft {
				id
				name
				path
				size
			}
			justification {
				purpose
				revisionJustification
				technicalChanges {
					title
					change
				}
				consideredStandards {
					id
					name
				}
				externalConsideredStandards
				knownPatentIssue
				patentFile {
					id
					name
					path
					size
				}
				patentReferences
			}
			additionalInformation {
				tags {
					value
				}
				classifications {
					id
					number
					name
				}
				icsCodes {
					code
					name
					description
				}
			}
			experts {
				experts {
					id
					firstName
					lastName
					picture {
						path
					}
					domains {
						code
						name
					}
					workingGroups {
						code
						name
					}
				}
				wgs {
					id
					firstName
					lastName
					picture {
						path
					}
				}
			}
			attachments {
				file {
					id
					name
					path
					size
					mimeType
				}
				type
				description
				createdAt
				createdBy {
					id
					firstName
					lastName
					picture {
						path
						name
					}
				}
			}
			enData {
				name
				publicationDate
				title
			}
			remarks {
				text
				remarkType
				createdBy {
					id
					firstName
					lastName
					picture {
						path
					}
				}
				createdAt
			}
		}
	}
`;

export class DraftRepo {
	async getDrafts(
		limit: number,
		offset: number,
		filter?: Partial<DraftCollectionFilter>
	): Promise<Page<Standard>> {
		const { data } = await stanWorksClient.query({
			query: DRAFT_LIST,
			variables: {
				limit: limit,
				offset: offset,
				filter: filter,
			},
		});

		const newData = data.draftList.map((draft: DraftDTO) => {
			return makeDraftFromDraftDTO(draft);
		});
		return { totalNumber: data.draftCount, data: newData };
	}

	async getStandardDraftsCount(): Promise<number> {
		try {
			const { data } = await stanWorksClient.query({
				query: DRAFT_LIST,
				variables: {
					limit: 20,
					offset: 0,
				},
			});

			return data.draftCount;
		} catch (e) {
			return 0;
		}
	}

	async getDraftList(limit: number, offset: number) {
		const { data } = await stanWorksClient.query({
			query: DRAFT_LIST,
			variables: {
				limit: limit,
				offset: offset,
			},
		});

		return data;
	}

	async getDraftById(id: number): Promise<DraftDetailed | null | string> {
		try {
			const { data, errors } = await stanWorksClient.query({
				query: SINGLE_DRAFT,
				variables: {
					id,
				},
			});
			if (errors && Array.isArray(errors) && errors[0].message === 'draft.notFound') {
				return '';
			}

			return makeDraftDetailedFromDraftDetailedDTO(data.draft);
		} catch (error) {
			return null;
		}
	}

	async createDraft() {
		const { data } = await stanWorksClient.mutate({
			mutation: CREATE_DRAFT,
		});

		return data.createDraft.id;
	}
}
