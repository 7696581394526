import styled from 'styled-components';

export const StyledMeetingList = styled.div`
	margin-top: 24px;

	th {
		cursor: default;
	}

	td {
		vertical-align: top;
	}

	.cell {
		font-weight: 400;
		white-space: normal;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		line-clamp: 5;
		-webkit-box-orient: vertical;

		&.title {
			font-weight: 600;
		}
	}
`;
