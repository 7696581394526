import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { Remark } from '@asd-stan/standard/domain/remark.entity';
import { StandardDetailedRemark } from '@asd-stan/standard/domain/standard-detailed.entity';
import { Standard } from '@asd-stan/standard/domain/standard.entity';
import { makeAutoObservable, runInAction } from 'mobx';

import { getDraftRepo } from '../infrastructure/getters';

import { DraftDetailed } from './draft-detailed.entity';

export interface DraftCollectionFilter {}

export class DraftService {
	singleDraft: DraftDetailed | null = null;
	singleDraftNotFound: boolean = false;
	drafts: PaginationCollectionController<Standard, DraftCollectionFilter>;
	draftsCount: number = 0;
	draftId: number | null = null;
	remarks: Array<Remark> | null = [];

	constructor() {
		this.drafts = new PaginationCollectionController<Standard, DraftCollectionFilter>(
			20,
			async (limit, offset, filter) => {
				this.singleDraft = null;
				return await this._draftRepo.getDrafts(limit, offset, filter);
			}
		);

		makeAutoObservable(this);
	}

	private get _draftRepo() {
		return getDraftRepo();
	}

	async getDraftsCount() {
		const count = await this._draftRepo.getStandardDraftsCount();

		runInAction(() => {
			this.draftsCount = count;
		});
	}

	async getDraftById(id: number) {
		const response = await this._draftRepo.getDraftById(id);

		runInAction(() => {
			if (typeof response === 'string') {
				this.singleDraftNotFound = true;
			} else {
				this.singleDraft = response;
			}
		});
	}

	async createDraft() {
		const id = await this._draftRepo.createDraft();

		runInAction(() => {
			this.draftId = id;
		});
	}

	addDraftRemark({ name, text, date, stageUpdate }: Remark) {
		if (this.singleDraft !== null && this.singleDraft.remarks) {
			this.singleDraft.remarks.push({ name, text, date, stageUpdate });
		}
		if (this.singleDraft && !this.singleDraft.remarks) {
			this.singleDraft.remarks = [{ name, text, date, stageUpdate }];
		}
		if (this.remarks !== null) {
			this.remarks.push({ name, text, date, stageUpdate });
		}
		if (!this.remarks) {
			this.remarks = [{ name, text, date, stageUpdate }];
		}
	}

	removeDraftRemark(index: number) {
		if (this.singleDraft !== null) {
			this.singleDraft.remarks.splice(index, 1);
		}
		if (this.remarks !== null) {
			this.remarks.splice(index, 1);
		}
	}

	updateDraftRemarks(remarks: Array<StandardDetailedRemark>) {
		this.remarks = remarks.map(remark => ({
			name: `${remark.createdBy?.firstName} ${remark.createdBy?.lastName}`,
			text: remark.text,
			date: remark.createdAt!,
			stageUpdate: remark.stageUpdate,
		}));
	}
}
