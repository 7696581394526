import { VoteListItem, VotingDecision } from '../domain/ballot.entity';

interface VoteListItemDto {
	id: number;
	createdBy: {
		firstName: string;
		lastName: string;
		picture: {
			path: string;
		};
	};
	updatedAt: string;
	organization: {
		name: string;
	};
	attachments: {
		id: number;
		createdAt: string;
		createdBy: {
			firstName: string;
			lastName: string;
		};
		file: {
			id: number;
			name: string;
			path: string;
			size: number;
		};
	}[];
	decisions: {
		id: number;
		question: {
			title: string;
			comment: string;
		};
		decision: 'approve' | 'abstain' | 'disapprove';
		comment: string;
	}[];
}

interface VoteListDto {
	voteList: VoteListItemDto[];
	voteCount: number;
}

export const mapVoteList = ({
	voteList,
	voteCount,
}: VoteListDto): {
	data: VoteListItem[];
	totalCount: number;
} => ({
	data: voteList.map(({ createdBy, updatedAt, id, organization, attachments, decisions }) => ({
		doneBy: {
			firstName: createdBy.firstName,
			lastName: createdBy.lastName,
		},
		doneAt: new Date(updatedAt),
		value: {
			id,
			organizationName: organization.name,
			attachments: attachments.map(({ id, createdAt, createdBy, file }) => ({
				doneAt: new Date(createdAt),
				doneBy: createdBy,
				value: {
					fileId: file.id,
					id,
					path: file.path,
					size: file.size,
					title: file.name,
				},
			})),
			decisions: decisions.map(({ comment, decision, question, id }) => ({
				id,
				question: {
					title: question.title,
					comment: question.comment,
				},
				decision: decision as VotingDecision,
				comment,
			})),
		},
	})),
	totalCount: voteCount,
});
