import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Justification as JustificationData } from '@asd-stan/standard/domain/standard-detailed.entity';

import {
	JustificationChangeContainer,
	JustificationChangeItem,
	JustificationChangeMainWrapper,
	JustificationHeader,
} from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/page-blocks/justification/justifiaction-changes/justification-changes.styled';
import {
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
} from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/single-standard.styled';

interface Props {
	data: JustificationData;
	standardName: string;
}

export const JustificationChanges: FC<Props> = ({ data, standardName }) => {
	const { t } = useTranslation();
	const renderChanges = () =>
		data.technicalChanges.map((change, index) => (
			<JustificationChangeContainer key={index}>
				<JustificationChangeItem>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.technicalChangeItemTitle')} {index + 1}
					</StandardDisplayFieldHeader>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.technicalChangeItemHeader')}
					</StandardDisplayFieldHeader>
					{change.title.length > 0 ? (
						<StandardDisplayFieldValue>{change.title}</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.general.technicalTitleAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.technicalChangeItemValue')}
					</StandardDisplayFieldHeader>
					{change.change.length > 0 ? (
						<StandardDisplayFieldValue>{change.change}</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.general.technicalChangeAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
				</JustificationChangeItem>
			</JustificationChangeContainer>
		));

	if (data.technicalChanges.length === 0) {
		return null;
	}

	return (
		<JustificationChangeMainWrapper>
			<JustificationHeader>
				{t('standard.singleStandardPage.justification.technicalChangesTitle')} {standardName}
			</JustificationHeader>
			{renderChanges()}
		</JustificationChangeMainWrapper>
	);
};
