import { styled } from 'styled-components';
import { scrollStyles } from '@components/utility/shared-styles';

export const StandardChangeContainer = styled.div` 
	background: #EDF2F3;
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-top: ${({ theme }) => theme.borders.divider};
	gap: 12px;
	max-height: 500px;
	overflow: hidden;
	overflow-y: auto;
	${scrollStyles};
	&.no-items {
			gap: 0;
	}
`
