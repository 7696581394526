import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/button/button';
import { ButtonClose } from '@components/button/button-close';
import { FormikValues, useFormikContext } from 'formik';

import { StyledReplaceContainer } from './replace-container.styled';

interface ReplaceContainerProps {
	standard: any;
}

export const ReplaceContainer: React.FC<ReplaceContainerProps> = ({ standard }) => {
	const { t } = useTranslation();
	const { setFieldValue, handleSubmit } = useFormikContext<FormikValues>();

	const label = t('standard.createNWP.general.replaceTitle', { label: standard && standard.label });

	const handleReplace = async () => {
		await setFieldValue('stanNumber', standard.value.stanNumber);
		await setFieldValue('registrationNumber', standard.value.registrationNumber);
		await setFieldValue('showReplaceStandard', false);
		handleSubmit();
	};

	return (
		<StyledReplaceContainer>
			<p
				dangerouslySetInnerHTML={{
					__html: label,
				}}
			/>
			<Button secondary title={t('standard.createNWP.buttons.replace')} onClick={handleReplace} />
			<ButtonClose
				onClick={() => {
					setFieldValue('showReplaceStandard', false);
				}}
			/>
		</StyledReplaceContainer>
	);
};
