import React, { ReactNode } from 'react';

import { StyledTabPanelContainer } from './tab-panel.styled';

interface TabPanelProps {
	component: ReactNode;
}

export const TabPanel: React.FC<TabPanelProps> = ({ component }: TabPanelProps) => {
	return (
		<>
			{component}
		</>
	);
};
