import { useState } from 'react';

import { Flex } from '@components/utility/flex';

import { ReactComponent as ArrowIcon } from './assets/arrrow.svg';
import { StyledFilterWrapper } from './filter-wrapper.styled';

export const FilterWrapper = ({
	children,
	title,
	optionsNumber,
}: {
	children: React.ReactNode;
	title: string;
	optionsNumber?: number;
}) => {
	const [opened, setOpened] = useState(true);

	return (
		<StyledFilterWrapper className={opened ? 'opened' : ''}>
			<Flex $align="center" $justify="space-between" className="head">
				<div className="title">
					{title} {optionsNumber !== undefined && <span>{optionsNumber}</span>}
				</div>
				<ArrowIcon className="open" onClick={() => setOpened(v => !v)} />
			</Flex>
			{opened && <div>{children}</div>}
		</StyledFilterWrapper>
	);
};
