export enum ChangeFieldName {
	scope = 'scope.en',
	titleEn = 'title.en',
	titleFr = 'title.fr',
	titleDe = 'title.de',
	purpose = 'purpose',
	revisionJustification = 'revisionJustification',
	justificationPurpose = 'justification.purpose',
	justificationTechnicalChangesCreate = 'justification.technicalChanges.create',
	justificationTechnicalChangesDelete = 'justification.technicalChanges.delete',
	justificationRevisionJustification = 'justification.revisionJustification',
	justificationTechnicalChangesChange = 'justification.technicalChanges.change',
	justificationTechnicalChangesTitle = 'justification.technicalChanges.title',
	patentReferences = 'patentReferences',
	attachments = 'attachments',
	file = 'file',
	enTitle = 'enTitle',
}

export enum HTMLParseFieldName {
	justificationExternalConsideredStandards = 'justification.externalConsideredStandards',
	externalConsideredStandards = 'externalConsideredStandards',
	justificationPatentReferences = 'justification.patentReferences',
	patentReferences = 'patentReferences',
}

export enum LinkFieldName {
	consideredStandards = 'justification.consideredStandards',
	revisionId = 'revisionId',
}

export const checkIfNeedToSplit = (property: string): boolean => {
	return !!Object.values(ChangeFieldName).find(prop => prop === property);
};

export const checkIfNeedToParseHTML = (property: string): boolean => {
	return !!Object.values(HTMLParseFieldName).find(prop => prop === property);
};

export const checkIfNeedToShowLink = (property: string): boolean => {
	return !!Object.values(LinkFieldName).find(prop => prop === property);
};
