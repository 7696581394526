import { CellContext, ColumnDef } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { userHasRoles } from '@asd-stan/helpers/app-utils';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { ReactComponent as ExportIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/export-icon.svg';
import { ReactComponent as InviteIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/invite-icon.svg';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { RoleCell } from '@asd-stan/user/components/user-list/user-table/role-cell/role-cell';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { User } from '@asd-stan/user/domain/user.entity';
import { getUserService } from '@asd-stan/user/infrastructure/getters';
import { Button } from '@components/button/button';
import { SecondaryCell } from '@components/content-table/cells/secondary-cell';
import { ContentTable, IdsFilter, allRows } from '@components/content-table/content-table';
import { TableCheckbox } from '@components/content-table/table-checkbox/table-checkbox';
import { TableControl } from '@components/content-table/table-control/table-control';
import { PageTitle } from '@components/page-title/page-title';

import { StyledUserList } from './user-list.styled';

import { ExportToasterBody } from '@asd-stan/standard/components/standard-list/standard-list.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';
import { StyledButtonGroup } from '@components/utility/button-group.styled';

const ExportUsers = observer(
	({ selectedUsersIds, title }: { selectedUsersIds: IdsFilter; title: string }) => {
		const { t } = useTranslation();
		const userService = getUserService();
		const toasterService = getToasterService();
		const [progress, setProgress] = useState(0);
		const fileNameRef = useRef(userService.getExportFileName(title));

		const fileName = fileNameRef.current;

		useEffect(() => {
			const abortController = new AbortController();
			const signal = abortController.signal;

			try {
				userService.exportUsers(
					selectedUsersIds,
					fileName,
					setProgress,
					() => toasterService.hideToast(),
					signal
				);
			} catch (err: any) {
				console.error(err);
			}

			return () => {
				abortController.abort();
			};
		}, []);

		return (
			<ExportToasterBody>
				<div style={{ height: 20, width: 20 }}>
					<CircularProgressbar
						value={progress}
						maxValue={100}
						styles={buildStyles({
							pathColor: '#0B93E3',
							trailColor: '#DAE4E8',
						})}
					/>
				</div>
				<p>
					<span className="file-name">{fileName}</span> {t('standard.standardList.isExporting')}
				</p>
				<span className="cancel" onClick={() => toasterService.hideToast()}>
					{t('standard.standardList.cancelExport')}
				</span>
			</ExportToasterBody>
		);
	}
);

export const UserList: React.FC = observer(() => {
	const [filtering, setFiltering] = useState<string>('');
	const userService = getUserService();
	const [selectedUsersIds, setSelectedUsersIds] = useState<IdsFilter>([]);
	const currentUserService = getCurrentUserService();
	const toasterService = getToasterService();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const getVisibleUsers = () => {
		let visibleUsers;

		if (!currentUserService.userRoles?.includes(SystemRole.ADMIN)) {
			visibleUsers = userService.users.data.filter(
				user => !user.systemRoles.includes(SystemRole.ADMIN)
			);
		}

		return visibleUsers || [...userService.users.data];
	};

	const columns: ColumnDef<User, string>[] = [
		{
			id: 'select',
			header: () => (
				<TableCheckbox
					{...{
						checked: selectedUsersIds === allRows,
						indeterminate: selectedUsersIds.length > 0,
						onChange: () => {
							if (selectedUsersIds === allRows) {
								setSelectedUsersIds([]);
								return;
							}
							if (selectedUsersIds.length === 0) {
								setSelectedUsersIds(allRows);
								return;
							}
							setSelectedUsersIds([]);
						},
					}}
				/>
			),
			cell: ({ row }: CellContext<User, string>) => {
				const id: number = row.original.id;
				const checked = selectedUsersIds === allRows || selectedUsersIds.includes(id);
				return (
					<TableCheckbox
						{...{
							checked,
							onChange: () => {
								setSelectedUsersIds(rows => {
									if (checked) {
										if (rows === allRows) {
											return [id];
										}
										return rows.filter(r => r !== id);
									}
									if (rows === allRows) {
										return rows;
									}
									return [...rows, id];
								});
							},
						}}
					/>
				);
			},
		},
		{
			header: t('user.userList.firstName'),
			accessorKey: 'firstName',
			cell: ({ row }: CellContext<User, string>) => <div>{row.getValue('firstName')}</div>,
		},
		{
			header: t('user.userList.lastName'),
			accessorKey: 'lastName',
			cell: ({ row }: CellContext<User, string>) => <div>{row.getValue('lastName')}</div>,
		},
		{
			header: t('user.userList.role'),
			accessorKey: 'systemRoles',
			cell: ({ row }: CellContext<User, string>) => (
				<RoleCell roles={row.getValue('systemRoles')} />
			),
		},
		{
			header: t('user.userList.email'),
			accessorKey: 'email',
			cell: ({ row }: CellContext<User, string>) => <SecondaryCell value={row.getValue('email')} />,
		},
		{
			header: t('user.userList.company'),
			accessorKey: 'company.name',
		},
		{
			header: t('user.userList.country'),
			accessorKey: 'country.name',
			cell: ({ row }: CellContext<User, string>) => (
				<SecondaryCell value={row.original.country?.name || ''} />
			),
		},
	];

	useEffect(() => {
		userService.users.resetFilter();
	}, []);

	useEffect(() => {
		userService.users.loadCurrentPage();
	}, [userService.users.currentPage]);

	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		userService.users.setPageSize(parseInt(e.target.value));
	};

	const goToInviteUsers = () => {
		navigate('invite-users');
	};

	return (
		<StyledUserList>
			<ContentContainer>
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={t('user.userList.title')}
						count={userService.users.totalNumber}
						countName={t('user.userList.count')}
					/>
					<StyledButtonGroup>
						<Button
							secondary
							icon={<ExportIcon />}
							disabled={!userService.users.data.length || toasterService.isExporting}
							title={t('user.userList.buttons.export')}
							onClick={() => {
								toasterService.showToast(
									ToasterStatus.export,
									<ExportUsers selectedUsersIds={selectedUsersIds} title="Users" />
								);
							}}
						/>
						{userHasRoles(
							[SystemRole.ADMIN, SystemRole.ES, SystemRole.DIRECTOR],
							currentUserService.userRoles!
						) && (
							<Button
								icon={<InviteIcon />}
								title={t('user.userList.buttons.invite')}
								onClick={goToInviteUsers}
							/>
						)}
					</StyledButtonGroup>
				</Flex>
				<TableControl
					paginator={userService.users}
					pageSize={userService.users.pageSize}
					onPageSizeChange={onPageSizeChange}
					minSearchLength={2}
					hideFilter
					onFilterOrSearchChange={() => setSelectedUsersIds([])}
				/>
				<ContentTable
					tableData={getVisibleUsers()}
					columns={columns}
					filtering={filtering}
					setFiltering={setFiltering}
					paginator={userService.users}
					emptySearchText={t('user.userList.emptySearchText')}
					onRowClick={({ original }) => navigate(`/user-list/${original.id}`)}
				/>
			</ContentContainer>
		</StyledUserList>
	);
});
