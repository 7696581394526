import moment from 'moment';

import { UpdateStageForm } from '../domain/stage-update.entity';

export interface UpdateStageDto {
	comment: string;
	stages: string[];
	targetDate: string | null;
	notify: {
		users: number[];
		onScreen: boolean;
		email: boolean;
	};
	publicationData: null | {
		source: 'draft' | 'fonto';
		name: string;
		publicationDate: string;
		abstract: string;
	};
}

const dateFormat = 'YYYY-MM-DD';
const dateFomatWithoutMonth = 'YYYY-MM';

export const mapUpdateStageDto = (
	{ comment, publicationData, stages, targetDate, notify }: UpdateStageForm,
	ableToPublish: boolean
): UpdateStageDto => {
	return {
		comment,
		stages: [...stages.newStages, ...stages.activeStages],
		targetDate: targetDate ? moment(targetDate).format(dateFormat) : null,
		notify: {
			...notify,
			users: notify.users.map(({ id }) => id),
		},
		publicationData: ableToPublish
			? {
					...publicationData,
					publicationDate: moment(publicationData.publicationDate).format(dateFomatWithoutMonth),
			  }
			: null,
	};
};
