import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { BallotForm as BallotFormType, BallotStatus } from '@asd-stan/ballot/domain/ballot.entity';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { BreadCrumbs } from '@components/bread-crumbs/bread-crumbs';
import { Button } from '@components/button/button';
import { checkIfFilesBeingUploaded } from '@components/file-dropzone/file-dropzone';
import { PageTitle } from '@components/page-title/page-title';
import { Flex } from '@components/utility/flex';
import { Form, Formik, useFormikContext } from 'formik';

import { StyledBallotForm } from './ballot-form.styled';
import { BallotAttachments } from './forms/ballot-attachments';
import { BallotResult } from './forms/ballot-result';
import { General } from './forms/general';
import { Questions } from './forms/questions';
import { createBallotValidationSchema } from './validationSchema';

import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

export const SubmitButton = observer(
	({ title, submitStatus }: { title: string; submitStatus: BallotStatus }) => {
		const { t } = useTranslation();
		const toasterService = getToasterService();
		const { values, validateForm, setFieldValue, handleSubmit, isSubmitting } =
			useFormikContext<BallotFormType>();

		const disabled = isSubmitting || checkIfFilesBeingUploaded(values.attachments);

		return (
			<Button
				disabled={disabled}
				title={title}
				onClick={async () => {
					validateForm().then(errors => {
						if (errors && !(Object.keys(errors).length === 0)) {
							toasterService.showToast(
								ToasterStatus.error,
								t('ballot.createBallot.validationErrorToast')
							);
						}
					});
					setFieldValue('status', submitStatus);
					handleSubmit();
				}}
			/>
		);
	}
);

const defaultValues: BallotFormType = {
	title: '',
	description: '',
	standard: null,
	openingDate: null,
	closingDate: null,
	selectedVoters: [],
	result: null,
	resultComment: '',
	attachments: [],
	questions: [{ id: null, localId: uuid(), title: '', comment: '' }],
	status: BallotStatus.Pending,
};

export const BallotForm = observer(
	({
		title,
		onSubmit: handleSubmit,
		initialValues,
		actions,
	}: {
		title: string;
		initialValues?: BallotFormType;
		onSubmit(values: BallotFormType): void;
		actions: React.ReactNode;
	}) => {
		return (
			<Formik
				initialValues={initialValues || defaultValues}
				onSubmit={handleSubmit}
				validationSchema={createBallotValidationSchema}
				enableReinitialize>
				<StyledBallotForm>
					<ContentContainer>
						<BreadCrumbs />
						<Flex $justify="space-between" $align="center">
							<PageTitle title={title} />
							<StyledButtonGroup>{actions}</StyledButtonGroup>
						</Flex>
						<Form>
							<General />
							<BallotResult />
							<BallotAttachments />
							<Questions />
						</Form>
					</ContentContainer>
				</StyledBallotForm>
			</Formik>
		);
	}
);
