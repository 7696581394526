import React from 'react';

import { StyledLoader } from '@asd-stan/ui-kit/components/loader/loader.styled';

interface LoaderProps {
	width?: number;
	height?: number;
}

export const Loader: React.FC<LoaderProps> = ({ width, height }) => {
	return (
		<StyledLoader $width={width} $height={height} className="loader">
			<div className="loader-spinner" />
		</StyledLoader>
	);
};
