import '@asd-stan/domain/infrastructure/constants';
import { injector } from '@asd-stan/injector/injector';

import { BallotRepo } from '../api/ballot.repo';
import { BallotService } from '../domain/ballot.service';

import { BALLOT_REPO, BALLOT_SERVICE } from './constants';

injector.set(BALLOT_REPO, new BallotRepo());
injector.set(BALLOT_SERVICE, new BallotService());
