import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generatePlaceholder, getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { ReactComponent as Copy } from '@assets/icons/copy-icon.svg';
import { Button } from '@components/button/button';
import { ErrorMessage, FieldProps } from 'formik';

import {
	CopyButton,
	FormButtons,
	FormContainer,
	FormInput,
	InputContainer,
	StyledFormInputPopup,
} from './form-input-popup.styled';

import { StyledFieldErrorMessage } from '@components/utility/field-error-message.styled';

interface InputPopupProps {
	placeholder?: string;
	mandatory?: boolean;
	errors: string;
	title: string;
	name: string;
	fullWidth?: boolean;
	showError?: boolean;
	disabled?: boolean;
	showCheckbox?: boolean;
	maxLength?: number;
	setTouchedOnBlur?: boolean;
	useSubmitOnBlur?: boolean;
	disableMaxLength?: boolean;
}

export const FormInputPopup: React.FC<InputPopupProps & FieldProps> = ({
	title,
	placeholder,
	mandatory,
	field,
	form,
	fullWidth,
	showError,
	disabled,
	showCheckbox = false,
	maxLength,
	setTouchedOnBlur = false,
	useSubmitOnBlur = false,
	disableMaxLength = false,
}) => {
	const { t } = useTranslation();

	const toasterService = getToasterService();

	const divRef = useRef<HTMLDivElement>(null);
	const divElement = divRef.current;

	if (divElement && !disabled) {
		divElement.setAttribute('contentEditable', 'plaintext-only');
	}

	const [text, setText] = useState<string>('');
	const [textError, setTextError] = useState<boolean>(false);
	const [divRange, setDivRange] = useState<Range | null>(null);

	const [link, setLink] = useState<string>('');
	const [linkError, setLinkError] = useState<boolean>(false);
	const [showForm, setShowForm] = useState<boolean>(false);

	const linkRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

	useEffect(() => {
		if (field.value) {
			if (divRef.current) {
				divRef.current.innerHTML = field.value;
			}
		}
	}, []);

	const setCaretPosition = (el: Node, pos: number) => {
		// Convert NodeList to an array
		const childNodes = Array.from(el.childNodes);
		// Loop through all child nodes
		for (let node of childNodes) {
			if (node.nodeType === Node.TEXT_NODE) {
				if (node.textContent!.length >= pos) {
					// finally add our range
					const range = document.createRange();
					const sel = window.getSelection();
					range.setStart(node, pos);
					range.collapse(true);
					sel?.removeAllRanges();
					sel?.addRange(range);
					return -1; // done
				} else {
					pos -= node.textContent!.length;
				}
			} else {
				pos = setCaretPosition(node, pos);
				if (pos === -1) {
					return -1; // no need to finish the for loop
				}
			}
		}
		return pos; // needed because of recursion stuff
	};

	const nodeWalk = (node: Node, func: (node: Node) => void): void => {
		func(node);
		for (let child = node.firstChild; child; child = child.nextSibling) {
			nodeWalk(child, func);
		}
	};

	const getCaretPosition = (elem: Node) => {
		const selection = window.getSelection();
		let totalLength = [0, 0];
		if (selection && selection.anchorNode === elem)
			totalLength = [selection.anchorOffset, selection.focusOffset];
		else {
			const nodesToFind = [selection!.anchorNode, selection!.focusNode];

			if (!elem.contains(nodesToFind[0]) || !elem.contains(nodesToFind[1])) return undefined;
			else {
				const found = [false, false];
				let i;

				nodeWalk(elem, function (node) {
					for (i = 0; i < 2; i++) {
						if (node === nodesToFind[i]) {
							found[i] = true;
							if (found[i === 0 ? 1 : 0])
								// If we found the other node we were looking for, we can stop searching.
								return false; // Stop the nodeWalk loop
						}
					}

					if (node.textContent && !node.firstChild) {
						for (i = 0; i < 2; i++) {
							if (!found[i]) totalLength[i] += node.textContent.length;
						}
					}
				});

				totalLength[0] += selection!.anchorOffset;
				totalLength[1] += selection!.focusOffset;
			}
		}
		if (totalLength[0] <= totalLength[1]) return totalLength;
		return [totalLength[1], totalLength[0]];
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTextError(false);
		setText(event.target.value);
	};

	const handleChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLinkError(false);
		setLink(event.target.value);
	};

	const handleChange = (event: React.ChangeEvent<HTMLDivElement>) => {
		form.setFieldValue(field.name, event.currentTarget.innerHTML);
		form.setFieldValue(`${field.name}WH`, event.currentTarget.innerText);
		const currentPosMain = getCaretPosition(divRef.current!);

		if (currentPosMain !== undefined) {
			const node = event.currentTarget;

			setCaretPosition(node, currentPosMain[0]);
		}

		if (
			event.nativeEvent.type === 'input' &&
			(event.currentTarget.innerText.length === 0 ||
				event.currentTarget.innerHTML === '<br><span></span>') &&
			(event.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
		) {
			form.setFieldValue(field.name, '');
			form.setFieldValue(`${field.name}WH`, '');
			event.currentTarget.innerHTML = ''; //TODO: need put empty string to display placeholder
		}

		setShowForm(false);

		if (event.target.innerHTML.length === 0) {
			form.setFieldValue(field.name, undefined);
			form.setFieldValue(`${field.name}WH`, undefined);
		}
	};

	const handleInputBlur = () => {
		// form.setFieldTouched(field.name);
		setText('');
		setShowForm(false);
	};

	const handleMouseUp = async () => {
		if (!link && !text) {
			toasterService.showToast(
				ToasterStatus.error,
				t('standard.createNWP.toaster.incorrectFields')
			);
			setTextError(true);
			return setLinkError(true);
		}
		if (!link) {
			toasterService.showToast(
				ToasterStatus.error,
				t('standard.createNWP.toaster.incorrectFields')
			);
			return setLinkError(true);
		}
		if (!text) {
			toasterService.showToast(
				ToasterStatus.error,
				t('standard.createNWP.toaster.incorrectFields')
			);
			return setTextError(true);
		}
		if (!divRange) {
			return;
		}
		if (!linkRegex.test(link)) {
			toasterService.showToast(
				ToasterStatus.error,
				t('standard.createNWP.toaster.incorrectFields')
			);
			return setLinkError(true);
		}

		const linkHTML = `<a href="${
			link.startsWith('https://') ? link : `https://${link}`
		}" target="_blank">${text}</a>`;
		const linkNode = document.createElement('span');
		linkNode.innerHTML = linkHTML;
		divRange.deleteContents();
		divRange.insertNode(linkNode);

		setShowForm(false);
		setLink('');
		setLinkError(false);
		setText('');
		setTextError(false);
		await form.setFieldValue(field.name, divRef.current?.innerHTML);
		await form.setFieldValue(`${field.name}WH`, divRef.current?.innerText);
		if (useSubmitOnBlur) {
			if (disableMaxLength) {
				return form.handleSubmit();
			}
			if (maxLength && onlyText.body.textContent && onlyText.body.textContent.length > maxLength) {
				return;
			}
			form.handleSubmit();
		}
	};

	const handleLinkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const selection = window.getSelection();
		const selectedText = selection?.toString();
		const target = e.target as HTMLAnchorElement;
		const link = target.getAttribute('href');

		if (link && selectedText && selection && selection.rangeCount > 0) {
			setLink(link);
			setText(selectedText);
			setDivRange(selection.getRangeAt(0));
		}
	};

	const handleSelectionChange = () => {
		const selection = window.getSelection();
		const selectedText = selection?.toString();
		if (selectedText && selection && selection.rangeCount > 0) {
			setText(selectedText);
			setDivRange(selection.getRangeAt(0));
		}
	};

	const handleShowForm = () => {
		if (!text) {
			return setShowForm(false);
		}
		setShowForm(true);
	};

	const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
		setText('');
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (useSubmitOnBlur) {
			if (disableMaxLength) {
				return await form.handleSubmit();
			}
			if (maxLength && onlyText.body.textContent && onlyText.body.textContent.length > maxLength) {
				return;
			}
			await form.handleSubmit();
		}
	};
	const onlyText = new DOMParser().parseFromString(field.value, 'text/html');

	return (
		<StyledFormInputPopup
			$error={
				!!(
					getValueByFieldName(form.touched, field.name) &&
					getValueByFieldName(form.errors, field.name)
				)
			}
			$fullWidth={fullWidth}
			$showCheckbox={showCheckbox}
			$disabled={disabled}>
			<label title={title} className={disabled ? 'disabled' : ''}>
				{title}
				{mandatory && <span> *</span>}
			</label>
			<FormContainer $visible={showForm}>
				<FormInput
					$error={textError}
					type="text"
					placeholder={t('standard.createNWP.buttons.linkNameTitle')}
					value={text}
					onChange={handleChangeName}
				/>
				<FormInput
					$error={linkError}
					type="text"
					placeholder={t('standard.createNWP.buttons.linkTitle')}
					value={link}
					onChange={handleChangeLink}
				/>
				<FormButtons>
					<Button
						secondary
						title={t('standard.createNWP.buttons.cancel')}
						onClick={() => {
							setLink('');
							setLinkError(false);
							setText('');
							setTextError(false);
							setShowForm(false);
						}}
					/>
					<Button title={t('standard.createNWP.buttons.ok')} onClick={handleMouseUp} />
				</FormButtons>
			</FormContainer>
			<InputContainer>
				<div
					id="input-popup"
					aria-disabled={disabled}
					ref={divRef}
					onInput={handleChange}
					className="input-container"
					contentEditable={!disabled}
					data-placeholder={generatePlaceholder(title, placeholder)}
					{...field}
					onBlur={handleBlur}
					onFocus={handleInputBlur}
					onClick={handleLinkClick}
					onMouseUp={handleSelectionChange}
					onKeyUp={handleKeyUp}
				/>
				{!disabled && (
					<CopyButton type="button" onClick={handleShowForm} $enabled={text.length > 0}>
						<Copy />
					</CopyButton>
				)}
			</InputContainer>
			{maxLength &&
			onlyText.body.textContent !== null &&
			onlyText.body.textContent.length > maxLength ? (
				<div className="error-message">
					{onlyText.body.textContent.length} / {maxLength}
				</div>
			) : null}
			{showError ? <ErrorMessage name={field.name} component={StyledFieldErrorMessage} /> : null}
		</StyledFormInputPopup>
	);
};
