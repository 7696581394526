import styled from 'styled-components';
import {scrollStyles} from '@components/utility/shared-styles';

export const StyledSidebar = styled.nav`
  & {
    overflow-x: hidden;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    width: 297px;
    background-color: ${({theme}) => theme.bgColors.sidebar};

    & li {
      cursor: pointer;
    }

    & ul {
      margin-top: 36px;
      color: ${({theme}) => theme.fontColors.white};
    }

    ${scrollStyles}
    .menu-primary-enter {
      position: absolute;
      transform: translateX(-110%);
      overflow-y: hidden;
    }

    .menu-primary-enter-active {
      transform: translateX(0%);
      transition: all 400ms ease;
      width: 93%;
    }

    .menu-primary-exit {
      position: absolute;
    }

    .menu-primary-exit-active {
      transform: translateX(-110%);
      transition: all 400ms ease;

      width: 93%;
    }

    .menu-secondary-enter {
      position: absolute;
      top: 100px;
      transform: translateX(110%);
      z-index: 10;
      width: 93%;
    }

    .menu-secondary-enter-active {
      transform: translateX(0%);
      transition: all 400ms ease;
    }

    .menu-secondary-exit {

    }

    .menu-secondary-exit-active {
      transform: translateX(110%);
      transition: all 400ms ease;
    }
  }
`;
