import { IdsFilter, allRows } from '@components/content-table/content-table';

import { downloadFileByUrl } from './download-file-by-url';

export interface ExportStatus {
	progress: number;
	completed: boolean;
}

export class ExportController<T extends { ids?: null | number[] }> {
	constructor(
		private init: (filter: T) => Promise<string>,
		private cancel: (id: string) => Promise<void>,
		private checkStatus: (id: string) => Promise<ExportStatus>,
		private getFileLink: (id: string) => Promise<string>
	) {}

	async export(
		ids: IdsFilter,
		filter: T,
		fileName: string,
		handleProgressUpdate: (nextProgress: number) => void,
		handleDownloaded: () => void,
		signal: AbortSignal
	) {
		let timeout: null | NodeJS.Timeout = null;
		let id: null | string = null;
		let created = false;

		signal.onabort = () => {
			if (timeout) {
				clearTimeout(timeout);
			}
			if (id && !created) {
				this.cancel(id);
			}
		};

		const mergedFilter = { ...filter, ids: ids === allRows ? undefined : ids };
		if (signal.aborted) {
			return;
		}
		id = await this.init(mergedFilter);
		if (signal.aborted) {
			this.cancel(id);
			return;
		}
		let progress = 0;
		const retry = () => {
			timeout = setTimeout(async () => {
				timeout = null;
				if (!id) {
					return;
				}
				if (signal.aborted) {
					this.cancel(id);
					return;
				}
				const { completed, progress: nextProgress } = await this.checkStatus(id);
				if (signal.aborted) {
					if (completed) {
						return;
					}
					this.cancel(id);
					return;
				}
				if (nextProgress > progress) {
					progress = nextProgress;
					handleProgressUpdate(nextProgress);
				}
				if (completed) {
					created = true;
					const fileUrl = await this.getFileLink(id);
					if (signal.aborted) {
						return;
					}
					await downloadFileByUrl(fileUrl, fileName);
					handleDownloaded();
				} else {
					retry();
				}
			}, 30000);
		};

		retry();
	}
}
