import { scrollStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const StageSelectorTitle = styled.div`
	display: block;
	width: 100%;
	color: #abb3bb;
	font-size: 16px;
	margin: 12px 0;
`;

export const StagePickerContainer = styled.div`
	border: solid 1px #dae4e8;
	border-radius: 8px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 300px;
	max-width: 460px;
	overflow-y: scroll;
	align-self: stretch;
	${scrollStyles};

	&::-webkit-scrollbar {
		border-radius: 10px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.bgColors.scrollTrack};
		margin: 4px 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.fontColors.accent};
		border-radius: 8px;
	}
`;

export const StageItem = styled.div`
	cursor: pointer;
	border: solid 1px #dae4e8;
	border-radius: 4px;
	padding: 12px 16px;
	&:hover:not(.disabled),
	&.active {
		border-color: #0b93e3;
		background-color: rgba(11, 147, 227, 0.08);

		.optionName {
			color: #525259;
		}
	}

	&.disabled {
		background: #f0f4f6;
		cursor: default;

		label {
			cursor: default;
		}
	}

	.optionName {
		color: #abb3bb;
	}

	.disabledOptionMessage {
		color: #ea3e53;
		font-style: italic;
		font-size: 10px;
		margin-top: 4px;
	}
`;
