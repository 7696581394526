'use client';

import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from './assets/logo.svg';
import { StyledEmailSent } from './init-reset-password.styled';

export const ResendPasswordResetEmail = ({
	onEmailResend: handleEmailResend,
	emailSentTo,
}: {
	onEmailResend(): Promise<void>;
	emailSentTo: string;
}) => {
	const { t } = useTranslation();
	const [secondsToResend, setSecondsToResend] = useState<number>(60);

	const resetTimer = () => setSecondsToResend(60);

	const activateTimer = () => {
		const interval = setInterval(() => {
			setSecondsToResend(s => {
				if (!s) {
					clearInterval(interval);
					return 0;
				}
				return s - 1;
			});
		}, 1000);

		return interval;
	};

	const sendCode = async () => {
		if (!handleEmailResend) {
			return;
		}
		resetTimer();
		await handleEmailResend();
		activateTimer();
	};

	useEffect(() => {
		if (!handleEmailResend) {
			return;
		}
		const interval = activateTimer();
		return () => clearInterval(interval);
	}, []);

	return (
		<StyledEmailSent>
			<div className="body">
				<Logo />
				<h6 className="title">{t('auth.resetPassword.emailSentTitle')}</h6>
				<p>
					<Trans i18nKey="auth.resetPassword.emailSentParagraph" values={{ emailSentTo }} />
				</p>
				{secondsToResend === 0 ? (
					<span className="resend" onClick={sendCode}>
						{t('auth.resetPassword.resend')}
					</span>
				) : (
					<div className="resend-timer">
						{t('auth.resetPassword.resendIn')} {secondsToResend === 60 ? '01' : '00'}:
						{String(secondsToResend === 60 ? 0 : secondsToResend).padStart(2, '0')}
					</div>
				)}
			</div>
		</StyledEmailSent>
	);
};
