import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';

import { mapWGMembers } from './wg-member.mapper';

const WG_MEMBERS_BY_CODE = gql`
	query ($code: String!, $limit: Int!, $offset: Int!) {
		workingGroupParticipationsByCode(code: $code, limit: $limit, offset: $offset) {
			user {
				id
				firstName
				lastName
				email
				company {
					name
				}
				positions {
					name
				}
			}
			roles
		}
		workingGroupParticipationsCountByCode(code: $code)
	}
`;

export class WGRepo {
	async getWGMembersrByCode(code: string, limit: number, offset: number) {
		const { data } = await stanWorksClient.query({
			query: WG_MEMBERS_BY_CODE,
			variables: {
				code,
				limit,
				offset,
			},
		});

		return mapWGMembers(data);
	}
}
