import { styled } from 'styled-components';

export const RemarkContainerStyled = styled.div`
	width: 100%;
	margin: 12px auto;

	img {
		width: 32px;
		height: 32px;
	}

	h2 {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		margin-left: 8px;
		margin-right: 12px;
	}

	.containerHeader {
		color: ${({ theme }) => theme.fontColors.lightGrey};

		h2 {
			white-space: nowrap;
		}

		> div {
			width: 100%;

			span {
				width: -webkit-fill-available;
				display: flex;
				justify-content: flex-end;
			}
		}

		> div > div {
			flex-grow: 1;
			flex-shrink: 0;
			text-align: end;
		}
	}

	p {
		text-align: center;
		color: ${({ theme }) => theme.fontColors.lightGrey};

		b {
			font-weight: 400;
			color: ${({ theme }) => theme.fontColors.accent};
		}
	}

	.stageUpdate {
		font-size: 13px;
		color: #0b93e3;
		line-height: 150%;
		font-weight: 600;
		padding-left: 40px;
	}

	.text {
		padding-left: 40px;
		color: ${({ theme }) => theme.fontColors.textGrey};
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
`;
