import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSelect, Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { FormSelectCreatable } from '@asd-stan/ui-kit/components/form-select/form-select-creatable';
import {
	getCompanyService,
	getCountryService,
	getPositionService,
} from '@asd-stan/user/infrastructure/getters';
import { Field, FormikValues, useFormikContext } from 'formik';

export const Company = observer(
	({ ableToEditIfNotMe, isMe }: { ableToEditIfNotMe: boolean; isMe: boolean }) => {
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [loadingCompanyOptions, setLoadingCompanyOptions] = useState<boolean>(false);
		const [loadingCountryOptions, setLoadingCountryOptions] = useState<boolean>(false);
		const [loadingPositionsOptions, setLoadingPositionsOptions] = useState<boolean>(false);

		const { values, setFieldValue, setFieldError, setFieldTouched } =
			useFormikContext<FormikValues>();
		const { t } = useTranslation();

		const companyService = getCompanyService();
		const positionService = getPositionService();
		const countryService = getCountryService();

		const companyOptions = companyService.companies.map(company => {
			return { label: company.name, value: company.id };
		});

		const positionOptions = positionService.positions.map(position => {
			return { label: position.name, value: position.id };
		});

		const countryOptions = countryService.countries.map(country => {
			return { label: country.name, value: country.id };
		});

		useEffect(() => {
			const loadCompany = async () => {
				setLoadingCompanyOptions(true);
				await companyService.getCompanies();
				setLoadingCompanyOptions(false);
			};

			const loadPositions = async () => {
				setLoadingPositionsOptions(true);
				await positionService.getPositions();
				setLoadingPositionsOptions(false);
			};

			loadCompany();
			loadPositions();
		}, [companyService, positionService]);

		useEffect(() => {
			if (!values.company) {
				return;
			}

			const updateCountry = async () => {
				if (values.company.value) {
					setLoadingCountryOptions(true);
					await countryService.getCountriesByCurrentCompany(parseInt(values.company.value));
					setLoadingCountryOptions(false);
				}
			};

			updateCountry();
		}, [countryService, values.company]);

		const onCompanyChange = (name: string, option: Option) => {
			setFieldValue(name, option);
			setFieldTouched(name, false);
			setFieldValue('country', { label: '', value: '' });
		};

		const handleCreateNewPosition = async (value: string) => {
			if (value.length > 255) {
				setFieldError('position', t('user.userInvite.fieldErrors.length', { number: '1-255' }));
				return;
			}

			setIsLoading(true);

			const newPosition = await positionService.createPosition(value);

			setFieldValue('position', [
				...values.position,
				{ label: newPosition.name, value: newPosition.id.toString() },
			]);

			setIsLoading(false);
		};

		return (
			<>
				<Field
					component={FormSelect}
					name="company"
					title={t('user.userInvite.company')}
					options={companyOptions}
					onChange={onCompanyChange}
					showError
					isLoading={loadingCompanyOptions}
					disabled={!isMe && !ableToEditIfNotMe}
				/>
				<Field
					key={`${values.company && values.company.value}`}
					component={FormSelect}
					name="country"
					title={t('user.userInvite.country')}
					options={countryOptions}
					disabled={!values.company || values.company.label === '' || (!isMe && !ableToEditIfNotMe)}
					showError
					isLoading={loadingCountryOptions}
				/>
				<Field
					component={FormSelectCreatable}
					name="positions"
					title={t('user.userInvite.position')}
					options={positionOptions}
					onCreateOption={handleCreateNewPosition}
					isMulti
					fullWidth
					showError
					isLoading={isLoading || loadingPositionsOptions}
					disabled={!isMe && !ableToEditIfNotMe}
				/>
			</>
		);
	}
);
