import styled from 'styled-components';

export const ButtonGroup = styled.div`
	display: flex;
	justify-content: end;
	width: 250px;

	& > div {
		margin-left: 16px;
	}
`;
