import styled from 'styled-components';

export const ContentContainer = styled.div`
	padding: 0 30px 30px 30px;
	height: 100%;
	margin-left: 297px;

	.description {
		margin-top: 6px;
		font-weight: 500;
		color: ${props => props.theme.fontColors.lightGrey};

		& > span {
			font-weight: 600;
		}
	}
`;
