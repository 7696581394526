import { makeAutoObservable } from 'mobx';

interface CompanyArgs {
	id: number;
	name: string;
}

export class Company {
	private _id: number;
	private _name: string;

	constructor(args: CompanyArgs) {
		makeAutoObservable(this);

		this._id = args.id;
		this._name = args.name;
	}

	// getters

	get id() {
		return this._id;
	}
	get name() {
		return this._name;
	}

	// getters end
}
