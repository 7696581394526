import React, { ReactNode } from 'react';

import { StyledIconButton } from './button.styled';

interface IconButtonProps {
	onClick?: () => void;
	icon: ReactNode;
	disabled?: boolean;
}
export const IconButton: React.FC<IconButtonProps> = ({ onClick, icon, disabled }) => {
	return (
		<StyledIconButton>
			<button type="button" onClick={onClick} disabled={disabled}>
				{icon}
			</button>
		</StyledIconButton>
	);
};
