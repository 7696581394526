import React from 'react';

import horizontalLoading from '@asd-stan/ui-kit/assets/horizontal-loading.gif';
import loading from '@asd-stan/ui-kit/assets/loading.gif';

interface LoadingProps {
	width?: string | number;
	height?: string | number;
	horizontal?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({
	width = 80,
	height = 80,
	horizontal = false,
}) => {
	return (
		<img
			src={horizontal ? horizontalLoading : loading}
			alt="Loading"
			width={width}
			height={height}
		/>
	);
};
