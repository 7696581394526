import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ignoredErrors } from '@asd-stan/config/ignored-errors';
import { checkExpire } from '@asd-stan/helpers/app-utils';
import i18n from '@asd-stan/i18n/i18n';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';

const errorLink = onError(({ graphQLErrors, response }) => {
	const toasterService = getToasterService();

	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, extensions }) => {
			const data = extensions.data as any;
			var key = '';
			for (const value in data) {
				key = data[value];
			}

			for (const key in ignoredErrors) {
				if (key !== message) {
					const translation = i18n.t(`api.errors.${message}`, { key: key });
					const displayMessage = translation.includes(`api.errors.${message}`)
						? message
						: translation;

					toasterService.showToast(ToasterStatus.error, displayMessage);
				}
			}
		});
	}
});

export const link = from([
	errorLink,
	new HttpLink({
		uri: process.env.REACT_APP_BASE_URL,
	}),
]);

const authLink = setContext(async (_, { headers }) => {
	await checkExpire();
	const { accessToken }: Storage = localStorage;

	return {
		headers: {
			...headers,
			authorization: accessToken && `Bearer ${accessToken}`,
		},
	};
});

export const stanWorksClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: authLink.concat(link),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
		mutate: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	},
});
