import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
import { Participation } from '@asd-stan/current-user/domain/current-user.service';
import { RemarkContainer } from '@asd-stan/standard/components/remark-textarea/remark-container';
import { RemarkTextarea } from '@asd-stan/standard/components/remark-textarea/remark-textarea';
import { canSendRemarks } from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/page-blocks/remarks/remarks.helpers';
import {
	Expert,
	Originator,
	StandardDetailedRemark,
} from '@asd-stan/standard/domain/standard-detailed.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { FormikValues, useFormikContext } from 'formik';

interface Props {
	remarks: StandardDetailedRemark[];
	skipRemarksSendCheck?: boolean;
	domainParticipations?: Participation[];
	user?: CurrentUser | null;
	experts?: Expert[];
	originator?: Originator | null;
}

export const Remarks: FC<Props> = observer(
	({ remarks, user, experts, originator, skipRemarksSendCheck = true, domainParticipations }) => {
		const { t } = useTranslation();
		const standardService = getStandardService();

		const [isNeedClearValue, setIsNeedClearValue] = useState<boolean>(false);

		const { values, errors, setFieldValue } = useFormikContext<FormikValues>();

		const handleSendEvent = async () => {
			if (errors.remark || !values.remark) {
				return;
			}

			setIsNeedClearValue(true);

			await standardService.addRemarkToStandard(standardService.singleStandard!.id, values.remark);
			setIsNeedClearValue(false);
			setFieldValue('remark', '');
		};

		return (
			<>
				{remarks && remarks.length === 0 && <RemarkContainer remark={null} removeEvent={null} />}
				{remarks &&
					remarks.map((remark, index) => (
						<RemarkContainer
							notFound={remark.createdBy === null}
							key={index}
							remark={{
								name: remark.createdBy
									? `${remark.createdBy.firstName} ${remark.createdBy.lastName}`
									: t('standard.singleStandardPage.remarks.notFoundUser'),
								text: remark.text,
								date: remark.createdAt,
								stageUpdate: remark.stageUpdate,
							}}
						/>
					))}
				{skipRemarksSendCheck ||
				canSendRemarks(user!, experts, originator, domainParticipations) ? (
					<RemarkTextarea
						placeholder={t('standard.singleStandardPage.remarks.inputPlaceholder')}
						isNeedClearValue={isNeedClearValue}
						sendEvent={handleSendEvent}
					/>
				) : null}
			</>
		);
	}
);
