import styled from 'styled-components';

export const StyledTitleCell = styled.td`
	span {
		color: ${({ theme }) => theme.fontColors.error};
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
`;
