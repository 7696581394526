import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { FormError } from '@asd-stan/ui-kit/components/form-error/form-error';
import { ReactComponent as ArrowBack } from '@assets/icons/arrow-back.svg';
import { FormInput } from '@components/form-input/form-input';
import { Flex } from '@components/utility/flex';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { AuthLayout } from '../layout/auth-layout/auth-layout';

import { ResendPasswordResetEmail } from './resend-password-reset-email';

interface InitResetPasswordForm {
	email: string;
}

const validationSchema = yup.object().shape({
	email: yup
		.string()
		.email('auth.resetPassword.errors.invalid')
		.required('auth.resetPassword.errors.required'),
});

const initialValues: InitResetPasswordForm = {
	email: '',
};

export const InitResetPassword: React.FC = observer(() => {
	const { t } = useTranslation();
	const authService = getAuthService();
	const [emailSentTo, setEmailSentTo] = useState<null | string>(null);

	const handleSubmit = async (
		{ email }: InitResetPasswordForm,
		{ setFieldError }: FormikHelpers<InitResetPasswordForm>
	) => {
		try {
			await authService.initResetPassword(email);
			setEmailSentTo(email);
		} catch {
			setFieldError('email', 'auth.resetPassword.errors.exists');
		}
	};

	const handleResendEmail = async () => {
		if (!emailSentTo) {
			return;
		}
		await authService.initResetPassword(emailSentTo);
	};

	if (emailSentTo) {
		return <ResendPasswordResetEmail emailSentTo={emailSentTo} onEmailResend={handleResendEmail} />;
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ errors, handleSubmit, isSubmitting }) => (
				<AuthLayout>
					<h1>{t('auth.resetPassword.title')}</h1>
					{!!errors.email && <FormError title={t(errors.email)} />}
					<Form>
						<Field
							component={FormInput}
							name="email"
							title={t('auth.resetPassword.emailTitle')}
							placeholder={t('auth.resetPassword.emailPlaceholder')}
							fullWidth
						/>
					</Form>
					<Button
						title={t('auth.resetPassword.action')}
						onClick={handleSubmit}
						disabled={isSubmitting}
						fullWidth
					/>
					<Link to="/signin" className="backLink">
						<Flex $align="center">
							<ArrowBack />
							{t('auth.resetPassword.backLink')}
						</Flex>
					</Link>
				</AuthLayout>
			)}
		</Formik>
	);
});
