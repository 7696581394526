import React from 'react';

import { ReactComponent as LogoIcon } from '@assets/logo.svg';

import { StyledLogo } from './logo.styled';

interface LogoProps {
	color?: string;
}

export const Logo: React.FC<LogoProps> = ({ color = '#fff' }) => (
	<StyledLogo color={color} to="/">
		<LogoIcon />
	</StyledLogo>
);
