import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Classification, ICSCode, Tag } from '@asd-stan/standard/domain/standard-detailed.entity';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import {
	DataRow,
	DataRowItem,
	StandardDisplayField,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValueAbsent,
} from '../../single-standard.styled';

import { AdditionalInfoContainer } from './additional-information-component.styled';

interface Props {
	tags?: Tag[];
	classifications?: Classification[];
	ICSCodes?: ICSCode[];
}

export const AdditionalInformation: FC<Props> = props => {
	const { t } = useTranslation();

	const renderTags = () =>
		props.tags &&
		props.tags.map((tag, index) => (
			<DataRowItem key={`${tag.value}-${index}`}>
				{tag.value}
				{index !== (props.tags && props.tags.length - 1) ? ',\xA0' : null}
			</DataRowItem>
		));

	const renderClassifications = () =>
		props.classifications &&
		props.classifications.map((classification, index) => (
			<DataRowItem key={`${classification.name}-${index}`}>
				{classification.name}
				{index !== (props.classifications && props.classifications.length - 1) ? ',\xA0' : null}
			</DataRowItem>
		));

	const renderICSCodes = () =>
		props.ICSCodes &&
		props.ICSCodes.map((ICSCode, index) => (
			<DataRowItem key={`${ICSCode.name}-${ICSCode.code}`}>
				{ICSCode.name}
				{index !== (props.ICSCodes && props.ICSCodes.length - 1) ? ',\xA0' : null}
			</DataRowItem>
		));

	return (
		<InputGroup title={t('standard.singleStandardPage.additionalInformation.title')}>
			<AdditionalInfoContainer>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.additionalInformation.tags')}
					</StandardDisplayFieldHeader>
					{props.tags?.length ? (
						<DataRow>{renderTags()}</DataRow>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.additionalInformation.tagsAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.additionalInformation.classifications')}
					</StandardDisplayFieldHeader>
					{props.classifications?.length ? (
						<DataRow>{renderClassifications()}</DataRow>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.additionalInformation.classificationAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.additionalInformation.ICSCodes')}
					</StandardDisplayFieldHeader>
					{props.ICSCodes?.length ? (
						<DataRow>{renderICSCodes()}</DataRow>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.additionalInformation.ICSCodesAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
			</AdditionalInfoContainer>
		</InputGroup>
	);
};
