import styled from 'styled-components';

export const StyledTableFilter = styled.div`
	position: relative;

	& > button {
		display: flex;
		height: 36px;
		background-color: ${({ theme }) => theme.fontColors.white};
		border: ${({ theme }) => theme.borders.divider};
		color: ${({ theme }) => theme.fontColors.darkGrey};
		padding: 8px 10px;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 500;

		&:active:not(:disabled) {
			-webkit-box-shadow: ${({ theme }) => theme.button.active};
			-moz-box-shadow: ${({ theme }) => theme.button.active};
			box-shadow: ${({ theme }) => theme.button.active};
			outline: none;
		}

		&:disabled {
			cursor: not-allowed;
			background-color: ${({ theme }) => theme.button.disabled};
			opacity: 0.7;

			&:hover {
				background-color: ${({ theme }) => theme.button.disabled};
			}
		}

		& > svg {
			width: 16px;
			height: 16px;
			fill: ${({ theme }) => theme.fontColors.darkGrey};
		}

		& > p {
			margin: 0 8px;
		}

		& > div {
			width: 18px;
			height: 18px;
			padding: 3px;
			border-radius: 50px;
			font-size: 10px;
			font-weight: 600;

			color: ${({ theme }) => theme.fontColors.white};
			background-color: ${({ theme }) => theme.fontColors.accent};
		}
	}
`;

export const FiltersPopup = styled.div<{ $leftPosition?: boolean }>`
	position: absolute;
	top: 100%;
	left: ${({ $leftPosition }) => ($leftPosition ? 'none' : 0)};
	right: ${({ $leftPosition }) => ($leftPosition ? 0 : 'none')};
	margin-top: 8px;
	width: 292px;
	background: #fff;
	border-radius: 8px;
	padding: 20px;
	z-index: 1;
	height: fit-content !important;
	box-shadow: 0px 12px 24px 0px #1e242d29;
	color: #333336;

	h6 {
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 16px;
	}

	.buttons {
		display: flex;
		margin-top: 16px;

		button {
			width: 118px;

			&:first-child {
				margin-right: 16px;
			}
		}
	}
`;
