import { User } from '@asd-stan/user/domain/user.entity';
import { UserCollectionFilter } from '@asd-stan/user/domain/user.service';
import { getUserRepo } from '@asd-stan/user/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

import { getStandardRepo } from '../infrastructure/getters';

export class StandardPublicService {
	private _userList: Array<User> = [];
	private _emailOriginator: User | null = null;
	private _phoneOriginator: User | null = null;
	private _loadUserList: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	// getters

	private get _userRepo() {
		return getUserRepo();
	}

	private get _standardRepo() {
		return getStandardRepo();
	}

	get userPublicList() {
		return this._userList;
	}

	get emailOriginator() {
		return this._emailOriginator;
	}

	get phoneOriginator() {
		return this._phoneOriginator;
	}

	get loadUserList() {
		return this._loadUserList;
	}

	// getters end
	// methods

	async getUsersByDomains(
		codes: Partial<UserCollectionFilter>,
		limit: number = 6,
		offset: number = 0
	) {
		this._loadUserList = true;
		const response = await this._userRepo.getUserList(limit, offset, codes);
		runInAction(() => {
			this._userList = response.data;
			this._loadUserList = false;
		});

		return response;
	}

	clearUserPublicList = () => {
		this._userList = [];
	};

	async checkOriginator(filter: Partial<any>, limit: number = 6, offset: number = 0) {
		const userList = await this._userRepo.getUserList(limit, offset, filter);
		const originatorList = await this._standardRepo.getOriginatorList(limit, offset, {
			...filter,
			firstName: '',
			lastName: '',
			phone: filter.phone ?? '',
			email: filter.email ?? '',
			company: '',
		});

		runInAction(() => {
			if (userList.data.length > 0) {
				if (filter.email) {
					return (this._emailOriginator = userList.data[0]);
				} else {
					return (this._phoneOriginator = userList.data[0]);
				}
			}
			if (originatorList.length > 0) {
				if (filter.email) {
					return (this._emailOriginator = originatorList[0]);
				} else {
					return (this._phoneOriginator = originatorList[0]);
				}
			}
			this.clearOriginator();
		});
	}

	clearOriginator() {
		this._emailOriginator = null;
		this._phoneOriginator = null;
	}

	// methods end
}
