import { styled } from 'styled-components';

export const ExpertsMoreBtn = styled.button`
	background-color: transparent;
	font-size: 13px;
	font-weight: 600;
`;

export const ShowMoreBtn = styled.button`
	background-color: #041630;
	color: white;
	padding: 4px 8px 4px 8px;
	font-size: 12px;
	border-radius: 4px;
`;

export const TableRow = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const ExpertsContainer = styled.div<{ $hasExperts?: boolean }>`
	margin-top: ${props => (props.$hasExperts ? '0px' : '24px')};
	position: relative;
	flex: 1;
`;

export const ShowMoreBtnWrapper = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	z-index: 2;
`;

export const WGSWrapper = styled.div`
	margin-top: 24px;
`;

export const StyledTableHead = styled.thead`
	border-top-style: hidden;
`;

export const StyledTableBody = styled.tbody`
	& > tr:last-child > td {
		vertical-align: top;
		border: none;
	}
`;
