import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as Plus } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/plus-icon.svg';
import { BorderButton } from '@components/button/border-button';
import { ButtonClose } from '@components/button/button-close';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInput } from '@components/form-input/form-input';
import { Flex } from '@components/utility/flex';
import { Field, FieldArray, FormikValues, useFormikContext } from 'formik';

import { StyledTechnicalChanges } from './technical-changes.styled';

export const TechnicalChanges: React.FC = observer(() => {
	const url = window.location.href;
	const { t } = useTranslation();
	const standardService = getStandardService();
	const { values, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();

	const [technicalChanges, setTechnicalChanges] = useState<number>(
		values.technicalChanges ? values.technicalChanges.length : 0
	);

	const addChange = async () => {
		setFieldValue('technicalChanges', [
			...values.technicalChanges,
			{
				title: '',
				change: '',
			},
		]);
		await setTechnicalChanges((prevChanges: number) => prevChanges + 1);
		handleSubmit();
	};

	const deleteChange = async (index: number) => {
		if (values.technicalChanges.length === 1) {
			return;
		}

		const updateChanges = values.technicalChanges.filter(
			(_change: number, i: number) => i !== index
		);
		setFieldValue('technicalChanges', updateChanges);
		await setTechnicalChanges((prevChanges: number) => prevChanges - 1);
		handleSubmit();
	};

	const handleChange = async (name: string, value: string) => {
		await setFieldValue(name, value);
	};

	useEffect(() => {
		setTechnicalChanges(values.technicalChanges?.length);
	}, [values.technicalChanges?.length]);

	return (
		<StyledTechnicalChanges>
			<h1>
				{t('standard.createNWP.justification.technicalChangesTitle')}
				{`${values.form && values.form.value} ${values.registrationNumber} ${
					values.edition && values.edition.length > 60
						? `${values.edition.slice(0, 60)}...`
						: values.edition
				}`}
			</h1>
			<FieldArray
				name="technicalChanges"
				key={`${values.revisionId.value}-${
					values.technicalChanges ? values.technicalChanges.length : -1
				}`}>
				{() =>
					Array.from(Array(technicalChanges)).map((change, index) => (
						<div key={`changes-input-group-${index}`}>
							<Flex $align="center" $justify="space-between" className="container-header">
								<p>
									{t('standard.createNWP.justification.technicalChangesCounter', {
										count: index + 1,
									})}
								</p>
								{values.technicalChanges.length !== 1 && (
									<ButtonClose onClick={() => deleteChange(index)} />
								)}
							</Flex>
							<Field
								key={`changes-input-group-${index}-title-${
									values.technicalChanges ? values.technicalChanges.length : -1
								}`}
								component={FormInput}
								name={`technicalChanges.${index}.title`}
								title={t('standard.createNWP.justification.technicalChangeTitle')}
								mandatory={!standardService.singleStandard?.isMigrated}
								fullWidth
								showError
								useSubmitOnBlur
								onChange={handleChange}
							/>
							<Field
								key={`changes-input-group-${index}-change-${
									values.technicalChanges ? values.technicalChanges.length : -1
								}`}
								component={FormInputParagraph}
								name={`technicalChanges.${index}.change`}
								title={t('standard.createNWP.justification.technicalChangeChange')}
								mandatory={!standardService.singleStandard?.isMigrated}
								maxLength={2000}
								fullWidth
								showError
								disableMaxLength={url.includes('draft')}
								useSubmitOnBlur
							/>
						</div>
					))
				}
			</FieldArray>

			<BorderButton
				icon={<Plus />}
				title={t('standard.createNWP.justification.addTechnicalChange')}
				onClick={addChange}
			/>
		</StyledTechnicalChanges>
	);
});
