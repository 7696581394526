import i18n from '@asd-stan/i18n/i18n';
import * as yup from 'yup';

const InviteUsersSchema = yup.object().shape({
	firstName: yup
		.string()
		.max(50, i18n.t('user.userInvite.fieldErrors.length', { number: '1-50' }))
		.required(i18n.t('user.userInvite.fieldErrors.required')),
	lastName: yup
		.string()
		.max(50, i18n.t('user.userInvite.fieldErrors.length', { number: '1-50' }))
		.required(i18n.t('user.userInvite.fieldErrors.required')),
	email: yup
		.string()
		.email(i18n.t('user.userInvite.fieldErrors.email'))
		.required(i18n.t('user.userInvite.fieldErrors.required'))
		.matches(/@[^.]*\./, i18n.t('user.userInvite.fieldErrors.email')),
	phone: yup.string().min(8, i18n.t('user.userInvite.fieldErrors.required')),
	appointer: yup.object().shape({
		value: yup.string(),
		label: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')).max(255),
	}),
	systemRoles: yup
		.array()
		.of(
			yup.object().shape({
				value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
				label: yup.string(),
			})
		)
		.min(1, i18n.t('user.userInvite.fieldErrors.required')),
	position: yup.array().of(
		yup.object().shape({
			value: yup.string(),
			label: yup.string(),
		})
	),
	domains: yup.array().of(
		yup.object().shape({
			domain: yup.object().shape({
				value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
				label: yup.string(),
			}),
			domainRoles: yup.lazy(val =>
				Array.isArray(val) && val.length > 0
					? yup.array().of(
							yup.object().shape({
								value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
								label: yup.string(),
							})
					  )
					: Array.isArray(val)
					  ? yup.array().min(1, i18n.t('user.userInvite.fieldErrors.required'))
					  : yup.object().shape({
								value: yup.string().required(),
								label: yup.string().required(),
					    })
			),
			seeAllDomain: yup.boolean(),
			WGS: yup.array().when('domainRoles', domainRoles =>
				domainRoles[0]?.value === 'None'
					? yup
							.array()
							.of(
								yup.object().shape({
									value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
									label: yup.string(),
								})
							)
							.required()
							.min(1, i18n.t('user.userInvite.fieldErrors.required'))
					: yup.array().of(
							yup.object().shape({
								value: yup.string(),
								label: yup.string(),
							})
					  )
			),
			workingGroupExpert: yup
				.array()
				.when(['seeAllDomain', 'domainRoles', 'systemRoles'], (values, schema, field) => {
					const [seeAllDomain, domainRoles] = values;

					return seeAllDomain === false &&
						domainRoles.length === 1 &&
						domainRoles[0].value === 'Expert' &&
						field.context.systemRoles.length === 1 &&
						field.context.systemRoles[0]?.value === 'Expert'
						? yup
								.array()
								.of(
									yup.object().shape({
										value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
										label: yup.string(),
									})
								)
								.required()
								.min(1, i18n.t('user.userInvite.fieldErrors.required'))
						: yup.array().of(
								yup.object().shape({
									value: yup.string(),
									label: yup.string(),
								})
						  );
				}),
		})
	),
});

export default InviteUsersSchema;
