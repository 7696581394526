import { observer } from 'mobx-react';

import { BallotList } from '@asd-stan/ballot/components/ballot-list/ballot-list';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { useRequestWithPagination } from '@asd-stan/helpers/use-request-with-pagination';

import { StyledBallotsSection } from './ballots-section.styled';

export const BallotsSection = observer(({ standardId }: { standardId: number }) => {
	const ballotService = getBallotService();
	const ballotList = useRequestWithPagination(20, (limit, offset, filter) =>
		ballotService.getBallotList(limit, offset, Object.assign(filter, { standardId }))
	);

	return (
		<StyledBallotsSection>
			<BallotList ballotList={ballotList} />
		</StyledBallotsSection>
	);
});
