import ReactDatePicker from 'react-datepicker';

import { getIn, useFormikContext } from 'formik';

import { FilterWrapper } from '../filter-wrapper/filter-wrapper';

import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { StyledFilterDateRange } from './filter-date-range.styled';

export const FilterDateRange = ({ name, title }: { name: string; title: string }) => {
	const { setFieldValue, values } = useFormikContext();

	const handleChange = (date: [Date | null, Date | null]) => {
		setFieldValue(name, {
			from: date[0],
			to: date[1],
		});
	};

	return (
		<FilterWrapper title={title}>
			<StyledFilterDateRange>
				<CalendarIcon />
				<ReactDatePicker
					onChange={handleChange}
					selectsRange
					startDate={getIn(values, `${name}.from`)}
					endDate={getIn(values, `${name}.to`)}
					placeholderText="Choose date"
				/>
			</StyledFilterDateRange>
		</FilterWrapper>
	);
};
