import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { languages } from '@asd-stan/common/enums';
import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { userHasRoles } from '@asd-stan/helpers/app-utils';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { AdditionalInformation } from '@asd-stan/standard/components/nwp/forms/additional-information';
import { StandardDraft } from '@asd-stan/standard/components/nwp/forms/standard-draft';
import { Titles } from '@asd-stan/standard/components/nwp/forms/titles';
import { attachmentTypes, forms, revisions } from '@asd-stan/standard/domain/enums';
import { TechnicalChanges } from '@asd-stan/standard/domain/standard-detailed.entity';
import { StandardUpdate } from '@asd-stan/standard/domain/standard-update';
import {
	getStandardDetailsService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { NWPSchema, emptySchema } from '@asd-stan/standard/validation-schemas/nwp.schema';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { BreadCrumbs } from '@asd-stan/ui-kit/components/bread-crumbs/bread-crumbs';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { Option } from '@components/form-select/form-select-async';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import { MarkedText } from '@components/utility/marked-text';
import { Form, Formik, FormikValues } from 'formik';
import moment from 'moment';

import { ProjectLeader } from '../general/project-leader/project-leader';
import { RegistrationData } from '../single-standard/tabs-sections/single-standard-page-section/page-blocks/reg-data/reg-data';
import { Remarks as UpdateRemarks } from '../single-standard/tabs-sections/single-standard-page-section/page-blocks/remarks/remarks';
import { StyledLoaderContainer } from '../single-standard/tabs-sections/single-standard-page-section/single-standard.styled';

import { ENFields } from './forms/enFields';
import { Experts } from './forms/experts';
import { General } from './forms/general';
import { Justification } from './forms/justification';
import { Originator } from './forms/originator';
import { Remarks as CreateRemarks } from './forms/remarks';

import { StyledButtonGroup } from '@asd-stan/ui-kit/components/utility/button-group.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';

export const EditNwp: React.FC = observer(() => {
	const navigation = useNavigate();
	const { t } = useTranslation();
	const params = useParams();

	const currentUserService = getCurrentUserService();
	const toasterService = getToasterService();
	const standardService = getStandardService();
	const standardDetailsService = getStandardDetailsService();

	const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
	const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

	const isEsOrDirector = userHasRoles(
		[SystemRole.ES, SystemRole.DIRECTOR],
		currentUserService.userRoles!
	);

	useEffect(() => {
		const onLoadPage = async () => {
			await standardService.getAttachmentsByStandardId(Number(params.id));
			standardService.getStandardById(Number(params.id));
			if (
				standardService.singleStandard &&
				standardService.singleStandard.remarks &&
				standardService.singleStandard.remarks.length > 0 &&
				standardService.remarks.length === 0
			) {
				standardService.updateRemarks(standardService.singleStandard.remarks);
			}
		};

		onLoadPage();
	}, [params.id, standardService]);

	const updateInitialValues = {
		form: forms.find(form => form.value === standardService.singleStandard?.form),
		registrationNumber: standardService.singleStandard?.registrationNumber,
		revisionId: standardService.singleStandard?.revision ? revisions[0] : revisions[1],
		standard:
			standardService.singleStandard?.revision === null
				? ''
				: {
						label: `${standardService.singleStandard?.revision.registrationNumber} ${standardService.singleStandard?.revision.form} ${standardService.singleStandard?.revision.localizedTitle}`,
						value: standardService.singleStandard?.revision,
				  },
		showReplaceStandard: false,
		stanNumber: standardService.singleStandard?.stanNumber,
		edition: standardService.singleStandard?.edition,
		languages: standardService.singleStandard?.languages.map(updateLanguage =>
			languages.find(language => language.value === updateLanguage)
		),
		typeIds:
			standardService.singleStandard?.standardTypes.map(({ id, name }) => ({
				label: name,
				value: id,
			})) ?? [],
		pages:
			standardService.singleStandard?.pages === null ? '' : standardService.singleStandard?.pages,
		domainCodes: standardService.singleStandard?.domains.map(domain => ({
			label: domain.name,
			value: domain.code,
		})),
		workingGroupCodes: standardService.singleStandard?.workingGroups.map(wg => ({
			label: wg.name,
			value: wg.code,
		})),
		leaderId:
			standardService.singleStandard?.leader === null
				? { label: '', value: '' }
				: {
						label: <ProjectLeader user={standardService.singleStandard?.leader!} />,
						value: standardService.singleStandard?.leader.id,
				  },
		scope: standardService.singleStandard?.scope,
		newOriginator:
			standardService.singleStandard?.originator?.userId === null &&
			standardService.singleStandard.originator?.id === null &&
			standardService.singleStandard?.originator !== undefined &&
			Object.values(standardService.singleStandard?.originator)
				.slice(0, -1)
				.some(user => user !== null && user !== '')
				? {
						...standardService.singleStandard.originator,
						phone:
							standardService.singleStandard.originator.phone === null
								? ''
								: standardService.singleStandard.originator.phone,
						companyId:
							standardService.singleStandard.originator.company &&
							standardService.singleStandard.originator.company.id,
				  }
				: {
						firstName: '',
						lastName: '',
						email: '',
						phone: '',
						company: '',
						companyCountry: '',
						position: [],
				  },
		originator:
			standardService.singleStandard?.originator === null
				? { label: '', value: '' }
				: standardService.singleStandard?.originator?.userId === null
				  ? standardService.singleStandard?.originator?.id === null
						? { label: '', value: '' }
						: {
								value: {
									id: standardService.singleStandard?.originator.id,
									firstName: standardService.singleStandard?.originator.firstName,
									lastName: standardService.singleStandard?.originator.lastName,
									phone: standardService.singleStandard?.originator.phone,
									email: standardService.singleStandard?.originator.email,
									companyId:
										standardService.singleStandard?.originator.company &&
										standardService.singleStandard?.originator.company.id,
									countryId:
										standardService.singleStandard?.originator.country &&
										standardService.singleStandard?.originator.country.id,
									positions:
										standardService.singleStandard?.originator.positions &&
										standardService.singleStandard?.originator.positions.map(
											position => position.id
										),
								},
								label: (
									<>
										<b
											style={{
												fontWeight: 500,
											}}>{`${standardService.singleStandard?.originator.firstName ?? ''} ${
											standardService.singleStandard?.originator.lastName ?? ''
										}`}</b>
										{` ${
											standardService.singleStandard?.originator.email === null
												? ''
												: `, ${standardService.singleStandard?.originator.email}`
										} ${
											standardService.singleStandard?.originator.phone === null
												? ''
												: `, ${standardService.singleStandard?.originator.phone}`
										}
										${
											standardService.singleStandard?.originator.company
												? ', ' + standardService.singleStandard?.originator.company.name
												: ''
										}
										${
											standardService.singleStandard?.originator.country
												? ', ' + standardService.singleStandard?.originator.country.name
												: ''
										}
											
											${
												standardService.singleStandard?.originator.positions &&
												standardService.singleStandard?.originator.positions.length > 0
													? ', ' +
													  standardService.singleStandard?.originator.positions.map(
															position => ` ${position.name}`
													  )
													: ''
											}`}
									</>
								),
						  }
				  : {
							label: `${standardService.singleStandard?.originator?.firstName ?? ''} ${
								standardService.singleStandard?.originator?.lastName ?? ''
							}`,
							value: {
								userId: standardService.singleStandard?.originator?.userId,
								firstName: standardService.singleStandard?.originator?.firstName,
								lastName: standardService.singleStandard?.originator?.lastName,
								email: standardService.singleStandard?.originator?.email,
								phone: standardService.singleStandard?.originator?.phone,
								companyId:
									standardService.singleStandard?.originator?.company &&
									standardService.singleStandard?.originator?.company.id,
								countryId:
									standardService.singleStandard?.originator?.country &&
									standardService.singleStandard?.originator?.country.id,
								positions:
									standardService.singleStandard?.originator?.positions &&
									standardService.singleStandard?.originator?.positions.map(
										position => position.id
									),
							},
				    },
		radioForm:
			standardService.singleStandard?.originator?.userId === null &&
			standardService.singleStandard.originator?.id === null &&
			standardService.singleStandard?.originator !== undefined &&
			Object.values(standardService.singleStandard?.originator)
				.slice(0, -1)
				.some(user => user !== null && user !== '')
				? false
				: true,
		standardDraft:
			standardService.singleStandard?.file === null
				? undefined
				: [
						{
							id: standardService.singleStandard?.file?.id,
							path: standardService.singleStandard?.file?.path,
							size: standardService.singleStandard?.file?.size,
							title: standardService.singleStandard?.file?.name,
						},
				  ],
		isAsdOriginator:
			standardService.singleStandard?.originator?.userId === null &&
			standardService.singleStandard?.originator?.id === null &&
			standardService.singleStandard?.originator !== undefined &&
			Object.values(standardService.singleStandard?.originator)
				.slice(0, -1)
				.some(user => user !== null && user !== '')
				? true
				: standardService.singleStandard?.originator?.userId === null &&
				    standardService.singleStandard?.originator?.id === null
				  ? false
				  : standardService.singleStandard?.originator?.userId !== null
				    ? false
				    : isEsOrDirector
				      ? true
				      : false,
		purpose:
			standardService.singleStandard?.justification.purpose === null
				? ''
				: standardService.singleStandard?.justification.purpose,
		revisionJustification: standardService.singleStandard?.justification.revisionJustification,
		technicalChanges:
			standardService.singleStandard?.justification.technicalChanges &&
			standardService.singleStandard?.justification.technicalChanges.length > 0
				? standardService.singleStandard?.justification.technicalChanges
				: [
						{
							title: '',
							change: '',
						},
				  ],
		consideredStandards: standardService.singleStandard?.justification.consideredStandards.map(
			standard => ({
				label: standard.name,
				value: standard.id,
			})
		),
		externalConsideredStandards:
			standardService.singleStandard?.justification.externalConsideredStandards === null
				? ''
				: standardService.singleStandard?.justification.externalConsideredStandards,
		knownPatentIssue: standardService.singleStandard?.justification.knownPatentIssue,
		patentReferences:
			standardService.singleStandard?.justification.patentReferences === null
				? ''
				: standardService.singleStandard?.justification.patentReferences,
		patentFile:
			standardService.singleStandard?.justification.patentFile === null
				? undefined
				: [
						{
							id: standardService.singleStandard?.justification.patentFile?.id,
							path: standardService.singleStandard?.justification.patentFile?.path,
							size: standardService.singleStandard?.justification.patentFile?.size,
							title: standardService.singleStandard?.justification.patentFile?.name,
						},
				  ],
		tags: standardService.singleStandard?.tags?.map(tag => ({
			label: tag.value,
			value: tag.value,
		})),
		classification: standardService.singleStandard?.classifications.map(classification => ({
			label: `${classification.number} ${classification.name}`,
			value: classification.id.toString(),
		})),
		ICSCodes: standardService.singleStandard?.icsCodes.map(icsCode => ({
			label: `${icsCode.code} ${icsCode.name}`,
			value: icsCode.code,
		})),
		standardTitleEN: standardService.singleStandard?.titles.en,
		standardTitleDE:
			standardService.singleStandard?.titles.de === null
				? ''
				: standardService.singleStandard?.titles.de,
		standardTitleFR:
			standardService.singleStandard?.titles.fr === null
				? ''
				: standardService.singleStandard?.titles.fr,
		experts: standardService.singleStandard?.experts.map(expert => ({
			label: expert.fullName,
			value: expert.id,
		})),
		wgs: standardService.singleStandard?.wgs !== null && {
			label: <ProjectLeader user={standardService.singleStandard?.wgs!} />,
			value: standardService.singleStandard?.wgs.id,
		},
		enData: {
			name: standardService.singleStandard?.enData.name,
			publicationDate: standardService.singleStandard?.enData.publicationDate,
			title: standardService.singleStandard?.enData.title,
		},
		attachments: standardService.attachments?.map(attachment => ({
			file: {
				title: attachment?.file?.name,
				size: attachment?.file?.size,
				fileId: attachment?.file?.id,
				id: attachment?.id,
				path: attachment?.file?.path,
			},
			attachmentDescription: attachment.description,
			attachmentType: attachmentTypes.find(type => type.value === attachment.type),
		})),
		cenWiNumber: standardService.singleStandard?.cenWiNumber,
	};

	const goToPreviousPage = () => {
		standardService.clearSingleStandard();
		return navigation(`/standards/detailed/${params.id}/general`);
	};

	const onSubmit = async (values: FormikValues) => {
		if (!isSaveClicked) {
			return;
		}
		const creation = makeNwpCreation(values);
		try {
			setIsSaveDisabled(true);
			if (standardService.singleStandard && params.id && isSaveClicked) {
				await standardService.updateStandard({ ...creation, id: Number(params.id) });
				standardService.clearSingleStandard();
				await standardDetailsService.getStandardById(Number(params.id));
				toasterService.showToast(
					ToasterStatus.success,
					t('standard.createNWP.toaster.successfullUpdate')
				);
				navigation(`/standards/detailed/${params.id}/general`);
			}
			setIsSaveDisabled(false);
			setIsSaveClicked(false);
		} catch (error) {
			setIsSaveDisabled(false);
			setIsSaveClicked(false);
		}
	};

	const makeNwpCreation = (values: FormikValues): StandardUpdate => {
		const originator =
			values.originator && values.originator.label !== ''
				? {
						...values.originator.value,
				  }
				: values.newOriginator
				  ? {
							firstName: values.newOriginator.firstName,
							lastName: values.newOriginator.lastName,
							email: values.newOriginator.email,
							phone:
								values.newOriginator.phone && values.newOriginator.phone.length === 0
									? null
									: values.newOriginator.phone === ''
									  ? null
									  : values.newOriginator.phone,
							companyId:
								values.newOriginator.company === null || values.newOriginator.company === ''
									? null
									: values.newOriginator.company && values.newOriginator.company.value === ''
									  ? null
									  : values.newOriginator.company && Number(values.newOriginator.company.value),
							countryId:
								values.newOriginator.companyCountry === null ||
								values.newOriginator.companyCountry === ''
									? null
									: values.newOriginator.companyCountry &&
									    values.newOriginator.companyCountry.value === ''
									  ? null
									  : values.newOriginator.companyCountry &&
									    Number(values.newOriginator.companyCountry.value),
							positions:
								values.newOriginator.position &&
								values.newOriginator.position.map((position: Option) => Number(position.value)),
				    }
				  : {};

		return {
			id: Number(params.id!),
			generalData: {
				form: values.form && values.form.value,
				registrationNumber: values.registrationNumber,
				stanNumber: values.stanNumber,
				revisionId: values.standard
					? values.standard.length === 0
						? undefined
						: values.standard.value
						  ? Number(values.standard.value.id)
						  : undefined
					: null,
				edition: values.edition,
				standard: values.standard.id,
				languages: values.languages
					? values.languages.map((language: Option) => language.value)
					: [],
				typeIds: values.typeIds ? values.typeIds.map((t: Option) => t.value) : [],
				pages:
					values.pages && values.pages === ''
						? null
						: values.pages && values.pages.length === 0
						  ? null
						  : Number(values.pages),
				domainCodes:
					values.domainCodes && values.domainCodes.length === 0
						? []
						: values.domainCodes && values.domainCodes.map((domain: Option) => domain.value),
				workingGroupCodes: Array.isArray(values.workingGroupCodes)
					? values.workingGroupCodes.map((workingGroup: Option) => workingGroup.value)
					: [],
				leaderId:
					values.leaderId === undefined || values.leaderId === null
						? undefined
						: values.leaderId.value === ''
						  ? undefined
						  : values.leaderId.value,
				scope: values.scope,
				cenWiNumber: values.cenWiNumber,
			},
			originator: originator,
			draft:
				values.standardDraft && values.standardDraft.length > 0
					? values.standardDraft[0].fileId ?? values.standardDraft[0].id
					: undefined,
			justification: {
				purpose: values.purpose,
				revisionJustification:
					values.revisionId && values.revisionId.value === 'yes'
						? values.revisionJustification
						: '',
				technicalChanges:
					values.technicalChanges &&
					values.technicalChanges[0].title === '' &&
					values.technicalChanges[0].change === ''
						? []
						: values.technicalChanges.map((item: TechnicalChanges) => ({
								id: item.id,
								title: item.title,
								change: item.change,
						  })),
				consideredStandards:
					values.consideredStandards &&
					values.consideredStandards.map((standard: Option) => standard.value),
				externalConsideredStandards: values.externalConsideredStandards ?? '',
				knownPatentIssue: values.knownPatentIssue,
				patentFile:
					values.patentFile && values.patentFile.length > 0
						? values.patentFile[0].fileId ?? values.patentFile[0].id
						: undefined,
				patentReferences:
					values.patentReferences && values.patentReferences.length === 0
						? undefined
						: values.patentReferences,
			},
			tags: values.tags && values.tags.map((tag: Option) => tag.value),
			classifications: values.classification
				? values.classification.map((classification: Option) => Number(classification.value))
				: null,
			icsCodes: values.ICSCodes && values.ICSCodes.map((code: Option) => code.value),
			titles: {
				en: values.standardTitleEN,
				de: values.standardTitleDE,
				fr: values.standardTitleFR,
			},
			enData: {
				name: values.enData.name,
				publicationDate: values.enData.publicationDate
					? moment(values.enData.publicationDate).format('YYYY-MM')
					: null,
				title: values.enData.title,
			},
			experts: {
				experts:
					values.experts &&
					values.experts.map((expert: { label: string; value: number }) => expert.value),
				wgs:
					values.wgs === undefined || values.wgs === null
						? undefined
						: values.wgs.value === ''
						  ? undefined
						  : values.wgs.value,
			},
		};
	};

	if (params.id && (!standardService.attachments || standardService.singleStandard === null)) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	return (
		<Formik
			validationSchema={
				isSaveClicked
					? !standardService.singleStandard?.isMigrated
						? NWPSchema
						: emptySchema
					: emptySchema
			}
			initialValues={updateInitialValues}
			onSubmit={onSubmit}
			validateOnChange
			validateOnBlur={false}>
			{({ handleSubmit, validateForm }) => {
				return (
					<ContentContainer>
						<BreadCrumbs />
						<Flex $justify="space-between" $align="center">
							<PageTitle
								title={
									params.id
										? `${
												standardService.singleStandard?.form === null
													? ''
													: standardService.singleStandard?.form
										  } ${standardService.singleStandard?.registrationNumber} ${
												standardService.singleStandard?.edition &&
												standardService.singleStandard?.edition.length > 60
													? `${standardService.singleStandard?.edition.slice(0, 60)}...`
													: standardService.singleStandard?.edition
										  }`
										: t('standard.createNWP.title')
								}
							/>
							<StyledButtonGroup>
								<Button
									disabled={isSaveDisabled}
									secondary
									title={t('standard.createNWP.buttons.cancel')}
									onClick={goToPreviousPage}
								/>
								<Button
									disabled={isSaveDisabled}
									title={t('standard.createNWP.buttons.save')}
									onClick={async () => {
										await setIsSaveClicked(prevState => true);
										validateForm().then(errors => {
											if (errors && !(Object.keys(errors).length === 0)) {
												toasterService.showToast(
													ToasterStatus.error,
													t('standard.createNWP.toaster.incorrectFields')
												);
												setIsSaveClicked(prevState => false);
											}
										});

										try {
											await handleSubmit();
										} catch (error) {
											setIsSaveClicked(() => false);
										}
									}}
								/>
							</StyledButtonGroup>
						</Flex>

						{standardService.singleStandard && (
							<>
								{standardService.singleStandard.stage && (
									<div className="description">
										<Trans
											i18nKey="standard.singleStandard.subTitle"
											values={{
												currentStage: `${standardService.singleStandard.stage?.code} ${standardService.singleStandard.stage.name}`,
											}}>
											Current stage:
											<MarkedText>
												{standardService.singleStandard.stage?.code}{' '}
												{standardService.singleStandard.stage?.name}
											</MarkedText>
										</Trans>
									</div>
								)}

								<RegistrationData
									createdAt={standardService.singleStandard.createdAt}
									updatedAt={standardService.singleStandard.updateAt}
								/>
							</>
						)}

						<Form>
							<InputGroup title={t('standard.createNWP.general.title')} children={<General />} />
							<InputGroup
								title={t('standard.createNWP.originator.title')}
								children={<Originator isSaveClicked={isSaveClicked} />}
							/>
							<InputGroup
								title={t('standard.createNWP.standardDraft.title')}
								children={<StandardDraft />}
							/>
							<InputGroup
								title={t('standard.createNWP.justification.title')}
								children={<Justification />}
							/>
							<InputGroup
								title={t('standard.createNWP.additionalInformation.title')}
								children={<AdditionalInformation />}
							/>
							<InputGroup title={t('standard.createNWP.titles.title')} children={<Titles />} />
							<InputGroup title={t('standard.createNWP.experts.title')} children={<Experts />} />
							{/*<InputGroup*/}
							{/*	title={t('standard.createNWP.suggestExpertsFromOutside.title')}*/}
							{/*	children={<></>}*/}
							{/*/>*/}
							{/*<InputGroup title={t('standard.createNWP.qualifications.title')} children={<></>} />*/}
							<InputGroup title={t('standard.createNWP.enFields.title')} children={<ENFields />} />
							<InputGroup
								title={t('standard.createNWP.remarks.title')}
								children={
									standardService.singleStandard ? (
										<UpdateRemarks remarks={standardService.singleStandard.remarks} />
									) : (
										<CreateRemarks />
									)
								}
							/>
						</Form>
					</ContentContainer>
				);
			}}
		</Formik>
	);
});
