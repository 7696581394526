import React, { useState } from 'react';

import { Checkbox } from '@components/checkbox/checkbox';
import { FieldProps, getIn } from 'formik';

import { StyledFormCheckbox } from '@asd-stan/ui-kit/components/form-checkbox/form-checkbox.styled';

interface FormCheckboxProps {
	label: string;
	className?: string;
	big?: boolean;
}

export const FormCheckbox: React.FC<FormCheckboxProps & FieldProps> = ({
	label,
	field,
	form,
	className,
	big,
}) => {
	const [isChecked, setIsChecked] = useState<boolean>(!!field.value);

	const handleClick = () => {
		const newValue = !isChecked;
		setIsChecked(newValue);
		field.onChange({
			target: {
				name: field.name,
				value: newValue,
				type: 'checkbox',
				checked: newValue,
			},
		});
	};

	if (big) {
		return (
			<Checkbox
				label={label}
				checked={field.value}
				onChange={checked => {
					form.setFieldValue(field.name, checked);
				}}
			/>
		);
	}

	return (
		<StyledFormCheckbox className={className}>
			<input
				type="checkbox"
				{...field}
				checked={isChecked}
				onChange={() => {}}
				onClick={handleClick}
			/>
			<span onClick={handleClick}>{label}</span>
		</StyledFormCheckbox>
	);
};
