import styled from 'styled-components';

export const CheckboxContainer = styled.label`
	display: flex;
	position: relative;
	font-weight: 500;
	color: #525259;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	align-items: center;
	& > input:checked ~ span:after {
		display: block;
	}
`;

export const CheckboxInput = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
`;

export const Checkmark = styled.div<{ checked: boolean }>`
	height: 18px;
	width: 18px;
	border: ${({ checked }) => (checked ? 'none' : 'solid 2px #dae4e8')};
	background: ${({ checked, theme }) => (checked ? theme.bgColors.accent : 'transparent')};
	border-radius: 3px;
	margin-right: 15px;
	position: relative;
	flex-shrink: 0;

	&:after {
		content: '';
		position: absolute;
		display: ${({ checked }) => (checked ? 'block' : 'none')};
		left: 7px;
		top: 3px;
		width: 5px;
		height: 9px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;
