import { styled } from 'styled-components';
import { CSS } from 'styled-components/dist/types';

export const JustificationContainer = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 24px;
`;

export const JustificationLinkContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	flex-wrap: wrap;
	line-height: 19px;
`;

export const LinkContainer = styled.div`
	font-weight: 600;
	color: ${props => props.theme.fontColors.textGrey};
	white-space: normal;
	& a,
	a:visited {
		color: #0b93e3;
		text-decoration: underline !important;
	}
`;

export const LinkStyles: CSS.Properties = {
	color: '#0B93E3',
	textDecoration: 'underline',
	whiteSpace: 'normal',
};

export const JustificationChangeContainer = styled.div`
	background: #f7fafb;
	border-radius: 12px;
	padding: 16px;
`;
