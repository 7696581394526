import React from 'react';

import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
import { ReactComponent as NotificationCenter } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/notification-center-icon.svg';

import { UserControl } from './user-control/user-control';
import { StyledUserPanel } from './user-panel.styled';

interface UserPanelProps {
	currentUser: CurrentUser;
}

export const UserPanel: React.FC<UserPanelProps> = ({ currentUser }) => {
	if (!currentUser) {
		return (
			<StyledUserPanel>
				{/* <NotificationCenter /> */}
				<UserControl loading={true} />
			</StyledUserPanel>
		);
	}

	return (
		<StyledUserPanel>
			{/* <NotificationCenter /> */}
			<UserControl title={`${currentUser.firstName} ${currentUser.lastName}`} />
		</StyledUserPanel>
	);
};
