import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isRoleAllowToSeeDomain } from '@asd-stan/helpers/app-utils';
import { FormInputPhone } from '@asd-stan/ui-kit/components/form-input-phone/form-input-phone';
import { FormInput } from '@asd-stan/ui-kit/components/form-input/form-input';
import { FormSelect, Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { FormSelectCreatable } from '@asd-stan/ui-kit/components/form-select/form-select-creatable';
import { roles } from '@asd-stan/user/components/invite-users/forms/select-data/data';
import { getAppointerService } from '@asd-stan/user/infrastructure/getters';
import { Field, FormikValues, useFormikContext } from 'formik';

export const General: React.FC = observer(() => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { values, setFieldValue, setFieldError, setFieldTouched } =
		useFormikContext<FormikValues>();

	useEffect(() => {
		if (!isRoleAllowToSeeDomain(values)) {
			setFieldValue(`domains`, []);
			setFieldTouched('domains', false);
		}
	}, [values.systemRoles]);

	const { t } = useTranslation();

	const appointerService = getAppointerService();

	const appointerOptions = appointerService.appointers.map(appointer => {
		return { label: appointer.name, value: appointer.id.toString() };
	});

	const handleCreateNewAppointer = async (value: string) => {
		if (value.length > 255) {
			setFieldError('appointer', t('user.userInvite.fieldErrors.length', { number: '1-255' }));
			return;
		}

		setIsLoading(true);

		const newAppointer = await appointerService.createAppointer(value);
		setFieldValue('appointer', { value: newAppointer.id, label: newAppointer.name });

		setIsLoading(false);
	};

	const onSystemRoleSelect = (name: string, option: Option) => {
		setFieldValue(name, option);
		setFieldTouched(name, false);
		setFieldValue('domains', []);
	};

	return (
		<>
			<Field
				component={FormInput}
				name="firstName"
				title={t('user.userInvite.firstName')}
				placeholder={t('user.userInvite.firstNamePlaceholder')}
				mandatory
				showError
			/>
			<Field
				component={FormInput}
				name="lastName"
				title={t('user.userInvite.lastName')}
				mandatory
				showError
			/>
			<Field
				component={FormInput}
				name="email"
				title={t('user.userInvite.email')}
				placeholder="example@gmail.com"
				mandatory
				showError
			/>
			<Field
				component={FormInputPhone}
				name="phone"
				title={t('user.userInvite.phone')}
				placeholder="+11 1111 111111"
				type="number"
				showError
			/>
			<Field
				component={FormSelectCreatable}
				name="appointer"
				title={t('user.userInvite.appointer')}
				options={appointerOptions}
				onCreateOption={handleCreateNewAppointer}
				isLoading={isLoading}
				mandatory
				showError
			/>
			<Field
				component={FormSelect}
				name="systemRoles"
				title={t('user.userInvite.role')}
				onChange={onSystemRoleSelect}
				options={roles}
				mandatory
				isMulti
				showError
			/>
		</>
	);
});
