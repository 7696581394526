export const downloadFileByUrl = async (fileUrl: string, fileName: string) => {
	const response = await fetch(fileUrl);
	const file = await response.blob();
	const localUrl = URL.createObjectURL(file);
	const link = document.createElement('a');
	link.href = localUrl;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	URL.revokeObjectURL(localUrl);
	document.body.removeChild(link);
};

export const getFileByUrl = async (fileUrl: string) => {
	const response = await fetch(fileUrl);
	return response.blob();
};
