import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { CompanyListDTO } from '@asd-stan/user/api/company/company.dto';
import { makeCompanyFromCompanyDTO } from '@asd-stan/user/api/company/company.factory';
import { Company } from '@asd-stan/user/domain/company.entity';

const ALL_COMPANIES = gql`
	query allCompanies {
		allCompanies {
			id
			name
		}
	}
`;

const COMPANY_BY_COUNTRY_ID = gql`
	query ($countryId: Int!) {
		companyByCountry(countryId: $countryId) {
			id
			name
		}
	}
`;

export class CompanyRepo {
	async getCompanies(): Promise<Company[]> {
		const { data } = await stanWorksClient.query<CompanyListDTO>({
			query: ALL_COMPANIES,
		});

		return data.allCompanies.map(dto => makeCompanyFromCompanyDTO(dto));
	}

	async getCompanyByCountryId(id: number): Promise<Company[]> {
		const { data } = await stanWorksClient.query<CompanyListDTO>({
			query: COMPANY_BY_COUNTRY_ID,
			variables: {
				countryId: id,
			},
		});

		return data.companyByCountry.map(dto => makeCompanyFromCompanyDTO(dto));
	}
}
