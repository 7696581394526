import styled from 'styled-components';

export const StyledPageTitle = styled.div`
	display: -webkit-flex;
	padding-bottom: 3px;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	max-width: 68%;
	justify-content: space-between;
	align-items: flex-end;

	color: ${({ theme }) => theme.fontColors.darkGrey};
	font-size: 32px;
	font-weight: 600;
`;
