import { injector } from '@asd-stan/injector/injector';
import { ModalService } from '@asd-stan/shell/domain/modal.service';
import { ToasterService } from '@asd-stan/shell/domain/toaster.service';
import { MODAL_SERVICE, TOASTER_SERVICE } from '@asd-stan/shell/infrastructure/constants';

export const getModalService = () => {
	return injector.get<ModalService>(MODAL_SERVICE);
};

export const getToasterService = () => {
	return injector.get<ToasterService>(TOASTER_SERVICE);
};
