import styled from 'styled-components';

export const StyledDownloadFile = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	color: ${({ theme }) => theme.fontColors.darkGrey};
	font-weight: 500;

	button {
		background-color: transparent;
	}
`;
