import styled from 'styled-components';

export const StyledRestrictedPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${({ theme }) => theme.bgColors.primary};
  
  & .content {
    margin: 0 auto;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    & .title {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 600;
      color:  ${({ theme }) => theme.fontColors.accent};
    }
    
    & .description {
      margin: 12px 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color:  ${({ theme }) => theme.fontColors.lightGrey};
    }
  }
`;