import { useState } from 'react';

import { Checkbox } from '@components/checkbox/checkbox';
import { getIn, useFormikContext } from 'formik';

import { FilterWrapper } from '../filter-wrapper/filter-wrapper';

import { ReactComponent as SearchIcon } from './assets/search.svg';
import { StyledFilterCheckboxGroup } from './filter-checkbox-group.styled';

export const FilterCheckboxGroup = ({
	title,
	checkboxes,
}: {
	title: string;
	checkboxes: { label: string; name: string }[];
}) => {
	const { values, setFieldValue } = useFormikContext();
	const [search, setSearch] = useState('');

	const filteredCheckboxes = checkboxes.filter(({ label }) =>
		label.toLowerCase().includes(search.toLowerCase())
	);

	return (
		<FilterWrapper title={title} optionsNumber={checkboxes.length}>
			<StyledFilterCheckboxGroup>
				<div className="search">
					<SearchIcon />
					<input value={search} onChange={e => setSearch(e.target.value)} placeholder="Search..." />
				</div>
				<div className="checkboxes">
					{filteredCheckboxes.map(({ label, name }) => (
						<Checkbox
							key={name}
							checked={getIn(values, name)}
							onChange={checked => setFieldValue(name, checked)}
							label={label}
						/>
					))}
				</div>
			</StyledFilterCheckboxGroup>
		</FilterWrapper>
	);
};
