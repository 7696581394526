import { styled } from 'styled-components';

export const StepperContainer = styled.div`
	margin: 15px 0 30px 0;
	display: flex;
	width: 87%;
	align-items: center;
`;

export const StepWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;
	position: relative;
`;

export const Step = styled.div`
	background: white;
	border: solid 1px #dae4e8;
	border-radius: 100%;
	text-align: center;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	&.active {
		background: #0b93e3;
		color: white;
		border: solid 1px #0b93e3;
	}
`;

export const StepLabel = styled.div`
	color: #abb3bb;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	position: absolute;
	top: 33px;
	white-space: nowrap;
	&.active {
		color: #0b93e3;
	}
`;

export const StepConnector = styled.div`
	height: 1px;
	width: 100%;
	background: #dae4e8;
	&.active {
		background: #0b93e3;
	}
`;
