import { observer } from 'mobx-react';
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { isArray } from '@apollo/client/utilities';
import { FileUploadController, UploadedFile } from '@asd-stan/file/domain/file-upload-controller';
import { attachmentTypes } from '@asd-stan/standard/domain/enums';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as DropzoneIcon } from '@asd-stan/ui-kit/assets/icons/dropzone-icon.svg';
import { ReactComponent as FileIcon } from '@asd-stan/ui-kit/assets/icons/uploaded-file-icon.svg';
import { FormSelect, Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { FormTextarea } from '@asd-stan/ui-kit/components/form-textarea/form-texarea';
import { ButtonClose } from '@components/button/button-close';
import { formatBytes } from '@components/file-dropzone/utils';
import { Loader } from '@components/loader/loader';
import { MarkedText } from '@components/utility/marked-text';
import { Field, FieldArray, FormikValues, useFormikContext } from 'formik';

import { StyledDroopedFiles, StyledDropzone } from '@components/file-dropzone/file-dropzone.styled';

const fileTypes = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg': ['.svg'],
	'application/xml': ['.xml'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'text/csv': ['.csv'],
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'image/tiff': ['.tiff'],
	'image/vnd.dwg': ['.dwg'],
	'image/bmp': ['.bmp'],
	'application/zip': ['.zip'],
};

export interface Attachment {
	file: UploadedFile;
	attachmentDescription: string;
	attachmentType: Option;
}

export interface AttachmentsMethods {
	clearUploadedFiles: () => void;
}

export const Attachments = observer(
	forwardRef<AttachmentsMethods>((props, ref) => {
		const url = window.location.href;
		const [fileData, setFileData] = useState<Attachment[]>([]);
		const { values, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();
		const { t } = useTranslation();
		const fileUploadController = useMemo(() => new FileUploadController(), []);
		const standardService = getStandardService();

		useImperativeHandle(ref, () => ({
			clearUploadedFiles: () => {
				fileUploadController.clearUploadedFiles();
				setFieldValue('attachments', []).then(() => setFileData([]));
			},
		}));

		useEffect(() => {
			if (values.attachments && values.attachments.length && fileData.length === 0) {
				setFileData(values.attachments);
				fileUploadController.setUploadedFiles(values.attachments);
			}
		}, []);

		useEffect(() => {
			const setValue = () => {
				setFieldValue('attachments', fileData);

				if (Array.isArray(fileData) && fileData.length > 0) {
					if (fileData?.some(file => file && file.file?.fileId === null)) {
						return;
					} else {
						handleSubmit();
					}
				}
			};

			setValue();
		}, [fileData]);

		const updateFiles = async (file: UploadedFile[]) => {
			if (isArray(file)) {
				const waitAndRetry = () => {
					if (fileUploadController.isLoading) {
						setTimeout(waitAndRetry, 200);
					} else {
						updateIds();
					}
				};

				waitAndRetry();
			}
		};

		const onFileDelete = async (fileToDelete: Attachment) => {
			if (values.attachments?.length === 1) {
				await setFieldValue('attachments', []);
				setFileData(() => []);
				fileUploadController.clearUploadedFiles();
				handleSubmit();
			} else {
				setFileData(previousAttachments => {
					const updatedAttachments = previousAttachments.filter(
						attachment => attachment.file !== fileToDelete.file
					);
					fileUploadController.setUploadedFiles(updatedAttachments);
					return [
						...updatedAttachments.map((file, index) => {
							const baseId =
								standardService.attachmentIds.find(item => item?.file?.id === file.file?.fileId)
									?.id || null;

							return {
								file: {
									id: baseId,
									title: file.file.title,
									size: file.file.size,
									fileId: file.file.fileId,
									path: file.file.path,
								},
								attachmentDescription: previousAttachments[index]?.attachmentDescription,
								attachmentType: previousAttachments[index]?.attachmentType,
							};
						}),
					];
				});
				setFileData(files => {
					fileUploadController.setUploadedFiles(
						files.filter(attachment => attachment.file !== fileToDelete.file)
					);
					return files.filter(attachment => attachment.file !== fileToDelete.file);
				});
			}
		};

		const onDrop = useCallback(
			async (acceptedFiles: File[]) => {
				if (acceptedFiles?.length) {
					let fileObjects: File[];
					setFileData(previousAttachments => {
						return [
							...previousAttachments,
							...acceptedFiles.map(file => {
								return {
									file: {
										id: null,
										title: file.name,
										size: file.size,
										fileId: null,
										path: '',
									},
									attachmentDescription: '',
									attachmentType: { label: '', value: '' },
								};
							}),
						];
					});

					fileObjects = [
						...acceptedFiles.map(file =>
							Object.assign(file, { preview: URL.createObjectURL(file) })
						),
					];

					let files: UploadedFile[] = [];

					for (const file of fileObjects) {
						const uploadedFile = await fileUploadController.uploadFile(file, true);

						files.push(uploadedFile);
					}

					updateFiles(files);
				}
			},
			[fileUploadController, updateFiles]
		);

		const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
			onDrop,
			multiple: true,
			accept: fileTypes,
			maxSize: 20971520,
		});

		const updateIds = () => {
			setFileData(previousAttachments => {
				return [
					...fileUploadController.uploadedFiles.map((file, index) => {
						const baseId =
							standardService.attachmentIds.find(item => item?.file?.id === file?.fileId)?.id ||
							null;

						return {
							file: {
								id: baseId,
								title: file.title,
								size: file.size,
								fileId: file.fileId,
								path: file.path,
							},
							attachmentDescription: previousAttachments[index]?.attachmentDescription,
							attachmentType: previousAttachments[index]?.attachmentType,
						};
					}),
				];
			});
		};

		const onDescriptionChange = (e: React.ChangeEvent<HTMLDivElement>, index: number) => {
			const updatedFileData = fileData;
			updatedFileData[index].attachmentDescription = e.target.innerText;
			updateIds();
		};

		const onTypeChange = (option: Option, index: number) => {
			const updatedFileData = fileData;
			updatedFileData[index].attachmentType = option;
			updateIds();
		};

		return (
			<>
				<StyledDropzone className={`${isDragActive ? 'active' : ''}`} {...getRootProps()}>
					<DropzoneIcon />
					<input {...getInputProps()} />
					<div className="dropzone-text">
						<Trans i18nKey="common.uploader.dragAndDrop">
							Drag & Drop or <MarkedText>Choose</MarkedText> a file to upload
						</Trans>
						<p>
							{Object.values(fileTypes)
								.flat()
								.map(ext => ext.replace('.', ''))
								.join(', ')}
						</p>
						<p>
							{t('common.uploader.tooltips.unlimitedFilesSize', { size: formatBytes(20971520) })}
						</p>
						{fileRejections.map((file, index) => (
							<React.Fragment key={index}>
								{file.errors.some(err => err.code === 'file-too-large') && (
									<p className="dropzone-error">
										{t('common.uploader.errors.fileIsTooLarge', { name: file.file.name })}
									</p>
								)}
							</React.Fragment>
						))}
					</div>
				</StyledDropzone>

				<FieldArray name="attachments">
					{() => (
						<StyledDroopedFiles key={`files-${values.attachments && values.attachments.length}`}>
							{values.attachments?.map((attachment: Attachment, index: number) => (
								<div className="file-item" key={attachment.file.title + index}>
									<li className="file-list">
										<div className="file-container">
											<FileIcon />
											<div>
												<p className="file-name">{attachment.file?.title}</p>
												<p className="file-properties">
													{formatBytes(attachment.file?.size)}
													{fileUploadController.isLoading ? (
														<>
															<span>{' \u2219 '}</span> {t('common.uploader.pleaseWait')}
														</>
													) : null}
												</p>
											</div>
											{fileUploadController.isLoading ? (
												<Loader />
											) : (
												<ButtonClose onClick={() => onFileDelete(attachment)} />
											)}
										</div>
									</li>
									<Field
										component={FormTextarea}
										name={`attachments.${index}.attachmentDescription`}
										title={t('standard.createNWP.attachments.attachmentsDescription')}
										hint={t('standard.createNWP.attachments.attachmentsDescriptionHint')}
										fullWidth
										mandatory
										showError
										disabled={fileUploadController.isLoading}
										maxLength={500}
										disableMaxLength={url.includes('draft')}
										useSubmitOnBlur
										onBlur={(e: React.ChangeEvent<HTMLDivElement>) => onDescriptionChange(e, index)}
									/>
									<Field
										component={FormSelect}
										name={`attachments.${index}.attachmentType`}
										options={attachmentTypes}
										title={t('standard.createNWP.attachments.attachmentsType')}
										showError
										disabled={fileUploadController.isLoading}
										mandatory
										fullWidth
										customChange={(option: Option) => onTypeChange(option, index)}
									/>
								</div>
							))}
						</StyledDroopedFiles>
					)}
				</FieldArray>
			</>
		);
	})
);
