import { CompanyDTO } from '@asd-stan/user/api/company/company.dto';
import { Company } from '@asd-stan/user/domain/company.entity';

export const makeCompanyFromCompanyDTO = (dto: CompanyDTO | null): Company => {
	if (!dto) {
		return new Company({
			id: 0,
			name: '',
		});
	}

	return new Company({
		id: dto.id,
		name: dto.name,
	});
};
