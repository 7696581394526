import React, { useState } from 'react';

import { ReactComponent as HideIcon } from '@asd-stan/ui-kit/assets/icons/eye-close-icon.svg';
import { ReactComponent as ShowIcon } from '@asd-stan/ui-kit/assets/icons/eye-open-icon.svg';
import { FieldProps } from 'formik';

import { Flex } from '../utility/flex';

import {
	ForgotPasswordLink,
	PasswordInput,
	PasswordInputContainer,
	ShowHideButton,
	StyledFormInputPassword,
} from './form-input-password.styled';

interface InputProps {
	placeholder?: string;
	errors: string;
	title: string;
	name: string;
	forgot?: boolean;
	handleSubmit?: () => void;
}

export const FormInputPassword: React.FC<InputProps & FieldProps> = ({
	title,
	placeholder,
	field,
	form: { touched, errors },
	forgot = false,
	handleSubmit,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (handleSubmit) {
				return handleSubmit();
			}
		}
	};

	return (
		<StyledFormInputPassword $error={!!(touched[field.name] && errors[field.name])}>
			<Flex $justify="space-between" $align="center">
				<label title={title}>{title}</label>
				{forgot && (
					<ForgotPasswordLink href="/init-reset-password">Forgot password?</ForgotPasswordLink>
				)}
			</Flex>
			<PasswordInputContainer>
				<PasswordInput
					type={showPassword ? 'text' : 'password'}
					placeholder={placeholder}
					onKeyDown={handleKeyDown}
					{...field}
				/>
				<ShowHideButton type="button" onClick={handleTogglePassword} $show={showPassword}>
					{showPassword ? <ShowIcon /> : <HideIcon />}
				</ShowHideButton>
			</PasswordInputContainer>
		</StyledFormInputPassword>
	);
};
