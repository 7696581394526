import React, { useEffect, useState } from 'react';

import { ButtonContainer, StyledScrollToTop } from './scroll-to-top.styled';

export const ScrollToTop: React.FC = () => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const handleScroll = () => {
		if (window.scrollY > 100) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<ButtonContainer>
			{isVisible && <StyledScrollToTop onClick={scrollToTop}>&uarr;</StyledScrollToTop>}
		</ButtonContainer>
	);
};
