import uuid from 'react-uuid';

import i18n from '@asd-stan/i18n/i18n';
import moment from 'moment';

import {
	BallotResult,
	BallotStatus,
	SingleBallotToUpdate,
	ValidatedBallotForm,
} from '../domain/ballot.entity';

interface CreateUpdateBallotDto {
	title: string;
	description: string;
	status: 'pending' | 'active' | 'closed';
	standardId: number | null;
	openingDate: string;
	closingDate: string;
	selectedVoters: number[];
	result: 'pending' | 'approved' | 'disapproved' | 'notValid';
	resultComment: string;
	attachments: {
		id?: null | number;
		fileId: number;
	}[];
	questions: {
		id: null | number;
		title: string;
		comment: string;
	}[];
}

const dateFormat = 'YYYY-MM-DD';

export const mapCreateUpdateBallotDto = ({
	title,
	description,
	standard,
	openingDate,
	closingDate,
	selectedVoters,
	result,
	resultComment,
	attachments,
	questions,
	status,
}: ValidatedBallotForm): CreateUpdateBallotDto => ({
	title,
	description,
	status,
	standardId: standard?.value ? standard.value : null,
	openingDate: moment(openingDate).format(dateFormat),
	closingDate: moment(closingDate).format(dateFormat),
	selectedVoters: selectedVoters.map(({ value }) => value),
	result: result.value,
	resultComment,
	attachments: attachments
		.filter(({ fileId }) => fileId !== null)
		.map(({ fileId, id }) => ({
			id,
			fileId: fileId as number,
		})),
	questions: questions.map(({ id, comment, title }) => ({
		id,
		comment,
		title,
	})),
});

interface BallotFormDto {
	title: string;
	description: string;
	status: 'active' | 'pending';
	standard: {
		id: number;
		registrationNumber: string;
		form: string;
		edition: string;
		title: string;
	} | null;
	openingDate: string;
	closingDate: string;
	selectedVoters: {
		id: number;
		firstName: string;
		lastName: string;
	}[];
	attachments: {
		id: number;
		file: { id: number; name: string; path: string | null; size: number };
	}[];
	result: 'pending' | 'approved' | 'disapproved' | 'notValid';
	resultComment: string | null;
	questions: { id: number; title: string; comment: string }[];
	createdBy: {
		id: number;
	};
}

export const mapBallotForm = ({
	status,
	title,
	description,
	standard,
	openingDate,
	closingDate,
	selectedVoters,
	result,
	resultComment,
	attachments,
	questions,
	createdBy,
}: BallotFormDto): SingleBallotToUpdate => ({
	createdById: createdBy.id,
	form: {
		title,
		description,
		standard: standard
			? {
					label: `${standard.registrationNumber} ${standard.form} ${standard.edition} ${standard.title}`,
					value: standard.id,
			  }
			: null,
		openingDate: new Date(openingDate),
		closingDate: new Date(closingDate),
		selectedVoters: selectedVoters.map(({ id, firstName, lastName }) => ({
			label: `${firstName} ${lastName}`,
			value: id,
		})),
		result: {
			label: i18n.t(`ballot.ballotResult.${result}`),
			value: result as BallotResult,
		},
		resultComment: resultComment || '',
		attachments: attachments.map(({ file, id }) => ({
			id,
			fileId: file.id,
			path: file.path || '',
			size: file.size,
			title: file.name,
		})),
		status: status as BallotStatus,
		questions: questions.map(({ id, title, comment }) => ({ id, localId: uuid(), title, comment })),
	},
});
