import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DownloadFile } from '@asd-stan/standard/components/download-file/download-file';
import { JustificationChanges } from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/page-blocks/justification/justifiaction-changes/justification-changes';
import { Justification as JustificationData } from '@asd-stan/standard/domain/standard-detailed.entity';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import {
	StandardDisplayField,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
	StandardReference,
} from '../../single-standard.styled';

import {
	JustificationContainer,
	JustificationLinkContainer,
	LinkContainer,
	LinkStyles,
} from './justification-components.styled';

interface Props {
	data: JustificationData | null;
	standardName: string;
}

export const Justification: FC<Props> = props => {
	const { t } = useTranslation();

	const renderStandards = () =>
		props.data?.consideredStandards.map((standard, index) => (
			<div key={`${standard.id}-${standard.name}`}>
				<Link target="_blank" style={LinkStyles} to={`/standards/detailed/${standard.id}/general`}>
					{standard.name}
				</Link>
				{index !== props.data!.consideredStandards.length - 1 ? ',' : null}
			</div>
		));

	const renderOutsideStandards = () => {
		if (!props.data) {
			return null;
		}
		return (
			<StandardReference
				dangerouslySetInnerHTML={{ __html: props.data.externalConsideredStandards }}
			/>
		);
		// const standards = props.data.externalConsideredStandards.split(', ');

		// return standards.map((standard, index) => (
		// 	<div key={`${standard}-${index}`}>
		// 		{index !== standards.length - 1 ? (
		// 			<>
		// 				<LinkContainer dangerouslySetInnerHTML={{ __html: standard }} />
		// 			</>
		// 		) : (
		// 			<LinkContainer dangerouslySetInnerHTML={{ __html: standard }} />
		// 		)}
		// 	</div>
		// ));
	};

	const patentRender = () => {
		if (!props.data) {
			return null;
		}
		if (!props.data.patentFile && !props.data.patentReferences) {
			return null;
		}

		if (!props.data.patentFile && props.data.patentReferences) {
			return (
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.patentReferences')}
					</StandardDisplayFieldHeader>
					<StandardReference dangerouslySetInnerHTML={{ __html: props.data.patentReferences }} />
				</StandardDisplayField>
			);
		}

		return (
			<StandardDisplayField>
				<StandardDisplayFieldHeader>
					{t('standard.singleStandardPage.justification.patentReferences')}
				</StandardDisplayFieldHeader>
				<DownloadFile
					notFromAssets
					title={props.data.patentFile!.name}
					file={props.data.patentFile!.path}
				/>
			</StandardDisplayField>
		);
	};

	if (
		props.data &&
		props.data.knownPatentIssue === false &&
		!props.data.purpose &&
		props.data.consideredStandards.length === 0 &&
		!props.data.externalConsideredStandards &&
		props.data.technicalChanges &&
		props.data.technicalChanges.length === 1 &&
		props.data.technicalChanges[0] &&
		props.data.technicalChanges[0].title === '' &&
		!props.data.revisionJustification
	) {
		return null;
	}

	if (!props.data) {
		return (
			<InputGroup title={t('standard.singleStandardPage.justification.title')}>
				<JustificationContainer>
					<StandardDisplayField>
						<StandardDisplayFieldHeader>
							{t('standard.singleStandardPage.justification.purpose')}
						</StandardDisplayFieldHeader>
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.justification.purposeAbsent')}
						</StandardDisplayFieldValueAbsent>
					</StandardDisplayField>
					<StandardDisplayField>
						<StandardDisplayFieldHeader>
							{t('standard.singleStandardPage.justification.revisionJustification')}
						</StandardDisplayFieldHeader>
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.justification.revisionJustificationAbsent')}
						</StandardDisplayFieldValueAbsent>
					</StandardDisplayField>
					<StandardDisplayField>
						<StandardDisplayFieldHeader>
							{t('standard.singleStandardPage.justification.otherStandardsInASD')}
						</StandardDisplayFieldHeader>
						<JustificationLinkContainer>
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.justification.otherStandardsOutsideASDAbsent')}
							</StandardDisplayFieldValueAbsent>
						</JustificationLinkContainer>
					</StandardDisplayField>
					<StandardDisplayField>
						<StandardDisplayFieldHeader>
							{t('standard.singleStandardPage.justification.otherStandardsOutsideASD')}
						</StandardDisplayFieldHeader>
						<JustificationLinkContainer>
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.justification.otherStandardsInASDAbsent')}
							</StandardDisplayFieldValueAbsent>
						</JustificationLinkContainer>
					</StandardDisplayField>
				</JustificationContainer>
			</InputGroup>
		);
	}

	return (
		<InputGroup title={t('standard.singleStandardPage.justification.title')}>
			<JustificationContainer>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.purpose')}
					</StandardDisplayFieldHeader>
					{props.data.purpose ? (
						<StandardDisplayFieldValue>{props.data.purpose}</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.justification.purposeAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
				{props.data.revisionJustification?.length !== 0 && (
					<StandardDisplayField>
						<StandardDisplayFieldHeader>
							{t('standard.singleStandardPage.justification.revisionJustification')}
						</StandardDisplayFieldHeader>
						{props.data.revisionJustification ? (
							<StandardDisplayFieldValue>
								{props.data.revisionJustification}
							</StandardDisplayFieldValue>
						) : (
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.justification.revisionJustificationAbsent')}
							</StandardDisplayFieldValueAbsent>
						)}
					</StandardDisplayField>
				)}
				{props.data.technicalChanges.length > 0 &&
					props.data.technicalChanges[0].title !== '' &&
					props.data.technicalChanges[0].change !== '' && (
						<JustificationChanges standardName={props.standardName} data={props.data} />
					)}
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.otherStandardsInASD')}
					</StandardDisplayFieldHeader>
					<JustificationLinkContainer>
						{props.data.consideredStandards.length ? (
							renderStandards()
						) : (
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.justification.otherStandardsOutsideASDAbsent')}
							</StandardDisplayFieldValueAbsent>
						)}
					</JustificationLinkContainer>
				</StandardDisplayField>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.justification.otherStandardsOutsideASD')}
					</StandardDisplayFieldHeader>
					<JustificationLinkContainer>
						{props.data.externalConsideredStandards ? (
							renderOutsideStandards()
						) : (
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.justification.otherStandardsInASDAbsent')}
							</StandardDisplayFieldValueAbsent>
						)}
					</JustificationLinkContainer>
				</StandardDisplayField>
				{patentRender()}
			</JustificationContainer>
		</InputGroup>
	);
};
