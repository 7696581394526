import React, { ReactNode } from 'react';

import { StyledBorderButton } from './button.styled';

interface ButtonProps {
	title: string;
	onClick: () => void;
	icon?: ReactNode | undefined;
	className?: string;
	disabled?: boolean;
}

export const BorderButton: React.FC<ButtonProps> = ({
	title,
	onClick,
	icon,
	className,
	disabled = false,
}) => {
	return (
		<StyledBorderButton>
			<button
				type="button"
				className={className}
				title={title}
				onClick={onClick}
				disabled={disabled}>
				<span>{icon}</span>
				{title}
			</button>
		</StyledBorderButton>
	);
};
