import { makeAutoObservable } from 'mobx';

interface WorkingGroupArgs {
	name: string;
	code: string;
	domain: string;
}

export class WorkingGroup {
	private _name: string;
	private _code: string;
	private _domain: string;

	constructor(args: WorkingGroupArgs) {
		makeAutoObservable(this);

		this._name = args.name;
		this._code = args.code;
		this._domain = args.domain;
	}

	//getters

	get code() {
		return this._code;
	}
	get name() {
		return this._name;
	}

	get domain() {
		return this._domain;
	}

	// getters end
}
