import { styled } from 'styled-components';

export const JustificationChangeMainWrapper = styled.div`
    display: flex;
		flex-direction: column;
		gap: 12px;
`

export const JustificationChangeContainer = styled.div`
    background: #F7FAFB;
    border-radius: 12px;
    padding: 16px;
`

export const JustificationHeader = styled.div`
    color: #525259;
    font-size: 14px;
    font-weight: 600;
    margin-top: 4px;
    flex: 1;
    flex-wrap: wrap;
    line-height: 19px;
`

export const JustificationChangeItem = styled.div`
    line-height: 19px;
    gap: 4px;
    display: flex;
    flex-direction: column;
`
