import styled from 'styled-components';

export const StyledCatalogList = styled.div`
	margin-top: 20px;

	.actions {
		gap: 10px;
		flex-wrap: wrap;
	}

	td.title {
		max-width: 250px;

		& > div {
			white-space: normal;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
		}
	}

	td.published {
		color: ${({ theme }) => theme.fontColors.lightGrey};
		font-weight: 400;

		& > div {
			display: table-caption;
			white-space: normal;
		}
	}

	td.price {
		font-size: 16px;
		color: ${({ theme }) => theme.fontColors.textGrey};
		font-weight: 700;
	}

	.table-container {
		& > div {
			height: auto;
		}
	}
`;

export const StyledLink = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	background: rgba(11, 147, 227, 0.12);
	border-radius: 4px;
	padding: 8px;
	font-size: 11px;
	line-height: 150%;
	color: #0b93e3;

	&:hover {
		background: #0b93e3;
		color: #fff;

		path {
			fill: #fff;
			stroke: #fff;
		}
	}
`;
