import {
	BallotResult,
	VotingDecision,
	VotingDecisionResult,
} from '@asd-stan/ballot/domain/ballot.entity';
import styled from 'styled-components';

export const StyledSingleBallot = styled.div`
	margin-top: 24px;

	.title {
		max-width: fit-content;
	}
`;

export const StyledBallotField = styled.div<{ $fullWidth?: boolean }>`
	width: ${props => (props.$fullWidth ? '100%' : '400px')};
	margin-top: 16px;

	.label {
		font-weight: 500;
		font-size: 11px;
		line-height: 16.5px;
		color: #abb3bb;
		text-transform: uppercase;
	}

	.value {
		font-size: 13px;
		line-height: 19.5px;
		font-weight: 600;
		margin-top: 4px;

		a {
			color: #0b93e3;
			text-decoration: underline;
		}
	}

	.emptyValuePlaceholder {
		font-size: 13px;
		line-height: 19.5px;
		font-weight: 500;
		margin-top: 4px;
		color: #abb3bb;
	}

	.timestamp {
		font-weight: 400;
		font-size: 13px;
		line-height: 19.5px;
		color: #abb3bb;
		text-align: end;
	}

	.file {
		padding: 8px 16px;
		border: 1px solid #dae4e8;
		border-radius: 8px;
		display: flex;
		align-items: center;

		.downloadIcon {
			cursor: pointer;
		}

		.file-img {
			margin-right: 16px;
		}

		.file-right {
			flex-grow: 1;

			.file-title {
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 6px;
			}

			.file-meta {
				font-size: 13px;
				color: #abb3bb;
			}
		}
	}
`;

const ballotResultColors: Record<BallotResult, string> = {
	[BallotResult.Approved]: '#09B910',
	[BallotResult.Disapproved]: '#EA3E53',
	[BallotResult.Pending]: '#E3A70B',
	[BallotResult.NotValid]: '#525259',
};

export const StyledBallotResult = styled.span<{ $type: BallotResult }>`
	font-size: 13px;
	font-weight: 600;
	text-transform: capitalize;
	color: ${({ $type }) => ballotResultColors[$type]};
`;

const VotingDecisionColors: Record<VotingDecisionResult, string> = {
	[VotingDecisionResult.Approved]: '#09B910',
	[VotingDecisionResult.Disapproved]: '#EA3E53',
	[VotingDecisionResult.Pending]: '#E3A70B',
	[VotingDecisionResult.Abstain]: '#525259',
};

export const StyledStatsBallotResult = styled.div<{ $result: VotingDecisionResult }>`
	color: #abb3bb;
	font-weight: 500;
	font-size: 13px;
	line-height: 19.5px;
	margin-top: 12px;

	span {
		font-weight: 600;
		color: ${({ $result }) => VotingDecisionColors[$result]};
		text-transform: capitalize;
	}
`;

export const StyledStats = styled.div`
	display: flex;
	width: 100%;
	margin-top: 16px;

	.item {
		width: 25%;
		margin-right: 4px;
		border: 1px solid #dae4e8;
		padding: 8px 24px 8px 16px;
		display: flex;
		align-items: center;
		border-radius: 8px;

		&:last-child {
			margin-right: 0;
		}

		.icon {
			padding: 8px;
			border-radius: 8px;
			background-color: #0b93e329;
			margin-right: 10px;

			&.approve {
				background-color: #09b91029;
			}

			&.disapprove {
				background-color: #ea3e5329;
			}

			&.abstain {
				background-color: #04163029;
			}

			svg {
				display: block;
			}
		}

		.label {
			font-size: 12px;
			line-height: 18px;
			color: #abb3bb;
			margin-bottom: 2px;
		}

		.value {
			font-size: 20px;
			line-height: 24.4px;
			font-weight: 500;
		}
	}
`;

export const StyledLoaderContainer = styled.div`
	height: calc(100vh - 300px);
	> div {
		height: 100%;
		margin: 0 auto;
	}

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;

export const StyledBallotStatus = styled.div`
	color: #abb3bb;
	font-weight: 500;
	font-size: 13px;
	line-height: 19.5px;

	span {
		font-weight: 600;
		color: #0b93e3;
		text-transform: capitalize;
	}
`;

export const StyledVoteSection = styled.div`
	width: 888px;
	border-radius: 16px;
	background-color: ${({ theme }) => theme.fontColors.white};
	padding: 24px 32px;
	margin: 16px 0;

	@media (min-width: 1500px) {
		width: 80%;
	}

	& h4 {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		margin-bottom: 8px;
	}

	.user {
		margin-bottom: 16px;

		.userInfo {
			margin-left: 10px;
			font-size: 14px;
			line-height: 21px;

			.userName {
				font-weight: 600;
				color: #525259;

				span {
					color: #0b93e3;
					margin-left: 6px;
				}
			}

			.lastUpdate {
				color: #abb3bb;
			}
		}
	}

	.attachments {
		h6 {
			text-transform: uppercase;
			font-size: 11px;
			line-height: 16.5px;
			font-weight: 500;
			color: #abb3bb;
		}

		& > div {
			margin-top: 4px;
		}

		.seeAll {
			color: #0b93e3;
			font-weight: 500;
			font-size: 11px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.block {
		padding: 20px;
		border-radius: 16px;
		border: 1px solid #dae4e8;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		& > div {
			margin-top: 0;
		}

		.block-title {
			font-size: 13px;
			font-weight: 500;
			line-height: 15.86px;
			margin-bottom: 16px;

			span {
				color: #0b93e3;
			}
		}

		.question {
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
		}

		.buttons {
			margin-bottom: 16px;

			.buttons-wrapper {
				display: flex;
				margin-bottom: 8px;

				button {
					margin-right: 8px;
					width: 100%;
					padding: 10px;
					font-size: 14px;
					border-radius: 50px;
					background: #dae4e8;

					&:last-child {
						margin-right: 0;
					}

					&:hover,
					&.active {
						font-weight: 600;
						color: #fff;
						background-color: #0b93e3;
					}

					&.disabled {
						cursor: default;
						color: #abb3bb;
						background: #edf2f3;

						&.active {
							background: #7daac5;
							color: #fff;

							&:hover {
								background: #7daac5;
								font-weight: 600;
							}
						}

						&:hover {
							background: #edf2f3;
							font-weight: 400;
						}
					}
				}
			}

			.buttons-error {
				color: #ea3e53;
			}
		}
	}
`;

export const StyledDecision = styled.div<{ $decision: VotingDecision }>`
	display: flex;
	align-self: flex-start;
	align-items: center;
	font-weight: 600;
	font-size: 13px;
	line-height: 19.5px;
	color: ${({ $decision: $result }) =>
		$result === VotingDecision.Approve
			? '#09B910'
			: $result === VotingDecision.Disapprove
			  ? '#EA3E53'
			  : '#525259'};

	.icon {
		padding: 8px;
		border-radius: 8px;
		background-color: ${({ $decision: $result }) =>
			$result === VotingDecision.Approve
				? '#09B91029'
				: $result === VotingDecision.Disapprove
				  ? '#EA3E5329'
				  : '#04163029'};
		margin-right: 10px;

		svg {
			display: block;
		}
	}
`;

export const StyledVoteListLoading = styled.div`
	width: 888px;
	display: flex;
	justify-content: center;

	@media (min-width: 1500px) {
		width: 80%;
	}
`;

export const StyledNoPermissionToVote = styled.div`
	padding: 12px 24px 12px 24px;
	border: 1px solid #e3a70b52;
	background: #fffcec;
	display: flex;
	align-items: center;
	border-radius: 8px;
	width: 888px;
	font-weight: 600;
	font-size: 15px;

	@media (min-width: 1500px) {
		width: 80%;
	}

	svg {
		margin-right: 10px;
	}
`;
