import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useRequest } from '@asd-stan/helpers/use-request';
import {
	MeetingForm as MeetingFormType,
	ValidatedMeetingForm,
} from '@asd-stan/meeting/domain/meeting.entity';
import { getMeetingService } from '@asd-stan/meeting/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';

import { MeetingForm } from '../meeting-form/meeting-form';
import { StyledLoaderContainer } from '../single-meeting/single-meeting.styled';

import { ContentContainer } from '@components/utility/content-container.styled';

export const UpdateMeeting = () => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const id = Number(params.id);
	const meetingService = getMeetingService();
	const toasterService = getToasterService();
	const { data: meeting, isLoading: meetingLoading } = useRequest(
		useCallback(() => meetingService.getSingleMeeting(id), [id])
	);

	if (meetingLoading || !meeting) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	const handleSubmit = async (values: MeetingFormType) => {
		try {
			await meetingService.updateMeeting(id, values as ValidatedMeetingForm);
			toasterService.showToast(ToasterStatus.success, t('meeting.updateMeeting.success'));
			navigate('/meetings');
		} catch (err: any) {
			console.error(err);
		}
	};

	return (
		<MeetingForm
			title={t('meeting.updateMeeting.title')}
			onSubmit={handleSubmit}
			initialValues={meeting.form}
		/>
	);
};
