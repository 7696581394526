import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateStageForm } from '@asd-stan/standard/domain/stage-update.entity';
import { StandardStage } from '@asd-stan/standard/domain/standard-stage.entity';
import { Checkbox } from '@components/checkbox/checkbox';
import { useFormikContext } from 'formik';

import {
	StageItem,
	StagePickerContainer,
	StageSelectorTitle,
} from '@asd-stan/standard/components/single-standard/stage-update/add-new-stage/add-new-stage.styled';

interface Props {
	newStageOptions: StandardStage[];
	priceAdded: boolean;
	fileAdded: boolean;
}

export const AddNewStage: FC<Props> = ({ newStageOptions, priceAdded, fileAdded }) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<UpdateStageForm>();

	const options = newStageOptions.map(({ code, name, immediatelyPublication }) => {
		const active = values.stages.newStages.includes(code);
		const disabledMessage =
			immediatelyPublication && !priceAdded
				? t('standard.singleStandard.stageUpdateModal.priceNotAdded')
				: immediatelyPublication && !fileAdded
				  ? t('standard.singleStandard.stageUpdateModal.fileNotAdded')
				  : null;

		const handleChange = () => {
			if (disabledMessage) {
				return;
			}
			if (active) {
				setFieldValue(
					'stages.newStages',
					values.stages.newStages.filter(c => code !== c)
				);
				return;
			}
			setFieldValue('stages.newStages', [...values.stages.newStages, code]);
		};

		return {
			code,
			name,
			active,
			disabledMessage,
			handleChange,
		};
	});

	return (
		<>
			<StageSelectorTitle>
				{t('standard.singleStandard.stageUpdateModal.allStages')}
			</StageSelectorTitle>
			<StagePickerContainer>
				{options.map(({ code, name, active, disabledMessage, handleChange }) => {
					return (
						<StageItem
							onClick={handleChange}
							className={active ? 'active' : disabledMessage ? 'disabled' : ''}
							key={code}>
							<Checkbox
								checked={active}
								onChange={handleChange}
								label={
									<div>
										<div className="optionName">
											{code} {name}
										</div>
										{disabledMessage && (
											<div className="disabledOptionMessage">{disabledMessage}</div>
										)}
									</div>
								}
							/>
						</StageItem>
					);
				})}
			</StagePickerContainer>
		</>
	);
};
