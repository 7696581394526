import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { BallotForm } from '@asd-stan/ballot/domain/ballot.entity';
import { ReactComponent as Plus } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/plus-icon.svg';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { BorderButton } from '@components/button/border-button';
import { FormInput } from '@components/form-input/form-input';
import { Flex } from '@components/utility/flex';
import { Field, useFormikContext } from 'formik';

import { ReactComponent as DeleteIcon } from '../assets/delete.svg';

export const Questions = () => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<BallotForm>();

	const questions = values.questions;

	const addQuestion = () =>
		setFieldValue('questions', [
			...questions,
			{ id: null, localId: uuid(), title: '', comment: '' },
		]);

	const removeQuestion = (localId: string | number) =>
		setFieldValue(
			'questions',
			questions.filter(q => localId !== q.localId)
		);

	return (
		<InputGroup title={t('ballot.createBallot.questions')}>
			<Flex $direction="column" $align="center" style={{ width: '100%' }}>
				{questions.map(({ localId }, index) => (
					<Flex key={localId} className="question" $align="flex-start">
						<div className="questionWrapper">
							<Field
								component={FormInput}
								name={`questions[${index}].title`}
								title={`${t('ballot.createBallot.titleField')} ${index + 1}`}
								mandatory
								fullWidth
								showError
							/>
							<Field
								component={FormInput}
								name={`questions[${index}].comment`}
								title={t('ballot.createBallot.descriptionComment')}
								fullWidth
								showError
							/>
						</div>
						<button
							disabled={questions.length === 1}
							className="deleteQuestion"
							type="button"
							onClick={() => removeQuestion(localId)}>
							<DeleteIcon />
						</button>
					</Flex>
				))}
				<BorderButton
					className="add-domain-button"
					icon={<Plus />}
					title={t('ballot.createBallot.addQuestion')}
					onClick={addQuestion}
				/>
			</Flex>
		</InputGroup>
	);
};
