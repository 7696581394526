import styled from 'styled-components';

export const StyledErrorFormContainer = styled.div<{ $isError: boolean }>`
	width: 100%;
	padding: 12px 24px;
	justify-content: space-between;
	display: flex;
	margin-top: 20px;

	border: ${props =>
		props.$isError ? props.theme.borders.error : props.theme.borders.warningMessage};
	background: ${props =>
		props.$isError ? props.theme.bgColors.errorMessage : props.theme.bgColors.warningMessage};
	border-radius: 8px;

	> div:last-child {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div:first-child {
			align-self: flex-end;
			margin-top: 0px;
		}

		> div:last-child > button {
			margin-top: 0px;
			background-color: ${({ theme }) => theme.bgColors.white};
			margin-bottom: 4px;
		}
	}

	.error-header {
		width: 100%;
		display: flex;
		flex-direction: column;

		> div {
			display: flex;
			align-items: center;
			width: 100%;
			font-weight: 600;
			color: ${({ theme }) => theme.fontColors.darkGrey};
			margin-bottom: 6px;

			> svg {
				margin-right: 12px;

				path {
					fill: ${props =>
						props.$isError ? props.theme.fontColors.error : props.theme.bgColors.warningMessage};
				}
			}
		}
		> span {
			font-size: 13px;
			color: ${props =>
				props.$isError ? props.theme.fontColors.textGrey : props.theme.fontColors.lightGrey};
			font-weight: ${props => (props.$isError ? 500 : 400)};
			margin: 0px 10px 0px 32px;

			> div {
				min-height: auto;
				> div {
					min-height: auto !important;

					.Select__control {
						margin: 0px 0px 4px;
					}

					.Select__value-container {
						padding-right: 0px;
					}
				}
			}
		}
	}
`;
