import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { isRoleAllowToSeeDomain } from '@asd-stan/helpers/app-utils';
import { ReactComponent as Dropdown } from '@asd-stan/ui-kit/assets/icons/dropdown.svg';
import { StyledAnimatedIconWrapper } from '@asd-stan/ui-kit/components/utility/animated-icon-wrapper';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { FormikValues, useFormikContext } from 'formik';

import { StyledDomainInputGroup } from '@asd-stan/user/components/layout/input-group/input-group.styled';

export const DomainInputGroup = ({
	children,
	initiallyOpened = false,
}: {
	children: React.ReactNode;
	initiallyOpened?: boolean;
}) => {
	const { values } = useFormikContext<FormikValues>();
	const [isOpen, setIsOpen] = useState<boolean>(initiallyOpened);
	const { t } = useTranslation();

	const domainService = getDomainService();

	useEffect(() => {
		domainService.getDomains();
	}, []);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	if (isRoleAllowToSeeDomain(values)) {
		return (
			<StyledDomainInputGroup>
				<Flex $cursor="pointer" $justify="space-between" $align="center" onClick={handleClick}>
					<h4>{t('user.userInvite.domainTitle')}</h4>
					<StyledAnimatedIconWrapper open={isOpen}>
						<Dropdown fill="#525259" />
					</StyledAnimatedIconWrapper>
				</Flex>
				{isOpen ? <>{children}</> : null}
			</StyledDomainInputGroup>
		);
	} else {
		return null;
	}
};
