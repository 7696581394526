import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { languages } from '@asd-stan/common/enums';
import { DraftUpdate } from '@asd-stan/draft/domain/draft-update';
import { DraftService } from '@asd-stan/draft/domain/draft.service';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { AdditionalInformation } from '@asd-stan/standard/components/nwp/forms/additional-information';
import { Attachment } from '@asd-stan/standard/components/nwp/forms/attachments';
import { Attachments } from '@asd-stan/standard/components/nwp/forms/attachments';
import { StandardDraft } from '@asd-stan/standard/components/nwp/forms/standard-draft';
import { Titles } from '@asd-stan/standard/components/nwp/forms/titles';
import { attachmentTypes, forms, revisions } from '@asd-stan/standard/domain/enums';
import { Remark } from '@asd-stan/standard/domain/remark.entity';
import {
	AttachmentResponse,
	Originator,
	TechnicalChanges,
} from '@asd-stan/standard/domain/standard-detailed.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { NWPSchema, emptySchema } from '@asd-stan/standard/validation-schemas/nwp.schema';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { BreadCrumbs } from '@asd-stan/ui-kit/components/bread-crumbs/bread-crumbs';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { Position } from '@asd-stan/user/domain/position.entity';
import { Option } from '@components/form-select/form-select-async';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import { Form, Formik, FormikValues } from 'formik';

import { ProjectLeader } from '../general/project-leader/project-leader';
import { StyledLoaderContainer } from '../single-standard/tabs-sections/single-standard-page-section/single-standard.styled';

import { DraftRemarks } from './forms/draft-remarks';
import { ENFields } from './forms/enFields';
import { Experts } from './forms/experts';
import { General } from './forms/general';
import { Justification } from './forms/justification';
import { Originator as OriginatorForm } from './forms/originator';

import { StyledButtonGroup } from '@asd-stan/ui-kit/components/utility/button-group.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';

export const makeDraftNwpCreation = (
	values: FormikValues,
	draftService: DraftService
): DraftUpdate => {
	const originator =
		values.originator && values.originator.label !== ''
			? {
					...values.originator.value,
					isAsdOriginator: values.isAsdOriginator,
					isExistOriginator: values.radioForm,
			  }
			: values.newOriginator
			  ? {
						isAsdOriginator: values.isAsdOriginator,
						isExistOriginator: values.radioForm,
						firstName: values.newOriginator.firstName,
						lastName: values.newOriginator.lastName,
						email: values.newOriginator.email,
						phone:
							values.newOriginator.phone && values.newOriginator.phone.length === 0
								? null
								: values.newOriginator.phone === ''
								  ? null
								  : values.newOriginator.phone,
						companyId:
							values.newOriginator.company === null || values.newOriginator.company === ''
								? null
								: values.newOriginator.company && values.newOriginator.company.value === ''
								  ? null
								  : values.newOriginator.company && Number(values.newOriginator.company.value),
						countryId:
							values.newOriginator.companyCountry === null ||
							values.newOriginator.companyCountry === ''
								? null
								: values.newOriginator.companyCountry &&
								    values.newOriginator.companyCountry.value === ''
								  ? null
								  : values.newOriginator.companyCountry &&
								    Number(values.newOriginator.companyCountry.value),
						positions:
							values.newOriginator.position &&
							values.newOriginator.position.map((position: Option) => Number(position.value)),
			    }
			  : {};

	return {
		generalData: {
			form: values.form && values.form.value,
			registrationNumber: values.registrationNumber,
			stanNumber: values.stanNumber,
			isRevision: values.revisionId
				? values.revisionId.value === ''
					? null
					: values.revisionId.value === 'no'
					  ? false
					  : true
				: null,
			revisionId: values.standard
				? values.standard.length === 0
					? undefined
					: values.standard.value
					  ? Number(values.standard.value.id)
					  : undefined
				: null,
			edition: values.edition,
			languages: values.languages ? values.languages.map((language: Option) => language.value) : [],
			typeIds:
				values.typeIds.length > 0 ? values.typeIds.map((typeId: Option) => typeId.value) : [],
			pages:
				values.pages && values.pages === ''
					? null
					: values.pages?.length === 0
					  ? null
					  : Number(values.pages),
			domainCodes:
				values.domainCodes && values.domainCodes.length === 0
					? []
					: values.domainCodes && values.domainCodes.map((domain: Option) => domain.value),
			isWorkingGroupNull:
				values.workingGroupCodes && values.workingGroupCodes.value === 'None' ? true : false,
			workingGroupCodes: Array.isArray(values.workingGroupCodes)
				? values.workingGroupCodes.map((workingGroup: Option) => workingGroup.value)
				: [],
			leaderId:
				values.leaderId === undefined || values.leaderId === null
					? undefined
					: values.leaderId.value === ''
					  ? undefined
					  : values.leaderId.value,
			scope: values.scope,
			cenWiNumber: values.cenWiNumber,
		},
		originator: originator,
		draft:
			values.standardDraft && values.standardDraft.length > 0
				? values.standardDraft[0].fileId ?? values.standardDraft[0].id
				: undefined,
		justification: {
			purpose: values.purpose,
			revisionJustification:
				values.revisionId && values.revisionId.value === 'yes' ? values.revisionJustification : '',
			technicalChanges:
				values.technicalChanges &&
				values.technicalChanges.map((item: TechnicalChanges) => ({
					id: item.id,
					title: item.title,
					change: item.change,
				})),
			consideredStandards:
				values.consideredStandards &&
				values.consideredStandards.map((standard: Option) => standard.value),
			externalConsideredStandards: values.externalConsideredStandards,
			knownPatentIssue: values.knownPatentIssue,
			patentFile: values.patentFile
				? values.patentFile.length > 0
					? values.patentFile[0].fileId ?? values.patentFile[0].id
					: undefined
				: null,
			patentReferences:
				values.patentReferences && values.patentReferences.length === 0
					? undefined
					: values.patentReferences,
		},
		additionalInformation: {
			tags: values.tags && values.tags.map((tag: Option) => tag.value),
			classifications: values.classification
				? values.classification.map((classification: Option) => Number(classification.value))
				: null,
			icsCodes: values.ICSCodes && values.ICSCodes.map((code: Option) => code.value),
		},
		titles: {
			en: values.standardTitleEN,
			de: values.standardTitleDE,
			fr: values.standardTitleFR,
		},
		experts: {
			experts:
				values.experts &&
				values.experts.map((expert: { label: string; value: number }) => expert.value),
			wgs:
				values.wgs === undefined || values.wgs === null
					? undefined
					: values.wgs.value === ''
					  ? undefined
					  : values.wgs.value,
		},
		attachments:
			values.attachments && values.attachments.length > 0
				? values.attachments.map((item: Attachment) => {
						return {
							id: item.file.id,
							fileId: item.file.fileId,
							type:
								item.attachmentType && item.attachmentType.value !== ''
									? item.attachmentType.value
									: null,
							description: item.attachmentDescription,
						};
				  })
				: draftService.singleDraft?.attachments && draftService.singleDraft?.attachments.length > 0
				  ? draftService.singleDraft.attachments.map((item: AttachmentResponse) => {
							return {
								id: item.id,
								fileId: item.file.id,
								type: item.type,
								description: item.description,
							};
				    })
				  : [],
		enData: {
			name: values.enData.name,
			publicationDate: values.enData.publicationDate,
			title: values.enData.title,
		},
		remarks:
			draftService.remarks && draftService.remarks.length > 0
				? draftService.remarks.map((remark: Remark) => ({
						text: remark.text,
				  }))
				: draftService.singleDraft !== null &&
				    draftService.singleDraft.remarks &&
				    draftService.singleDraft.remarks.length > 0
				  ? draftService.singleDraft.remarks.map((remark: { text: string }) => ({
							text: remark.text,
				    }))
				  : [],
	};
};

export const DraftEditNwp: React.FC = observer(() => {
	const navigation = useNavigate();
	const { t } = useTranslation();
	const params = useParams();

	const toasterService = getToasterService();
	const standardService = getStandardService();
	const draftService = getDraftService();

	const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
	const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

	useEffect(() => {
		const onLoadPage = async () => {
			draftService.remarks = [];
			await draftService.getDraftById(Number(params.id));
			if (
				draftService.singleDraft &&
				draftService.singleDraft.remarks &&
				draftService.singleDraft.remarks.length > 0 &&
				draftService.remarks?.length === 0
			) {
				draftService.updateDraftRemarks(draftService.singleDraft.remarks);
			}
		};

		if (params.id) {
			onLoadPage();
		}
	}, [params.id, standardService, draftService]);

	const generateOriginator = (originator?: Originator) => {
		if (!originator || (originator.isAsdOriginator === false && !originator.userId)) {
			return { label: '', value: '' };
		}
		if (originator.isAsdOriginator === false && originator.userId) {
			return {
				label: `${originator.firstName} ${originator.lastName}`,
				value: {
					userId: originator.userId,
					firstName: originator.firstName,
					lastName: originator.lastName,
					email: originator.email,
					phone: originator.phone,
					companyId: originator.company && originator.company.id,
					countryId: originator.country && originator.country.id,
					positions: originator.positions && originator.positions.map(position => position.id),
				},
			};
		}
		if (originator.isAsdOriginator === true && originator.id) {
			const positionsString = originator.positions
				? originator.positions.map(position => position.name).join(', ')
				: '';

			const nonEmptyValues = [
				originator.email,
				originator.phone,
				originator.company?.name,
				originator.country?.name,
				positionsString,
			]
				.filter(Boolean)
				.join(', ');

			return {
				label: (
					<>
						<b
							style={{
								fontWeight: 500,
							}}>{`${originator.firstName} ${originator.lastName}, `}</b>
						{nonEmptyValues}
					</>
				),
				value: {
					id: originator.id,
					firstName: originator.firstName,
					lastName: originator.lastName,
					email: originator.email,
					phone: originator.phone,
					companyId: originator.company && originator.company.id,
					countryId: originator.country && originator.country.id,
					positions: originator.positions && originator.positions.map(position => position.id),
				},
			};
		}
	};

	const updateInitialValues = {
		form: forms.find(form => form.value === draftService.singleDraft?.generalData?.form),
		registrationNumber: draftService.singleDraft?.generalData?.registrationNumber,
		revisionId: draftService.singleDraft?.generalData
			? draftService.singleDraft?.generalData.isRevision === null
				? null
				: draftService.singleDraft?.generalData.isRevision
				  ? revisions[0]
				  : revisions[1]
			: null,
		standard: draftService.singleDraft?.generalData
			? draftService.singleDraft?.generalData.revision === null
				? ''
				: {
						label: `${draftService.singleDraft?.generalData.revision.registrationNumber ?? ''} ${
							draftService.singleDraft?.generalData.revision.form ?? ''
						} ${draftService.singleDraft?.generalData.revision.localizedTitle ?? ''}`,
						value: draftService.singleDraft?.generalData.revision,
				  }
			: '',
		showReplaceStandard: false,
		stanNumber: draftService.singleDraft?.generalData?.stanNumber,
		edition: draftService.singleDraft?.generalData?.edition,
		languages: draftService.singleDraft?.generalData?.languages.map(updateLanguage =>
			languages.find(language => language.value === updateLanguage)
		),
		typeIds:
			draftService.singleDraft?.generalData?.standardTypes.map(({ id, name }) => ({
				label: name,
				value: id,
			})) ?? [],
		pages:
			draftService.singleDraft?.generalData?.pages === null
				? ''
				: draftService.singleDraft?.generalData?.pages,
		domainCodes: draftService.singleDraft?.generalData?.domains.map(domain => ({
			label: domain.name,
			value: domain.code,
		})),
		workingGroupCodes: draftService.singleDraft?.generalData?.workingGroups.map(wg => ({
			label: wg.name,
			value: wg.code,
		})),
		leaderId: !draftService.singleDraft?.generalData?.leader
			? { label: '', value: '' }
			: {
					label: <ProjectLeader user={draftService.singleDraft?.generalData?.leader!} />,
					value: draftService.singleDraft?.generalData?.leader.id,
			  },
		scope: draftService.singleDraft?.generalData?.scope,
		isAsdOriginator:
			draftService.singleDraft?.originator?.isAsdOriginator === null
				? false
				: draftService.singleDraft?.originator?.isAsdOriginator,
		radioForm:
			draftService.singleDraft?.originator?.isExistOriginator === null
				? true
				: draftService.singleDraft?.originator?.isExistOriginator,
		newOriginator: draftService.singleDraft?.originator?.isExistOriginator
			? {
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					company: '',
					companyCountry: '',
					position: [],
			  }
			: {
					...draftService.singleDraft?.originator,
					phone:
						draftService.singleDraft?.originator?.phone === null
							? ''
							: draftService.singleDraft?.originator?.phone,
					company: draftService.singleDraft?.originator?.company && {
						label: draftService.singleDraft?.originator?.company.name,
						value: draftService.singleDraft?.originator?.company.id.toString(),
					},
					companyCountry: draftService.singleDraft?.originator?.country && {
						label: draftService.singleDraft.originator.country.name,
						value: draftService.singleDraft.originator.country.id.toString(),
					},
					position:
						draftService.singleDraft?.originator?.positions &&
						draftService.singleDraft.originator.positions.map((position: Position) => ({
							label: position.name,
							value: position.id.toString(),
						})),
			  },
		originator: generateOriginator(draftService.singleDraft?.originator),
		standardDraft: draftService.singleDraft
			? draftService.singleDraft?.draft === null
				? undefined
				: [
						{
							id: draftService.singleDraft?.draft?.id,
							path: draftService.singleDraft?.draft?.path,
							size: draftService.singleDraft?.draft?.size,
							title: draftService.singleDraft?.draft?.name,
						},
				  ]
			: undefined,
		purpose:
			draftService.singleDraft?.justification?.purpose === null
				? ''
				: draftService.singleDraft?.justification?.purpose,
		revisionJustification: draftService.singleDraft?.justification?.revisionJustification,
		technicalChanges: draftService.singleDraft?.justification?.technicalChanges,
		consideredStandards: draftService.singleDraft?.justification?.consideredStandards.map(
			standard => ({
				label: standard.name,
				value: standard.id,
			})
		),
		externalConsideredStandards:
			draftService.singleDraft?.justification?.externalConsideredStandards === null
				? ''
				: draftService.singleDraft?.justification?.externalConsideredStandards,
		knownPatentIssue: draftService.singleDraft?.justification?.knownPatentIssue,
		patentReferences:
			draftService.singleDraft?.justification?.patentReferences === null
				? ''
				: draftService.singleDraft?.justification?.patentReferences,
		patentFile: draftService.singleDraft
			? draftService.singleDraft?.justification === null
				? []
				: draftService.singleDraft?.justification?.patentFile === null
				  ? undefined
				  : [
							{
								id: draftService.singleDraft?.justification?.patentFile?.id,
								path: draftService.singleDraft?.justification?.patentFile?.path,
								size: draftService.singleDraft?.justification?.patentFile?.size,
								title: draftService.singleDraft?.justification?.patentFile?.name,
							},
				    ]
			: undefined,
		tags: draftService.singleDraft?.additionalInformation?.tags?.map(tag => ({
			label: tag.value,
			value: tag.value,
		})),
		classification: draftService.singleDraft?.additionalInformation?.classifications?.map(
			classification => ({
				label: `${classification.number} ${classification.name}`,
				value: classification.id.toString(),
			})
		),
		ICSCodes:
			draftService.singleDraft?.additionalInformation &&
			draftService.singleDraft.additionalInformation.icsCodes
				? draftService.singleDraft?.additionalInformation.icsCodes.map(icsCode => ({
						label: `${icsCode.code} ${icsCode.name}`,
						value: icsCode.code,
				  }))
				: [],
		standardTitleEN: draftService.singleDraft?.titles?.en,
		standardTitleDE:
			draftService.singleDraft?.titles?.de === null ? '' : draftService.singleDraft?.titles?.de,
		standardTitleFR:
			draftService.singleDraft?.titles?.fr === null ? '' : draftService.singleDraft?.titles?.fr,
		experts: draftService.singleDraft?.experts?.experts.map(expert => ({
			label: expert.fullName,
			value: expert.id,
		})),
		wgs: draftService.singleDraft?.experts?.wgs !== null && {
			label: <ProjectLeader user={draftService.singleDraft?.experts?.wgs!} />,
			value: draftService.singleDraft?.experts?.wgs.id,
		},
		attachments: draftService.singleDraft?.attachments?.map(attachment => ({
			file: {
				title: attachment?.file?.name,
				size: attachment?.file?.size,
				fileId: attachment?.file?.id,
				id: attachment?.id,
				path: attachment?.file?.path,
			},
			attachmentDescription: attachment.description,
			attachmentType: attachmentTypes.find(type => type.value === attachment.type),
		})),
		enData: {
			name: draftService.singleDraft?.enData.name || '',
			publicationDate: draftService.singleDraft?.enData.publicationDate || null,
			title: draftService.singleDraft?.enData.title || '',
		},
		cenWiNumber: draftService.singleDraft?.generalData?.cenWiNumber,
	};

	const goToPreviousPage = () => {
		draftService.singleDraft = null;
		standardService.clearSingleStandard();
		return navigation(`/standards/draft/${params.id}/general`);
	};

	const onSubmit = async (values: FormikValues) => {
		const creation = makeDraftNwpCreation(values, draftService);
		try {
			setIsSaveDisabled(true);
			if (draftService.singleDraft && params.id) {
				if (isSaveClicked) {
					await standardService.moveDraftToNWP(Number(params.id));
				} else {
					await standardService.updateDraft(Number(params.id), creation);
				}
			} else {
				if (isSaveClicked) {
					await standardService.moveDraftToNWP(Number(params.id));
				} else {
					await standardService.updateDraft(Number(params.id), creation);
				}
			}
			if (isSaveClicked) {
				toasterService.showToast(
					ToasterStatus.success,
					t('standard.createNWP.toaster.successfullCreate')
				);
				draftService.getDraftsCount();
				draftService.remarks = [];
			}
			if (params.id) {
				if (isSaveClicked) {
					navigation(`/standards/detailed/${params.id}/general`);
				}
			} else {
				if (isSaveClicked) {
					navigation('/standards');
				}
			}
			setIsSaveDisabled(false);
			setIsSaveClicked(false);
		} catch (error) {
			setIsSaveDisabled(false);
			setIsSaveClicked(false);
		}
	};

	if (params.id && !draftService.singleDraft) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	return (
		<Formik
			validationSchema={isSaveClicked ? NWPSchema : emptySchema}
			initialValues={updateInitialValues}
			onSubmit={onSubmit}
			validateOnChange
			validateOnBlur={false}>
			{({ handleSubmit, validateForm }) => {
				return (
					<ContentContainer>
						<BreadCrumbs />
						<Flex $justify="space-between" $align="center">
							<PageTitle
								title={`${draftService.singleDraft?.generalData?.form ?? ''} ${
									draftService.singleDraft?.generalData?.registrationNumber ?? ''
								} ${
									draftService.singleDraft?.generalData?.edition &&
									draftService.singleDraft?.generalData?.edition.length > 60
										? `${draftService.singleDraft?.generalData?.edition}`
										: draftService.singleDraft?.generalData?.edition ?? ''
								}`}
							/>
							<StyledButtonGroup>
								<Button
									disabled={isSaveDisabled}
									secondary
									title={t('standard.createNWP.buttons.cancel')}
									onClick={goToPreviousPage}
								/>
								<Button
									disabled={isSaveDisabled}
									title={t('standard.createNWP.buttons.save')}
									onClick={async () => {
										await setIsSaveClicked(prevState => true);
										validateForm().then(errors => {
											if (errors && !(Object.keys(errors).length === 0)) {
												toasterService.showToast(
													ToasterStatus.error,
													t('standard.createNWP.toaster.incorrectFields')
												);
												setIsSaveClicked(prevState => false);
											}
										});

										try {
											await handleSubmit();
										} catch (error) {
											setIsSaveClicked(prevState => false);
										}
									}}
								/>
							</StyledButtonGroup>
						</Flex>

						<Form>
							<InputGroup title={t('standard.createNWP.general.title')} children={<General />} />
							<InputGroup
								title={t('standard.createNWP.originator.title')}
								children={<OriginatorForm isSaveClicked={isSaveClicked} />}
							/>
							<InputGroup
								title={t('standard.createNWP.standardDraft.title')}
								children={<StandardDraft />}
							/>
							<InputGroup
								title={t('standard.createNWP.justification.title')}
								children={<Justification />}
							/>
							<InputGroup
								title={t('standard.createNWP.additionalInformation.title')}
								children={<AdditionalInformation />}
							/>
							<InputGroup title={t('standard.createNWP.titles.title')} children={<Titles />} />
							<InputGroup title={t('standard.createNWP.experts.title')} children={<Experts />} />
							<InputGroup
								title={t('standard.createNWP.attachments.title')}
								children={<Attachments />}
							/>
							<InputGroup title={t('standard.createNWP.enFields.title')} children={<ENFields />} />
							<InputGroup
								title={t('standard.createNWP.remarks.title')}
								children={<DraftRemarks />}
							/>
						</Form>
					</ContentContainer>
				);
			}}
		</Formik>
	);
});
