import React, { useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import { getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { useDebounce } from '@asd-stan/helpers/customHooks';
import { ErrorMessage, FieldProps } from 'formik';

import { StyledFormInputPhone } from '@asd-stan/ui-kit/components/form-input-phone/form-input-phone.styled';
import { StyledFieldErrorMessage } from '@components/utility/field-error-message.styled';

interface InputProps {
	placeholder?: string;
	mandatory?: boolean;
	showError?: boolean;
	errors: string;
	title: string;
	name: string;
	onChange: () => void;
	fullWidth?: boolean;
	smallerWidth: boolean;
	isWarning?: boolean;
	useSubmitOnBlur?: boolean;
	disabled?: boolean;
}

export const FormInputPhone: React.FC<InputProps & FieldProps> = ({
	title,
	placeholder,
	mandatory,
	field,
	form,
	showError,
	smallerWidth = false,
	isWarning,
	fullWidth,
	useSubmitOnBlur,
	disabled,
}) => {
	const [value, setValue] = useState((form.values[field.name] as string) || '49');

	const handleInputBlur = async (e: any) => {
		await form.handleBlur(e);
		if (useSubmitOnBlur) {
			form.handleSubmit();
		}
	};

	const debounceSubmit = useDebounce(async () => await form.handleSubmit());

	const onValueChange = async (value: string, { dialCode }: CountryData) => {
		setValue(value);
		const nextValue = value === dialCode || value.length < dialCode.length ? '' : value;
		await form.setFieldValue(field.name, nextValue);
		if (useSubmitOnBlur) {
			debounceSubmit();
		}
	};

	return (
		<StyledFormInputPhone
			$warning={isWarning}
			$smallerWidth={smallerWidth}
			$error={
				!!(
					getValueByFieldName(form.touched, field.name) &&
					getValueByFieldName(form.errors, field.name)
				)
			}
			$fullWidth={fullWidth}
			$disabled={disabled}>
			<label title={title}>
				{title}
				{mandatory && <span>*</span>}
			</label>
			<PhoneInput
				placeholder={placeholder}
				{...field}
				onBlur={handleInputBlur}
				onChange={onValueChange}
				disabled={disabled}
				value={value}
			/>
			{showError ? <ErrorMessage name={field.name} component={StyledFieldErrorMessage} /> : null}
		</StyledFormInputPhone>
	);
};
