import { CurrentUserRepo } from '@asd-stan/current-user/api/current-user.repo';
import { CurrentUserService } from '@asd-stan/current-user/domain/current-user.service';
import {
	CURRENT_USER_REPO,
	CURRENT_USER_SERVICE,
} from '@asd-stan/current-user/infrastructure/constants';
import { injector } from '@asd-stan/injector/injector';

injector.set(CURRENT_USER_REPO, new CurrentUserRepo());
injector.set(CURRENT_USER_SERVICE, new CurrentUserService());
