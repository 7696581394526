import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LogoHeader } from '@asd-stan/shell/components/logo-header/logo-header';
import { ReactComponent as LockIcon } from '@assets/icons/no-access.svg';
import { Button } from '@components/button/button';

import { StyledRestrictedPage } from '@components/restricted-page/restricted-page.styled';

export const RestrictedPage: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleRedirectToHome = () => {
		navigate('/dashboard');
	};

	return (
		<StyledRestrictedPage>
			<LogoHeader />
			<div className="content">
				<LockIcon />
				<div className="title">{t('restricted.noAccess')}</div>
				<div className="description">{t('restricted.noAccessDescription')}</div>
				<Button fullWidth title={t('restricted.buttonGoHome')} onClick={handleRedirectToHome} />
			</div>
		</StyledRestrictedPage>
	);
};
