import React, { ReactNode } from 'react';

import { StyledButton } from './button.styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	title?: string;
	onClick?: () => void;
	icon?: ReactNode | undefined;
	secondary?: boolean;
	type?: 'button' | 'submit' | 'reset';
	fullWidth?: boolean;
	disabled?: boolean;
	flat?: boolean;
	className?: string;
}

export const Button: React.FC<ButtonProps> = ({
	title,
	onClick,
	icon,
	secondary,
	type = 'button',
	fullWidth,
	disabled,
	flat,
	...props
}) => {
	return (
		<StyledButton
			$secondary={secondary}
			$fullWidth={fullWidth}
			$disabled={disabled}
			$flat={flat}
			$title={!!title}>
			<button type={type} title={title} onClick={onClick} disabled={disabled} {...props}>
				<span>{icon}</span>
				{title}
			</button>
		</StyledButton>
	);
};
