import uuid from 'react-uuid';

export enum UserAccess {
	Unavailable = -1,
	InvitationPending = 0,
	Available = 1,
}

export interface UserDto {
	domainParticipationsByUserId: [
		{
			domain: {
				code: string;
				name: string;
			};
			workingGroupParticipations: {
				workingGroup: {
					code: string;
					name: string;
				};
				roles: string[];
			}[];
			roles: string[];
			seeAllDomain: boolean;
		},
	];
	userById: {
		access: UserAccess;
		id: number;
		firstName: string;
		lastName: string;
		email: string;
		phone: string | null;
		systemRoles: string[];
		appointedBy: {
			id: number;
			name: string;
		};
		company: {
			id: number;
			name: string;
		};
		country: {
			id: number;
			name: string;
		};
		positions: {
			id: number;
			name: string;
		}[];
	};
}

export interface DomainParticipation {
	code: string;
	roles: string[];
	seeAllDomain: boolean;
}

export interface WorkingGroupParticipation {
	code: string;
	roles: string[];
}

export interface SingleUserDomain {
	id: string;
	domain: { label: string; value: string };
	domainRoles: { label: string; value: string }[];
	seeAllDomain: boolean | undefined;
	WGS: { label: string; value: string }[] | undefined;
	WGC: { label: string; value: string }[] | undefined;
	workingGroupExpert: { label: string; value: string }[] | undefined;
}

export interface SingleUser {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	appointedBy: {
		label: string;
		value: number;
	};
	systemRoles: {
		label: string;
		value: string;
	}[];
	company: null | {
		label: string;
		value: number;
	};
	country: null | {
		label: string;
		value: number;
	};
	positions: {
		label: string;
		value: number;
	}[];
	domains: SingleUserDomain[];
}

export interface UserInfo {
	fields: SingleUser;
	access: UserAccess;
	id: number;
}

export const mapUser = ({
	domainParticipationsByUserId,
	userById: {
		id,
		access,
		firstName,
		lastName,
		email,
		phone,
		appointedBy,
		systemRoles,
		company,
		country,
		positions,
	},
}: UserDto): UserInfo => {
	return {
		id,
		access: access,
		fields: {
			firstName,
			lastName,
			email,
			phone: phone || '',
			appointedBy: {
				label: appointedBy.name,
				value: appointedBy.id,
			},
			systemRoles: systemRoles.map(value => ({ value: value, label: value })),
			company: company
				? {
						value: company.id,
						label: company.name,
				  }
				: null,
			country: country
				? {
						value: country.id,
						label: country.name,
				  }
				: null,
			positions: positions.map(({ id, name }) => ({ value: id, label: name })),
			domains: domainParticipationsByUserId.map(
				({ domain, roles, seeAllDomain, workingGroupParticipations }) => ({
					id: uuid(),
					domain: { label: domain.name, value: domain.code },
					seeAllDomain,
					domainRoles: roles.map(r => ({ label: r, value: r })),
					WGC: workingGroupParticipations
						.filter(({ roles }) => roles.includes('WGC'))
						.map(({ workingGroup: wg }) => ({ label: wg.name, value: wg.code })),
					WGS: workingGroupParticipations
						.filter(({ roles }) => roles.includes('WGS'))
						.map(({ workingGroup: wg }) => ({ label: wg.name, value: wg.code })),
					workingGroupExpert: workingGroupParticipations
						.filter(({ roles }) => roles.includes('Expert'))
						.map(({ workingGroup: wg }) => ({ label: wg.name, value: wg.code })),
				})
			),
		},
	};
};

export const mapUserToDto = (values: SingleUser) => {
	const workingGroupParticipations: WorkingGroupParticipation[] = [];
	const codeRolesMap: { [key: string]: string[] } = {};

	values.domains.forEach((domain: SingleUserDomain) => {
		if (Array.isArray(domain.WGS)) {
			domain.WGS.forEach(participation => {
				if (participation.value !== '') {
					if (!codeRolesMap[participation.value]) {
						codeRolesMap[participation.value] = ['WGS'];
					} else {
						codeRolesMap[participation.value].push('WGS');
					}
				}
			});
		}

		if (Array.isArray(domain.WGC)) {
			domain.WGC.forEach(participation => {
				if (participation.value !== '') {
					if (!codeRolesMap[participation.value]) {
						codeRolesMap[participation.value] = ['WGC'];
					} else {
						codeRolesMap[participation.value].push('WGC');
					}
				}
			});
		}

		if (Array.isArray(domain.workingGroupExpert)) {
			domain.workingGroupExpert.forEach(participation => {
				if (participation.value !== '') {
					if (!codeRolesMap[participation.value]) {
						codeRolesMap[participation.value] = ['Expert'];
					} else {
						codeRolesMap[participation.value].push('Expert');
					}
				}
			});
		}
	});

	/* Convert the codeRolesMap into workingGroupParticipations array */
	Object.keys(codeRolesMap).forEach(code => {
		workingGroupParticipations.push({
			code,
			roles: codeRolesMap[code],
		});
	});

	const updateUser = {
		firstName: values.firstName,
		lastName: values.lastName,
		email: values.email,
		phone:
			values.phone.toString().charAt(0) === '+'
				? values.phone.toString()
				: values.phone.length !== 0
				  ? `+${values.phone}`
				  : null,
		appointerId: values.appointedBy.value,
		systemRoles: values.systemRoles.map(role => role.value),
		companyId: values.company && values.company.value,
		companyCountryId: values.country && values.country.value,
		positionIds: values.positions[0]?.value ? values.positions.map(position => position.value) : [],
		domainParticipations: values.domains
			.map(domain => ({
				code: domain.domain.value,
				roles: domain.domainRoles.map(role => role.value),
				seeAllDomain: !!domain.seeAllDomain,
			}))
			.filter(participation => participation !== undefined),
		workingGroupParticipations,
	};

	return {
		user: {
			firstName: updateUser.firstName,
			lastName: updateUser.lastName,
			email: updateUser.email,
			phone: updateUser.phone,
			appointedById: updateUser.appointerId,
			systemRoles: updateUser.systemRoles,
			companyId: updateUser.companyId || null,
			countryId: updateUser.companyCountryId || null,
			positionIds: updateUser.positionIds,
		},
		domainParticipations: updateUser.domainParticipations,
		workingGroupParticipations: updateUser.workingGroupParticipations,
	};
};
