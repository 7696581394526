import React, { HTMLInputTypeAttribute } from 'react';

import { getCurrencySymbol } from '@asd-stan/common/currency.mapper';
import { generatePlaceholder, getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { ErrorMessage, FieldProps } from 'formik';

import { StyledFormInputPrice } from './form-input.styled';

import { StyledFieldErrorMessage } from '@asd-stan/ui-kit/components/utility/field-error-message.styled';

interface InputProps {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	mandatory?: boolean;
	errors: string;
	title: string;
	name: string;
	fullWidth?: boolean;
	smallerWidth?: boolean;
	staticWidth?: boolean;
	showError?: boolean;
	disabled?: boolean;
	onChange?: (field: string, value: string) => void;
	useSubmitOnBlur?: boolean;
	setTouchedOnBlur?: boolean;
	isWarning?: boolean;
	currency?: string;
	hint?: string;
}

const priceRegEx = /^\d{0,6}(?:\.\d{0,2})?$/;

export const FormInputPrice: React.FC<InputProps & FieldProps> = ({
	title,
	placeholder,
	mandatory,
	field,
	form,
	type = 'text',
	fullWidth,
	smallerWidth,
	staticWidth,
	showError,
	disabled,
	onChange,
	useSubmitOnBlur,
	setTouchedOnBlur = false,
	isWarning,
	currency,
	hint,
}) => {
	const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const nextPrice = event.target.value;
		if (priceRegEx.test(nextPrice)) {
			if (onChange) {
				return onChange(field.name, nextPrice);
			}

			form.setFieldValue(field.name, nextPrice);
		}
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (field.value && field.value.length > 0) {
			await form.setFieldValue(field.name, field.value.trim());
		}
		if (useSubmitOnBlur) {
			await form.handleSubmit();
		}
	};

	const isError = !!(
		getValueByFieldName(form.touched, field.name) && getValueByFieldName(form.errors, field.name)
	);

	return (
		<StyledFormInputPrice
			$error={isError}
			$warning={isWarning}
			$fullWidth={fullWidth}
			$disabled={disabled}
			$smallerWidth={smallerWidth}
			$staticWidth={staticWidth}>
			<label title={title} className={disabled ? 'disabled' : ''}>
				{title}
				{mandatory && <span>*</span>}
			</label>
			<div className="input-container">
				{currency ? <span className="currency-symbol">{getCurrencySymbol(currency)}</span> : null}
				<input
					type={type}
					disabled={disabled}
					placeholder={generatePlaceholder(title, placeholder)}
					{...field}
					onBlur={handleBlur}
					onChange={onValueChange}
				/>
			</div>
			{showError && isError ? (
				<ErrorMessage name={field.name} component={StyledFieldErrorMessage} />
			) : hint ? (
				<div className="hint">{hint}</div>
			) : null}
		</StyledFormInputPrice>
	);
};
