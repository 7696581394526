import styled from 'styled-components';

export const StyledUserControl = styled.div`
	cursor: pointer;

	.open {
		transition: all 0.3s ease-out;
		transform: rotateX(180deg);
	}

	& {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		width: 293px;
		padding-left: 20px;
		margin: 11px 0;
		height: 36px;
		border-left: ${({ theme }) => theme.borders.divider};

		& img {
			width: 36px;
			height: 36px;
		}

		& div {
			min-width: 200px;
			margin: 0 12px;
			color: ${({ theme }) => theme.fontColors.darkGrey};
			font-size: 13px;
			font-weight: 500;
			line-height: 150%;
			& > svg {
				fill: ${({ theme }) => theme.fontColors.lightGrey};
			}
		}

		& p {
			font-size: 14px;
			font-weight: 500;
			line-height: 150%;
		}
	}
`;

export const StyledDropdown = styled.ul<{
	$display: boolean;
}>`
	display: ${props => (props.$display ? 'block' : 'none')};
	z-index: 1000;
	position: fixed;
	top: 60px;
	right: 20px;
	width: 212px;
	padding: 4px;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;

	border-radius: 8px;
	border: 1px solid var(--divider-light-grey, #dae4e8);
	background: #fff;
	box-shadow: 0px 12px 24px 0px rgba(30, 36, 45, 0.16);

	margin: 0px 4px;

	> li {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 13px;
		padding: 12px 24px 12px 12px;

		svg {
			margin-right: 8px;
		}
	}

	> li:nth-child(even) {
		margin: 4px auto;
	}

	> li:last-child {
		border-top: ${({ theme }) => theme.borders.divider};
	}

	> li:hover {
		background: rgba(11, 147, 227, 0.08);
	}
`;
