import styled from 'styled-components';

export const StyledInput = styled.div<{ $icon: boolean }>`
  display: flex;
  position: relative;
  height: 50px;
  border: 1px solid #11396f1a;
  border-radius: 8px;
  background-color: ${({theme}) => theme.fontColors.white};
  color: ${({theme}) => theme.fontColors.primary};

  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 17px;

    & > svg {
      fill: ${({theme}) => theme.fontColors.primary};
    }
  }

  & > label {
    position: absolute;
    color: ${({theme}) => theme.fontColors.textGrey};
    font-size: 13px;
    font-weight: 500;
    top: -40px;
    pointer-events: none;
    height: 72px;
  }

  & > input {
    width: 100%;
    color: ${({theme}) => theme.fontColors.lightGrey};
    font-size: 13px;
    border-radius: 8px;
    padding: 16px 16px 16px ${props => (props.$icon ? '32px' : '16px')};

    &::placeholder {
      color: ${({theme}) => theme.fontColors.primaryBlurred40};
    }
  }

  & > input[type='date']::-webkit-calendar-picker-indicator {
    width: 60%;
    opacity: 0;
  }
`;
