import React, { FC } from 'react';

import { ReactComponent as Checkmark } from '@assets/icons/checkmark.svg';

import {
	Step,
	StepConnector,
	StepLabel,
	StepWrapper,
	StepperContainer,
} from '@asd-stan/standard/components/single-standard/stage-update/stage-stepper/stage-stepper.styled';

export interface StepProps {
	label: string;
	step: number;
}

interface Props {
	activeStep: number;
	steps: StepProps[];
}

export const StageStepper: FC<Props> = ({ activeStep, steps }) => {
	const renderStep = (step: StepProps, index: number) => {
		const relativeIndex = index + 1;
		const className = activeStep >= relativeIndex ? 'active' : '';
		const classNameConnector = activeStep > relativeIndex ? 'active' : '';

		if (index !== steps.length - 1) {
			return (
				<React.Fragment key={`${index}-${step.step}-${step.label}`}>
					<StepWrapper>
						<Step className={className}>
							{activeStep > relativeIndex ? <Checkmark /> : step.step}
						</Step>
						<StepLabel className={className}>{step.label}</StepLabel>
					</StepWrapper>
					<StepConnector className={classNameConnector} />
				</React.Fragment>
			);
		}

		return (
			<StepWrapper key={`${index}-${step.step}-${step.label}`}>
				<Step className={className}>{step.step}</Step>
				<StepLabel className={className}>{step.label}</StepLabel>
			</StepWrapper>
		);
	};

	return <StepperContainer>{steps.map(renderStep)}</StepperContainer>;
};
