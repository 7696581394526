import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { Page } from '@asd-stan/helpers/pagination-collection-controller';
import {
	makeCatalogFromCatalogDTO,
	makePartialCatalogItemDTO,
} from '@asd-stan/standard/api/catalog.factory';
import { CatalogItem } from '@asd-stan/standard/domain/catalog-item.entity';
import { CatalogCollectionFilter } from '@asd-stan/standard/domain/catalog.service';

import { CatalogItemDTO } from './catalog.dto';

const CATALOG_ITEMS_LIST = gql`
	query ($limit: Int!, $offset: Int!, $filter: CatalogFilterInput!) {
		catalogList(limit: $limit, offset: $offset, filter: $filter) {
			id
			standardId
			name
			localizedTitle
			price {
				amount
				currency
			}
			coverPage {
				id
				path
				name
				size
			}
			isTop
			publicationDate
			availableForPurchase
			description
			published
			form
			registrationNumber
			types {
				id
				name
			}
			edition
			languages
			domains {
				name
				code
			}
			scope
			internalReferences {
				id
				name
			}
			externalReferences
			tags
			status
			publisher
		}

		catalogCount(filter: $filter)
	}
`;

const UPDATE_CATALOG_ITEM = gql`
	mutation ($id: Int!, $catalogItem: CatalogItemInput!) {
		updateCatalogItem(id: $id, catalogItem: $catalogItem) {
			id
		}
	}
`;

const UPDATE_CATALOG_ITEM_SHOW = gql`
	mutation ($id: Int!, $show: Boolean!) {
		updateCatalogItemShow(id: $id, show: $show) {
			id
			published
		}
	}
`;

export class CatalogRepo {
	async getCatalogItemsList(
		limit: number,
		offset: number,
		filter?: Partial<CatalogCollectionFilter>
	): Promise<Page<CatalogItem>> {
		const { data } = await stanWorksClient.query({
			query: CATALOG_ITEMS_LIST,
			variables: {
				limit: limit,
				offset: offset,
				filter: filter,
			},
		});

		const newData = data.catalogList.map((catalogItem: CatalogItemDTO) =>
			makeCatalogFromCatalogDTO(catalogItem)
		);

		return { totalNumber: data.catalogCount, data: newData };
	}

	async editCatalogItem(id: number, values: object) {
		const newCatalogItem = makePartialCatalogItemDTO(values);
		await stanWorksClient.mutate({
			mutation: UPDATE_CATALOG_ITEM,
			variables: {
				id: id,
				catalogItem: newCatalogItem,
			},
		});
	}

	async updateCatalogItemShow(id: number, show: boolean) {
		await stanWorksClient.mutate({
			mutation: UPDATE_CATALOG_ITEM_SHOW,
			variables: {
				id,
				show,
			},
		});
	}
}
