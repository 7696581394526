import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
import { Originator as OriginatorData } from '@asd-stan/standard/domain/standard-detailed.entity';
import { ReactComponent as LinkIcon } from '@asd-stan/ui-kit/assets/icons/link-icon-black.svg';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { Avatar } from '@components/avatar/avatar';
import { Button } from '@components/button/button';

import {
	OriginatorContainer,
	OriginatorEmail,
	OriginatorHeader,
	OriginatorInfo,
	OriginatorName,
	OriginatorStatus,
	OriginatorViewLinksIcon,
	OriginatorWrapper,
} from './originator-components.styled';

interface Props {
	user: CurrentUser | null;
	originator: OriginatorData;
}

export const Originator: FC<Props> = props => {
	const { t } = useTranslation();

	const originatorOutOfSystem = !props.originator.userId;

	const hasRoleForOriginatorInSystem =
		props.user?.systemRoles.includes(SystemRole.ES) ||
		props.user?.systemRoles.includes(SystemRole.DIRECTOR) ||
		props.user?.systemRoles.includes(SystemRole.ADMIN);

	const hasRoleForOriginatorOutOfSystem =
		props.user?.systemRoles.includes(SystemRole.ES) ||
		props.user?.systemRoles.includes(SystemRole.DIRECTOR) ||
		props.user?.systemRoles.includes(SystemRole.ADMIN) ||
		props.user?.systemRoles.includes(SystemRole.MC) ||
		props.user?.systemRoles.includes(SystemRole.TAC) ||
		props.user?.systemRoles.includes(SystemRole.EXPERT);

	const renderOriginatorHeader = () => (
		<OriginatorHeader>
			<h4>{t('standard.singleStandardPage.originator.title')}</h4>
			{originatorOutOfSystem ? (
				<OriginatorStatus>
					{t('standard.singleStandardPage.originator.outOfTheSystem')}
				</OriginatorStatus>
			) : (
				<OriginatorStatus>
					{t('standard.singleStandardPage.originator.inTheSystem')}
				</OriginatorStatus>
			)}
		</OriginatorHeader>
	);

	const renderViewAllStandards = () => {
		return null;
		// if (originatorOutOfSystem) {
		// 	return hasRoleForOriginatorOutOfSystem ? (
		// 		<Button
		// 			disabled
		// 			icon={
		// 				<OriginatorViewLinksIcon>
		// 					<LinkIcon />
		// 				</OriginatorViewLinksIcon>
		// 			}
		// 			secondary
		// 			title={t('standard.singleStandardPage.originator.viewStandards')}
		// 			onClick={() => null}
		// 		/>
		// 	) : null;
		// }

		// return hasRoleForOriginatorInSystem ? (
		// 	<Button
		// 		icon={
		// 			<OriginatorViewLinksIcon>
		// 				<LinkIcon />
		// 			</OriginatorViewLinksIcon>
		// 		}
		// 		disabled
		// 		secondary
		// 		title={t('standard.singleStandardPage.originator.viewStandards')}
		// 		onClick={() => null}
		// 	/>
		// ) : null;
	};

	return (
		<InputGroup titleComponent={renderOriginatorHeader()}>
			<OriginatorContainer>
				<OriginatorWrapper>
					{!originatorOutOfSystem ? (
						<Avatar uri={props.originator.picture && props.originator.picture.path} />
					) : null}
					<OriginatorInfo>
						<OriginatorName>
							{props.originator.firstName} {props.originator.lastName}
						</OriginatorName>
						<OriginatorEmail>{props.originator.email}</OriginatorEmail>
					</OriginatorInfo>
				</OriginatorWrapper>
				{renderViewAllStandards()}
			</OriginatorContainer>
		</InputGroup>
	);
};
