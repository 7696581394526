import { scrollStyles } from '@asd-stan/ui-kit/components/utility/shared-styles';
import styled from 'styled-components';

export const StyledFormInputPopup = styled.div<{
	$error: boolean;
	$fullWidth?: boolean;
	$showCheckbox?: boolean;
	$disabled?: boolean;
}>`
	line-height: 150%;
	position: relative;
	display: flex;
	margin-top: 14px;
	flex-direction: column;
	width: ${props => (props.$fullWidth ? '100%' : '400px')};
	margin-bottom: ${props => props.$showCheckbox && '8px'};

	label {
		min-height: 16px;
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 13px;
		font-weight: 500;

		span {
			width: 100%;
			height: 100%;
			color: ${({ theme }) => theme.fontColors.accent};
		}

		&.disabled {
			color: ${({ theme }) => theme.fontColors.lightGrey};
		}
	}

	input {
		margin-top: 8px;
		padding: 12px 16px;
		height: 42px;
		width: 100%;
		color: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.darkGrey};
		font-size: 13px;
		border-radius: 8px;
		border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};

		background-color: ${props => props.$disabled && props.theme.bgColors.primary};

		&::placeholder {
			color: ${({ theme }) => theme.fontColors.lightGrey};
		}

		// Hide arrows for number inputs
		// Chrome, Safari, Edge, Opera
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		// Firefox
		&[type='number'] {
			-moz-appearance: textfield;
		}
	}

	.input-container {
		border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};
	}

	[data-placeholder]:empty:before {
		content: attr(data-placeholder);
		color: ${({ theme }) => theme.fontColors.lightGrey};
	}

	[aria-disabled='true'] {
		background-color: ${({ theme }) => theme.bgColors.primary};
		color: ${({ theme }) => theme.fontColors.lightGrey};
		border: ${({ theme }) => theme.borders.divider};
	}

	.error-message {
		margin-top: 8px;
		font-size: 11px;
		font-weight: 500;
		color: ${({ theme }) => theme.fontColors.error};
	}
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: center;
	line-height: 150%;
	> div {
		display: block;
		min-height: 42px;
		max-height: 232px;
		width: 100%;
		border-radius: 8px;
		color: ${({ theme }) => theme.fontColors.darkGrey};
		padding: 10px 60px 10px 10px;
		resize: none;
		overflow-y: auto;

		${scrollStyles}

		a {
			text-decoration: underline;
			color: ${({ theme }) => theme.fontColors.accent};
			font-weight: 600;
			line-height: 150%;
		}
	}
`;

export const Input = styled.input`
	flex: 1;
	border: none;
	outline: none;
`;

export const CopyButton = styled.button<{ $enabled?: boolean }>`
	background: ${props =>
		props.$enabled ? 'rgba(11, 147, 227, 0.12)' : 'rgba(218, 228, 232, 0.4)'};
	padding: 4px;
	border-radius: 4px;
	cursor: pointer;
	position: absolute;
	right: 2%;
	top: 26px;
	display: flex;
	opacity: ${props => (props.$enabled ? 1 : 0.5)};
`;

export const FormContainer = styled.div<{ $visible?: boolean }>`
	display: ${props => (props.$visible ? 'block' : 'none')};
	position: absolute;
	top: 50%;
	left: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 16px;
	z-index: 1;
	transform: translate(0%, -110%);
`;

export const FormInput = styled.input<{ $error: boolean }>`
	width: 100%;
	margin-bottom: 8px;
	padding: 8px;
	border: ${props => (props.$error ? `${props.theme.borders.error} !important` : '1px solid #ccc')};
	border-radius: 4px;
`;

export const FormButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;
	gap: 12px;
`;

export const CancelButton = styled.button`
	background-color: #ccc;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 8px 16px;
	margin-right: 8px;
	cursor: pointer;
`;

export const SaveButton = styled.button`
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 8px 16px;
	cursor: pointer;
`;
