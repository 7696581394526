import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Flex } from '@components/utility/flex';

import {
	SidebarItemIcon,
	SidebarItemTitle,
} from '@asd-stan/shell/components/sidebar/sidebar-item/sidebar-item.styled';
import {
	StyledMultilevelMenuItem,
	StyledSidebarItem,
} from './sidebar-item.styled';

interface DropdownItemProps {
	goToMenu?: string;
	setActive?: React.Dispatch<string>;
	icon?: ReactNode;
	children?: React.ReactFragment;
	link?: string;
	className?: string;
	menuGoBack?: boolean;
	mainMenu?: boolean;
	count?: number;
}

export const MultilevelMenuItem: React.FC<DropdownItemProps> = ({
	goToMenu,
	setActive,
	icon,
	children,
	link,
	className,
	menuGoBack,
	mainMenu,
	count,
}) => {
	const handleNextMenu = () => {
		if (goToMenu && setActive) {
			setActive(goToMenu);
		}
	};

	if (mainMenu) {
		return (
			<StyledSidebarItem onClick={handleNextMenu} className={className}>
				<NavLink to={link || '#'} onClick={handleNextMenu}>
					<Flex>
						<SidebarItemIcon>{icon}</SidebarItemIcon>
						<SidebarItemTitle>{children}</SidebarItemTitle>
					</Flex>
				</NavLink>
			</StyledSidebarItem>
		);
	}

	return (
		<StyledMultilevelMenuItem className={className} onClick={handleNextMenu}>
			<NavLink to={link || '#'}>
				<Flex>
					{icon && !menuGoBack ? (
						<div className="icon-container">{icon}</div>
					) : (
						<div className="menu-go-back-icon">{icon}</div>
					)}
					<SidebarItemTitle>{children} {count ? <div className="count-container">{count}</div> : null}</SidebarItemTitle>
				</Flex>
			</NavLink>
		</StyledMultilevelMenuItem>
	);
};
