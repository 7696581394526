import styled from 'styled-components';

export const StyledScrollToTop = styled.button`
	background-color: ${props => props.theme.bgColors.scrollToTop};
	color: #fff;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	font-size: 24px;
	cursor: pointer;
`;

export const ButtonContainer = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9;
`;
