import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DocumentsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/documents-icon.svg';
import {
	NoAttachmentBlock,
	NoAttachmentIconWrapper, NoAttachmentTextBlock,
} from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachments-section.styled';

export const NoAttachments: FC = () => {
	const { t } = useTranslation();

	return (
		<NoAttachmentBlock>
			<NoAttachmentIconWrapper>
				<DocumentsIcon height={40} width={32} fill="#0B93E3" />
			</NoAttachmentIconWrapper>
			<NoAttachmentTextBlock>
				<span className="no-attachment-header">{t('standard.singleStandard.attachments.noAttachmentsTitle')}</span>
				<span className="no-attachment-text">{t('standard.singleStandard.attachments.noAttachments')}</span>
			</NoAttachmentTextBlock>
		</NoAttachmentBlock>
	)
}
