import { SingleBallot } from '@asd-stan/ballot/domain/ballot.entity';

import { Results } from './sections/results';
import { Stats } from './sections/stats';
import { VoteList } from './sections/vote-list';

export const Result = ({ ballotId, ballot }: { ballotId: number; ballot: SingleBallot }) => {
	return (
		<>
			<Results ballot={ballot} />
			<Stats ballotId={ballotId} />
			<VoteList ballotId={ballotId} />
		</>
	);
};
