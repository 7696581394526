import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	MeetingForm as MeetingFormType,
	ValidatedMeetingForm,
} from '@asd-stan/meeting/domain/meeting.entity';
import { getMeetingService } from '@asd-stan/meeting/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';

import { MeetingForm } from '../meeting-form/meeting-form';

export const CreateMeeting = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const meetingService = getMeetingService();
	const toasterService = getToasterService();

	const handleSubmit = async (values: MeetingFormType) => {
		try {
			await meetingService.createMeeting(values as ValidatedMeetingForm);
			toasterService.showToast(ToasterStatus.success, t('meeting.createMeeting.success'));
			navigate('/meetings');
		} catch (err: any) {
			console.error(err);
		}
	};

	return <MeetingForm title={t('meeting.createMeeting.title')} onSubmit={handleSubmit} />;
};
