import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {
	isOriginatorInStandard,
	isUserParticipatingInStandardDomain,
	userHasRoles,
	userHasRolesAndParticipatesInDomain,
} from '@asd-stan/helpers/app-utils';
import { getModalService } from '@asd-stan/shell/infrastructure/getters';
import { AttachmentsSection } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachments-section';
import { PriceHistorySection } from '@asd-stan/standard/components/single-standard/tabs-sections/price-history-section/price-history-section';
import { StageAndStatusSection } from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/stage-and-status-section';
import { forms } from '@asd-stan/standard/domain/enums';
import {
	getStagesService,
	getStandardDetailsService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as SettingIcon } from '@asd-stan/ui-kit/assets/icons/dark-setting.svg';
import { ReactComponent as EditIcon } from '@asd-stan/ui-kit/assets/icons/edit.svg';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { BreadCrumbs } from '@components/bread-crumbs/bread-crumbs';
import { Button } from '@components/button/button';
import { IconButton } from '@components/button/icon-button';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import { TabPanel } from '@components/tabs/tab/tab-panel';
import { Tabs } from '@components/tabs/tabs';
import { Flex } from '@components/utility/flex';
import { MarkedText } from '@components/utility/marked-text';

import { ButtonGroup } from './button-group';
import { FontoButton } from './fonto-button/fonto-button';
import { StageUpdate } from './stage-update/stage-update';
import { BallotsSection } from './tabs-sections/ballots-section/ballots-section';
import { SingleStandardPageTabSection } from './tabs-sections/single-standard-page-section/single-standard-page-tab-section';
import { StyledLoaderContainer } from './tabs-sections/single-standard-page-section/single-standard.styled';

import { ContentContainer } from '@components/utility/content-container.styled';

export const SingleStandardPage: React.FC = observer(() => {
	const { t } = useTranslation();
	const params = useParams();
	const id = Number(params.id);

	const navigate = useNavigate();
	const currentUserService = getCurrentUserService();
	const standardDetailsService = getStandardDetailsService();
	const standardService = getStandardService();
	const stagesService = getStagesService();
	const modalService = getModalService();

	useEffect(() => {
		stagesService.getStageList(standardDetailsService.standard!.form);
		stagesService.getNewStageOptions(standardDetailsService.standard!.form, id);

		standardService.getStandardById(id);
		standardDetailsService.getPriceHistory(id);
	}, [id, standardService, stagesService, standardDetailsService]);

	const isEsOrDirector = userHasRoles(
		[SystemRole.ES, SystemRole.DIRECTOR],
		currentUserService.userRoles!
	);

	const tabLists = [
		{ tabKey: 'general', title: t('standard.singleStandard.general.title') },
		{
			tabKey: 'stageAndStatus',
			title: t('standard.singleStandard.stageAndStatus.title'),
		},
		{
			tabKey: 'priceHistory',
			title: t('standard.singleStandard.priceHistory.title'),
		},
		{ tabKey: 'attachments', title: t('standard.singleStandard.attachments.title') },
		{ tabKey: 'ballots', title: t('standard.singleStandard.ballots.title') },
	];

	const getTabComponent = (key: string) => {
		if (key === 'general') {
			return <SingleStandardPageTabSection />;
		}

		if (key === 'attachments') {
			return <AttachmentsSection standardId={standardDetailsService.standard?.id} />;
		}

		if (key === 'priceHistory') {
			return <PriceHistorySection />;
		}

		if (key === 'stageAndStatus') {
			return <StageAndStatusSection standardId={standardDetailsService.standard?.id!} />;
		}

		if (key === 'ballots') {
			return <BallotsSection standardId={standardDetailsService.standard?.id!} />;
		}
	};

	const onStageBtnClick = () => {
		if (standardDetailsService.standard && standardDetailsService.standard.transaction) {
			modalService.openModal(<StageUpdate id={id} />);
		}
	};

	if (!standardDetailsService.standard) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	if (
		userHasRoles([SystemRole.EXPERT], currentUserService.userRoles!) &&
		!isOriginatorInStandard(standardDetailsService.standard, currentUserService.currentUser) &&
		!isUserParticipatingInStandardDomain(
			[standardDetailsService.standard!.domains],
			currentUserService.domainParticipations
		)
	) {
		return <Navigate to="/forbidden" replace />;
	}

	const renderStages = () => (
		<div className="description">
			<div>
				<span>{t('standard.singleStandard.subTitle')}</span>
				{standardDetailsService.standard!.transaction.stages.map((stage, index) => {
					if (index !== standardDetailsService.standard!.transaction.stages.length - 1) {
						return (
							<MarkedText key={`${stage.code}-${stage.name}`}>
								{stage.code} {stage.name},{' '}
							</MarkedText>
						);
					}

					return (
						<MarkedText key={`${stage.code}-${stage.name}`}>
							{stage.code} {stage.name}
						</MarkedText>
					);
				})}
			</div>
		</div>
	);

	return (
		<>
			<ContentContainer>
				<BreadCrumbs />
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={`${
							standardDetailsService.standard?.form === null
								? ''
								: forms.find(({ value }) => standardDetailsService.standard?.form === value)
										?.label ?? ''
						} ${standardDetailsService.standard?.registrationNumber} ${
							standardDetailsService.standard?.edition || ''
						}`}
					/>
					<ButtonGroup>
						{/* {isEsOrDirector ||
						isOriginatorInStandard(
							standardDetailsService.standard,
							currentUserService.currentUser
						) ? (
							<IconButton disabled icon={<SettingIcon />} />
						) : null} */}
						{userHasRolesAndParticipatesInDomain(
							[SystemRole.DIRECTOR, SystemRole.ES],
							currentUserService.userRoles!,
							[SystemRole.MC, SystemRole.BOARD_MEMBER],
							currentUserService.domainParticipations
						) ||
						isOriginatorInStandard(
							standardDetailsService.standard,
							currentUserService.currentUser
						) ? (
							<IconButton
								icon={<EditIcon />}
								onClick={() => navigate(`/standards/edit/${params.id}`)}
							/>
						) : null}
						<FontoButton
							editorId={standardDetailsService.standard.editorId}
							disabled={!standardDetailsService.standard.editorId}
						/>
						{isEsOrDirector ? (
							<Button
								disabled={
									// standardDetailsService.standard.isDraft ||
									!stagesService.stageList.length ||
									!stagesService.newStageOptions.length ||
									!standardDetailsService.priceHistory
								}
								title={t('standard.singleStandard.buttons.update')}
								onClick={onStageBtnClick}
							/>
						) : null}
					</ButtonGroup>
				</Flex>
				{standardDetailsService.standard.transaction && renderStages()}
				<Tabs tabLists={tabLists}>
					<Routes>
						{tabLists.map((tabList, index) => {
							if (tabList !== null) {
								return (
									<Route
										key={`${tabList.tabKey} ${index}`}
										path={tabList.tabKey}
										element={<TabPanel component={getTabComponent(tabList.tabKey)} />}
									/>
								);
							}
							return null;
						})}
					</Routes>
				</Tabs>
			</ContentContainer>
		</>
	);
});
