import { TabList, TabListType } from './tab/tab-list';
import { StyledTabContainer, StyledTabs } from './tabs.styled';

interface TabsProps {
	children: React.ReactNode;
	tabLists: Array<TabListType | null>;
	isDraft?: boolean;
	basePath?: string;
}

export const Tabs: React.FC<TabsProps> = ({
	children,
	tabLists,
	isDraft = false,
	basePath,
}: TabsProps) => {
	return (
		<StyledTabs>
			<TabList isDraft={isDraft} tabLists={tabLists} basePath={basePath} />
			<StyledTabContainer>{children}</StyledTabContainer>
		</StyledTabs>
	);
};
