import styled from 'styled-components';

export const StyledCounter = styled.p`
	display: flex;
	margin-left: 16px;
	color: ${({ theme }) => theme.fontColors.lightGrey};
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
	align-items: end;
`;
