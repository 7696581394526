import { ColumnDef, Row } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { useRequestWithPagination } from '@asd-stan/helpers/use-request-with-pagination';
import { MeetingListFilter, MeetingListItem } from '@asd-stan/meeting/domain/meeting.entity';
import { getMeetingService } from '@asd-stan/meeting/infrastructure/getters';
import { ReactComponent as Plus } from '@assets/asd-stan-works/icons/plus-icon.svg';
import { Button } from '@components/button/button';
import { ContentTable } from '@components/content-table/content-table';
import { TableControl } from '@components/content-table/table-control/table-control';
import { PageTitle } from '@components/page-title/page-title';
import { FilterCheckboxGroup } from '@components/table-filter/filter-checkbox-group/filter-checkbox-group';
import { Flex } from '@components/utility/flex';
import moment from 'moment';

import { StyledMeetingList } from './meeting-list.styled';

import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

const timeFormat = 'YYYY/MM/DD HH:mm';

export const MeetingList = observer(
	({
		meetingList,
	}: {
		meetingList: PaginationCollectionController<MeetingListItem, MeetingListFilter>;
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();

		const columns: ColumnDef<MeetingListItem, string>[] = [
			{
				header: t('meeting.createMeeting.domains'),
				accessorKey: 'domains',
				enableSorting: false,
				cell: ({ row }) => {
					const domains: { code: string; name: string }[] = row.getValue('domains');
					const domainsValue = domains.map(({ code }) => `${code}`).join(', ');
					return (
						<div className="cell" title={domainsValue}>
							{domainsValue}
						</div>
					);
				},
			},
			{
				header: t('meeting.createMeeting.workingGroups'),
				accessorKey: 'workingGroups',
				enableSorting: false,
				cell: ({ row }) => {
					const workingGroups: { code: string; name: string }[] = row.getValue('workingGroups');
					const workingGroupsValue = workingGroups.map(({ code }) => `${code}`).join(', ');
					return (
						<div className="cell" title={workingGroupsValue}>
							{workingGroupsValue}
						</div>
					);
				},
			},
			{
				header: t('meeting.createMeeting.type'),
				accessorKey: 'type',
				enableSorting: false,
				cell: ({ row }) => (
					<div className="cell">{t(`meeting.meetingType.${row.getValue('type')}`)}</div>
				),
			},
			{
				header: t('meeting.createMeeting.titleField'),
				accessorKey: 'title',
				enableSorting: false,
				cell: ({ row }) => (
					<div className="cell title" title={row.getValue('title')}>
						{row.getValue('title')}
					</div>
				),
			},
			{
				header: t('meeting.meetingList.startTime'),
				accessorKey: 'startTime',
				enableSorting: false,
				cell: ({ row }) => (
					<div className="cell">{moment(row.getValue('startTime')).format(timeFormat)}</div>
				),
			},
			{
				header: t('meeting.createMeeting.location'),
				accessorKey: 'location',
				enableSorting: false,
				cell: ({ row }) => <div className="cell">{row.getValue('location')}</div>,
			},
		];

		return (
			<ContentTable
				tableData={[...meetingList.data]}
				columns={columns}
				filtering=""
				setFiltering={() => {}}
				paginator={meetingList}
				ableToSortFirstColumn
				onRowClick={(row: Row<any>) => navigate(`/meetings/${row.original.id}/general`)}
				emptySearchText={t('meeting.meetingList.emptySearchText')}
			/>
		);
	}
);

const meetingFilterEmptyValues: MeetingListFilter = {
	domains: {},
	workingGroups: {},
};

export const MeetingFilteringForm = observer(() => {
	const domainService = getDomainService();

	const domainCheckboxes = domainService.domains.map(({ code, name }) => ({
		label: `${code} ${name}`,
		name: `domains.${code}`,
	}));

	const workingGroupCheckboxes = domainService.domains.flatMap(({ workingGroups }) =>
		workingGroups.map(({ code, name }) => ({
			label: `${code} ${name}`,
			name: `workingGroups.${code}`,
		}))
	);

	return (
		<>
			<FilterCheckboxGroup title="Domain" checkboxes={domainCheckboxes} />
			<FilterCheckboxGroup title="Working group" checkboxes={workingGroupCheckboxes} />
		</>
	);
});

const initialLimit = 20;

export const MeetingListPage = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const meetingService = getMeetingService();
	const meetingList = useRequestWithPagination(
		initialLimit,
		meetingService.getMeetingList.bind(meetingService),
		meetingService.getMeetingListFilter()
	);

	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		meetingList.setPageSize(parseInt(e.target.value));
	};

	const ableToCreate = meetingService.checkIfCurrentUserIsAbleToCreateMeeting();

	return (
		<StyledMeetingList>
			<ContentContainer>
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={t('meeting.meetingList.title')}
						count={meetingList.totalNumber}
						countName={t('meeting.meetingList.count')}
					/>
					<StyledButtonGroup>
						{ableToCreate && (
							<Button
								icon={<Plus fill="#fff" />}
								title={t('meeting.meetingList.addNew')}
								onClick={() => navigate('create')}
							/>
						)}
					</StyledButtonGroup>
				</Flex>
				<TableControl
					paginator={meetingList}
					pageSize={meetingList.pageSize}
					onPageSizeChange={onPageSizeChange}
					minSearchLength={2}
					filterValues={meetingList.filter}
					filterEmptyValues={meetingFilterEmptyValues}
					onFilterSubmit={meetingList.addFilter}
					filterForm={<MeetingFilteringForm />}
					hideSearch
				/>
				<MeetingList meetingList={meetingList} />
			</ContentContainer>
		</StyledMeetingList>
	);
});
