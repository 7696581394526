import styled from 'styled-components';

export const StyledLinkExpired = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon {
		padding: 16px;
		border-radius: 50%;
		background: rgba(11, 147, 227, 0.08);
		margin-bottom: 16px;

		svg {
			display: block;
		}
	}

	h6 {
		margin-bottom: 12px;
		font-weight: 700;
		line-height: 130%;
		font-size: 24px;
	}

	p {
		text-align: center;
		font-weight: 400;
		line-height: 150%;
		font-size: 14px;
	}

	a {
		font-size: 14px;
		color: #0b93e3;
		font-weight: 600;
		margin-top: 32px;
	}
`;

export const StyledResetSuccess = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon {
		padding: 16px;
		border-radius: 50%;
		background: rgba(9, 185, 16, 0.08);
		margin-bottom: 16px;

		svg {
			display: block;
		}
	}

	h6 {
		margin-bottom: 16px;
		font-weight: 700;
		line-height: 130%;
		font-size: 24px;
	}

	.action {
		align-self: stretch;

		& > div,
		& button {
			width: 100%;
		}
	}
`;
