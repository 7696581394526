import styled from 'styled-components';

export const StyledSingleMeeting = styled.div`
	margin-top: 24px;
`;

export const StyledLoaderContainer = styled.div`
	height: calc(100vh - 300px);
	> div {
		height: 100%;
		margin: 0 auto;
	}

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;

export const StyledUploadedFile = styled.div`
	margin-top: 16px;
	border: 1px solid #dae4e8;
	border-radius: 6px;
	padding: 8px 16px;

	.file {
		display: flex;
		align-items: center;

		.actions {
			display: flex;
			align-items: center;
			gap: 16px;

			svg {
				cursor: pointer;
			}
		}

		.file-img {
			margin-right: 16px;
		}

		.file-right {
			flex-grow: 1;

			.file-title {
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 6px;
			}

			.file-meta {
				font-size: 13px;
				color: #abb3bb;
			}
		}
	}

	.description {
		margin-top: 12px;
		line-height: 1.5;

		.label {
			text-transform: uppercase;
			font-size: 11px;
			color: #abb3bb;
			font-weight: 500;
			margin-bottom: 4px;
		}

		.value {
			color: #525259;
			font-size: 13px;
			font-weight: 600;
		}
	}
`;

export const StyledModal = styled.div`
	display: flex;

	.header {
		text-align: center;
		font-size: 32px;
		font-weight: 600;
		color: #333336;
	}

	.title {
		display: block;
		width: 100%;
		color: #abb3bb;
		font-size: 14px;
		margin: 12px 0;
		font-weight: 500;
	}

	.buttons {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 12px;
		gap: 24px;
	}
`;
