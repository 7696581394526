import styled from 'styled-components';

export const StyledBreadCrumbs = styled.div`
	& {
		margin-top: 12px;
		color: ${({ theme }) => theme.fontColors.lightGrey};

		& a {
			font-size: 13px;
			font-weight: 500;
			line-height: 150%;
			color: ${({ theme }) => theme.fontColors.lightGrey};
			text-decoration: none;
		}

		& > span {
			cursor: pointer;

			&.current {
				a {
					color: ${({ theme }) => theme.fontColors.darkGrey};
				}
			}
		}
	}
`;
