import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserService } from '@asd-stan/user/infrastructure/getters';
import moment from 'moment';

import { ReactComponent as ResendIcon } from './assets/resend.svg';

const resendTimeoutSeconds = 120;

const resendInvitationStorageKey = 'RESEND_INVITATION';

interface ResendInvitationStorageItem {
	id: number;
	addedAtString: string;
}

const getResendInvitationStorageValues = (): ResendInvitationStorageItem[] => {
	const valuesFromStorage = localStorage.getItem(resendInvitationStorageKey);
	return valuesFromStorage ? JSON.parse(valuesFromStorage) : [];
};

const saveResendInvitationStorageValues = (values: ResendInvitationStorageItem[]) => {
	localStorage.setItem(resendInvitationStorageKey, JSON.stringify(values));
};

const removeIrrelevantValues = (date: Date) => {
	const values = getResendInvitationStorageValues();
	const nextValues = values.filter(
		({ addedAtString }) =>
			!moment(addedAtString).add(resendTimeoutSeconds, 'seconds').isBefore(date)
	);
	saveResendInvitationStorageValues(nextValues);
};

const invitationTimeoutStorage = {
	set(id: number) {
		const now = new Date();
		removeIrrelevantValues(now);
		const nextValues = getResendInvitationStorageValues();
		nextValues.push({ id, addedAtString: now.toString() });
		localStorage.setItem(resendInvitationStorageKey, JSON.stringify(nextValues));
	},

	getTimeoutSeconds(id: number) {
		const now = new Date();
		removeIrrelevantValues(now);
		const values = getResendInvitationStorageValues();
		const value = values.find(v => v.id === id);
		const diff = value
			? moment(value.addedAtString).add(resendTimeoutSeconds, 'seconds').diff(now, 'seconds')
			: 0;
		return diff;
	},
};

export const Resend = ({ id }: { id: number }) => {
	const { t } = useTranslation();
	const userService = getUserService();
	const [secondsToResend, setSecondsToResend] = useState<number>(() =>
		invitationTimeoutStorage.getTimeoutSeconds(id)
	);
	const intervalRef = useRef<null | NodeJS.Timeout>(null);

	const activateTimer = () => {
		intervalRef.current = setInterval(() => {
			setSecondsToResend(s => {
				if (s <= 0) {
					return 0;
				}
				return s - 1;
			});
		}, 1000);
	};

	useEffect(() => {
		if (secondsToResend === 0 && intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	}, [secondsToResend]);

	useEffect(() => {
		if (secondsToResend) {
			activateTimer();
		}
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, []);

	const resetTimer = () => setSecondsToResend(resendTimeoutSeconds);

	const resendInvitation = async () => {
		try {
			resetTimer();
			await userService.resendInvitation(id);
			invitationTimeoutStorage.set(id);
			activateTimer();
		} catch (err: any) {
			console.error(err);
		}
	};

	return (
		<>
			{t('user.updateUser.access.pending')}
			{secondsToResend === 0 ? (
				<ResendIcon className="resendIcon" onClick={resendInvitation} />
			) : (
				<div className="resendTimer">
					{t('user.updateUser.access.resendTimer')} {Math.floor(secondsToResend / 60)}:
					{(secondsToResend % 60).toString().padStart(2, '0')}
				</div>
			)}
		</>
	);
};
