import styled from 'styled-components';

export const StyledSecondaryCell = styled.div`
	& {
		overflow: clip;
		text-overflow: ellipsis;
		color: ${({ theme }) => theme.fontColors.lightGrey};
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
	}
`;
