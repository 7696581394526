import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import {
	StandardDisplayField,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
} from '../../single-standard.styled';
import { AdditionalInfoContainer } from '../additional-information/additional-information-component.styled';

import { TitleContainer } from './titles-components.styled';

interface Props {
	titles: {
		[key: string]: string;
	};
}

export const Titles: FC<Props> = props => {
	const { t } = useTranslation();
	const languageNames = new Intl.DisplayNames(['en'], {
		type: 'language',
	});
	const titles = Object.values(props.titles);
	const titlesKeys = Object.keys(props.titles);

	const renderTags = () => (
		<>
			<TitleContainer key={`${titlesKeys[0]}`}>
				<StandardDisplayFieldHeader>{`${t(
					'standard.singleStandardPage.titles.standardTitleIn'
				)} ${languageNames.of(titlesKeys[0])}`}</StandardDisplayFieldHeader>
				{titles[0] ? (
					<StandardDisplayFieldValue key={titles[0]}>{titles[0]}</StandardDisplayFieldValue>
				) : (
					<StandardDisplayFieldValueAbsent>
						{t('standard.singleStandardPage.titles.titleAbsent')}
					</StandardDisplayFieldValueAbsent>
				)}
			</TitleContainer>
			<TitleContainer key={`${titlesKeys[2]}`}>
				<StandardDisplayFieldHeader>{`${t(
					'standard.singleStandardPage.titles.standardTitleIn'
				)} ${languageNames.of(titlesKeys[2])}`}</StandardDisplayFieldHeader>
				{titles[2] ? (
					<StandardDisplayFieldValue key={titles[2]}>{titles[2]}</StandardDisplayFieldValue>
				) : (
					<StandardDisplayFieldValueAbsent>
						{t('standard.singleStandardPage.titles.titleAbsent')}
					</StandardDisplayFieldValueAbsent>
				)}
			</TitleContainer>
			<TitleContainer key={`${titlesKeys[1]}`}>
				<StandardDisplayFieldHeader>{`${t(
					'standard.singleStandardPage.titles.standardTitleIn'
				)} ${languageNames.of(titlesKeys[1])}`}</StandardDisplayFieldHeader>
				{titles[1] ? (
					<StandardDisplayFieldValue key={titles[1]}>{titles[1]}</StandardDisplayFieldValue>
				) : (
					<StandardDisplayFieldValueAbsent>
						{t('standard.singleStandardPage.titles.titleAbsent')}
					</StandardDisplayFieldValueAbsent>
				)}
			</TitleContainer>
		</>
	);

	return (
		<InputGroup title={t('standard.singleStandardPage.titles.title')}>
			<AdditionalInfoContainer>
				<StandardDisplayField>
					{titles.length ? renderTags() : t('standard.singleStandardPage.titles.title')}
				</StandardDisplayField>
			</AdditionalInfoContainer>
		</InputGroup>
	);
};
