import { CurrentUserRepo } from '@asd-stan/current-user/api/current-user.repo';
import { CurrentUserService } from '@asd-stan/current-user/domain/current-user.service';
import {
	CURRENT_USER_REPO,
	CURRENT_USER_SERVICE,
} from '@asd-stan/current-user/infrastructure/constants';
import { injector } from '@asd-stan/injector/injector';

export const getCurrentUserRepo = () => {
	return injector.get<CurrentUserRepo>(CURRENT_USER_REPO);
};

export const getCurrentUserService = () => {
	return injector.get<CurrentUserService>(CURRENT_USER_SERVICE);
};
