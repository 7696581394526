import React from 'react';
import { Row } from '@tanstack/react-table';

import { Avatar } from '@components/avatar/avatar';
import { ExpertRow } from './experts-row.styled';
import { Expert } from '@asd-stan/standard/domain/standard-detailed.entity';

interface Props {
	row: Row<Expert>
}

export const ProjectLeader: React.FC<Props> = ({ row }) => {
	return (
		<ExpertRow>
			<Avatar />
			<p>{row.getValue('fullName')}</p>
		</ExpertRow>
	);
};
