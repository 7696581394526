import styled, { css } from 'styled-components';

const baseStyles = css`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.bgColors.sidebar};
	border: 1px solid ${({ theme }) => theme.bgColors.sidebar};

	& .dropdown {
		position: absolute;
		top: 26px;
		right: 20px;
	}

	& > a {
		padding: 12px 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}

	&:hover,
	& .active {
		background-color: ${({ theme }) => theme.bgColors.sidebarIcon};
		border-radius: 4px;

		span {
			background-color: ${({ theme }) => theme.bgColors.sidebar};
		}

		div {
			color: ${({ theme }) => theme.fontColors.white};
		}
	}
`;

export const SidebarItemTitle = styled.div`
	display: flex;
	color: ${({ theme }) => theme.fontColors.lightGrey};
	margin-left: 12px;
	align-items: center;

	& .count-container {
		display: flex;
		justify-content: center;
		margin-left: 8px;
		background-color: ${({ theme }) => theme.bgColors.warning};
		padding: 2px;
		width: 16px;
		height: 20px;
		border-radius: 50px;
		color: ${({ theme }) => theme.fontColors.darkGrey};
		font-size: 10px;
		font-weight: 500;
	}
`;

export const SidebarItemIcon = styled.span`
	background-color: ${({ theme }) => theme.bgColors.sidebarIcon};
	border-radius: 4px;
	padding: 8px;
	margin-left: 20px;

	& > svg {
		width: 20px;
		height: 20px;
	}
`;

export const StyledSidebarItem = styled.li`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({theme}) => theme.bgColors.sidebar};

  & > a {
    width: 100%;
  }

  &:hover {
    background-color: ${({theme}) => theme.bgColors.sidebarIcon};

    span {
      background-color: ${({theme}) => theme.bgColors.sidebar};
    }

    div {
      color: ${({theme}) => theme.fontColors.white};
    }
  }
`;

export const StyledMultilevelMenuItem = styled.li`
	display: flex;
	align-items: center;
	height: fit-content;
	font-size: 14px;
	padding: 4px;
	line-height: 108%;
	width: 95%;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.bgColors.sidebar};
	margin: 15px;

	& > a {
		width: 100%;
	}

	& svg {
		fill: ${({ theme }) => theme.fontColors.lightGrey};
	}

	&:hover {
		background-color: ${({ theme }) => theme.bgColors.sidebarIcon};

		span {
			background-color: ${({ theme }) => theme.bgColors.sidebar};
		}

		div {
			color: ${({ theme }) => theme.fontColors.white};
		}

		svg {
			fill: ${({ theme }) => theme.fontColors.white};
		}
	}

	& .icon-container {
		display: flex;
		align-items: center;

		svg {
			transform: rotate(-90deg);
		}
	}

	&.menu-go-back {
		position: relative;
		height: 30px;
		padding: 12px 20px;
		border-radius: inherit;

		&:after {
			position: absolute;
			content: '';
			border-bottom: rgba(218, 228, 232, 0.2) 1px solid;
			width: 100%;
			transform: translateX(-50%);
			bottom: -5px;
			padding-right: 10px;
			left: 50%;
		}
	}

	&.active {
		background-color: ${({ theme }) => theme.bgColors.sidebarIcon};
		border-radius: 4px;

		span {
			background-color: ${({ theme }) => theme.bgColors.sidebar};
		}

		div {
			color: ${({ theme }) => theme.fontColors.white};
		}
	}
`;