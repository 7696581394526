import styled from 'styled-components';

export const StyledDashboard = styled.div`
	margin-top: 20px;
`;

export const StyledDashboardTableControls = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 8px;

	& > span {
		flex-grow: 1;
	}

	h6 {
		display: inline;
		font-size: 24px;
		font-weight: 600;
		margin-right: 8px;
		color: #333336;
	}

	.amount {
		font-size: 14px;
		color: #abb3bb;
		font-weight: 500;
	}

	.search {
		height: 36px;
		margin-right: 8px;

		input {
			color: #333336;
		}
	}
`;
