import styled from 'styled-components';

export const StyledFilterDateRange = styled.label`
	border: 1px solid #dae4e8;
	border-radius: 8px;
	height: 36px;
	padding: 0 16px;
	display: flex;
	align-items: center;

	input {
		font-size: 13px;

		&::placeholder {
			color: #abb3bb;
		}
	}

	svg {
		margin-right: 6px;
	}
`;
