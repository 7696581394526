import { FC } from "react";
import { InputGroup } from "@asd-stan/user/components/layout/input-group/input-group";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { StandardDisplayRow } from "../../single-standard.styled";
import { SingleStandardField } from "../../single-standard-field";

interface Props {
    createdAt: string;
    updatedAt: string;
}

export const RegistrationData: FC<Props> = (props) => {
    const { t } = useTranslation();
    const createdAt = DateTime.fromISO(props.createdAt).toFormat('dd/LL/yyyy hh:mm');
    const updatedAt = DateTime.fromISO(props.updatedAt).toFormat('dd/LL/yyyy hh:mm');

    return (
        <InputGroup title={t('standard.singleStandardPage.regData.title')} nonCollapsable>
            <StandardDisplayRow style={{marginTop: 24}}>
                <SingleStandardField
                    header={t('standard.singleStandardPage.regData.createdAt')}
                    value={createdAt}
                />
                <SingleStandardField
                    header={t('standard.singleStandardPage.regData.updatedAt')}
                    value={updatedAt}
                />
            </StandardDisplayRow>
        </InputGroup>
    )
}