import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const StyledLogo = styled(Link)`
	& {
		margin-left: 20px;

		& > svg {
			margin-top: 24px;
			fill: ${({ color }) => color};
		}
	}
`;
