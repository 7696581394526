import React, { ChangeEvent, FocusEventHandler, HTMLInputTypeAttribute, ReactNode } from 'react';

import { StyledInput } from '@components/input/input.styled';

interface InputProps {
	value?: string;
	type?: HTMLInputTypeAttribute | undefined;
	placeholder?: string | undefined;
	className?: string | undefined;
	icon?: ReactNode | undefined;
	onFocus?: FocusEventHandler | undefined;
	onBlur?: FocusEventHandler | undefined;
	title?: string | undefined;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<InputProps> = ({
	title,
	type,
	placeholder,
	className = '',
	icon,
	...rest
}) => {
	return (
		<StyledInput className={className} $icon={!!icon}>
			{title ? <label title={title}>{title}</label> : null}
			{icon && <div>{icon}</div>}
			<input
				id={title && title}
				name={title && title}
				type={type}
				placeholder={placeholder}
				{...rest}
			/>
		</StyledInput>
	);
};
