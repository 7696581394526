import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
	BallotForm as BallotFormType,
	BallotStatus,
	ValidatedBallotForm,
} from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { useRequest } from '@asd-stan/helpers/use-request';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { Button } from '@components/button/button';
import { IconButton } from '@components/button/icon-button';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';

import { BallotForm, SubmitButton } from '../ballot-form/ballot-form';
import { StyledLoaderContainer } from '../single-ballot/single-ballot.styled';

import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import { StyledModal } from './update-ballot.styled';

import { ContentContainer } from '@components/utility/content-container.styled';

const DeleteButtonModal = ({ onSubmit }: { onSubmit(): Promise<void> }) => {
	const { t } = useTranslation();
	const modalService = getModalService();
	const [submitting, setSubmitting] = useState(false);

	return (
		<StyledModal>
			<h4>{t('ballot.updateBallot.deleteBallotTitle')}</h4>
			<div className="body">{t('ballot.updateBallot.deleteBallotText')}</div>
			<div className="buttons">
				<Button
					secondary
					title={t('ballot.updateBallot.deleteBallotCancel')}
					onClick={() => modalService.closeModal()}
				/>
				<Button
					disabled={submitting}
					title={t('ballot.updateBallot.deleteBallotConfirm')}
					onClick={async () => {
						setSubmitting(true);
						await onSubmit();
						modalService.closeModal();
					}}
				/>
			</div>
		</StyledModal>
	);
};

export const UpdateBallot = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ballotService = getBallotService();
	const toasterService = getToasterService();
	const modalService = getModalService();
	const params = useParams();
	const id = Number(params.id);
	const { data: singleBallotToEdit, isLoading: ballotLoading } = useRequest(
		useCallback(() => ballotService.getSingleBallotToUpdate(id), [id]),
		{
			onSuccess({ createdById }) {
				if (!ballotService.checkIfCurrentUserIsAbleToUpdateBallot(createdById)) {
					navigate('/forbidden');
				}
			},
		}
	);

	const handleBallotUpdate = async (values: BallotFormType) => {
		try {
			await ballotService.updateBallot(id, values as ValidatedBallotForm);
			toasterService.showToast(ToasterStatus.success, t('ballot.updateBallot.success'));
			navigate(`/ballots`);
		} catch (err) {
			console.error(err);
		}
	};

	const deleteBallot = async () => {
		try {
			await ballotService.deleteBallot(id);
			toasterService.showToast(ToasterStatus.success, t('ballot.updateBallot.ballotDeleted'));
			navigate('/ballots');
		} catch (err) {
			console.error(err);
		}
	};

	const openDeleteBallotModal = () => {
		modalService.openModal(<DeleteButtonModal onSubmit={deleteBallot} />);
	};

	if (ballotLoading) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	if (!singleBallotToEdit) {
		return null;
	}

	return (
		<BallotForm
			title={t('ballot.updateBallot.title')}
			onSubmit={handleBallotUpdate}
			initialValues={singleBallotToEdit.form}
			actions={
				<>
					<IconButton icon={<DeleteIcon />} onClick={openDeleteBallotModal} />
					<Button secondary title={t('ballot.createBallot.cancel')} onClick={() => navigate(-1)} />
					<SubmitButton
						title={t('ballot.createBallot.save')}
						submitStatus={singleBallotToEdit.form.status}
					/>
					{singleBallotToEdit.form.status === BallotStatus.Pending ||
					singleBallotToEdit.form.status === BallotStatus.Closed ? (
						<SubmitButton
							title={t('ballot.createBallot.launch')}
							submitStatus={BallotStatus.Active}
						/>
					) : (
						<SubmitButton
							title={t('ballot.updateBallot.closeBallot')}
							submitStatus={BallotStatus.Closed}
						/>
					)}
				</>
			}
		/>
	);
});
