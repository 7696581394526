import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardDetailedRemark } from '@asd-stan/standard/domain/standard-detailed.entity';
import { Avatar } from '@components/avatar/avatar';
import { ButtonClose } from '@components/button/button-close';
import { Flex } from '@components/utility/flex';
import { DateTime } from 'luxon';

import { RemarkContainerStyled } from './remark-container.styled';

interface RemarkContainerProps {
	remark: StandardDetailedRemark | null;
	fromSingleStandard?: boolean;
	removeEvent?: (() => void) | null;
	notFound?: boolean;
}

export const RemarkContainer: React.FC<RemarkContainerProps> = observer(
	({ remark, fromSingleStandard = false, removeEvent, notFound = false }) => {
		const { t } = useTranslation();

		if (remark === null || removeEvent === null) {
			return (
				<RemarkContainerStyled>
					<p
						dangerouslySetInnerHTML={{
							__html: t('standard.createNWP.remarks.noRemarkTitle'),
						}}
					/>
				</RemarkContainerStyled>
			);
		}
		console.log(remark);
		return (
			<RemarkContainerStyled>
				<Flex $align="start" className="containerHeader">
					<Avatar notFound={notFound} />
					<Flex $align="center">
						<h2>
							{fromSingleStandard
								? `${remark.createdBy && remark.createdBy.firstName} ${
										remark.createdBy && remark.createdBy.lastName
								  }`
								: remark.name}
						</h2>
						{fromSingleStandard
							? DateTime.fromISO(remark.createdAt!).toFormat('dd/LL/yyyy')
							: DateTime.fromISO(remark.date!).toFormat('dd/LL/yyyy') === 'Invalid DateTime'
							  ? remark.date
							  : DateTime.fromISO(remark.date!).toFormat('dd/LL/yyyy')}
						{removeEvent ? <ButtonClose onClick={removeEvent} /> : null}
					</Flex>
				</Flex>
				{remark.stageUpdate && (
					<div className="stageUpdate">
						{remark.stageUpdate.from.map(({ code, name }) => `${code} ${name}`).join(', ')} -&gt;{' '}
						{remark.stageUpdate.to.map(({ code, name }) => `${code} ${name}`).join(', ')}
					</div>
				)}
				<div className="text">{remark.text}</div>
			</RemarkContainerStyled>
		);
	}
);
