import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { CatalogItem } from '@asd-stan/standard/domain/catalog-item.entity';
import { makeAutoObservable } from 'mobx';

import { getCatalogRepo } from '../infrastructure/getters';

export interface CatalogCollectionFilter {
	onlyPublished: boolean;
	search: string | null;
	orderField?: string;
	orderDirection?: 'DESC' | 'ASC';
}

const publishersToMakeFieldsOptional = ['DIN', 'SAE'];

export class CatalogService {
	catalog: PaginationCollectionController<CatalogItem, CatalogCollectionFilter>;

	constructor() {
		this.catalog = new PaginationCollectionController<CatalogItem, CatalogCollectionFilter>(
			20,
			async (limit, offset, filter) => {
				return await this._catalogRepo.getCatalogItemsList(limit, offset, filter);
			},
			{
				onlyPublished: false,
				search: null,
				orderField: 'id',
				orderDirection: 'DESC',
			}
		);

		makeAutoObservable(this);
	}

	//getters

	private get _catalogRepo() {
		return getCatalogRepo();
	}

	//methods end

	async editCatalogItem(id: number, values: object) {
		await this._catalogRepo.editCatalogItem(id, values);
	}

	async updateCatalogItemShow(id: number, show: boolean) {
		await this._catalogRepo.updateCatalogItemShow(id, show);
	}

	checkIfFieldsShouldBeOptional(publisher: string) {
		return publishersToMakeFieldsOptional.includes(publisher);
	}
}
