import styled from 'styled-components';

export const StyledFontoButton = styled.div<{ $disabled?: boolean }>`
	& button {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		align-content: center;
		padding: 7px 26px;
		border-radius: 8px;
		background-color: ${props =>
			props.$disabled ? props.theme.bgColors.lightGrey : props.theme.button.fonto};
		height: 42px;
		cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
		opacity: ${props => (props.$disabled ? 0.7 : 1)};
	}

	& button:hover {
		background-color: ${props =>
			props.$disabled ? props.theme.bgColors.lightGrey : props.theme.button.fontoHover};
	}
`;
