import { useTranslation } from 'react-i18next';

import { SavedFile, WithTimestamp } from '@asd-stan/ballot/domain/ballot.entity';

import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';
import { StyledBallotField } from './single-ballot.styled';
import { transformDate } from './transformDate';

export const FileField = ({
	attachment,
	onDownloadClick: handleDownload,
}: {
	attachment: WithTimestamp<SavedFile>;
	onDownloadClick(id: number, fileName: string): void;
}) => {
	const { t } = useTranslation();

	const downloadFile = async (id: number, fileName: string) => {
		try {
			await handleDownload(id, fileName);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<StyledBallotField $fullWidth>
			<div className="file">
				<FileIcon className="file-img" />
				<div className="file-right">
					<div className="file-title">{attachment.value.title}</div>
					<div className="file-meta">
						{t('ballot.singleBallot.sharerdBy')} {attachment.doneBy.firstName}{' '}
						{attachment.doneBy.lastName}, {transformDate(attachment.doneAt)}
					</div>
				</div>
				<DownloadIcon
					className="downloadIcon"
					onClick={() => downloadFile(attachment.value.id, attachment.value.title)}
				/>
			</div>
		</StyledBallotField>
	);
};
