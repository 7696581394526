import { useEffect, useState } from 'react';

export type InfiniteRequestFetchFunction<T> = (
	limit: number,
	offset: number
) => Promise<{
	data: T[];
	totalCount: number;
}>;

type State<T> = {
	offset: number;
	data: T[];
	isLoading: boolean;
	error: any;
	hasMore: boolean;
};

interface UseRequestResult<T> extends Omit<State<T>, 'offset'> {
	loadMore(): void;
}

export const useInfiniteRequest = <Data>(
	fetchFunction: InfiniteRequestFetchFunction<Data>,
	limit: number
): UseRequestResult<Data> => {
	const [state, setState] = useState<State<Data>>({
		offset: 0,
		data: [],
		isLoading: false,
		error: null,
		hasMore: true,
	});

	const { offset, data, error, isLoading, hasMore } = state;

	const loadMore = async () => {
		if (isLoading || !hasMore || error) {
			return;
		}

		setState({
			...state,
			isLoading: true,
		});
		try {
			const fetchedData = await fetchFunction(limit, state.offset);
			setState({
				...state,
				offset: limit + state.offset,
				data: [...data, ...fetchedData.data],
				isLoading: false,
				hasMore: offset + limit < fetchedData.totalCount,
			});
		} catch (error: any) {
			console.error(error);
			setState({
				...state,
				isLoading: false,
				error,
				hasMore: false,
			});
		}
	};

	useEffect(() => {
		loadMore();
	}, []);

	return { data, isLoading, error, loadMore, hasMore };
};
