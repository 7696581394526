import { TransactionFromAPI } from '@asd-stan/standard/domain/standard-stage.entity';
import { User } from '@asd-stan/user/domain/user.entity';
import { makeAutoObservable } from 'mobx';

export interface AttachmentUpload {
	file: {
		title: string;
		size: number;
		id: number;
		fileId: number;
		path: string;
	};
	attachmentDescription: string;
	attachmentType: {
		label: string;
		value: string;
	};
}

interface AttachmentAuthor extends Pick<User, 'id' | 'firstName' | 'lastName'> {
	picture: StandardFile | null;
}

export interface AttachmentResponse {
	id: number;
	file: StandardFile;
	description: string;
	type: string;
	createdAt: string;
	createdBy: AttachmentAuthor;
}

export interface StandardDomain {
	code: string;
	name: string;
}

export interface StandardWorkingGroup extends StandardDomain {}

export interface TechnicalChanges {
	id: number;
	title: string;
	change: string;
}

export interface Justification {
	purpose: string | null;
	revisionJustification: string | null;
	technicalChanges: TechnicalChanges[];
	consideredStandards: InternalStandardPart[];
	externalConsideredStandards: string;
	knownPatentIssue: boolean;
	patentFile: StandardFile | null;
	patentReferences: string;
}

export interface Tag {
	value: string;
}

export interface Classification {
	id: number;
	number: string;
	name: string;
}

export interface ICSCode {
	code: string;
	name: string;
}

export interface Domain {
	code: string;
	name: string;
}

export interface WorkingGroup {
	code: string;
	name: string;
}

export interface Expert extends Pick<User, 'id' | 'firstName' | 'lastName'> {
	domains: Domain[];
	workingGroups: WorkingGroup[];
	picture: StandardFile | null;
	fullName?: string;
}

export interface Originator
	extends Pick<
		User,
		'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'company' | 'country' | 'positions'
	> {
	userId: string | null;
	picture: StandardFile | null;
	isAsdOriginator?: boolean;
	isExistOriginator?: boolean;
}

export interface Leader extends Pick<User, 'id' | 'firstName' | 'lastName'> {
	systemRoles: string[];
	picture: StandardFile | null;
	fullName?: string | null;
}

export interface WGS extends Leader {}

export interface StandardDetailedRemark {
	id?: number;
	name?: string;
	date?: string;
	text: string;
	remarkType?: string | null;
	createdBy?:
		| (Pick<User, 'id' | 'firstName' | 'lastName'> & { picture: StandardFile | null })
		| null;
	createdAt?: string;
	stageUpdate: null | {
		from: { code: string; name: string }[];
		to: { code: string; name: string }[];
	};
}

export interface StandardFile {
	id: number;
	name: string;
	path: string;
	size: number;
	mimeType?: string;
}

export interface InternalStandardPart {
	id: number;
	name: string;
}

export interface Revision {
	id: number;
	registrationNumber: string;
	form: string;
	localizedTitle: string;
}

export interface Stage {
	code: string;
	name: string;
	startDate?: string;
	targetDate?: string;
	completeDate?: string;
}

export interface StandardDetailedArgs {
	id: number;
	stanNumber: string;
	transaction: TransactionFromAPI;
	registrationNumber: string;
	form: string;
	edition: string;
	editorId: number | null;
	isDraft: boolean;
	createdAt: string;
	updatedAt: string;
	cenWiNumber: null | string;
	file: StandardFile | null;
	status: string;
	stage: Stage;
	revision: Revision | null;
	localizedTitle: string;
	titles: {
		[key: string]: string;
	};
	languages: string[];
	standardTypes: {
		id: number;
		name: string;
	}[];
	pages: number;
	domains: StandardDomain[];
	workingGroups: StandardWorkingGroup[];
	leader: Leader;
	wgs: WGS | null;
	scope: string;
	originator: Originator;
	justification: Justification;
	tags: Tag[];
	classifications: Classification[];
	icsCodes: ICSCode[];
	experts: Expert[];
	remarks: StandardDetailedRemark[];
	isMigrated: boolean;
	enName: null | string;
	enPublicationDate: null | Date;
	enTitle: null | string;
}

export class StandardDetailed {
	private _id: number;
	private _stanNumber: string;
	private _transaction: TransactionFromAPI;
	private _registrationNumber: string;
	private _form: string;
	private _edition: string;
	private _editorId: number | null;
	private _createdAt: string;
	private _updatedAt: string;
	private _cenWiNumber: null | string;
	private _status: string;
	private _stage: Stage;
	private _revision: Revision | null;
	private _localizedTitle: string;
	private _file: StandardFile | null;
	private _titles: {
		[key: string]: string;
	};
	private _languages: string[];
	private _standardTypes: {
		id: number;
		name: string;
	}[];
	private _pages: number;
	private _domains: StandardDomain[];
	private _workingGroups: StandardWorkingGroup[];
	private _leader: Leader;
	private _wgs: WGS | null;
	private _scope: string;
	private _originator: Originator | null;
	private _justification: Justification;
	private _tags: Tag[];
	private _classifications: Classification[];
	private _icsCodes: ICSCode[];
	private _experts: Expert[];
	private _remarks: StandardDetailedRemark[];
	private _isMigrated: boolean;
	enData: {
		name: string;
		publicationDate: null | Date;
		title: string;
	};

	constructor(args: StandardDetailedArgs) {
		makeAutoObservable(this);
		this._id = args.id;
		this._stanNumber = args.stanNumber;
		this._transaction = args.transaction;
		this._registrationNumber = args.registrationNumber;
		this._form = args.form;
		this._edition = args.edition;
		this._editorId = args.editorId;
		this._createdAt = args.createdAt;
		this._updatedAt = args.updatedAt;
		this._cenWiNumber = args.cenWiNumber;
		this._status = args.status;
		this._stage = args.stage;
		this._revision = args.revision;
		this._localizedTitle = args.localizedTitle;
		this._titles = args.titles;
		this._languages = args.languages;
		this._standardTypes = args.standardTypes;
		this._pages = args.pages;
		this._domains = args.domains;
		this._workingGroups = args.workingGroups;
		this._leader = args.leader && {
			...args.leader,
			systemRoles: args.leader.systemRoles.map(role => role.toLowerCase()),
		};
		this._wgs = args.wgs
			? {
					...args.wgs,
					fullName: `${args.wgs.firstName} ${args.wgs.lastName}`,
			  }
			: null;
		this._scope = args.scope;
		this._originator = args.originator;
		this._justification = args.justification;
		this._tags = args.tags;
		this._classifications = args.classifications;
		this._icsCodes = args.icsCodes;
		this._experts = args.experts.map(expert => ({
			...expert,
			fullName: `${expert.firstName} ${expert.lastName}`,
		}));
		this._remarks = args.remarks;
		this._file = args.file;
		this._isMigrated = args.isMigrated;
		this.enData = {
			name: args.enName || '',
			publicationDate: args.enPublicationDate ? new Date(args.enPublicationDate) : null,
			title: args.enTitle || '',
		};
	}

	get id() {
		return this._id;
	}

	get stanNumber() {
		return this._stanNumber;
	}

	get transaction() {
		return this._transaction;
	}

	get registrationNumber() {
		return this._registrationNumber;
	}

	get form() {
		return this._form;
	}

	get edition() {
		return this._edition;
	}

	get editorId() {
		return this._editorId;
	}

	get createdAt() {
		return this._createdAt;
	}

	get updateAt() {
		return this._updatedAt;
	}

	get cenWiNumber() {
		return this._cenWiNumber;
	}

	get status() {
		return this._status;
	}

	get stage() {
		return this._stage;
	}

	set stage(stageData) {
		this._stage = stageData;
	}

	get revision() {
		return this._revision;
	}

	get localizedTitle() {
		return this._localizedTitle;
	}

	get titles() {
		const { __typename, ...rest } = this._titles;
		return rest;
	}

	get languages() {
		return this._languages;
	}

	get standardTypes() {
		return this._standardTypes;
	}

	get pages() {
		return this._pages;
	}

	get domains() {
		return this._domains;
	}

	get workingGroups() {
		return this._workingGroups;
	}

	get leader() {
		return this._leader;
	}

	get wgs() {
		return this._wgs;
	}

	get scope() {
		return this._scope;
	}

	get originator() {
		return this._originator;
	}

	get justification() {
		return this._justification;
	}

	get tags() {
		return this._tags;
	}

	get classifications() {
		return this._classifications;
	}

	get icsCodes() {
		return this._icsCodes;
	}

	get experts() {
		return this._experts;
	}

	get remarks() {
		return this._remarks;
	}

	set remarks(remarksData) {
		this._remarks = remarksData;
	}

	get file() {
		return this._file;
	}

	get isMigrated() {
		return this._isMigrated;
	}
}
