import { useRef } from 'react';

import { Page, PaginationCollectionController } from './pagination-collection-controller';

export const useRequestWithPagination = <T, U>(
	pageSize: number,
	obtainFn: (limit: number, offset: number, filter: Partial<U>) => Promise<Page<T>>,
	initialFilter?: Partial<U>
) => {
	const ref = useRef<null | PaginationCollectionController<T, U>>(null);

	if (ref.current === null) {
		ref.current = new PaginationCollectionController(pageSize, obtainFn, initialFilter);
	}

	return ref.current;
};
