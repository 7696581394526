import React, { ReactNode } from 'react';

import { Radio } from '@components/radio/radio';

import { StyledRadioForm } from './radio-form.styled';

interface RadioFormProps {
	children: ReactNode;
	label?: string;
	checked?: boolean;
	onChange?: () => void;
}

export const RadioForm: React.FC<RadioFormProps> = ({ children, label, checked, onChange }) => {
	return (
		<StyledRadioForm $checked={checked}>
			<Radio label={label} checked={checked} onChange={onChange} />
			{children}
		</StyledRadioForm>
	);
};
