import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import styled, { DefaultTheme, css } from 'styled-components';

const toasterStatusColors = (theme: DefaultTheme) => ({
	[ToasterStatus.success]: css`
		border-left: ${theme.toaster.success};
	`,
	[ToasterStatus.warning]: css`
		border-left: ${theme.toaster.warning};
	`,
	[ToasterStatus.error]: css`
		border-left: ${theme.toaster.error};
	`,

	[ToasterStatus.export]: css`
		border-left: ${theme.toaster.export};
	`,
});

export const StyledToaster = styled.div<{
	$isOpen: boolean;
	$status: ToasterStatus;
}>`
	position: fixed;
	max-width: 460px;
	min-width: 300px;
	z-index: 10;
	right: 30px;
	bottom: 30px;
	padding: 16px;
	color: ${({ theme }) => theme.fontColors.darkGrey};
	border-radius: 8px;
	background: ${({ theme }) => theme.fontColors.white};
	box-shadow: 0 8px 24px 0 rgba(29, 45, 69, 0.16);
	transition:
		transform 0.5s ease,
		opacity 0.3s ease;

	${({ $isOpen }) =>
		$isOpen &&
		css`
			transform: translateX(0);
			opacity: 1;
		`}

	${({ $isOpen }) =>
		!$isOpen &&
		css`
			transform: translateX(100%);
			opacity: 0;
		`}

  ${({ $status, theme }) => toasterStatusColors(theme)[$status]};
`;
