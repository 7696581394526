import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MeetingType, SingleMeeting } from '@asd-stan/meeting/domain/meeting.entity';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { Field } from '@components/field/field';
import moment from 'moment';

import { FieldGrid } from '@components/field/field.styled';

const typeOptions: Record<MeetingType, string> = {
	[MeetingType.Physical]: 'Physical',
	[MeetingType.Remote]: 'Remote',
};

const timeFormat = 'YYYY/MM/DD HH:mm';

export const General = ({ meeting }: { meeting: SingleMeeting }) => {
	const { t } = useTranslation();

	const meetingForm = meeting.form;

	return (
		<InputGroup title="General" nonCollapsable>
			<FieldGrid>
				<Field
					label={t('meeting.createMeeting.domains')}
					value={meetingForm.domains.map(({ label }) => label).join(', ')}
				/>
				<Field
					label={t('meeting.createMeeting.workingGroups')}
					value={meetingForm.workingGroups.map(({ label }) => label).join(', ')}
				/>
				<Field label={t('meeting.createMeeting.titleField')} value={meetingForm.title} fullWidth />
				<Field
					label={t('meeting.createMeeting.type')}
					value={typeOptions[meetingForm.type.value]}
					fullWidth
				/>
				<Field
					label={t('meeting.createMeeting.startTime')}
					value={moment(meetingForm.startTime).format(timeFormat)}
				/>
				<Field
					label={t('meeting.createMeeting.endTime')}
					value={meetingForm.endTime ? moment(meetingForm.endTime).format(timeFormat) : null}
				/>
				<Field
					label={t('meeting.createMeeting.link')}
					value={
						meetingForm.link ? (
							<a
								href={
									meetingForm.link.startsWith('https://')
										? meetingForm.link
										: `https://${meetingForm.link}`
								}
								target="_blank"
								rel="noreferrer">
								{meetingForm.link}
							</a>
						) : null
					}
					fullWidth
				/>
				<Field label={t('meeting.createMeeting.location')} value={meetingForm.location} fullWidth />
				<Field
					label={t('meeting.createMeeting.standard')}
					value={
						meeting.standard && (
							<Link to={`/standards/detailed/${meeting.standard.id}/general`} target="_blank">
								{meeting.standard.label}
							</Link>
						)
					}
					fullWidth
				/>
			</FieldGrid>
		</InputGroup>
	);
};
