import { StandardRepo } from '@asd-stan/standard/api/standard.repo';
import {
	AttachmentResponse,
	AttachmentUpload,
	StandardDetailed,
} from '@asd-stan/standard/domain/standard-detailed.entity';
import { getStandardRepo } from '@asd-stan/standard/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

import { PriceHistory } from './price-history.entity';
import { UpdateStageForm } from './stage-update.entity';

export interface StandardDetailedService {
	isLoading: boolean;
	standard: StandardDetailed | null;
	attachments: AttachmentResponse[] | null;
	priceHistory: PriceHistory | null;
	getStandardById: (id: number) => Promise<void>;
	addRemarkToStandard: (standardId: number, text: string) => Promise<void>;
	getAttachmentsByStandardId: (id: number) => Promise<void>;
	addAttachmentToStandard: (attachments: AttachmentUpload[]) => Promise<void>;
	deleteAttachmentFromStandard: (id: number) => Promise<void>;
	updateStage: (id: number, data: UpdateStageForm, ableToPublish: boolean) => Promise<void>;
	getPriceHistory: (standardId: number) => Promise<void>;
	updatePrice: (standardId: number, amount: number) => Promise<void>;
	addPrice: (standardId: number, amount: number) => Promise<void>;
}

const priceHistoryLimit = 20;
const priceHistoryOffset = 0;

export class StandardDetailsService implements StandardDetailedService {
	public isLoading: boolean;
	public attachments: AttachmentResponse[] | null;
	public standard: StandardDetailed | null;
	private _standardRepo: StandardRepo;
	public priceHistory: PriceHistory | null = null;

	constructor() {
		makeAutoObservable(this);
		this._standardRepo = getStandardRepo();
		this.standard = null;
		this.attachments = [];
		this.isLoading = true;
	}

	async getStandardById(id: number) {
		this.isLoading = true;

		const response = await this._standardRepo.getStandardById(id);

		runInAction(() => {
			this.standard = response;
			this.isLoading = false;
		});
	}

	async addRemarkToStandard(standardId: number, text: string) {
		try {
			const response = await this._standardRepo.addRemarkToStandard(standardId, text);

			runInAction(() => {
				if (!this.standard?.remarks || !this.standard.remarks.length) {
					this.standard!.remarks = [response];
				} else {
					this.standard!.remarks = [...this.standard!.remarks, response];
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	async getAttachmentsByStandardId(id: number) {
		const response = await this._standardRepo.getAttachmentsByStandardId(id);

		runInAction(() => {
			this.attachments = response;
		});
	}

	async addAttachmentToStandard(attachments: AttachmentUpload[]) {
		try {
			const attachmentsToUpload = attachments.filter(attachment => attachment.file.fileId);

			for (const attachment of attachmentsToUpload) {
				await this._standardRepo.addAttachmentToStandard(this.standard!.id, attachment);
			}

			await this.getAttachmentsByStandardId(this.standard!.id);
		} catch (error) {
			console.error(error);
		}
	}

	async deleteAttachmentFromStandard(id: number) {
		try {
			await this._standardRepo.deleteAttachmentFromStandard(id);
			await this.getAttachmentsByStandardId(this.standard!.id);
		} catch (error) {
			console.error(error);
		}
	}

	async updateStage(id: number, updateStageForm: UpdateStageForm, ableToPublish: boolean) {
		await this._standardRepo.updateStage(id, updateStageForm, ableToPublish);
		await this.getStandardById(id);
	}

	async getPriceHistory(standardId: number) {
		try {
			this.priceHistory = null;
			this.priceHistory = await this._standardRepo.getPriceHistory(
				standardId,
				priceHistoryLimit,
				priceHistoryOffset
			);
		} catch (error) {
			console.error(error);
		}
	}

	async updatePrice(standardId: number, amount: number) {
		try {
			this.priceHistory = null;
			await this._standardRepo.updatePrice(standardId, amount);
			await this.getPriceHistory(standardId);
		} catch (error) {
			console.error(error);
		}
	}

	async addPrice(standardId: number, amount: number) {
		try {
			this.priceHistory = null;
			await this._standardRepo.addPrice(standardId, amount);
			await this.getPriceHistory(standardId);
		} catch (error) {
			console.error(error);
		}
	}
}
