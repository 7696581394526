import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardChangeItem } from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/standard-change-item/standard-change-item';
import { StandardStageUpdateHistoryResponseParsed } from '@asd-stan/standard/domain/standard-stage.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';

import { AttachmentsLoader } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachments-section.styled';
import { StandardChangeContainer } from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/standard-change-block/standard-change-block-styled';

const DATA_PER_PAGE = 20;

interface Props {
	transactionId: number;
	standardId: number;
}

export const StandardChangeBlock: FC<Props> = observer(({ transactionId, standardId }) => {
	const standardService = getStandardService();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [pageCount, setPageCount] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [updateHistory, setUpdateHistory] =
		useState<StandardStageUpdateHistoryResponseParsed | null>(null);
	const loader = useRef(null);
	const root = useRef(null);

	useEffect(() => {
		if (currentPage <= pageCount) {
			setIsLoading(true);
			standardService
				.getStandardStageUpdateChangeHistory(
					standardId,
					transactionId,
					DATA_PER_PAGE,
					currentPage * DATA_PER_PAGE
				)
				.then(data => {
					if (data) {
						setUpdateHistory(
							currentPage === 0
								? data
								: {
										...data,
										standardChangesHistory: updateHistory
											? [...updateHistory.standardChangesHistory, ...data.standardChangesHistory]
											: data.standardChangesHistory,
								  }
						);
					}
					setPageCount(Math.ceil(data?.standardChangesHistoryCount! / DATA_PER_PAGE));
					setIsLoading(false);
				});
		}
	}, [currentPage, pageCount, transactionId, standardId, standardService]);

	const handleObserver = useCallback(
		(entries: any) => {
			const target = entries[0];
			if (target.isIntersecting) {
				if (currentPage <= pageCount) {
					setCurrentPage(prev => prev + 1);
				}
			}
		},
		[currentPage, pageCount]
	);

	useEffect(() => {
		const option = {
			root: root.current,
			threshold: 1,
		};
		const observer = new IntersectionObserver(handleObserver, option);

		if (loader.current) {
			observer.observe(loader.current);
		}

		return () => {
			if (loader.current) {
				observer.unobserve(loader.current);
			}
		};
	}, [handleObserver]);

	if (!standardService.standardTransactionHistory) {
		return null;
	}

	return (
		<StandardChangeContainer
			className={!isLoading && !updateHistory?.standardChangesHistory.length ? 'no-items' : ''}>
			{updateHistory
				? updateHistory.standardChangesHistory.map((change, index) => (
						<StandardChangeItem key={`${change.date}-${index}`} data={change} />
				  ))
				: null}
			{!isLoading && !updateHistory?.standardChangesHistory.length ? (
				<>{t('standard.singleStandard.stageAndStatus.table.noItems')}</>
			) : null}
			<div ref={loader}>
				{isLoading && (
					<AttachmentsLoader>
						<Flex $align="center" $justify="center" $direction="column">
							<LogoIcon />
							<Loading horizontal />
						</Flex>
					</AttachmentsLoader>
				)}
			</div>
		</StandardChangeContainer>
	);
});
