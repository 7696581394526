import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadPrivateFile } from '@asd-stan/standard/components/download-file/download-private-file';
import { StandardFile } from '@asd-stan/standard/domain/standard-detailed.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

interface Props {
	data: StandardFile | null;
	standardId: number | null;
}

export const StandardDraft: FC<Props> = props => {
	const standardService = getStandardService();
	const { t } = useTranslation();

	const getFileLink = async (): Promise<string> => {
		try {
			if (props.standardId) {
				const link = await standardService.getPrivateStandardDraftLinkById(props.standardId);
				return link;
			}
			return '';
		} catch (error) {
			console.error('Error fetching link:', error);
			return '';
		}
	};

	if (!props.data) {
		return null;
	}

	return (
		<InputGroup title={t('standard.singleStandardPage.standardDraft.title')}>
			<DownloadPrivateFile title={props.data.name} fetchMethod={getFileLink} notFromAssets />
		</InputGroup>
	);
};
