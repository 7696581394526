import React, { FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, Options, SingleValue, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { generatePlaceholder } from '@asd-stan/helpers/app-utils';
import { ErrorMessage, FieldProps } from 'formik';

import { MarkedText } from '../utility/marked-text';

import { StyledCheckboxOption, StyledCreateLabel, StyledFormSelect } from './form-select.styled';

import { StyledFieldErrorMessage } from '@asd-stan/ui-kit/components/utility/field-error-message.styled';

interface Option {
	value: string;
	label: string;
}

interface FormSelectProps {
	title: string;
	isMulti?: boolean;
	placeholder?: string;
	fullWidth?: boolean;
	mandatory?: boolean;
	options: Option[];
	name: string;
	onChange?: (name: string, option: Option | Option[]) => void;
	onCreateOption?: (value: string) => void;
	value?: Option;
	touched?: boolean;
	errors?: string;
	isOptionDisabled?: ((option: Option, selectValue: Options<Option>) => boolean) | undefined;
	isLoading?: boolean;
	showError?: boolean;
	showCheckboxOption?: boolean;
	closeMenuOnSelect?: boolean;
	hideSelectedOptions?: boolean;
	setTouchedOnBlur?: boolean;
	onBlur?: () => void;
	useSubmitOnChange?: boolean;
	disabled?: boolean;
}

export const FormSelectCreatable: React.FC<FormSelectProps & FieldProps> = ({
	isMulti,
	placeholder,
	title,
	fullWidth,
	mandatory,
	options,
	isOptionDisabled,
	field,
	form,
	onCreateOption,
	onChange,
	isLoading,
	showError,
	showCheckboxOption,
	closeMenuOnSelect = true,
	hideSelectedOptions = true,
	setTouchedOnBlur = false,
	onBlur,
	useSubmitOnChange = false,
	disabled = false,
}) => {
	const handleChange = async (option: MultiValue<Option> | SingleValue<Option>) => {
		if (onChange) {
			await onChange(field.name, option as Option);
		} else {
			await form.setFieldValue(field.name, option);
		}
		if (!isMulti && useSubmitOnChange) {
			await form.handleSubmit();
		}
	};

	const getValue = () => {
		if (form.values[field.name]?.value) return form.values[field.name];
		if (options) {
			return Array.isArray(field.value)
				? options.filter(option => field.value.some((item: Option) => item.value === option.value))
				: options.find(option => option.value === field.value?.value);
		} else {
			return isMulti ? [] : ('' as any);
		}
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (onBlur) {
			await onBlur();
		}
		if (isMulti && useSubmitOnChange) {
			await form.handleSubmit();
		}
	};

	const CheckboxOption = (props: any) => {
		return (
			<StyledCheckboxOption>
				<components.Option {...props}>
					<div className="container">
						{(showCheckboxOption || isMulti) && (
							<input type="checkbox" checked={props.isSelected} onChange={() => null} />
						)}
						<label>{props.label}</label>
						{props.data.roles && props.data.roles}
					</div>
				</components.Option>
			</StyledCheckboxOption>
		);
	};

	return (
		<StyledFormSelect
			$error={!!(form.touched[field.name] && form.errors[field.name])}
			$fullWidth={fullWidth}>
			<label title={title}>
				{title}
				{mandatory && <MarkedText>*</MarkedText>}
			</label>
			<CreatableSelect
				isOptionDisabled={isOptionDisabled}
				classNamePrefix="Select"
				formatCreateLabel={(userInput: string) => (
					<StyledCreateLabel>
						{userInput}
						<span className="new-value-label">(New value)</span>
					</StyledCreateLabel>
				)}
				isMulti={isMulti}
				placeholder={generatePlaceholder(title, placeholder)}
				options={options}
				components={{
					Option: CheckboxOption,
				}}
				onBlur={handleBlur}
				onChange={handleChange}
				isClearable={!isMulti}
				escapeClearsValue={!isMulti}
				value={getValue()}
				onCreateOption={onCreateOption}
				isLoading={isLoading || options.length === 0}
				hideSelectedOptions={isMulti ? false : hideSelectedOptions ? hideSelectedOptions : false}
				closeMenuOnSelect={isMulti ? false : closeMenuOnSelect ? closeMenuOnSelect : false}
				isDisabled={disabled}
			/>
			{showError ? (
				<ErrorMessage
					name={field.name}
					children={errorMessage => {
						if (typeof errorMessage === 'string')
							return <StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>;
						if (typeof errorMessage === 'object' && !isMulti) {
							return (
								<StyledFieldErrorMessage className="error-message">
									{Object.values(errorMessage)[0] as string}
								</StyledFieldErrorMessage>
							);
						}
					}}
				/>
			) : null}
		</StyledFormSelect>
	);
};
