import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { userHasRoles } from '@asd-stan/helpers/app-utils';
import { useRequest } from '@asd-stan/helpers/use-request';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { BreadCrumbs } from '@asd-stan/ui-kit/components/bread-crumbs/bread-crumbs';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { SingleUser, SingleUserDomain, UserAccess } from '@asd-stan/user/api/single-user.mapper';
import { CancelInvite } from '@asd-stan/user/components/invite-users/modals/cancel-invite';
import { DomainInputGroup } from '@asd-stan/user/components/layout/input-group/domain-input-group';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { getAppointerService, getUserService } from '@asd-stan/user/infrastructure/getters';
import { UpdateUserValidationSchema } from '@asd-stan/user/validation-schemas/update-user.validation.schema';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import cn from 'classnames';
import { Form, Formik, FormikValues } from 'formik';

import { StyledEditUser, UpdateUserLoader } from './edit-users.styled';
import { Company } from './forms/company';
import { Domain } from './forms/domain';
import { General } from './forms/general';
import { Resend } from './resend';

import { StyledButtonGroup } from '@asd-stan/ui-kit/components/utility/button-group.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';

interface UpdateUserFormValues {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	appointedBy: null | {
		label: string;
		value: number;
	};
	systemRoles: {
		label: string;
		value: string;
	}[];
	company: null | {
		label: string;
		value: number;
	};
	country: null | {
		label: string;
		value: number;
	};
	positions: {
		label: string;
		value: number;
	}[];
	domains: SingleUserDomain[];
}

const rolesNotToBeEditedByDirector = [SystemRole.DIRECTOR];
const rolesNotToBeEditedByES = [SystemRole.DIRECTOR, SystemRole.ES];

export const EditUser: React.FC<{ me?: boolean }> = observer(({ me = false }) => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const modalService = getModalService();
	const toasterService = getToasterService();
	const appointerService = getAppointerService();
	const currentUserService = getCurrentUserService();
	const userService = getUserService();
	const id = me ? currentUserService.currentUser!.id : +params.id!;
	const isMe = me || id === currentUserService.currentUser!.id;
	const { data: user, isLoading } = useRequest(
		useCallback(() => (me ? currentUserService.getUser(id) : userService.getUser(id)), [me])
	);

	const userRoles = user ? user.fields.systemRoles.map(({ value }) => value) : [];
	const ableToEditIfNotMe =
		(currentUserService.hasRole(SystemRole.DIRECTOR) &&
			!userHasRoles(rolesNotToBeEditedByDirector, userRoles)) ||
		(currentUserService.hasRole(SystemRole.ES) && !userHasRoles(rolesNotToBeEditedByES, userRoles));

	useEffect(() => {
		(async () => {
			appointerService.getAppointers();
		})();
	}, []);

	const onSubmit = async (values: UpdateUserFormValues) => {
		try {
			if (isMe) {
				await currentUserService.updateMe(values as SingleUser);
			} else {
				await userService.updateUser(id, values as SingleUser);
			}
			toasterService.showToast(ToasterStatus.success, t('user.updateUser.success'));
			navigate(-1);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCancel = (values: FormikValues) => {
		modalService.openModal(<CancelInvite userName={`${values.firstName} ${values.lastName}`} />);
	};

	const initialValues: UpdateUserFormValues = user?.fields || {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		appointedBy: null,
		systemRoles: [],
		company: null,
		country: null,
		positions: [],
		domains: [],
	};

	if (isLoading || !user) {
		return (
			<ContentContainer>
				<UpdateUserLoader>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</UpdateUserLoader>
			</ContentContainer>
		);
	}

	const canSubmit =
		isMe ||
		currentUserService.hasRole(SystemRole.DIRECTOR) ||
		(currentUserService.hasRole(SystemRole.ES) &&
			!userHasRoles(
				[SystemRole.DIRECTOR, SystemRole.ES],
				user.fields.systemRoles.map(({ value }) => value)
			));

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={UpdateUserValidationSchema}
			validateOnChange
			validateOnBlur={false}>
			{({ values, handleSubmit, validateForm, isSubmitting }) => (
				<StyledEditUser>
					<ContentContainer>
						<BreadCrumbs />
						<Flex $justify="space-between" $align="center">
							<PageTitle title={user ? `${user.fields.firstName} ${user.fields.lastName}` : ''} />
							<StyledButtonGroup>
								<Button
									secondary
									disabled={isSubmitting}
									title={t('user.userInvite.buttons.cancel')}
									onClick={() => handleCancel(values)}
								/>
								<Button
									title={t('user.userInvite.buttons.save')}
									disabled={isSubmitting || !canSubmit}
									onClick={() => {
										validateForm().then(errors => {
											if (errors && !(Object.keys(errors).length === 0)) {
												toasterService.showToast(
													ToasterStatus.error,
													t('user.userInvite.toaster.incorrectFields')
												);
											}
										});

										handleSubmit();
									}}
								/>
							</StyledButtonGroup>
						</Flex>
						<div className="access">
							<div className={cn('indicator', { active: user?.access === UserAccess.Available })} />
							{user?.access === UserAccess.InvitationPending && <Resend id={id} />}
							{user?.access === UserAccess.Available && t('user.updateUser.access.active')}
						</div>
						<Form>
							<InputGroup
								title={t('user.userInvite.generalTitle')}
								children={<General ableToEditIfNotMe={ableToEditIfNotMe} isMe={isMe} />}
							/>
							<InputGroup
								title={t('user.userInvite.companyTitle')}
								children={<Company ableToEditIfNotMe={ableToEditIfNotMe} isMe={isMe} />}
							/>
							<DomainInputGroup initiallyOpened>
								<Domain
									key={JSON.stringify(values.systemRoles)}
									ableToEditIfNotMe={ableToEditIfNotMe}
									isMe={isMe}
								/>
							</DomainInputGroup>
						</Form>
					</ContentContainer>
				</StyledEditUser>
			)}
		</Formik>
	);
});
