import styled from 'styled-components';

export const StyledBallotForm = styled.div`
	margin-top: 24px;

	.question {
		width: 100%;
		margin-top: 16px;
		border-top: 1px solid #dae4e8;
		padding-top: 16px;

		&:first-child {
			margin-top: 0;
			border-top: none;
			padding-top: 0;
		}
	}

	.questionWrapper {
		flex-grow: 1;
	}

	.deleteQuestion {
		background: none;
		margin: 20px -5px 0 16px;

		&:disabled {
			cursor: default;
			opacity: 0.6;
		}

		svg {
			display: block;
		}
	}
`;
