import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploadController } from '@asd-stan/file/domain/file-upload-controller';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { FileDropzone } from '@components/file-dropzone/file-dropzone';
import { Field } from 'formik';

const fileTypes = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg': ['.svg'],
	'application/xml': ['.xml'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'text/csv': ['.csv'],
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'image/tiff': ['.tiff'],
	'image/vnd.dwg': ['.dwg'],
	'image/bmp': ['.bmp'],
	'application/zip': ['.zip'],
};

const maxSizeBytes = 20 * 1024 * 1024;

export const BallotAttachments = () => {
	const { t } = useTranslation();
	const fileUploadContrallerRef = useRef<null | FileUploadController>(null);

	if (fileUploadContrallerRef.current === null) {
		fileUploadContrallerRef.current = new FileUploadController();
	}

	return (
		<InputGroup title={t('ballot.createBallot.attachments')}>
			<Field
				name="attachments"
				component={FileDropzone}
				fileTypes={fileTypes}
				maxSize={maxSizeBytes}
				controller={fileUploadContrallerRef.current}
				maxFiles={0}
				isFilePrivate
			/>
		</InputGroup>
	);
};
