import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SingleBallot } from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import { Field } from '../../../field';
import { FileField } from '../../../file-field';
import { transformDate } from '../../../transformDate';

export const General = ({ ballot }: { ballot: SingleBallot }) => {
	const { t } = useTranslation();
	const ballotService = getBallotService();

	const standard = ballot.standard;

	return (
		<InputGroup title="General">
			<Field
				label={t('ballot.singleBallot.description')}
				value={ballot.description}
				fullWidth
				showEmptyValuePlaceholder
			/>
			<Field
				label={t('ballot.singleBallot.linkToStandard')}
				value={
					standard && (
						<Link to={`/standards/detailed/${standard.id}/general`} target="_blank">
							{standard.registrationNumber} {standard.form} {standard.edition} {standard.title}
						</Link>
					)
				}
				fullWidth
				showEmptyValuePlaceholder
			/>
			<Field
				label={t('ballot.singleBallot.openingDate')}
				value={transformDate(ballot.openingDate)}
			/>
			<Field
				label={t('ballot.singleBallot.closingDate')}
				value={transformDate(ballot.closingDate)}
			/>
			{ballot.attachments.map(attachment => (
				<FileField
					key={attachment.value.id}
					attachment={attachment}
					onDownloadClick={ballotService.downloadBallotAttachmentFile.bind(ballotService)}
				/>
			))}
		</InputGroup>
	);
};
