import React from 'react';
import Select, { MultiValue, Options, SingleValue, components } from 'react-select';

import { generatePlaceholder, getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { Tooltip } from '@asd-stan/ui-kit/components/tooltip/tooltip';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { Avatar } from '@components/avatar/avatar';
import { ErrorMessage, FieldProps } from 'formik';

import { MarkedText } from '../utility/marked-text';

import { StyledCheckboxOption, StyledFormSelect } from './form-select.styled';

import { StyledFieldErrorMessage } from '@asd-stan/ui-kit/components/utility/field-error-message.styled';

export interface Option {
	value: string;
	label: string | string[];
}

interface FormSelectProps {
	title: string;
	isMulti?: boolean;
	placeholder?: string;
	fullWidth?: boolean;
	smallerWidth?: boolean;
	mandatory?: boolean;
	options: Option[];
	name: string;
	isOptionDisabled?: ((option: Option, selectValue: Options<Option>) => boolean) | undefined;
	onChange?: (name: string, option: Option | Option[]) => void;
	customChange?: (option: Option | Option[], props?: any) => void;
	tooltip?: string;
	disabled?: boolean;
	isLoading?: boolean;
	showError?: boolean;
	showCheckboxOption?: boolean;
	showAvatar?: boolean;
	closeMenuOnSelect?: boolean;
	hideSelectedOptions?: boolean;
	setTouchedOnBlur?: boolean;
	onBlur?: () => void;
	useSubmitOnChange?: boolean;
	notClearable?: boolean;
	value?: Option | Option[];
}

export const FormSelect: React.FC<FormSelectProps & FieldProps> = ({
	isMulti,
	placeholder,
	title,
	fullWidth,
	smallerWidth,
	mandatory,
	options,
	isOptionDisabled,
	tooltip,
	field,
	form,
	onChange,
	customChange,
	disabled,
	isLoading,
	showError,
	showCheckboxOption,
	showAvatar,
	closeMenuOnSelect = true,
	hideSelectedOptions = true,
	setTouchedOnBlur = false,
	onBlur,
	useSubmitOnChange = false,
	notClearable,
	value,
}) => {
	const handleChange = async (option: MultiValue<Option> | SingleValue<Option>) => {
		if (onChange) {
			await onChange(field.name, option as Option);
		} else {
			await form.setFieldValue(field.name, option);
		}
		if (customChange) {
			await customChange(option as Option);
		}
		if (!isMulti && useSubmitOnChange) {
			await form.handleSubmit();
		}
	};

	const getValue = () => {
		if (value) return value;
		if (form.values[field.name]?.value) return form.values[field.name];
		if (options) {
			if (Array.isArray(field.value)) {
				return options.filter(option =>
					field.value.some((item: Option) => item.value === option.value)
				);
			}
			const value = options.find(option => option.value === field.value?.value);
			if (!value) {
				return ''; //TODO: Check this set value (maybe need set {label:'', value:''})
			}
			return value;
		} else {
			return isMulti ? [] : ('' as any);
		}
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (onBlur) {
			await onBlur();
		}
		if (isMulti && useSubmitOnChange) {
			await form.handleSubmit();
		}
	};

	const CheckboxOption = (props: any) => {
		return (
			<StyledCheckboxOption>
				<components.Option {...props}>
					{props.data.customOption ? (
						<div className="customContainer">
							<label>{props.data.customLabel ? props.data.customLabel : props.label}</label>
							{props.data.customOption}
						</div>
					) : (
						<div className="container">
							{(showCheckboxOption ||
								isMulti ||
								(form.values[field.name] && form.values[field.name].value === 'None')) && (
								<input type="checkbox" checked={props.isSelected} onChange={() => null} />
							)}
							{showAvatar && <Avatar />}
							<label>{props.data.customLabel ? props.data.customLabel : props.label}</label>
							{props.data.roles && props.data.roles}
						</div>
					)}
				</components.Option>
			</StyledCheckboxOption>
		);
	};

	return (
		<StyledFormSelect
			$error={
				!!(
					getValueByFieldName(form.touched, field.name) &&
					getValueByFieldName(form.errors, field.name)
				)
			}
			$fullWidth={fullWidth}
			$smallerWidth={smallerWidth}>
			<Flex style={{ minHeight: '16px' }}>
				<label title={title} className={disabled ? 'disabled' : ''}>
					{title}
					{mandatory && <MarkedText>*</MarkedText>}
				</label>
				{tooltip ? <Tooltip width="250" text={tooltip} /> : null}
			</Flex>
			<Select
				isDisabled={disabled}
				isOptionDisabled={isOptionDisabled}
				classNamePrefix="Select"
				isMulti={isMulti}
				placeholder={generatePlaceholder(title, placeholder, 'select')}
				options={options}
				components={{
					Option: CheckboxOption,
				}}
				isClearable={!isMulti && !notClearable}
				onBlur={handleBlur}
				escapeClearsValue={!isMulti && !notClearable}
				onChange={handleChange}
				value={getValue()}
				hideSelectedOptions={isMulti ? false : hideSelectedOptions && hideSelectedOptions}
				closeMenuOnSelect={
					form.values[field.name] && form.values[field.name].value === 'None'
						? false
						: isMulti
						  ? false
						  : closeMenuOnSelect && closeMenuOnSelect
				}
				isLoading={isLoading}
			/>
			{showError ? (
				<ErrorMessage
					name={field.name}
					children={(errorMessage: any) => {
						if (typeof errorMessage === 'string')
							return <StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>;
						if (typeof errorMessage === 'object') {
							if (!isMulti) {
								return (
									<StyledFieldErrorMessage className="error-message">
										{Object.values(errorMessage)[0] as string}
									</StyledFieldErrorMessage>
								);
							} else {
								return (
									<StyledFieldErrorMessage className="error-message">
										{errorMessage[0].value}
									</StyledFieldErrorMessage>
								);
							}
						}
					}}
				/>
			) : null}
		</StyledFormSelect>
	);
};
