import styled from 'styled-components';

export const StyledModalButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > div {
		margin-top: 24px;
		margin-left: 0;

		button {
			width: 96px;
			height: 42px;
		}
	}
`;
