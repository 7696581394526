import styled from 'styled-components';

export const StyledToggle = styled.div<{ $topMargin?: boolean; $disabled?: boolean }>`
	margin-top: ${props => props.$topMargin && '8px'};

	& > label {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		width: fit-content;
		display: flex;
		align-items: center;
		cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
		-webkit-tap-highlight-color: transparent;
		opacity: ${props => (props.$disabled ? 0.6 : 1)};

		& > i {
			position: relative;
			display: inline-block;
			margin-right: 0.5rem;
			width: 29px;
			height: 17px;
			background-color: ${({ theme }) => theme.bgColors.lightGrey};
			border-radius: 12px;
			vertical-align: text-bottom;
			transition: all 0.3s linear;
		}

		& > i::before {
			content: '';
			position: absolute;
			left: 0;
			width: 27px;
			height: 14px;
			background-color: ${({ theme }) => theme.bgColors.lightGrey};
			border-radius: 12px;
			transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
			transition: all 0.25s linear;
		}

		& > i::after {
			content: '';
			position: absolute;
			left: 0;
			width: 13px;
			height: 13px;
			background-color: #fff;
			border-radius: 9px;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
			transform: translate3d(2px, 2px, 0);
			transition: all 0.2s ease-in-out;
		}

		&:active > i::after {
			width: 13px;
			transform: translate3d(2px, 2px, 0);
		}

		&:active > input:checked + i::after {
			transform: translate3d(14px, 2px, 0);
		}

		& > input {
			display: none;
		}

		& > input:checked + i {
			background-color: ${({ theme }) => theme.bgColors.accent};
		}

		& > input:checked + i::before {
			transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
		}

		& > input:checked + i::after {
			transform: translate3d(14px, 2px, 0);
		}
	}
`;
