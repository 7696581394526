import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
import { Participation } from '@asd-stan/current-user/domain/current-user.service';
import { Expert, Originator } from '@asd-stan/standard/domain/standard-detailed.entity';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';

const hasRoleToSendRemark = (currentUser: CurrentUser): boolean => {
	return (
		currentUser?.systemRoles.includes(SystemRole.DIRECTOR) ||
		currentUser?.systemRoles.includes(SystemRole.ES)
	);
};

const isInExpertList = (currentUser: CurrentUser, experts: Expert[] | undefined): boolean => {
	if (!experts || !experts.length) {
		return false;
	}

	return !!experts.find(expert => expert.id === currentUser.id);
};

const isBoardMemberHasDomainRole = (
	currentUser: CurrentUser,
	domainParticipations: Participation[]
): boolean => {
	return (
		currentUser.systemRoles.includes(SystemRole.BOARD_MEMBER) && domainParticipations.length > 0
	);
};

const isMCAndHasDomainRole = (
	currentUser: CurrentUser,
	domainParticipations: Participation[]
): boolean => {
	return currentUser.systemRoles.includes(SystemRole.MC) && domainParticipations.length > 0;
};

const isOriginator = (currentUser: CurrentUser, originator?: Originator | null): boolean => {
	if (!originator) {
		return false;
	}

	if (originator.userId) {
		return currentUser.id === Number(originator.userId);
	}

	return currentUser.id === originator.id;
};

export const canSendRemarks = (
	currentUser: CurrentUser,
	experts: Expert[] | undefined,
	originator?: Originator | null,
	domainParticipations?: Participation[]
): boolean => {
	if (!currentUser) return false;
	if (!domainParticipations) return false;

	return (
		hasRoleToSendRemark(currentUser) ||
		isInExpertList(currentUser, experts) ||
		isBoardMemberHasDomainRole(currentUser, domainParticipations) ||
		isMCAndHasDomainRole(currentUser, domainParticipations) ||
		isOriginator(currentUser, originator)
	);
};
