import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@components/avatar/avatar';
import { Button } from '@components/button/button';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { Field } from 'formik';

import { StyledRemarkTextarea } from './remark-textarea.styled';

interface RemarkTextareaProps {
	isNeedClearValue: boolean;
	placeholder?: string;
	sendEvent?: () => void;
}

export const RemarkTextarea: React.FC<RemarkTextareaProps> = ({
	isNeedClearValue,
	placeholder,
	sendEvent,
}) => {
	const { t } = useTranslation();

	return (
		<StyledRemarkTextarea>
			<Avatar width="42px" height="42px" />
			<Field
				component={FormInputParagraph}
				name="remark"
				placeholder={placeholder || t('standard.createNWP.remarks.remarkPlaceholder')}
				maxLength={1000}
				isNeedClearValue={isNeedClearValue}
			/>
			<Button title={t('standard.createNWP.buttons.send')} onClick={sendEvent} />
		</StyledRemarkTextarea>
	);
};
