import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { useRequest } from '@asd-stan/helpers/use-request';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import { ReactComponent as AbstainIcon } from '../../../assets/abstain.svg';
import { ReactComponent as ApproveIcon } from '../../../assets/approved.svg';
import { ReactComponent as DisapproveIcon } from '../../../assets/disapproved.svg';
import { ReactComponent as UserIcon } from '../../../assets/userIcon.svg';
import { StyledStats, StyledStatsBallotResult } from '../../../single-ballot.styled';

export const Stats = ({ ballotId }: { ballotId: number }) => {
	const { t } = useTranslation();
	const ballotService = getBallotService();
	const { data: ballotStats, isLoading: ballotStatsLoading } = useRequest(
		useCallback(() => ballotService.getBallotStats(ballotId), [ballotId])
	);

	if (ballotStatsLoading) {
		return null;
	}

	if (!ballotStats) {
		return null;
	}

	return (
		<>
			{ballotStats.map(
				({
					questionId,
					questionTitle,
					result,
					approve,
					abstain,
					disapprove,
					allowedToVote,
					totalVoted,
				}) => (
					<InputGroup key={questionId} title={questionTitle}>
						<StyledStatsBallotResult $result={result}>
							{t('ballot.singleBallot.result')} <span>{result}</span>
						</StyledStatsBallotResult>
						<StyledStats>
							<div className="item">
								<div className="icon">
									<UserIcon />
								</div>
								<div>
									<div className="label">{t('ballot.singleBallot.totalVoted')}</div>
									<div className="value">
										{totalVoted}/{allowedToVote}
									</div>
								</div>
							</div>
							<div className="item">
								<div className="icon approve">
									<ApproveIcon />
								</div>
								<div>
									<div className="label">{t('ballot.singleBallot.votingDecision.approve')}</div>
									<div className="value">{approve}</div>
								</div>
							</div>
							<div className="item">
								<div className="icon disapprove">
									<DisapproveIcon />
								</div>
								<div>
									<div className="label">{t('ballot.singleBallot.votingDecision.disapprove')}</div>
									<div className="value">{disapprove}</div>
								</div>
							</div>
							<div className="item">
								<div className="icon abstain">
									<AbstainIcon />
								</div>
								<div>
									<div className="label">{t('ballot.singleBallot.votingDecision.abstention')}</div>
									<div className="value">{abstain}</div>
								</div>
							</div>
						</StyledStats>
					</InputGroup>
				)
			)}
		</>
	);
};
