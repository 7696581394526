import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	BallotFilteringForm,
	ballotFilterEmptyValues,
	ballotResultChipMap,
	ballotStatusChipMap,
	transformDate,
} from '@asd-stan/ballot/components/ballot-list/ballot-list';
import { BallotListItem, BallotResult, BallotStatus } from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {
	isOriginatorInStandard,
	userHasRolesAndParticipatesInDomain,
} from '@asd-stan/helpers/app-utils';
import { useRequest } from '@asd-stan/helpers/use-request';
import { useRequestWithPagination } from '@asd-stan/helpers/use-request-with-pagination';
import { useSearchInputChange } from '@asd-stan/helpers/use-search-input-change';
import { mapStandardStatusDTOToStandardStatus } from '@asd-stan/standard/api/standard-status.mapper';
import { StandardFilteringForm } from '@asd-stan/standard/components/standard-list/standard-list';
import { StandardListTable } from '@asd-stan/standard/components/standard-list/standard-list-table';
import { Form } from '@asd-stan/standard/domain/enums';
import { Standard } from '@asd-stan/standard/domain/standard.entity';
import { StandardCollectionFilter } from '@asd-stan/standard/domain/standard.service';
import { getStagesService, getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { ReactComponent as EditIcon } from '@assets/icons/edit-pencil.svg';
import { ReactComponent as Search } from '@assets/icons/search.svg';
import { ReactComponent as TrashBitIcon } from '@assets/icons/trash-bin.svg';
import { Button } from '@components/button/button';
import { ActionsCell } from '@components/content-table/cells/actions-cell';
import { SecondaryCell } from '@components/content-table/cells/secondary-cell';
import { ContentTable } from '@components/content-table/content-table';
import { TableFilter } from '@components/content-table/table-control/table-filter/table-filter';
import { Input } from '@components/input/input';
import { PageTitle } from '@components/page-title/page-title';

import { Chip } from '@asd-stan/ballot/components/ballot-list/ballot-list.styled';
import {
	StyledDashboard,
	StyledDashboardTableControls,
} from '@asd-stan/dashboard/components/dashboard.styled';
import { StyledAccentButton } from '@components/button/button.styled';
import { MarkedTextCell } from '@components/content-table/cells/marked-text-cell.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

export const Dashboard: React.FC = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const standardService = getStandardService();
	const ballotService = getBallotService();
	const stagesService = getStagesService();
	const currentUserService = getCurrentUserService();
	const location = useLocation();
	const standardList = useRequestWithPagination(
		20,
		standardService.getDashboardStandards.bind(standardService)
	);
	const { data: stages, isLoading: stagesLoading } = useRequest(
		useCallback(() => stagesService.getStages({ showHistoricalStages: true }), [])
	);
	const ballotList = useRequestWithPagination(
		20,
		ballotService.getDashboardBallotList.bind(ballotService)
	);
	const [standardSearch, setStandardSearch] = useSearchInputChange(standardList);
	const [ballotSearch, setBallotSearch] = useSearchInputChange(ballotList);

	const getLastColumn = () => {
		const assignButtons = (
			<>
				<Button
					disabled={
						!userHasRolesAndParticipatesInDomain(
							[SystemRole.DIRECTOR, SystemRole.ES],
							currentUserService.userRoles!,
							[SystemRole.MC, SystemRole.BOARD_MEMBER],
							currentUserService.domainParticipations
						)
					}
					title={t('standard.standardList.buttons.assign')}
					onClick={() => {}}
				/>
			</>
		);

		const draftButtons = (id: number, originator: number) => {
			return (
				<div className="action-buttons-container">
					<StyledAccentButton>
						<button
							disabled={
								!userHasRolesAndParticipatesInDomain(
									[SystemRole.DIRECTOR, SystemRole.ES],
									currentUserService.userRoles!,
									[SystemRole.MC, SystemRole.BOARD_MEMBER],
									currentUserService.domainParticipations
								) && !isOriginatorInStandard(originator, currentUserService.currentUser)
							}
							className="continue-edit-button"
							onClick={e => {
								e.stopPropagation();
								navigate(`/standards/edit/${id}`);
							}}>
							{t('standard.standardList.buttons.continueEdit')} {<EditIcon />}
						</button>
					</StyledAccentButton>
					<StyledAccentButton $iconButton>
						<button disabled className="delete-button">
							{<TrashBitIcon />}
						</button>
					</StyledAccentButton>
				</div>
			);
		};

		switch (location.pathname) {
			case '/standards/draft':
				return {
					header: t('standard.standardList.action'),
					accessorKey: 'status',
					enableSorting: false,
					cell: (row: { standardId: number; originator: number }) => {
						return <ActionsCell buttons={[draftButtons(row.standardId, row.originator)]} />;
					},
				};
			case '/standards/assign':
				return {
					header: t('standard.standardList.action'),
					accessorKey: 'status',
					enableSorting: false,
					cell: <ActionsCell buttons={[assignButtons]} />,
				};
			default:
				return {
					header: t('standard.standardList.status'),
					accessorKey: 'status',
					cell: ({ row }: CellContext<Standard, string>) => {
						const value = mapStandardStatusDTOToStandardStatus(row.getValue('status'));
						if (value) {
							return (
								<MarkedTextCell>
									<div>{value}</div>
								</MarkedTextCell>
							);
						}
					},
				};
		}
	};

	const standardColumns = [
		{
			header: t('standard.standardList.form'),
			accessorKey: 'form',
			className: 'form',
		},
		{
			header: t('standard.standardList.stanNumber'),
			accessorKey: 'registrationNumber',
			cell: ({ row }: CellContext<Standard, string>) => (
				<SecondaryCell value={row.getValue('registrationNumber')} />
			),
		},
		{
			header: t('standard.standardList.standardTitle'),
			accessorKey: 'localizedTitle',
			className: 'title',
		},
		{
			header: t('standard.standardList.edition'),
			accessorKey: 'edition',
		},
		{
			header: t('standard.standardList.cenWiNumber'),
			accessorKey: 'cenWiNumber',
			cell: ({ row }: CellContext<Standard, string>) => (
				<SecondaryCell value={row.getValue('cenWiNumber')} />
			),
		},
		getLastColumn(),
	];

	const ballotColumns: ColumnDef<BallotListItem, string>[] = [
		{
			header: t('ballot.ballotList.openingDate'),
			accessorKey: 'openingDate',
			cell: ({ row }: CellContext<BallotListItem, string>) => (
				<div className="date">{transformDate(row.getValue('openingDate'))}</div>
			),
		},
		{
			header: t('ballot.ballotList.closingDate'),
			accessorKey: 'closingDate',
			cell: ({ row }: CellContext<BallotListItem, string>) => (
				<div className="date">{transformDate(row.getValue('closingDate'))}</div>
			),
		},
		{
			header: t('ballot.ballotList.titleColumn'),
			accessorKey: 'title',
			cell: ({ row }: CellContext<BallotListItem, string>) => (
				<div className="titleCell">{row.getValue('title')}</div>
			),
		},
		{
			header: t('ballot.ballotList.status'),
			accessorKey: 'status',
			cell: ({ row }: CellContext<BallotListItem, string>) => {
				const value: BallotStatus = row.getValue('status');
				const { chipType, labelKey } = ballotStatusChipMap[value];
				return <Chip $type={chipType}>{t(`ballot.ballotStatus.${labelKey}`)}</Chip>;
			},
		},
		{
			header: t('ballot.ballotList.result'),
			accessorKey: 'result',
			cell: ({ row }: CellContext<BallotListItem, string>) => {
				const value: BallotResult = row.getValue('result');
				const { chipType, labelKey } = ballotResultChipMap[value];
				return <Chip $type={chipType}>{t(`ballot.ballotResult.${labelKey}`)}</Chip>;
			},
		},
	];

	const standardFilterEmptyValues: StandardCollectionFilter = useMemo(
		() => ({
			forms: {
				[Form.EN]: false,
				[Form.prEN]: false,
				[Form.STAN]: false,
				[Form.TR]: false,
			},
			stages:
				stages?.reduce((acc, stage) => {
					return { ...acc, [stage.code]: false };
				}, {}) ?? {},
		}),
		[stages]
	);

	return (
		<StyledDashboard>
			<ContentContainer>
				<PageTitle title={t('dashboard.title')} />
				<StyledDashboardTableControls>
					<span>
						<h6>My Standards</h6>
						<span className="amount">{standardList.totalNumber} items</span>
					</span>
					<Input
						className="search"
						value={standardSearch}
						onChange={e => {
							setStandardSearch(e.target.value);
						}}
						icon={<Search />}
						placeholder={t('user.userList.searchPlaceholder')}
					/>
					<TableFilter
						disabled={stagesLoading}
						emptyValues={standardFilterEmptyValues}
						values={standardList.filter}
						onSubmit={standardList.addFilter}
						leftPopupPosition>
						<StandardFilteringForm stageList={stages ?? []} />
					</TableFilter>
				</StyledDashboardTableControls>
				<StandardListTable
					tableData={[...standardList.data]}
					columns={standardColumns}
					filtering=""
					setFiltering={() => {}}
					paginator={standardList}
					emptySearchText={t('standard.standardList.emptySearchText')}
					dashboard
				/>
				<StyledDashboardTableControls>
					<span>
						<h6>My Ballots</h6>
						<span className="amount">{ballotList.totalNumber} items</span>
					</span>
					<Input
						className="search"
						value={ballotSearch}
						onChange={e => setBallotSearch(e.target.value)}
						icon={<Search />}
						placeholder={t('user.userList.searchPlaceholder')}
					/>
					<TableFilter
						values={ballotList.filter}
						emptyValues={ballotFilterEmptyValues}
						onSubmit={ballotList.addFilter}
						leftPopupPosition>
						<BallotFilteringForm />
					</TableFilter>
				</StyledDashboardTableControls>
				<ContentTable
					tableData={[...ballotList.data]}
					columns={ballotColumns}
					filtering=""
					setFiltering={() => {}}
					paginator={ballotList}
					ableToSortFirstColumn
					onRowClick={(row: Row<any>) => navigate(`/ballots/${row.original.id}/general`)}
					emptySearchText={t('ballot.ballotList.emptySearchText')}
					dashboard
				/>
			</ContentContainer>
		</StyledDashboard>
	);
});
