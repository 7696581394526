import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 300px;

	& div {
		margin-left: 16px;
	}
`;