import styled from 'styled-components';

export const StyledUserPanel = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 312px;
	justify-content: flex-end;
	margin-left: 297px;
	height: 60px;
	background-color: ${({ theme }) => theme.fontColors.white};
	border-bottom: ${({ theme }) => theme.borders.divider};

	& > svg {
		cursor: pointer;
	}
`;
