import { useEffect, useState } from 'react';

import { useDebounce } from './customHooks';
import { PaginationCollectionController } from './pagination-collection-controller';

export const useSearchInputChange = (
	paginator: PaginationCollectionController<any, any>,
	minSearchLength: number = 3
) => {
	const [search, setSearch] = useState('');

	const handleSearch = useDebounce(
		() => paginator?.addFilter(search.length > 0 ? { search } : { search: null }),
		500
	);

	useEffect(() => {
		if (search.length === 0 || search.length >= minSearchLength) {
			handleSearch();
		}
	}, [search]);

	return [search, setSearch] as const;
};
