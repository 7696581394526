import styled from 'styled-components';

export const StyledPriceHistorySection = styled.div`
	margin-top: 16px;

	th {
		text-align: start;
		font-weight: 400;
		cursor: default;
	}

	th:last-child,
	td:last-child {
		width: 176px;
		text-align: center;
	}

	.edit {
		display: flex;
		justify-content: center;
	}

	.editIcon {
		padding: 8px;
		background: rgba(11, 147, 227, 0.12);
		border-radius: 4px;

		svg {
			display: block;
		}
	}

	.loading {
		width: 100%;
		height: 163px;
		> div {
			height: 100%;
			margin: 0 auto;
		}

		svg {
			width: 148px;
			height: 23px;
			margin-bottom: 32px;
		}

		svg > path {
			fill: #0b93e3;
		}

		img {
			width: 40px;
			height: 40px;
		}
	}

	.addPrice {
		width: 157px;
	}
`;

export const StyledModal = styled.div`
	width: 456px !important;
	align-items: stretch !important;

	h4 {
		text-align: center;
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 24px;
	}

	input {
		color: #525259;
	}

	.buttons {
		display: flex;
		gap: 24px;
		margin-top: 24px;

		& > div {
			flex: 1;

			button {
				width: 100% !important;
			}
		}
	}

	.hint {
		margin-top: 5px;
		font-size: 13px;
		font-weight: 500;
	}
`;
