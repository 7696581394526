import { DomainDTO } from '@asd-stan/domain/api/domain.dto';
import { Domain } from '@asd-stan/domain/domain/domain.entity';
import { WorkingGroupDTO } from '@asd-stan/working-group/api/working-group.dto';
import { WorkingGroup } from '@asd-stan/working-group/domain/working-group.entity';

export const makeDomainFromDomainDTO = (dto: DomainDTO): Domain => {
	const workingGroups: WorkingGroup[] = dto.workingGroups.map((wgDto: WorkingGroupDTO) => {
		return new WorkingGroup({
			name: wgDto.name,
			code: wgDto.code,
			domain: wgDto.domain,
		});
	});

	return new Domain({
		name: dto.name,
		code: dto.code,
		workingGroups: workingGroups,
	});
};
