import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import {
	isOriginatorInStandard,
	userHasRolesAndParticipatesInDomain,
} from '@asd-stan/helpers/app-utils';
import { PaginationCollectionControllerStatus } from '@asd-stan/helpers/pagination-collection-controller';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { ReactComponent as ExportIcon } from '@assets/asd-stan-works/icons/export-icon.svg';
import { ReactComponent as Plus } from '@assets/asd-stan-works/icons/plus-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-pencil.svg';
import { ReactComponent as TrashBitIcon } from '@assets/icons/trash-bin.svg';
import { Button } from '@components/button/button';
import { ButtonWithSettings } from '@components/button/button-with-settings';
import { ActionsCell } from '@components/content-table/cells/actions-cell';
import { SecondaryCell } from '@components/content-table/cells/secondary-cell';
import { TableCheckbox } from '@components/content-table/table-checkbox/table-checkbox';
import { TableControl } from '@components/content-table/table-control/table-control';
import { PageTitle } from '@components/page-title/page-title';
import { Flex } from '@components/utility/flex';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';

import { DraftListTable } from './draft-list-table';
import { StyledDraftList } from './draft-list.styled';

import { StyledAccentButton } from '@components/button/button.styled';
import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

Chart.register(ArcElement, Tooltip, Legend);

export const DraftList: React.FC = observer(() => {
	const [filtering, setFiltering] = useState<string>('');

	const navigate = useNavigate();
	const { t } = useTranslation();
	const draftService = getDraftService();
	const currentUserService = getCurrentUserService();

	const goToNewStandard = async () => {
		navigate('/standards/new-standard');
	};

	const getLastColumn = () => {
		const draftButtons = (id: number, originator: number) => {
			return (
				<div className="action-buttons-container">
					<StyledAccentButton>
						<button
							disabled={
								!userHasRolesAndParticipatesInDomain(
									[SystemRole.DIRECTOR, SystemRole.ES],
									currentUserService.userRoles!,
									[SystemRole.MC, SystemRole.BOARD_MEMBER],
									currentUserService.domainParticipations
								) && !isOriginatorInStandard(originator, currentUserService.currentUser)
							}
							className="continue-edit-button"
							onClick={e => {
								e.stopPropagation();
								navigate(`/standards/draft/edit/${id}`);
							}}>
							{t('standard.standardList.buttons.continueEdit')} {<EditIcon />}
						</button>
					</StyledAccentButton>
					<StyledAccentButton $iconButton>
						<button disabled className="delete-button">
							{<TrashBitIcon />}
						</button>
					</StyledAccentButton>
				</div>
			);
		};

		return {
			header: t('standard.standardList.action'),
			accessorKey: 'status',
			enableSorting: false,
			cell: (row: { standardId: number; originator: number }) => {
				return <ActionsCell buttons={[draftButtons(row.standardId, row.originator)]} />;
			},
		};
	};

	const columns = [
		{
			id: 'select',
			header: ({ table }: any) => (
				<TableCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }: any) => (
				<TableCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
				/>
			),
		},
		{
			header: t('standard.standardList.form'),
			accessorKey: 'form',
			className: 'form',
		},
		{
			header: t('standard.standardList.stanNumber'),
			accessorKey: 'registrationNumber',
			cell: ({ row }: any) => <SecondaryCell value={row.getValue('registrationNumber')} />,
		},
		{
			header: t('standard.standardList.standardTitle'),
			accessorKey: 'localizedTitle',
			className: 'title',
		},
		{
			header: t('standard.standardList.edition'),
			accessorKey: 'edition',
		},
		{
			header: t('standard.standardList.cenWiNumber'),
			accessorKey: 'cenWiNumber',
			cell: ({ row }: any) => <SecondaryCell value={row.getValue('cenWiNumber')} />,
		},
		getLastColumn(),
	];

	useEffect(() => {
		draftService.drafts.resetFilter();
	}, []);

	useEffect(() => {
		draftService.drafts.resetFilter();

		draftService.drafts.addFilter({
			domain: null,
			workingGroup: null,
			isAssigned: false,
		});

		draftService.drafts.loadCurrentPage();
	}, []);

	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		draftService.drafts.setPageSize(parseInt(e.target.value));
	};

	return (
		<StyledDraftList>
			<ContentContainer>
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={t('standard.standardList.titleDrafts')}
						count={
							draftService.drafts.status === PaginationCollectionControllerStatus.LOADING
								? undefined
								: draftService.drafts.totalNumber
						}
						countName={t('standard.standardList.count')}
					/>
					<StyledButtonGroup>
						<ButtonWithSettings
							secondary
							icon={<ExportIcon />}
							disabled
							title={t('standard.standardList.buttons.export')}
							onClick={() => {}}
						/>
						{userHasRolesAndParticipatesInDomain(
							[SystemRole.EXPERT, SystemRole.DIRECTOR, SystemRole.ES],
							currentUserService.userRoles!,
							[SystemRole.MC, SystemRole.BOARD_MEMBER],
							currentUserService.domainParticipations
						) ? (
							<Button
								icon={<Plus fill="#fff" />}
								title={t('standard.standardList.buttons.addNew')}
								onClick={goToNewStandard}
							/>
						) : null}
					</StyledButtonGroup>
				</Flex>
				<TableControl
					paginator={draftService.drafts}
					pageSize={draftService.drafts.pageSize}
					onPageSizeChange={onPageSizeChange}
				/>
				<div className={'table-container'}>
					<DraftListTable
						tableData={[...draftService.drafts.data]}
						columns={columns}
						filtering={filtering}
						setFiltering={setFiltering}
						paginator={draftService.drafts}
						emptySearchText={t('standard.standardList.emptyDraftSearchText')}
					/>
				</div>
			</ContentContainer>
		</StyledDraftList>
	);
});
