import React, {ReactNode} from 'react';

interface ActionsCellProps {
  buttons: Iterable<ReactNode>;
}

export const ActionsCell: React.FC<ActionsCellProps> = ({buttons}) => {
  return (
    <div>
      {buttons}
    </div>
  );
};
