import { makeUserPublicFromUser } from '@asd-stan/user/api/user.factory';
import { getUserRepo } from '@asd-stan/user/infrastructure/getters';
import { UserPublic } from '@asd-stan/user/public-contract/user.public-entity';
import { makeAutoObservable } from 'mobx';

export class UserPublicService {
	private _selectedUser: null | UserPublic = null;

	constructor() {
		makeAutoObservable(this);
	}

	// getters

	private get _userRepo() {
		return getUserRepo();
	}
	get selectedUser() {
		return this._selectedUser;
	}

	// getters end
	// methods

	async setCurrentUserId(id: number) {
		const user = await this._userRepo.getUserById(id);

		if (user) {
			this._selectedUser = makeUserPublicFromUser(user);
		} else {
			this._selectedUser = null;
		}
	}

	// methods end
}
