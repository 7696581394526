import { styled } from 'styled-components';
import { CSS } from 'styled-components/dist/types';

export const StyledLoaderContainer = styled.div`
	height: calc(100vh - 300px);
	> div {
		height: 100%;
		margin: 0 auto;
	}

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;

export const StandardDisplayContainer = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 24px;
`;

export const StandardDisplayDomainContainer = styled.div`
	margin-top: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 4px;
`;

export const StandardDisplayRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

export const StandardDisplayDomainRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin-bottom: 24px;
	&:last-child {
		margin-bottom: 0;
	}
`;

export const StandardDisplayField = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 4px;
	padding-right: 5px;
`;

export const StandardDisplayFieldHeader = styled.div`
	color: #abb3bb;
	font-size: 11px;
	text-transform: uppercase;
	flex: 1;
`;

export const StandardDisplayFieldValue = styled.div`
	word-wrap: break-word;
	color: #525259;
	font-size: 13px;
	font-weight: 600;
	margin-top: 4px;
	flex: 1;
	flex-wrap: wrap;
	line-height: 19px;
	&.grey-toned {
		color: #abb3bb;
		font-weight: 400;
	}
`;

export const StandardDisplayFieldValueAbsent = styled.div`
	color: #abb3bb;
	font-size: 13px;
	font-weight: 400;
	margin-top: 4px;
	flex: 1;
	flex-wrap: wrap;
`;

export const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	flex-wrap: wrap;
`;

export const DataRowItem = styled.div`
	color: #525259;
	font-size: 13px;
	font-weight: 600;
	margin-top: 4px;
`;

export const StandardReference = styled.div`
	font-weight: 600;
	line-height: 19px;
	& a,
	a:visited {
		color: #0b93e3;
		text-decoration: underline !important;
	}
	color: ${props => props.theme.fontColors.textGrey};
`;

export const GeneralLinkStyles: CSS.Properties = {
	lineHeight: 2,
	color: '#0B93E3',
	textDecoration: 'underline',
	whiteSpace: 'normal',
};
