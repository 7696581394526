import { scrollStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const StageContainer = styled.form`
	display: flex;
	position: relative;
	width: 448px !important;
`;

export const StageHeader = styled.h1`
	text-align: center;
`;

export const StageSelectorTitle = styled.div`
	display: block;
	width: 100%;
	color: #abb3bb;
	font-size: 16px;
	margin: 12px 0;
`;

export const StagePickerContainer = styled.div`
	border: solid 1px #dae4e8;
	border-radius: 8px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 300px;
	max-width: 460px;
	overflow-y: scroll;
	${scrollStyles};
	scrollbar-width: thin;
	scrollbar-color: #0b93e3 #dae4e8;
`;

export const StageItem = styled.div`
	cursor: pointer;
	border: solid 1px #dae4e8;
	border-radius: 4px;
	padding: 12px 16px;
	&:hover,
	&.active {
		border-color: #0b93e3;
		background-color: rgba(11, 147, 227, 0.08);
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 24px;
	gap: 16px;
`;

export const CloseBtn = styled.span`
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: transparent;
	cursor: pointer;
	color: #abb3bb;

	svg {
		display: block;
	}
`;
