import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { DraftDetailed } from '@asd-stan/draft/domain/draft-detailed.entity';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import {
	isOriginatorInStandard,
	isUserParticipatingInStandardDomain,
	userHasRoles,
	userLacksRole,
} from '@asd-stan/helpers/app-utils';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { DownloadFile } from '@asd-stan/standard/components/download-file/download-file';
import { AttachmentsMethods } from '@asd-stan/standard/components/nwp/forms/attachments';
import { AttachmentDelete } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachment-delete/attachment-delete';
import { AttachmentIcon } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachment-icon';
import { AttachmentsUpload } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachments-upload';
import { NoAttachments } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/no-attachments';
import { attachmentTypes } from '@asd-stan/standard/domain/enums';
import { AttachmentResponse } from '@asd-stan/standard/domain/standard-detailed.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { SingleStandardAttachmentScheme } from '@asd-stan/standard/validation-schemas/single-standard.schema';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { ReactComponent as TrashBitIcon } from '@assets/icons/trash-bin.svg';
import { Button } from '@components/button/button';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { DateTime } from 'luxon';

import {
	AttachmentDeleteButton,
	AttachmentsContainer,
	AttachmentsDetails,
	AttachmentsDisplayField,
	AttachmentsFieldHeader,
	AttachmentsFieldValue,
	AttachmentsListActions,
	AttachmentsListAuthor,
	AttachmentsListBlock,
	AttachmentsListHeader,
	AttachmentsListItem,
	AttachmentsLoader,
	AttachmentsMainWrapper,
} from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachments-section.styled';

const initialValues = {
	attachments: [],
};

interface Props {
	draft: DraftDetailed | null;
}

export const AttachmentsDraftSection: FC<Props> = observer(({ draft }) => {
	const { t } = useTranslation();
	const draftService = getDraftService();
	const standardService = getStandardService();
	const [isLoading, setIsLoading] = useState(false);
	const [filePaths, setFilePaths] = useState<string[]>([]);
	const modalService = getModalService();
	const toasterService = getToasterService();
	const currentUserService = getCurrentUserService();
	const attachmentsRef = useRef<AttachmentsMethods | null>(null);

	const clearUploadedFiles = () => {
		if (attachmentsRef.current) {
			attachmentsRef.current.clearUploadedFiles();
		}
	};

	const onSubmit = async (
		values: FormikValues,
		{ resetForm }: FormikHelpers<{ attachments: never[] }>
	) => {
		setIsLoading(true);
		try {
			await standardService.addAttachmentToDraft(values.attachments, draft);
			resetForm();
			clearUploadedFiles();
		} catch (error) {
			console.error(error);
		}
		setIsLoading(false);
	};

	const closeDeleteModal = () => {
		modalService.closeModal();
	};

	const onDeleteTap = (id: number) => {
		modalService.openModal(
			<AttachmentDelete onCancel={closeDeleteModal} onDelete={() => onAttachmentDelete(id)} />
		);
	};

	const onAttachmentDelete = async (id: number) => {
		try {
			closeDeleteModal();
			setIsLoading(true);
			await standardService.deleteAttachmentFromDraft(draft, id);
			setIsLoading(false);
			toasterService.showToast(
				ToasterStatus.success,
				<Trans i18nKey="standard.singleStandard.attachments.attachmentDeleted" />
			);
		} catch (err) {
			console.error(err);
		}
	};

	const getPrivateFile = async (id: number, index: number) => {
		return await standardService.getPrivateDraftLinkById(id, index);
	};

	const fetchFilePaths = async () => {
		if (draft && draft.attachments) {
			const paths = await Promise.all(
				draft.attachments.map(async (attachment: AttachmentResponse, index: number) => {
					const path = await getPrivateFile(draft.id, index);
					return path;
				})
			);
			setFilePaths(paths);
		}
	};

	const renderAttachments =
		draft &&
		draft.attachments &&
		draft.attachments!.map((attachment, index) => {
			const createdAt = DateTime.fromISO(attachment.createdAt).toFormat('dd LLL yyyy');

			return (
				<AttachmentsListItem key={attachment.file?.id}>
					<AttachmentsListHeader>
						<AttachmentsListAuthor>
							<AttachmentIcon attachment={attachment} path={filePaths[index]} />
							<AttachmentsDisplayField>
								<AttachmentsFieldHeader>{attachment.file?.name}</AttachmentsFieldHeader>
								<AttachmentsFieldValue>
									<Trans
										i18nKey="standard.singleStandard.attachments.sharedBy"
										values={{
											author: `${attachment.createdBy.firstName} ${attachment.createdBy.lastName}`,
											date: createdAt,
										}}
									/>
								</AttachmentsFieldValue>
							</AttachmentsDisplayField>
						</AttachmentsListAuthor>
						<AttachmentsListActions>
							<AttachmentsFieldValue>
								{userHasRoles(
									[SystemRole.ES, SystemRole.DIRECTOR],
									currentUserService.userRoles!
								) ||
								isOriginatorInStandard(draft, currentUserService.currentUser) ||
								isUserParticipatingInStandardDomain(
									[draft.generalData!.domains],
									currentUserService.domainParticipations
								) ? (
									<AttachmentDeleteButton onClick={() => onDeleteTap(attachment.file.id)}>
										<TrashBitIcon width={20} height={20} fill={'#ABB3BB'} />
									</AttachmentDeleteButton>
								) : null}
							</AttachmentsFieldValue>
							<AttachmentsFieldValue>
								{userLacksRole([SystemRole.EXPERT], currentUserService.userRoles!) ||
								isUserParticipatingInStandardDomain(
									[draft.generalData!.domains],
									currentUserService.domainParticipations
								) ? (
									<DownloadFile
										hideFileIcon
										hideTitle
										notFromAssets
										title={attachment.file.name}
										file={filePaths[index]}
									/>
								) : null}
							</AttachmentsFieldValue>
						</AttachmentsListActions>
					</AttachmentsListHeader>
					<AttachmentsDetails>
						<AttachmentsDisplayField>
							<AttachmentsFieldHeader>
								{t('standard.singleStandard.attachments.type')}
							</AttachmentsFieldHeader>
							<AttachmentsFieldValue $isAbsent={attachment.type === ''}>
								{attachment.type === ''
									? t('standard.singleStandard.attachments.typeAbsent')
									: attachmentTypes.find(type => type.value === attachment.type)?.label}
							</AttachmentsFieldValue>
						</AttachmentsDisplayField>
						<AttachmentsDisplayField>
							<AttachmentsFieldHeader>
								{t('standard.singleStandard.attachments.description')}
							</AttachmentsFieldHeader>
							<AttachmentsFieldValue $isAbsent={attachment.description === ''}>
								{attachment.description === ''
									? t('standard.singleStandard.attachments.descriptionAbsent')
									: attachment.description}
							</AttachmentsFieldValue>
						</AttachmentsDisplayField>
					</AttachmentsDetails>
				</AttachmentsListItem>
			);
		});

	if (!draft) {
		return null;
	}

	useEffect(() => {
		if (draft) {
			setIsLoading(true);
			draftService.getDraftById(draft.id).then(async () => {
				await fetchFilePaths().then(() => {
					setIsLoading(false);
				});
			});
		}
	}, [draft.id, draftService, standardService.attachmentIds, draft.attachments.length]);

	return (
		<InputGroup nonCollapsable title={t('standard.singleStandard.attachments.title')}>
			<AttachmentsMainWrapper>
				<Formik
					validationSchema={SingleStandardAttachmentScheme}
					initialValues={initialValues}
					onSubmit={onSubmit}>
					{({ handleSubmit, values }) => {
						return (
							<AttachmentsContainer>
								<AttachmentsUpload ref={attachmentsRef} />
								{values.attachments.length > 0 ? (
									<Button
										disabled={isLoading}
										onClick={handleSubmit}
										title={t('standard.singleStandard.attachments.confirmBtn')}
									/>
								) : null}
							</AttachmentsContainer>
						);
					}}
				</Formik>
				<AttachmentsListBlock>
					{isLoading ? (
						<AttachmentsLoader>
							<Flex $align="center" $justify="center" $direction="column">
								<LogoIcon />
								<Loading horizontal />
							</Flex>
						</AttachmentsLoader>
					) : draft.attachments && draft.attachments.length ? (
						renderAttachments
					) : (
						<NoAttachments />
					)}
				</AttachmentsListBlock>
			</AttachmentsMainWrapper>
		</InputGroup>
	);
});
