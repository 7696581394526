import { injector } from '@asd-stan/injector/injector';
import { DomainAccessService } from '@asd-stan/domain/domain/domain-access.service';

import {DomainRepo} from '@asd-stan/domain/api/domain.repo';
import {DomainService} from '@asd-stan/domain/domain/domain.service';
import {DOMAIN_ACCESS_SERVICE, DOMAIN_REPO, DOMAIN_SERVICE} from '@asd-stan/domain/infrastructure/constants';

injector.set(DOMAIN_REPO, new DomainRepo());
injector.set(DOMAIN_SERVICE, new DomainService());

injector.set(DOMAIN_ACCESS_SERVICE, new DomainAccessService());
