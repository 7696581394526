import React, { HTMLInputTypeAttribute, useEffect, useRef } from 'react';

import { generatePlaceholder, getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { Tooltip } from '@asd-stan/ui-kit/components/tooltip/tooltip';
import { Flex } from '@components/utility/flex';
import { ErrorMessage, FieldProps } from 'formik';

import { StyledTextArea } from './form-texarea.styled';

import { StyledFieldErrorMessage } from '@asd-stan/ui-kit/components/utility/field-error-message.styled';

interface InputProps {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	mandatory?: boolean;
	errors: string;
	title: string;
	name: string;
	fullWidth?: boolean;
	maxLength?: number;
	isNeedClearValue?: boolean;
	showError?: boolean;
	hint?: string;
	setTouchedOnBlur?: boolean;
	tooltip?: string;
	description?: JSX.Element;
	onChange?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLDivElement>) => void;
	useSubmitOnBlur?: boolean;
	disabled?: boolean;
	disableMaxLength?: boolean;
}

export const FormTextarea: React.FC<InputProps & FieldProps> = ({
	title,
	placeholder,
	mandatory,
	field,
	form,
	fullWidth,
	maxLength,
	isNeedClearValue,
	showError,
	hint,
	setTouchedOnBlur = false,
	tooltip,
	description,
	disabled,
	onChange,
	onBlur,
	useSubmitOnBlur = false,
	disableMaxLength = false,
}) => {
	const textAreaRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (field.value) {
			if (textAreaRef.current) {
				if (maxLength && field.value.length > maxLength) {
					const fitText = field.value.slice(0, maxLength);
					textAreaRef.current.innerHTML = `<span class="main-input">${fitText}</span><span class='extra-input'>${field.value.slice(
						maxLength
					)}</span>`;
				} else {
					textAreaRef.current.innerHTML = field.value;
				}
			}
		}
	}, []);

	useEffect(() => {
		if (isNeedClearValue && textAreaRef.current) {
			textAreaRef.current.innerHTML = '';
		}
	}, [isNeedClearValue]);

	const setCaretPosition = (el: Node, pos: number) => {
		// Convert NodeList to an array
		const childNodes = Array.from(el.childNodes);
		// Loop through all child nodes
		for (let node of childNodes) {
			if (node.nodeType === Node.TEXT_NODE) {
				if (node.textContent!.length >= pos) {
					// finally add our range
					const range = document.createRange();
					const sel = window.getSelection();
					range.setStart(node, pos);
					range.collapse(true);
					sel?.removeAllRanges();
					sel?.addRange(range);
					return -1; // done
				} else {
					pos -= node.textContent!.length;
				}
			} else {
				pos = setCaretPosition(node, pos);
				if (pos === -1) {
					return -1; // no need to finish the for loop
				}
			}
		}
		return pos; // needed because of recursion stuff
	};

	const nodeWalk = (node: Node, func: (node: Node) => void): void => {
		func(node);
		for (let child = node.firstChild; child; child = child.nextSibling) {
			nodeWalk(child, func);
		}
	};

	/* returns [start, end] as offsets to elem.textContent that
    correspond to the selected portion of text
    (if start == end, caret is at given position and no text is selected) */
	const getCaretPosition = (elem: Node) => {
		const selection = window.getSelection();
		let totalLength = [0, 0];
		if (selection && selection.anchorNode === elem)
			totalLength = [selection.anchorOffset, selection.focusOffset];
		else {
			const nodesToFind = [selection!.anchorNode, selection!.focusNode];

			if (!elem.contains(nodesToFind[0]) || !elem.contains(nodesToFind[1])) return undefined;
			else {
				const found = [false, false];
				let i;

				nodeWalk(elem, function (node) {
					for (i = 0; i < 2; i++) {
						if (node === nodesToFind[i]) {
							found[i] = true;
							if (found[i === 0 ? 1 : 0])
								// If we found the other node we were looking for, we can stop searching.
								return false; // Stop the nodeWalk loop
						}
					}

					if (node.textContent && !node.firstChild) {
						for (i = 0; i < 2; i++) {
							if (!found[i]) totalLength[i] += node.textContent.length;
						}
					}
				});

				totalLength[0] += selection!.anchorOffset;
				totalLength[1] += selection!.focusOffset;
			}
		}
		if (totalLength[0] <= totalLength[1]) return totalLength;
		return [totalLength[1], totalLength[0]];
	};

	const handleChange = (e: React.SyntheticEvent<HTMLDivElement>) => {
		const text = e.currentTarget.innerText;
		form.setFieldValue(field.name, text);
		const currentPosMain = getCaretPosition(textAreaRef.current!);

		if (currentPosMain !== undefined) {
			const fitText = text.slice(0, maxLength);
			const formattedString = `<span class="main-input">${fitText}</span><span class='extra-input'>${text.slice(
				maxLength
			)}</span>`;
			e.currentTarget.innerHTML = formattedString;
			const node = e.currentTarget;

			setCaretPosition(node, currentPosMain[0]);
		}

		if (
			e.nativeEvent.type === 'input' &&
			e.currentTarget.innerText.length === 0 &&
			(e.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
		) {
			form.setFieldValue(field.name, '');
			e.currentTarget.innerHTML = '';
		}

		if (onChange) {
			onChange(e);
		}
	};

	const handleBlur = async (e: React.ChangeEvent<HTMLDivElement>) => {
		if (onBlur) {
			onBlur(e);
		}

		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (useSubmitOnBlur) {
			if (disableMaxLength) {
				return await form.handleSubmit();
			}
			if (maxLength && field.value && field.value.length > maxLength) {
				return;
			}
			await form.handleSubmit();
		}
	};

	return (
		<StyledTextArea
			$error={
				!!(
					getValueByFieldName(form.touched, field.name) &&
					getValueByFieldName(form.errors, field.name)
				)
			}
			$fullWidth={fullWidth}
			$disabled={disabled}>
			<Flex>
				<label title={title}>
					{title}
					{mandatory ? <span>*</span> : null}
					{hint ? <span className="field-hint"> {hint}</span> : null}
				</label>
				{tooltip ? <Tooltip width="250" text={tooltip} /> : null}
			</Flex>
			{description}
			<div
				ref={textAreaRef}
				onInput={handleChange}
				className="input-container"
				style={{ marginTop: !title ? '0px !important' : 'aut' }}
				contentEditable={!disabled}
				data-placeholder={generatePlaceholder(title, placeholder)}
				{...field}
				onBlur={handleBlur}></div>
			{maxLength && field.value?.length > maxLength ? (
				<div className="error-message">
					{field.value?.length} / {maxLength}
				</div>
			) : null}
			{showError ? (
				maxLength && form.errors[field.name] && field.value.length !== 0 ? (
					<ErrorMessage
						name={field.name}
						children={errorMessage => (
							<StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>
						)}
					/>
				) : (
					<ErrorMessage
						name={field.name}
						children={errorMessage => {
							if (typeof errorMessage === 'string')
								return <StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>;
							if (typeof errorMessage === 'object') {
								return (
									<StyledFieldErrorMessage className="error-message">
										{Object.values(errorMessage)[0] as string}
									</StyledFieldErrorMessage>
								);
							}
						}}
					/>
				)
			) : null}
		</StyledTextArea>
	);
};
