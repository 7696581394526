import { StandardFile } from '@asd-stan/standard/domain/standard-detailed.entity';
import { User } from '@asd-stan/user/domain/user.entity';
import { DateTime } from 'luxon';

export interface StandardStageArgs {
	id: number;
	name: string;
	code: string;
	immediatelyPublication: boolean;
}

export interface StandardStageArgsFromAPI {
	name: string;
	code: string;
}

export interface StandardStageListItemArgs extends StandardStageArgs {
	id: number;
	completeDate: string | null;
	startDate: string;
	targetDate: string | null;
	latestDraft: Pick<StandardFile, 'name' | 'path'> | null;
}

export interface StandardStageEntity {
	name: string;
	code: string;
	immediatelyPublication: boolean;
	recommendedStages: StandardStageEntity[];
}

export interface StandardStageListItemEntity {
	id: number;
	name: string;
	code: string;
	completeDate: string | null;
	startDate: string;
	targetDate: string | null;
	latestDraft: Pick<StandardFile, 'name' | 'path'> | null;
}

export interface StandardTransactionHistoryListItemDto {
	stages: {
		code: string;
		name: string;
	}[];
	id: number;
	startDate: string;
	targetDate: string | null;
	completeDate: string | null;
}

export interface StandardTransactionHistoryResponse {
	standardTransactionHistory: StandardTransactionHistoryListItemDto[];
	standardTransactionHistoryCount: number;
}

export interface StandardTransactionHistoryListItem {
	id: number;
	code: string;
	name: string;
	startDate: string;
	targetDate: string | null;
	completeDate: string | null;
}

export interface StandardTransactionHistory {
	standardTransactionHistory: StandardTransactionHistoryListItem[];
	standardTransactionHistoryCount: number;
}

export interface StandardStageUpdateHistoryResponse {
	standardChangesHistory: StandardStageUpdateItem[];
	standardChangesHistoryCount: number;
}

export interface StandardStageUpdateHistoryResponseParsed {
	standardChangesHistory: StandardStageUpdateItemParsed[];
	standardChangesHistoryCount: number;
}

export interface StandardStageUpdateItem {
	property: string;
	history: string;
	date: string;
	user: Pick<User, 'firstName' | 'lastName'>;
}

interface Value {
	id?: number;
	label: string;
}

export interface TransactionFromAPI {
	id: string;
	startDate: string;
	targetDate: string;
	completeDate: string;
	stages: StandardStageArgsFromAPI[];
}

export interface TransactionEntity {
	id: string;
	startDate: DateTime;
	targetDate: DateTime;
	completeDate: DateTime;
	stages: StandardStageArgsFromAPI[];
}

export interface StandardStageUpdateItemParsed {
	property: string;
	history: {
		from: Value[] | Value;
		to: Value | Value[];
		index?: number;
	};
	date: string;
	user: Pick<User, 'firstName' | 'lastName'>;
}

export class StandardStage implements StandardStageEntity {
	public id: number;
	public name: string;
	public code: string;
	public recommendedStages: StandardStageEntity[] = [];
	immediatelyPublication: boolean;

	constructor(args: StandardStageArgs) {
		this.id = args.id;
		this.name = args.name;
		this.code = args.code;
		this.immediatelyPublication = args.immediatelyPublication;
	}

	public setRecommendedStages(stages: StandardStageEntity[]) {
		this.recommendedStages = stages;
	}
}

export class StandardStageListItem implements StandardStageListItemEntity {
	id: number;
	code: string;
	completeDate: string | null;
	name: string;
	startDate: string;
	targetDate: string | null;
	latestDraft: Pick<StandardFile, 'name' | 'path'> | null;

	constructor(args: StandardStageListItemArgs) {
		this.id = args.id;
		this.name = args.name;
		this.code = args.code;
		this.completeDate = args.completeDate;
		this.startDate = args.startDate;
		this.targetDate = args.targetDate;
		this.latestDraft = args.latestDraft;
	}
}

export class Transaction implements TransactionEntity {
	public completeDate: DateTime;
	public id: string;
	public stages: StandardStageArgsFromAPI[];
	public startDate: DateTime;
	public targetDate: DateTime;

	constructor(args: TransactionFromAPI) {
		const { id, completeDate, startDate, targetDate, stages } = args;
		this.id = id;
		this.stages = stages;
		this.completeDate = DateTime.fromISO(completeDate);
		this.startDate = DateTime.fromISO(startDate);
		this.targetDate = DateTime.fromISO(targetDate);
	}
}
