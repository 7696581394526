import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import moment from 'moment';

import {
	StandardDisplayField,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
} from '../../single-standard.styled';

import { StyledENDataTabSection } from './enData-draft-tab-section.styled';

export const ENData = ({
	data: { name, publicationDate, title },
}: {
	data: { name: string; publicationDate: Date | null; title: string };
}) => {
	const { t } = useTranslation();

	return (
		<InputGroup title="Transformation into EN">
			<StyledENDataTabSection>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.enData.name')}
					</StandardDisplayFieldHeader>
					{name?.length ? (
						<StandardDisplayFieldValue>{name}</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.enData.noName')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.enData.date')}
					</StandardDisplayFieldHeader>
					{publicationDate ? (
						<StandardDisplayFieldValue>
							{moment(publicationDate).format('YYYY-MM')}
						</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.enData.noDate')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.enData.title')}
					</StandardDisplayFieldHeader>
					{title?.length ? (
						<StandardDisplayFieldValue>
							{title.split('\n').map((line, index) => (
								<Fragment key={index}>
									{line}
									<br />
								</Fragment>
							))}
						</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.enData.noTitle')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
			</StyledENDataTabSection>
		</InputGroup>
	);
};
