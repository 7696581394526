import { observer } from 'mobx-react';
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { isArray } from '@apollo/client/utilities';
import { FileUploadController, UploadedFile } from '@asd-stan/file/domain/file-upload-controller';
import { attachmentTypes } from '@asd-stan/standard/domain/enums';
import { ReactComponent as DropzoneIcon } from '@asd-stan/ui-kit/assets/icons/dropzone-icon.svg';
import { ReactComponent as FileIcon } from '@asd-stan/ui-kit/assets/icons/uploaded-file-icon.svg';
import { FormSelect, Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { FormTextarea } from '@asd-stan/ui-kit/components/form-textarea/form-texarea';
import { ButtonClose } from '@components/button/button-close';
import { formatBytes } from '@components/file-dropzone/utils';
import { Loader } from '@components/loader/loader';
import { MarkedText } from '@components/utility/marked-text';
import { Field, FieldArray, FormikValues, useFormikContext } from 'formik';

import { StyledDroopedFiles, StyledDropzone } from '@components/file-dropzone/file-dropzone.styled';

const fileTypes = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg': ['.svg'],
	'application/xml': ['.xml'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'text/csv': ['.csv'],
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'image/tiff': ['.tiff'],
	'image/vnd.dwg': ['.dwg'],
	'image/bmp': ['.bmp'],
	'application/zip': ['.zip'],
};

export interface Attachment {
	file: UploadedFile;
	attachmentDescription: string;
	attachmentType: Option;
}

export interface AttachmentsMethods {
	clearUploadedFiles: () => void;
}

export const AttachmentsUpload = observer(
	forwardRef<AttachmentsMethods>((props, ref) => {
		const [fileData, setFileData] = useState<Attachment[]>([]);
		const { values, setFieldValue } = useFormikContext<FormikValues>();
		const { t } = useTranslation();
		const fileUploadController = useMemo(() => new FileUploadController(), []);

		useImperativeHandle(ref, () => ({
			clearUploadedFiles: () => {
				fileUploadController.clearUploadedFiles();
				setFieldValue('attachments', []).then(() => setFileData([]));
			},
		}));

		useEffect(() => {
			if (values.attachments.length && fileData.length === 0) {
				setFileData(values.attachments);
			}
		}, [values.attachments]);

		useEffect(() => {
			setFieldValue('attachments', fileData);
		}, [fileData]);

		const updateFiles = async (file: UploadedFile[]) => {
			if (isArray(file)) {
				const waitAndRetry = () => {
					if (fileUploadController.isLoading) {
						setTimeout(waitAndRetry, 200);
					} else {
						setFileData(previousAttachments => {
							return [
								...fileUploadController.uploadedFiles.map((file, index) => {
									return {
										file: {
											id: file.id,
											title: file.title,
											size: file.size,
											fileId: file.fileId,
											path: file.path,
										},
										attachmentDescription: previousAttachments[index]?.attachmentDescription,
										attachmentType: previousAttachments[index]?.attachmentType,
									};
								}),
							];
						});
					}
				};

				waitAndRetry();
			}
		};

		const onFileDelete = async (fileToDelete: UploadedFile) => {
			if (values.attachments?.length === 1) {
				await setFieldValue('attachments', []);
				setFileData(() => []);
				fileUploadController.clearUploadedFiles();
			} else {
				setFileData(files => files.filter(attachment => attachment.file !== fileToDelete));
			}
		};

		const onDrop = useCallback(
			async (acceptedFiles: File[]) => {
				if (acceptedFiles?.length) {
					let fileObjects: File[];
					setFileData(previousAttachments => {
						return [
							...previousAttachments,
							...acceptedFiles.map(file => {
								return {
									file: {
										id: null,
										title: file.name,
										size: file.size,
										fileId: null,
										path: '',
									},
									attachmentDescription: '',
									attachmentType: { label: '', value: '' },
								};
							}),
						];
					});

					fileObjects = [
						...acceptedFiles.map(file =>
							Object.assign(file, { preview: URL.createObjectURL(file) })
						),
					];

					let files: UploadedFile[] = [];

					for (const file of fileObjects) {
						const uploadedFile = await fileUploadController.uploadFile(file, true);

						files.push(uploadedFile);
					}

					updateFiles(files);
				}
			},
			[fileUploadController, updateFiles]
		);

		const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
			onDrop,
			multiple: true,
			accept: fileTypes,
			maxSize: 20971520,
		});

		const onDescriptionChange = (e: React.ChangeEvent<HTMLDivElement>, index: number) => {
			const updatedFileData = fileData;
			updatedFileData[index].attachmentDescription = e.target.innerText;
			setFileData(updatedFileData);
		};

		const onTypeChange = (option: Option, index: number) => {
			const updatedFileData = fileData;
			updatedFileData[index].attachmentType = option;
			setFileData(updatedFileData);
		};

		return (
			<>
				<StyledDropzone className={`${isDragActive ? 'active' : ''}`} {...getRootProps()}>
					<DropzoneIcon />
					<input {...getInputProps()} />
					<div className="dropzone-text">
						<Trans i18nKey="common.uploader.dragAndDrop">
							Drag & Drop or <MarkedText>Choose</MarkedText> a file to upload
						</Trans>
						<p>
							{Object.values(fileTypes)
								.flat()
								.map(ext => ext.replace('.', ''))
								.join(', ')}
						</p>
						<p>
							{t('common.uploader.tooltips.unlimitedFilesSize', { size: formatBytes(20971520) })}
						</p>
						{fileRejections.map((file, index) => (
							<React.Fragment key={index}>
								{file.errors.some(err => err.code === 'file-too-large') && (
									<p className="dropzone-error">
										{t('common.uploader.errors.fileIsTooLarge', { name: file.file.name })}
									</p>
								)}
							</React.Fragment>
						))}
					</div>
				</StyledDropzone>

				<FieldArray name="attachments">
					{() => (
						<StyledDroopedFiles key={`files-${values.attachments.length}`}>
							{values.attachments?.map((attachment: Attachment, index: number) => (
								<div className="file-item" key={attachment.file.title + index}>
									<li className="file-list">
										<div className="file-container">
											<FileIcon />
											<div>
												<p className="file-name">{attachment.file.title}</p>
												<p className="file-properties">
													{formatBytes(attachment.file.size)}
													{fileUploadController.isLoading ? (
														<>
															<span> {' \u2219 '}</span> {t('common.uploader.pleaseWait')}
														</>
													) : null}
												</p>
											</div>
											{fileUploadController.isLoading ? (
												<Loader />
											) : (
												<ButtonClose onClick={() => onFileDelete(attachment.file)} />
											)}
										</div>
									</li>
									<Field
										component={FormTextarea}
										name={`attachments.${index}.attachmentDescription`}
										title={t('standard.createNWP.attachments.attachmentsDescription')}
										hint={t('standard.createNWP.attachments.attachmentsDescriptionHint')}
										disabled={fileUploadController.isLoading}
										fullWidth
										mandatory
										showError
										maxLength={500}
										onChange={(e: React.ChangeEvent<HTMLDivElement>) =>
											onDescriptionChange(e, index)
										}
									/>
									<Field
										component={FormSelect}
										name={`attachments.${index}.attachmentType`}
										options={attachmentTypes}
										title={t('standard.createNWP.attachments.attachmentsType')}
										disabled={fileUploadController.isLoading}
										showError
										mandatory
										fullWidth
										customChange={(option: Option) => onTypeChange(option, index)}
									/>
								</div>
							))}
						</StyledDroopedFiles>
					)}
				</FieldArray>
			</>
		);
	})
);
