import { styled } from 'styled-components';

export const AttachmentDeleteContainer = styled.div`
    display: flex;
`

export const AttachmentDeleteHeader = styled.h1`
    text-align: center;
		font-size: 32px;
`

export const StageSelectorTitle = styled.div`
    display: block;
    width: 100%;
    color: #ABB3BB;
    font-size: 14px;
    margin: 12px 0;
`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    gap: 16px;
`
