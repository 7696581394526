import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { makeAutoObservable } from 'mobx';

import { getAuthRepo } from '../infrastructure/getters';

import { AccessToken } from './access-token.entity';
import { Auth } from './auth.entity';

export class AuthService {
	private _inviteInformation: Auth | null = null;
	private _accessToken: AccessToken | null = null;
	private _token: string = '';

	constructor() {
		makeAutoObservable(this);
	}

	// getters

	private get _authRepo() {
		return getAuthRepo();
	}

	private get _currentUserService() {
		return getCurrentUserService();
	}

	get inviteInformation() {
		return this._inviteInformation;
	}

	get accessToken() {
		return this._accessToken;
	}

	get token() {
		return this._token;
	}

	// getters end
	// methods

	async confirmInvite(token: string): Promise<Auth> {
		const authResult = await this._authRepo.confirmInvite(token);
		this._inviteInformation = new Auth(authResult);
		return this._inviteInformation;
	}

	async resendConfirmInvite(token: string): Promise<Auth> {
		const authResult = await this._authRepo.resendConfirmInvite(token);
		this._inviteInformation = new Auth(authResult);
		return this._inviteInformation;
	}

	setItems(token: AccessToken) {
		localStorage.setItem('accessToken', token.token);
		localStorage.setItem('expiresAt', JSON.stringify(token.expiresAt));
		localStorage.setItem('refreshToken', token.refreshToken.token);
		localStorage.setItem('refreshExpiresAt', JSON.stringify(token.refreshToken.expiresAt));
	}

	async createPassword(token: string, password: string): Promise<AccessToken> {
		this._accessToken = await this._authRepo.createPassword(token, password);
		this.setItems(this._accessToken);
		return this._accessToken;
	}

	async login(email: string, password: string): Promise<AccessToken> {
		const result = await this._authRepo.login(email, password);
		this.setItems(result);
		this._accessToken = result;
		return this._accessToken;
	}

	async confirmLogin(token: string, code: string): Promise<AccessToken> {
		this._accessToken = await this._authRepo.confirmLogin(token, code);
		this.setItems(this._accessToken);
		return this._accessToken;
	}

	async refreshToken(token: string) {
		this._accessToken = await this._authRepo.refreshToken(token);
		this.setItems(this._accessToken);
	}

	async resendCode(token: string): Promise<string> {
		this._token = await this._authRepo.resendCode(token);
		return this._token;
	}

	async confirmEmailTokenValid(token: string | null): Promise<boolean> {
		return await this._authRepo.confirmEmailTokenValid(token);
	}

	async inviteTokenValid(token: string | null): Promise<boolean> {
		await this.handleLogout();
		return await this._authRepo.inviteTokenValid(token);
	}

	async handleLogout() {
		this._accessToken = null;
		localStorage.removeItem('expiresAt');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('refreshExpiresAt');
		this._currentUserService.clearCurrentUser();
		localStorage.removeItem('accessToken');
	}

	initResetPassword(email: string) {
		return this._authRepo.initResetPassword(email);
	}

	checkResetPasswordTokenValid(token: string) {
		return this._authRepo.checkResetPasswordTokenValid(token);
	}

	resetPassword(token: string, password: string) {
		return this._authRepo.resetPassword(token, password);
	}

	// methods end
}
