import styled from 'styled-components';

export const StyledReplaceContainer = styled.div`
	width: 100%;
	padding: 12px 24px;
	border-radius: 8px;
	border: ${props => props.theme.borders.replaceContainer};
	background: ${props => props.theme.bgColors.replaceContainer};
	display: flex;
	justify-content: space-between;
	align-items: center;

	> p {
		flex: 1;
		color: ${props => props.theme.fontColors.darkGrey};
		font-weight: 500;
		font-size: 15px;

		> b {
			font-weight: 600;
		}
	}

	> div:nth-child(even) {
		margin: 0px 5px;
		> button {
			color: ${props => props.theme.fontColors.accent};
			border: ${props => props.theme.borders.buttonBorder};
		}
	}
`;
