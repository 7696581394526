import styled from 'styled-components';

export const StyledTableControl = styled.div`
	& {
		display: flex;
		justify-content: space-between;
		margin: 24px 0;
		width: 100%;

		& div {
			& input {
				color: ${({ theme }) => theme.fontColors.darkGrey};
			}

			&.input-icon {
				pointer-events: none;
				top: 11px;
			}
		}

		.search {
			height: 36px;
		}
	}
`;

export const TableSelect = styled.div`
	& {
		margin-left: 16px;
		display: flex;
		align-items: center;
		color: ${({ theme }) => theme.fontColors.textGrey};
		font-weight: 500;

		& > div {
			margin-left: 8px;
			width: 76px;
			height: 36px;
			overflow: hidden;
			border-radius: 8px;
			border: ${({ theme }) => theme.borders.divider};
			position: relative;
			background-color: ${({ theme }) => theme.fontColors.white};

			&:after {
				pointer-events: none;
				width: 0;
				height: 0;
				border-left: 6px solid transparent;
				border-right: 1px solid transparent;
				margin-top: 8px;
				position: absolute;
				top: 40%;
				right: 15px;
				color: ${({ theme }) => theme.fontColors.lightGrey};
				content: '\u005e';
				transform: rotate(180deg);
				z-index: 2;
			}

			& > select {
				padding: 10px 15px;
				width: 100%;
				position: relative;
				z-index: 2;

				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;

				&:disabled {
					background-color: ${({ theme }) => theme.bgColors.lightGrey};
					opacity: 0.7;
				}
			}
		}
	}
`;
