import { StandardRepo } from '@asd-stan/standard/api/standard.repo';
import { StandardStage } from '@asd-stan/standard/domain/standard-stage.entity';
import { getStandardRepo } from '@asd-stan/standard/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

export class StagesService {
	public stageList: StandardStage[] = [];
	public newStageOptions: string[] = [];
	private _standardRepo: StandardRepo = getStandardRepo();

	constructor() {
		makeAutoObservable(this);
	}

	public async getStageList(form?: string): Promise<void> {
		this.stageList = [];

		const response = await this._standardRepo.getAllStagesList(form);

		runInAction(() => {
			this.stageList = response;
		});
	}

	getStages({ form, showHistoricalStages }: { form?: string; showHistoricalStages?: boolean }) {
		return this._standardRepo.getAllStagesList(form, showHistoricalStages);
	}

	public async getNewStageOptions(form: string, standardId: number): Promise<void> {
		this.newStageOptions = [];
		const response = await this._standardRepo.getNewStageOptions(form, standardId);
		runInAction(() => {
			this.newStageOptions = response;
		});
	}
}
