import React from 'react';
import { Link, RouteProps, createBrowserRouter } from 'react-router-dom';

import { CreatePassword } from '@asd-stan/auth/components/create-password/create-password';
import { InitResetPassword } from '@asd-stan/auth/components/init-reset-password/init-reset-password';
import { LinkExpired } from '@asd-stan/auth/components/link-expired/link-expired';
import { ResetPassword } from '@asd-stan/auth/components/reset-password/reset-password';
import { SignIn } from '@asd-stan/auth/components/sign-in/sign-in';
import { BallotListPage } from '@asd-stan/ballot/components/ballot-list/ballot-list';
import { CreateBallot } from '@asd-stan/ballot/components/create-ballot/create-ballot';
import { SingleBallot } from '@asd-stan/ballot/components/single-ballot/single-ballot';
import { UpdateBallot } from '@asd-stan/ballot/components/update-ballot/update-ballot';
import { Dashboard } from '@asd-stan/dashboard/components/dashboard';
import i18n from '@asd-stan/i18n/i18n';
import { CreateMeeting } from '@asd-stan/meeting/components/create-meeting/create-meeting';
import { MeetingListPage } from '@asd-stan/meeting/components/meeting-list/meeting-list';
import { SingleMeeting } from '@asd-stan/meeting/components/single-meeting/single-meeting';
import { UpdateMeeting } from '@asd-stan/meeting/components/update-meeting/upate-meeting';
import { RouteContainer } from '@asd-stan/router/route-container';
import { ProtectedCatalogRoute } from '@asd-stan/router/routes/protected-catalog-route';
import { ProtectedConfigRoute } from '@asd-stan/router/routes/protected-config-route';
import { ProtectedRoute } from '@asd-stan/router/routes/protected-route';
import { ProtectedSingleStandardRoute } from '@asd-stan/router/routes/protected-single-standard-route';
import { ProtectedStandardsRoute } from '@asd-stan/router/routes/protected-standards-route';
import { MainLayout } from '@asd-stan/shell/components/main-layout';
import { CatalogList } from '@asd-stan/standard/components/catalog-list/catalog-list';
import { DraftList } from '@asd-stan/standard/components/draft-list/draft-list';
import { CreateNwp } from '@asd-stan/standard/components/nwp/create-nwp';
import { DraftEditNwp } from '@asd-stan/standard/components/nwp/draft-edit-nwp';
import { EditNwp } from '@asd-stan/standard/components/nwp/edit-nwp';
import { SingleDraftPage } from '@asd-stan/standard/components/single-standard/single-draft-page';
import { SingleStandardPage } from '@asd-stan/standard/components/single-standard/single-standard-page';
import { StandardList } from '@asd-stan/standard/components/standard-list/standard-list';
import { EditUser } from '@asd-stan/user/components/edit-user/edit-user';
import { InviteUsers } from '@asd-stan/user/components/invite-users/invite-users';
import { UserList } from '@asd-stan/user/components/user-list/user-list';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { RestrictedPage } from '@components/restricted-page/restricted-page';
import { UnderConstruction } from '@components/under-construction/under-construction';

import { ProtectedSingleDraftRoute } from './routes/protected-single-draft-route';

export const stanWorksRouter = createBrowserRouter([
	{
		path: '/forbidden',
		element: <RestrictedPage />,
	},
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: '/dashboard',
				element: <Dashboard />,
				handle: { crumb: () => <Link to="/dashboard">{i18n.t('common.sidebar.dashboard')}</Link> },
			},
			{
				path: '/standards/detailed/:id/fonto',
				element: <UnderConstruction title="Fonto" />,
			},
			{
				path: 'standards',
				element: <RouteContainer />,
				handle: {
					crumb: () => <Link to="/standards">{i18n.t('common.sidebar.standards.title')}</Link>,
				},
				children: [
					{
						path: 'catalog',
						element: (
							<ProtectedCatalogRoute
								allowedRoles={[
									SystemRole.ADMIN,
									SystemRole.DIRECTOR,
									SystemRole.ES,
									SystemRole.MC,
								]}>
								<CatalogList />
							</ProtectedCatalogRoute>
						),
					},
					{
						index: true,
						element: (
							<ProtectedStandardsRoute
								allowedRoles={[
									SystemRole.ADMIN,
									SystemRole.DIRECTOR,
									SystemRole.ES,
									SystemRole.BOARD_MEMBER,
									SystemRole.TAC,
									SystemRole.MC,
								]}>
								<StandardList />
							</ProtectedStandardsRoute>
						),
					},
					{
						path: 'assign',
						element: (
							<ProtectedRoute
								allowedRoles={[
									SystemRole.ADMIN,
									SystemRole.DIRECTOR,
									SystemRole.ES,
									SystemRole.TAC,
									SystemRole.MC,
									SystemRole.BOARD_MEMBER,
								]}>
								<StandardList />
							</ProtectedRoute>
						),
					},
					{
						path: 'draft',
						element: (
							<ProtectedStandardsRoute
								allowedRoles={[
									SystemRole.ADMIN,
									SystemRole.DIRECTOR,
									SystemRole.ES,
									SystemRole.TAC,
									SystemRole.MC,
									SystemRole.BOARD_MEMBER,
								]}
								rolesShouldParticipateInDomain={[SystemRole.EXPERT]}>
								<DraftList />
							</ProtectedStandardsRoute>
						),
					},
					{
						path: ':domain',
						element: <StandardList />,
						children: [
							{
								path: ':workingGroup',
								element: <StandardList />,
							},
						],
					},
					{
						path: 'new-standard',
						element: (
							<ProtectedRoute
								allowedRoles={[SystemRole.DIRECTOR, SystemRole.EXPERT, SystemRole.ES]}
								rolesShouldParticipateInDomain={[SystemRole.MC, SystemRole.BOARD_MEMBER]}>
								<CreateNwp />
							</ProtectedRoute>
						),
						handle: {
							crumb: () => (
								<Link to="/standards/new-standard">
									{i18n.t('standard.breadcrumbs.newStandard')}
								</Link>
							),
						},
					},
					{
						path: 'detailed/:id/*',
						element: (
							<ProtectedSingleStandardRoute
								allowedRoles={[
									SystemRole.ADMIN,
									SystemRole.DIRECTOR,
									SystemRole.ES,
									SystemRole.MC,
									SystemRole.BOARD_MEMBER,
									SystemRole.TAC,
								]}
								shouldParticipateInDomain={[SystemRole.EXPERT]}>
								<SingleStandardPage />
							</ProtectedSingleStandardRoute>
						),
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/standards/detailed/${params.id}/general`}>
									{i18n.t('standard.breadcrumbs.singleStandard')}
								</Link>
							),
						},
					},
					{
						path: 'draft/:id/*',
						element: (
							<ProtectedSingleDraftRoute
								allowedRoles={[
									SystemRole.ADMIN,
									SystemRole.DIRECTOR,
									SystemRole.ES,
									SystemRole.MC,
									SystemRole.BOARD_MEMBER,
									SystemRole.TAC,
								]}
								shouldParticipateInDomain={[SystemRole.EXPERT]}>
								<SingleDraftPage />
							</ProtectedSingleDraftRoute>
						),
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/standards/draft/${params.id}/general`}>
									{i18n.t('standard.breadcrumbs.singleStandard')}
								</Link>
							),
						},
					},
					{
						path: 'draft/edit/:id',
						element: (
							<ProtectedSingleDraftRoute
								allowedRoles={[SystemRole.DIRECTOR, SystemRole.ES]}
								shouldParticipateInDomain={[SystemRole.MC, SystemRole.BOARD_MEMBER]}
								shouldBeOriginator={[SystemRole.EXPERT]}>
								<DraftEditNwp />
							</ProtectedSingleDraftRoute>
						),
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/standards/draft/edit/${params.id}`}>
									{i18n.t('standard.breadcrumbs.singleStandard')}
								</Link>
							),
						},
					},
					{
						path: 'edit/:id',
						element: (
							<ProtectedSingleStandardRoute
								allowedRoles={[SystemRole.DIRECTOR, SystemRole.ES]}
								shouldParticipateInDomain={[SystemRole.MC, SystemRole.BOARD_MEMBER]}
								shouldBeOriginator={[SystemRole.EXPERT]}>
								<EditNwp />
							</ProtectedSingleStandardRoute>
						),
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/standards/edit/${params.id}`}>
									{i18n.t('standard.breadcrumbs.singleStandard')}
								</Link>
							),
						},
					},
				],
			},
			{
				path: '/ballots',
				element: <RouteContainer />,
				handle: { crumb: () => <Link to="/ballots">{i18n.t('common.sidebar.ballots')}</Link> },
				children: [
					{
						index: true,
						element: <BallotListPage />,
					},
					{
						path: 'create',
						element: <CreateBallot />,
						handle: {
							crumb: () => <Link to="/ballots/create">{i18n.t('ballot.createBallot.title')}</Link>,
						},
					},
					{
						path: 'edit/:id',
						element: <UpdateBallot />,
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/ballots/edit/${params.id}`}>{i18n.t('ballot.updateBallot.title')}</Link>
							),
						},
					},
					{
						path: ':id/*',
						element: <SingleBallot />,
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/ballots/${params.id}/general`}>
									{i18n.t('ballot.singleBallot.breadcrumbs')}
								</Link>
							),
						},
					},
				],
			},
			{
				path: '/meetings',
				handle: { crumb: () => <Link to="/meetings">{i18n.t('common.sidebar.meetings')}</Link> },
				children: [
					{
						index: true,
						element: <MeetingListPage />,
					},
					{
						path: 'create',
						element: <CreateMeeting />,
						handle: {
							crumb: () => (
								<Link to="/meetings/create">{i18n.t('meeting.createMeeting.title')}</Link>
							),
						},
					},
					{
						path: ':id/*',
						element: <SingleMeeting />,
						handle: {
							crumb: (_: unknown, params: RouteProps) => (
								<Link to={`/meetings/${params.id}/general`}>
									{i18n.t('meeting.singleMeeting.breadcrumb')}
								</Link>
							),
						},
					},
					{
						path: 'edit/:id',
						element: <UpdateMeeting />,
						handle: {
							crumb: (_: unknown, params: RouteProps) => (
								<Link to={`/meetings/edit/${params.id}`}>
									{i18n.t('meeting.updateMeeting.title')}
								</Link>
							),
						},
					},
				],
			},
			{
				path: '/user-list',
				element: <RouteContainer />,
				handle: { crumb: () => <Link to="/user-list">{i18n.t('common.sidebar.userList')}</Link> },
				children: [
					{
						index: true,
						element: (
							<ProtectedRoute allowedRoles={[SystemRole.ADMIN, SystemRole.DIRECTOR, SystemRole.ES]}>
								<UserList />
							</ProtectedRoute>
						),
					},
					{
						path: 'invite-users',
						element: (
							<ProtectedRoute allowedRoles={[SystemRole.ADMIN, SystemRole.DIRECTOR, SystemRole.ES]}>
								<InviteUsers />
							</ProtectedRoute>
						),
						handle: {
							crumb: () => (
								<Link to="/user-list/invite-users">{i18n.t('user.breadcrumbs.userInvite')}</Link>
							),
						},
					},
					{
						path: ':id',
						element: (
							<ProtectedRoute allowedRoles={[SystemRole.ADMIN, SystemRole.DIRECTOR, SystemRole.ES]}>
								<EditUser />
							</ProtectedRoute>
						),
						handle: {
							crumb: (_data: any, params: RouteProps) => (
								<Link to={`/user-list/${params.id}`}>{i18n.t('user.updateUser.breadcrumbs')}</Link>
							),
						},
					},
					{
						path: 'me',
						element: <EditUser me />,
						handle: {
							crumb: () => <Link to="/user-list/me">{i18n.t('user.updateUser.breadcrumbs')}</Link>,
						},
					},
				],
			},
			{
				path: '/documents',
				element: <UnderConstruction title={i18n.t('common.sidebar.documents')} />,
				handle: { crumb: () => <Link to="/documents">{i18n.t('common.sidebar.documents')}</Link> },
			},
			{
				path: '/config',
				element: (
					<ProtectedConfigRoute restrictedRoles={SystemRole.EXPERT}>
						<UnderConstruction title={i18n.t('common.sidebar.configuration')} />
					</ProtectedConfigRoute>
				),
			},
		],
	},
	{
		path: '/invite',
		element: <CreatePassword />,
	},
	{
		path: '/expired',
		element: <LinkExpired />,
	},
	{
		path: '/signin',
		element: <SignIn />,
	},
	{
		path: '/init-reset-password',
		element: <InitResetPassword />,
	},
	{
		path: '/reset-password',
		element: <ResetPassword />,
	},
]);
