import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { FileUploadController, UploadedFile } from '@asd-stan/file/domain/file-upload-controller';
import { ReactComponent as DropzoneIcon } from '@asd-stan/ui-kit/assets/icons/dropzone-icon.svg';
import {
	CustomFilesView,
	DroppedFiles,
	checkIfFileBeingUploaded,
} from '@asd-stan/ui-kit/components/file-dropzone/dropped-files';
import { MarkedText } from '@asd-stan/ui-kit/components/utility/marked-text';
import { formatBytes } from '@components/file-dropzone/utils';
import { FieldProps, FormikValues, useFormikContext } from 'formik';

import { StyledDropzone, StyledDropzoneContainer } from './file-dropzone.styled';

interface FileDropzoneProps {
	title: string;
	mandatory: boolean;
	fileTypes: FileTypes;
	isLoading?: boolean;
	maxFiles?: number;
	maxSize?: number;
	disabled?: boolean;
	controller: FileUploadController;
	useSubmitOnDrop?: boolean;
	className?: string;
	isFilePrivate?: boolean;
	customFilesView?: CustomFilesView;
	onFilesChange?(files: UploadedFile[]): Promise<void>;
	initialValue?: UploadedFile[];
}

type FileTypes = {
	[key: string]: string[];
};

export const checkIfFilesBeingUploaded = (files: UploadedFile[]) =>
	files.some(checkIfFileBeingUploaded);

export const FileDropzone: React.FC<FileDropzoneProps & FieldProps> = observer(
	({
		title,
		mandatory,
		fileTypes,
		maxFiles = 1,
		maxSize,
		field,
		form,
		disabled,
		controller,
		useSubmitOnDrop,
		className,
		isFilePrivate,
		customFilesView,
		onFilesChange: handleFilesChange,
		initialValue,
	}) => {
		const [files, setFiles] = useState<UploadedFile[]>(
			(initialValue ? initialValue : field.value) ?? []
		);
		const { t } = useTranslation();
		const { handleSubmit } = useFormikContext<FormikValues>();

		useEffect(() => {
			const setValue = async () => {
				if (handleFilesChange) {
					await handleFilesChange(files);
				} else {
					await form.setFieldValue(field.name, files);
				}
				if (Array.isArray(files)) {
					if (files.every(file => file.fileId !== null) && useSubmitOnDrop) {
						handleSubmit();
					}
				}
			};
			setValue();
		}, [files]);

		const isMultiple = maxFiles !== 1;

		const onDrop = useCallback(async (filesToUpload: File[]) => {
			const filesToUploadWithUploadId = filesToUpload.map(file => ({ file, uploadId: uuid() }));
			setFiles(previousFiles => {
				const currentFiles = isMultiple ? previousFiles : [];
				const newFiles = filesToUploadWithUploadId.map(({ file, uploadId }) => ({
					title: file.name,
					size: file.size,
					fileId: null,
					path: '',
					uploadId,
				}));
				return [...currentFiles, ...newFiles];
			});
			const uploadedFiles = await Promise.all(
				filesToUploadWithUploadId.map(async ({ file, uploadId }) => ({
					file: await controller.uploadFile(file, isFilePrivate),
					uploadId,
				}))
			);
			setFiles(files =>
				files.map(file => {
					if (!file.uploadId) {
						return file;
					}
					return uploadedFiles.find(({ uploadId }) => uploadId === file.uploadId)!.file;
				})
			);
		}, []);

		const generateTooltip = () => {
			if (maxFiles === 1 && maxSize) {
				return t('common.uploader.tooltips.oneFileSize', { size: formatBytes(maxSize) });
			} else if ((maxFiles || !maxFiles) && maxSize) {
				return t('common.uploader.tooltips.unlimitedFilesSize', { size: formatBytes(maxSize) });
			} else if (maxSize) {
				return t('common.uploader.tooltips.manyFilesSize', {
					size: formatBytes(maxSize),
					files: maxFiles,
				});
			}
		};

		const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
			onDrop,
			multiple: isMultiple,
			accept: fileTypes,
			maxFiles: maxFiles || 0,
			maxSize: maxSize || Infinity,
		});

		const removeFile = (fileToDelete: UploadedFile) => {
			setFiles(files => files.filter(file => file !== fileToDelete));
		};

		return (
			<StyledDropzoneContainer $disabled={disabled} className={className + 'asdasddasd'}>
				{title ? (
					<label title={title} className={disabled ? 'disabled' : ''}>
						{title}
						{mandatory && <span>*</span>}
					</label>
				) : null}
				{isMultiple || files.length === 0 ? (
					<StyledDropzone
						$hasTitle={!!title}
						$disabled={disabled}
						className={`${isDragActive ? 'active' : ''}`}
						{...(!disabled ? getRootProps() : {})}>
						<DropzoneIcon />
						{!disabled && <input {...getInputProps()} />}
						<div className="dropzone-text">
							<Trans i18nKey="common.uploader.dragAndDrop">
								Drag & Drop or <MarkedText>Choose</MarkedText> a file to upload
							</Trans>
							<p>
								{Object.values(fileTypes)
									.flat()
									.map(ext => ext.replace('.', ''))
									.join(', ')}
							</p>
							<p>{generateTooltip()}</p>
							{fileRejections.map((file, index) => (
								<React.Fragment key={index}>
									{file.errors.some(err => err.code === 'file-too-large') && (
										<p className="dropzone-error">
											{t('common.uploader.errors.fileIsTooLarge', { name: file.file.name })}
										</p>
									)}
								</React.Fragment>
							))}
						</div>
					</StyledDropzone>
				) : (
					<DroppedFiles
						hasTitle={!!title}
						disabled={disabled}
						singleFile
						files={files}
						onFileDelete={removeFile}
					/>
				)}
				{isMultiple ? (
					<DroppedFiles
						disabled={disabled}
						files={files}
						onFileDelete={removeFile}
						customFilesView={customFilesView}
					/>
				) : null}
			</StyledDropzoneContainer>
		);
	}
);
