import { observer } from 'mobx-react';
import React from 'react';

import { getModalService } from '@asd-stan/shell/infrastructure/getters';

import { StyledModalRoot } from '@asd-stan/shell/components/modal/modal-root.styled';

export const Modal: React.FC = observer(() => {
	const modalService = getModalService();

	return (
		<>{modalService.modal?.open && <StyledModalRoot>{modalService.modal.body}</StyledModalRoot>}</>
	);
});
