import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	BallotListFilter,
	BallotListItem,
	BallotResult,
	BallotStatus,
} from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { useRequestWithPagination } from '@asd-stan/helpers/use-request-with-pagination';
import { ReactComponent as ExportIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/export-icon.svg';
import { ReactComponent as Plus } from '@assets/asd-stan-works/icons/plus-icon.svg';
import { Button } from '@components/button/button';
import { ContentTable } from '@components/content-table/content-table';
import { TableControl } from '@components/content-table/table-control/table-control';
import { PageTitle } from '@components/page-title/page-title';
import { FilterCheckboxGroup } from '@components/table-filter/filter-checkbox-group/filter-checkbox-group';
import { FilterDateRange } from '@components/table-filter/filter-date-range/filter-date-range';
import { Flex } from '@components/utility/flex';
import moment from 'moment';

import { Chip, ChipType, StyledBallotList } from './ballot-list.styled';

import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

export const transformDate = (date: string) => moment(date).format('DD/MM/YYYY');

export const ballotResultChipMap: Record<BallotResult, { labelKey: string; chipType: ChipType }> = {
	[BallotResult.Approved]: { labelKey: 'approved', chipType: ChipType.Success },
	[BallotResult.Disapproved]: { labelKey: 'disapproved', chipType: ChipType.Error },
	[BallotResult.Pending]: { labelKey: 'pending', chipType: ChipType.Warning },
	[BallotResult.NotValid]: { labelKey: 'notValid', chipType: ChipType.Default },
};

export const ballotStatusChipMap: Record<BallotStatus, { labelKey: string; chipType: ChipType }> = {
	[BallotStatus.Active]: { labelKey: 'active', chipType: ChipType.Success },
	[BallotStatus.Pending]: { labelKey: 'pending', chipType: ChipType.Warning },
	[BallotStatus.Closed]: { labelKey: 'closed', chipType: ChipType.Default },
};

export const BallotList = observer(
	({
		ballotList,
	}: {
		ballotList: PaginationCollectionController<BallotListItem, BallotListFilter>;
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();

		const columns: ColumnDef<BallotListItem, string>[] = [
			{
				header: t('ballot.ballotList.openingDate'),
				accessorKey: 'openingDate',
				cell: ({ row }: CellContext<BallotListItem, string>) => (
					<div className="date">{transformDate(row.getValue('openingDate'))}</div>
				),
			},
			{
				header: t('ballot.ballotList.closingDate'),
				accessorKey: 'closingDate',
				cell: ({ row }: CellContext<BallotListItem, string>) => (
					<div className="date">{transformDate(row.getValue('closingDate'))}</div>
				),
			},
			{
				header: t('ballot.ballotList.titleColumn'),
				accessorKey: 'title',
				cell: ({ row }: CellContext<BallotListItem, string>) => (
					<div className="titleCell">{row.getValue('title')}</div>
				),
			},
			{
				header: t('ballot.ballotList.status'),
				accessorKey: 'status',
				cell: ({ row }: CellContext<BallotListItem, string>) => {
					const value: BallotStatus = row.getValue('status');
					const { chipType, labelKey } = ballotStatusChipMap[value];
					return <Chip $type={chipType}>{t(`ballot.ballotStatus.${labelKey}`)}</Chip>;
				},
			},
			{
				header: t('ballot.ballotList.result'),
				accessorKey: 'result',
				cell: ({ row }: CellContext<BallotListItem, string>) => {
					const value: BallotResult = row.getValue('result');
					const { chipType, labelKey } = ballotResultChipMap[value];
					return <Chip $type={chipType}>{t(`ballot.ballotResult.${labelKey}`)}</Chip>;
				},
			},
		];

		return (
			<ContentTable
				tableData={[...ballotList.data]}
				columns={columns}
				filtering=""
				setFiltering={() => {}}
				paginator={ballotList}
				ableToSortFirstColumn
				onRowClick={(row: Row<any>) => navigate(`/ballots/${row.original.id}/general`)}
				emptySearchText={t('ballot.ballotList.emptySearchText')}
			/>
		);
	}
);

export const ballotFilterEmptyValues: BallotListFilter = {
	openingDate: {
		from: null,
		to: null,
	},
	results: {
		approved: false,
		disapproved: false,
		pending: false,
		notValid: false,
	},
};

const filterResultCheckboxes = [
	{ label: 'Pending', name: 'results.pending' },
	{ label: 'Approved', name: 'results.approved' },
	{ label: 'Disapproved', name: 'results.disapproved' },
	{ label: 'Not Valid', name: 'results.notValid' },
];

export const BallotFilteringForm = () => (
	<>
		<FilterDateRange name="openingDate" title="Opening date" />
		<FilterCheckboxGroup title="Result" checkboxes={filterResultCheckboxes} />
	</>
);

export const BallotListPage = observer(() => {
	const { t } = useTranslation();
	const ballotService = getBallotService();
	const navigate = useNavigate();
	const ballotList = useRequestWithPagination(20, ballotService.getBallotList.bind(ballotService));

	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		ballotList.setPageSize(parseInt(e.target.value));
	};

	const ableToCreate = ballotService.checkIfCurrentUserIsAbleToCreateBallot();

	return (
		<StyledBallotList>
			<ContentContainer>
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={t('ballot.ballotList.title')}
						count={ballotList.totalNumber}
						countName={t('ballot.ballotList.count')}
					/>
					<StyledButtonGroup>
						{/* <Button
							secondary
							icon={<ExportIcon />}
							disabled
							title={t('ballot.ballotList.export')}
							onClick={() => {}}
						/> */}
						{ableToCreate && (
							<Button
								icon={<Plus fill="#fff" />}
								title={t('ballot.ballotList.addNew')}
								onClick={() => navigate('create')}
							/>
						)}
					</StyledButtonGroup>
				</Flex>
				<TableControl
					paginator={ballotList}
					pageSize={ballotList.pageSize}
					onPageSizeChange={onPageSizeChange}
					minSearchLength={2}
					filterValues={ballotList.filter}
					filterEmptyValues={ballotFilterEmptyValues}
					onFilterSubmit={ballotList.addFilter}
					filterForm={<BallotFilteringForm />}
				/>
				<BallotList ballotList={ballotList} />
			</ContentContainer>
		</StyledBallotList>
	);
});
