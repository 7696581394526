import { CellContext, ColumnDef } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { getFormattedPrice } from '@asd-stan/common/currency.mapper';
import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { userHasRoles } from '@asd-stan/helpers/app-utils';
import { getModalService } from '@asd-stan/shell/infrastructure/getters';
import { CatalogListTable } from '@asd-stan/standard/components/catalog-list/catalog-list-table';
import { EditCatalogItem } from '@asd-stan/standard/components/catalog-list/modals/edit-catalog-item';
import { CatalogItem } from '@asd-stan/standard/domain/catalog-item.entity';
import { getCatalogService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as ExportIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/export-icon.svg';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { ReactComponent as Plus } from '@assets/asd-stan-works/icons/plus-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-pencil.svg';
import { Button } from '@components/button/button';
import { TableControl } from '@components/content-table/table-control/table-control';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';

import { ReactComponent as LinkIcon } from './assets/link.svg';

import {
	StyledCatalogList,
	StyledLink,
} from '@asd-stan/standard/components/catalog-list/catalog-list.styled';
import { StatusCell } from '@asd-stan/standard/components/catalog-list/table-cells/status-cell.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';
import { StyledAccentButton } from '@components/button/button.styled';
import { StyledButtonGroup } from '@components/utility/button-group.styled';

export const CatalogList: React.FC = observer(() => {
	const [changingStatus, setChangingStatus] = useState<number | null>(null);
	const catalogService = getCatalogService();
	const currentUserService = getCurrentUserService();
	const modalService = getModalService();
	const navigate = useNavigate();
	const { t } = useTranslation();

	let userCanEdit: boolean = userHasRoles(
		[SystemRole.DIRECTOR, SystemRole.ES],
		currentUserService.userRoles!
	);

	const togglePublished = async (status: boolean, id: number) => {
		setChangingStatus(id);
		await catalogService.updateCatalogItemShow(id, !status);
		await catalogService.catalog.loadCurrentPage();
		setChangingStatus(null);
	};

	const getStatusColumn = () => {
		const renderStatus = (status: boolean, id: number) => {
			const parsedStatus = status ? 'published' : 'unpublished';
			if (userCanEdit) {
				return (
					<>
						{changingStatus === id ? (
							<Loading width={26} height={26} />
						) : (
							<StatusCell
								className={status ? 'published' : 'unpublished'}
								$isPublished={status}
								defaultValue={parsedStatus}
								onChange={() => togglePublished(status, id)}
								onClick={e => {
									e.stopPropagation();
								}}>
								<option className={'published'} value="published">
									{t('standard.catalog.statusPublished')}
								</option>
								<option className={'unpublished'} value="unpublished">
									{t('standard.catalog.statusUnpublished')}
								</option>
							</StatusCell>
						)}
					</>
				);
			} else {
				return (
					<StatusCell
						className={status ? 'published' : 'unpublished'}
						disabled
						$isPublished={status}>
						<option value={parsedStatus}>{parsedStatus}</option>
					</StatusCell>
				);
			}
		};

		return {
			header: t('standard.catalog.itemStatus'),
			accessorKey: 'published',
			cell: ({ row }: CellContext<CatalogItem, string>) => {
				const status: boolean = row.getValue('published');
				const { id } = row.original;
				return renderStatus(status, id);
			},
		};
	};

	const getLastColumn = () => {
		const renderEditButton = (item: CatalogItem) => {
			return (
				<div className="action-buttons-container">
					<StyledAccentButton $iconButton>
						<button
							className="continue-edit-button"
							onClick={e => {
								e.stopPropagation();
								modalService.openModal(<EditCatalogItem item={item} />);
							}}>
							{<EditIcon />}
						</button>
					</StyledAccentButton>
				</div>
			);
		};

		return {
			header: t('standard.catalog.itemAction'),
			accessorKey: 'action',
			enableSorting: false,
			cell: ({ row }: CellContext<CatalogItem, string>) => {
				const item = row.original;
				return (
					<Flex className="actions">
						{!!row.original.standardId && (
							<StyledLink
								as={Link}
								to={`/standards/detailed/${row.original.standardId}/general`}
								target="_blank"
								onClick={e => {
									e.stopPropagation();
								}}>
								{t('standard.catalog.linkToStandard')} <LinkIcon />
							</StyledLink>
						)}
						{userCanEdit && renderEditButton(item)}
					</Flex>
				);
			},
		};
	};

	const columns = [
		{
			header: t('standard.catalog.itemName'),
			accessorKey: 'name',
		},
		{
			header: t('standard.catalog.itemTitle'),
			accessorKey: 'localizedTitle',
			className: 'title',
			cell: ({ row }: CellContext<CatalogItem, string>) => (
				<div>{row.getValue('localizedTitle')}</div>
			),
		},
		{
			header: t('standard.catalog.itemPrice'),
			accessorKey: 'price',
			className: 'price',
			cell: ({ row }: CellContext<CatalogItem, string>) => (
				<div>{getFormattedPrice(row.original.price)}</div>
			),
		},
		{
			header: t('standard.catalog.itemPublished'),
			accessorKey: 'publicationDate',
			className: 'published',
			cell: ({ row }: CellContext<CatalogItem, string>) => {
				const rawDate: string = row.getValue('publicationDate');
				const dateObject = new Date(rawDate);

				const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
				const year = dateObject.getUTCFullYear();

				const formattedDate = `${month}/${year}`;

				return <div>{formattedDate}</div>;
			},
		},
		getStatusColumn(),
		getLastColumn(),
	].filter(Boolean) as ColumnDef<CatalogItem, string>[];

	useEffect(() => {
		catalogService.catalog.resetFilter();
	}, []);

	useEffect(() => {
		catalogService.catalog.loadCurrentPage();
	}, [catalogService.catalog.currentPage]);

	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		catalogService.catalog.setPageSize(parseInt(e.target.value));
	};

	const goToAddNew = async () => {
		navigate('/standards/new-standard');
	};

	return (
		<StyledCatalogList>
			<ContentContainer>
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={t('standard.catalog.title')}
						count={catalogService.catalog.totalNumber}
						countName={t('standard.catalog.count')}
					/>
					<StyledButtonGroup>
						<Button
							secondary
							icon={<ExportIcon />}
							disabled
							title={t('standard.catalog.buttons.export')}
							onClick={() => {}}
						/>
						{userHasRoles(
							[SystemRole.ADMIN, SystemRole.ES, SystemRole.DIRECTOR],
							currentUserService.userRoles!
						) && (
							<Button
								icon={<Plus />}
								title={t('standard.catalog.buttons.new')}
								onClick={goToAddNew}
							/>
						)}
					</StyledButtonGroup>
				</Flex>
				<TableControl
					paginator={catalogService.catalog}
					pageSize={catalogService.catalog.pageSize}
					onPageSizeChange={onPageSizeChange}
					minSearchLength={2}
				/>
				<CatalogListTable
					tableData={[...catalogService.catalog.data]}
					columns={columns}
					paginator={catalogService.catalog}
					emptySearchText={t('user.userList.emptySearchText')}
				/>
			</ContentContainer>
		</StyledCatalogList>
	);
});
