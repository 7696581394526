import React, { useState } from 'react';
import DatePickerLib, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { generatePlaceholder, getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { ErrorMessage, FieldProps } from 'formik';

import { ReactComponent as Calendar } from './assets/calendar.svg';
import { StyledDatePicker } from './date-picker.styled';

import { StyledFieldErrorMessage } from '@components/utility/field-error-message.styled';

interface DatePickerProps extends FieldProps {
	onDateChange?: (date: Date | null) => void;
	placeholder?: string;
	mandatory?: boolean;
	title: string;
	fullWidth?: boolean;
	disabled?: boolean;
	date?: Date;
}

export const DatePicker: React.FC<DatePickerProps & ReactDatePickerProps> = ({
	onDateChange,
	placeholder,
	mandatory,
	field,
	form,
	title,
	fullWidth,
	disabled,
	date,
	...rest
}) => {
	const [startDate, setStartDate] = useState<Date | null>(field.value || date || new Date());
	const onInputDateChange = (date: Date) => {
		form.setFieldValue(field.name, date);
		setStartDate(date);

		if (onDateChange) {
			onDateChange(date);
		}
	};

	return (
		<StyledDatePicker
			$error={
				!!(
					getValueByFieldName(form.touched, field.name) &&
					getValueByFieldName(form.errors, field.name)
				)
			}
			$fullWidth={fullWidth}
			$disabled={disabled}>
			<label title={title} className={disabled ? 'disabled' : ''}>
				{title}
				{mandatory && <span>*</span>}
			</label>
			<DatePickerLib
				{...rest}
				selected={startDate}
				showIcon
				maxDate={new Date()}
				showPopperArrow={false}
				onChange={onInputDateChange}
				placeholderText={generatePlaceholder(title, placeholder, 'date')}
				icon={<Calendar />}
			/>
			<ErrorMessage name={field.name} component={StyledFieldErrorMessage} />
		</StyledDatePicker>
	);
};