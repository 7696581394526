import { inputStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const StyledDatePicker = styled.div<{
	$error: boolean;
	$fullWidth?: boolean;
	$disabled?: boolean;
}>`
  ${inputStyles};
  margin-top: 20px;

  border-radius: 8px;
  background-color: ${({theme}) => theme.fontColors.white};
  color: ${({theme}) => theme.fontColors.primary};

  & > .react-datepicker-wrapper {
    margin-top: 8px;
    padding: 12px 16px;
    height: 42px;
    border: 1px solid #11396f1a;
    width: 100%;
    border-radius: 8px;
    border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};

    & .react-datepicker__input-container {

			svg {
				padding: 0;
				top: 0;
				right: 0;
			}
    }

    & .react-datepicker__view-calendar-icon input {
			width: 100%;
      padding: 0;
      color: ${({theme}) => theme.fontColors.primary};
			
      &::placeholder {
        color: ${({theme}) => theme.fontColors.lightGrey};
      }
    }
  }

  @media (min-width: 1500px) {
    width: ${(props) => props.$fullWidth ? '100%' : '49%'};
  }
`;
