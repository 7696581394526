import styled from 'styled-components';

export const StyledPatentFile = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	.patent-title {
		color: ${({ theme }) => theme.fontColors.lightGrey};
		margin: 12px 0px;
	}

	i {
		color: ${({ theme }) => theme.fontColors.accent};
	}

	> div:first-child {
		margin-top: 12px;
	}
`;
