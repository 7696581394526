import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { SingleUser, mapUserToDto } from '@asd-stan/user/api/single-user.mapper';

import { mapMe } from './me.mapper';

const GET_ME = gql`
	query {
		me {
			id
			firstName
			lastName
			phone
			email
			systemRoles
			company {
				id
				name
			}
			country {
				id
				name
			}
		}
	}
`;

const GET_DOMAIN_PARTICIPATIONS_BY_ID = gql`
	query ($id: Int!) {
		domainParticipationsByUserId(userId: $id) {
			domain {
				code
				name
			}
			workingGroupParticipations {
				workingGroup {
					code
					name
				}
				roles
			}
			roles
			seeAllDomain
		}
	}
`;

const UPDATE_ME = gql`
	mutation (
		$user: UserInput!
		$domainParticipations: [DomainParticipationInput!]!
		$workingGroupParticipations: [WorkingGroupParticipationInput!]!
	) {
		updateMe(
			user: $user
			domainParticipations: $domainParticipations
			workingGroupParticipations: $workingGroupParticipations
		) {
			id
		}
	}
`;

const GET_USER_BY_ID = gql`
	query ($id: Int!) {
		domainParticipationsByUserId(userId: $id) {
			domain {
				code
				name
			}
			workingGroupParticipations {
				workingGroup {
					code
					name
				}
				roles
			}
			roles
			seeAllDomain
		}

		me {
			access
			id
			firstName
			lastName
			email
			phone
			systemRoles
			appointedBy {
				id
				name
			}
			company {
				id
				name
			}
			country {
				id
				name
			}
			positions {
				id
				name
			}
		}
	}
`;

export class CurrentUserRepo {
	async getMe() {
		const { data } = await stanWorksClient.query({
			query: GET_ME,
		});

		const { data: participations } = await stanWorksClient.query({
			query: GET_DOMAIN_PARTICIPATIONS_BY_ID,
			variables: {
				id: data.me.id,
			},
		});

		return { user: data.me, participations: participations.domainParticipationsByUserId };
	}

	async getUser(id: number) {
		const { data } = await stanWorksClient.query({
			query: GET_USER_BY_ID,
			variables: {
				id,
			},
		});
		return mapMe(data);
	}

	async updateMe(user: SingleUser) {
		const variables = mapUserToDto(user);
		const { errors } = await stanWorksClient.mutate({
			mutation: UPDATE_ME,
			variables,
		});
		if (!errors) {
			return;
		}
		const errorMessage = errors[0].message;
		throw new Error(errorMessage);
	}
}
