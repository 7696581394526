import { styled } from 'styled-components';

export const WGSContainer = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.fontColors.darkGrey};

	p {
		font-size: 14px;
	}

	> * {
		margin-right: 10px;
	}

	span {
		padding: 4px 6px;
		font-weight: 600;
		font-size: 12px;
		background: rgba(218, 228, 232, 0.4);
		border-radius: 4px;
	}
`;
