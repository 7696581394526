import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
import { Participation } from '@asd-stan/current-user/domain/current-user.service';
import { Domain } from '@asd-stan/domain/domain/domain.entity';
import { DraftDetailed } from '@asd-stan/draft/domain/draft-detailed.entity';
import {
	StandardDetailed,
	StandardDomain,
} from '@asd-stan/standard/domain/standard-detailed.entity';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { Option } from '@components/form-select/form-select';
import { FormikValues } from 'formik';
import i18n from 'i18next';
import moment from 'moment';

// invite user utils

export const isRoleAllowToSeeDomain = (values: FormikValues) => {
	return values.systemRoles.some(
		(option: Option) =>
			option.value === 'ES' ||
			option.value === 'Expert' ||
			option.value === 'MC' ||
			option.value === 'Board member'
	);
};

// input utils

export const getValueByFieldName = (target: any, fieldName: string) => {
	if (fieldName?.indexOf('.') !== -1) {
		const parts = fieldName?.split('.');
		if (target[parts[0]] && target[parts[0]].length > 0 && parts.length === 3) {
			if (target[parts[0]][parseInt(parts[1])] !== undefined) {
				return target[parts[0]][parseInt(parts[1])][parts[2]];
			}
		} else {
			if (parts.length === 2) {
				if (target[parts[0]] !== undefined) {
					return target[parts[0]][parts[1]];
				}
			} else return undefined;
		}
	} else {
		return target[fieldName];
	}
};

export const generatePlaceholder = (
	title: string | undefined,
	placeholder: string | undefined,
	fieldType: 'input' | 'select' | 'date' = 'input'
): string | undefined => {
	if (!title && !placeholder) {
		return undefined;
	}
	const typeMap = {
		input: 'common.inputPlaceholder',
		select: 'common.selectPlaceholder',
		date: 'common.datePickerPlaceholder',
	};

	if (!placeholder && title) {
		return i18n.t(typeMap[fieldType], {
			name: title.toLowerCase().trim(),
		});
	}

	return placeholder;
};

//user utils

const hasDomainParticipations = (domainParticipations: Participation[]) => {
	return domainParticipations.length > 0;
};

export const userHasDomainRoles = (
	roles: string | string[],
	domainParticipations: Participation[]
) => {
	return domainParticipations.some(participation => {
		return participation.roles && participation.roles.some(role => roles.includes(role));
	});
};

export const userHasWGRoles = (roles: string | string[], domainParticipations: Participation[]) => {
	return domainParticipations.some(domainParticipation => {
		return (
			domainParticipation.workingGroupParticipations &&
			domainParticipation.workingGroupParticipations.some(wgParticipation => {
				return wgParticipation.roles && wgParticipation.roles.some(role => roles.includes(role));
			})
		);
	});
};

export const userHasRoles = (roles: string | string[], userRoles: string[]): boolean => {
	return userRoles?.some(role => roles.includes(role));
};

export const userLacksRole = (roles: SystemRole[], userRoles: SystemRole[]): boolean => {
	return userRoles?.some(role => !roles.includes(role));
};

export const userHasRolesAndParticipatesInDomain = (
	roles: string[],
	userRoles: string[],
	rolesShouldParticipateInDomain: string[],
	domainParticipations: Participation[]
) => {
	if (userRoles.some(role => rolesShouldParticipateInDomain.includes(role))) {
		return hasDomainParticipations(domainParticipations);
	}

	return userHasRoles(roles, userRoles);
};

export const hasOnlyExpertRole = (userRoles: SystemRole[]) => {
	return userRoles.length === 1 && userRoles[0] === SystemRole.EXPERT;
};

//standard utils

export const isUserParticipatingInStandardDomain = (
	standardDomains: StandardDomain[][],
	userDomainParticipations: Participation[]
) => {
	const standardDomainSet = new Set(
		standardDomains.flat().map((domain: Partial<Domain>) => domain.code)
	);

	const userParticipations = userDomainParticipations.map(
		(participation: Participation) => participation.domain.code
	);

	return userParticipations.some((userDomain: string) => standardDomainSet.has(userDomain));
};

export const isOriginatorInStandard = (
	standard: StandardDetailed | DraftDetailed | number | null,
	user: CurrentUser | null
) => {
	if (typeof standard === 'number') {
		return standard === user?.id;
	} else {
		return standard?.originator?.userId === user?.id;
	}
};

//request utils

let refreshRequest: null | Promise<void> = null;

export const checkExpire = async () => {
	const currentTime = moment();
	const authService = getAuthService();
	const { accessToken, expiresAt, refreshToken }: Storage = localStorage;

	try {
		if (refreshRequest) {
			await refreshRequest;
			return;
		}

		if (accessToken) {
			const expiresAtMoment = moment(JSON.parse(expiresAt));
			if (currentTime.isBefore(expiresAtMoment)) {
				return;
			}
			refreshRequest = authService.refreshToken(refreshToken);
			await refreshRequest;
			refreshRequest = null;
		}
	} catch (error) {
		await authService.handleLogout();
		refreshRequest = null;
	}
};

export const calculateOffset = (page: number, limit: number) => {
	return (page - 1) * limit;
};
