import { FC } from 'react';

import { AttachmentResponse } from '@asd-stan/standard/domain/standard-detailed.entity';
import { ReactComponent as FileIcon } from '@asd-stan/ui-kit/assets/icons/uploaded-file-icon.svg';

interface Props {
	attachment: AttachmentResponse;
	path?: string;
}

enum AttachmentFormat {
	JPEG = 'image/jpg',
	JPG = 'image/jpeg',
	SVG = 'image/svg',
	PNG = 'image/png',
	BPM = 'image/bmp',
	DWG = 'image/vnd.dwg',
	TIFF = 'image/tiff',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	DOC = 'application/msword',
	XML = 'application/xml',
	XLS = 'application/vnd.ms-excel',
	CSV = 'text/csv',
	PDF = 'application/pdf',
}

function mapFormatToIcon(format: AttachmentFormat, path: string) {
	let component: JSX.Element = <></>;

	switch (format) {
		case AttachmentFormat.JPEG:
		case AttachmentFormat.JPG:
		case AttachmentFormat.SVG:
		case AttachmentFormat.BPM:
		case AttachmentFormat.DWG:
		case AttachmentFormat.PNG:
		case AttachmentFormat.TIFF:
			component = (
				<img
					style={{ width: 40, height: 40 }}
					src={path}
					referrerPolicy="no-referrer"
					alt={path}
				/>
			);
			break;
		default:
			component = <FileIcon width={40} height={40} />;
			break;
	}

	return component;
}

export const AttachmentIcon: FC<Props> = ({ attachment, path }) => {
	return (
		<>
			{mapFormatToIcon(
				attachment.file?.mimeType as AttachmentFormat,
				path || attachment.file?.path
			)}
		</>
	);
};
