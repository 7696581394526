import {
	ColumnSort,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

import {
	PaginationCollectionController,
	PaginationCollectionControllerStatus,
} from '@asd-stan/helpers/pagination-collection-controller';
import { Standard } from '@asd-stan/standard/domain/standard.entity';
import { StandardCollectionFilter } from '@asd-stan/standard/domain/standard.service';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as TableArrow } from '@assets/asd-stan-works/table/table-arrow.svg';
import { ReactComponent as FilterAsc } from '@assets/asd-stan-works/table/table-filter-a-z.svg';
import { ReactComponent as FilterDefault } from '@assets/asd-stan-works/table/table-filter-default.svg';
import { ReactComponent as FilterDesc } from '@assets/asd-stan-works/table/table-filter-z-a.svg';
import { EmptyTableSearch } from '@components/content-table/empty-table-search';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { MirroredIcon } from '@components/utility/mirrored-icon';

import { StyledTitleCell } from '@asd-stan/standard/components/standard-list/table-cells/title-cell.styled';
import {
	StyledTable,
	StyledTableLoader,
	StyledTablePagination,
} from '@components/content-table/table.styled';

interface StandardTableProps {
	columns: any;
	filtering: string;
	setFiltering: Dispatch<SetStateAction<string>>;
	emptySearchText?: string;
	paginator: PaginationCollectionController<Standard, StandardCollectionFilter>;
	tableData: Standard[];
	dashboard?: boolean;
}

export const StandardListTable: React.FC<StandardTableProps> = observer(
	({ filtering, setFiltering, columns, tableData, emptySearchText, paginator, dashboard }) => {
		const [sorting, setSorting] = useState<ColumnSort[]>([]);
		const { t } = useTranslation();
		const navigate = useNavigate();

		const table = useReactTable({
			data: tableData,
			columns: columns,
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			state: {
				sorting: sorting,
				globalFilter: filtering,
			},
			enableRowSelection: true,
			onSortingChange: prevState => setSorting(prevState),
			onGlobalFilterChange: setFiltering,
			manualSorting: true,
		});

		const sortingItem = sorting[0];

		const addSortingToFilter = (sorting: ColumnSort[]) => {
			if (sorting.length > 0) {
				const { id, desc } = sorting[0];

				if (desc) {
					paginator.addFilterOnCurrentPage({ orderField: id, orderDirection: 'DESC' });
				} else {
					paginator.addFilterOnCurrentPage({ orderField: id, orderDirection: 'ASC' });
				}
			} else {
				paginator.addFilterOnCurrentPage({
					orderField: paginator.initialFilter.orderField,
					orderDirection: paginator.initialFilter.orderDirection,
				});
			}
		};

		const handleGoToPage = (pageNumber: number) => {
			return paginator.goToPage(pageNumber);
		};

		const navigateToStandard = (idx: number) => {
			navigate(`/standards/detailed/${tableData[idx].id}/general`);
		};

		useEffect(() => {
			addSortingToFilter(sorting);
		}, [sortingItem?.id, sortingItem?.desc]);

		return (
			<>
				{table.getFilteredRowModel().rows.length > 0 ||
				paginator.status === PaginationCollectionControllerStatus.LOADING ? (
					<>
						{paginator.status === PaginationCollectionControllerStatus.LOADING ? (
							<StyledTableLoader $dashboard={dashboard}>
								<Flex $align="center" $justify="center" $direction="column">
									<LogoIcon />
									<Loading horizontal />
								</Flex>
							</StyledTableLoader>
						) : (
							<StyledTable $dashboard={dashboard}>
								<thead>
									{table.getHeaderGroups().map(headerGroup => (
										<tr key={headerGroup.id}>
											{headerGroup.headers.map((header, index) => (
												<th
													key={header.id}
													className={header.id}
													onClick={header.column.getToggleSortingHandler()}>
													{header.isPlaceholder ? null : (
														<div>
															{flexRender(header.column.columnDef.header, header.getContext())}
															{header.column.getCanSort() &&
																index !== 0 &&
																(header.column.getIsSorted() ? (
																	header.column.getIsSorted() === 'desc' ? (
																		<FilterDesc />
																	) : (
																		<FilterAsc />
																	)
																) : (
																	<FilterDefault />
																))}
														</div>
													)}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody>
									{table.getRowModel().rows.map(row => {
										const standardId = tableData[+row.id]?.id;
										return (
											<tr
												key={row.id}
												style={{ cursor: 'pointer' }}
												onClick={() => navigateToStandard(Number(row.id))}>
												{row.getVisibleCells().map(cell => {
													return cell.column.id === 'localizedTitle' ? (
														<StyledTitleCell className="title" key={cell.id}>
															<div>
																<span>
																	{cell.row.original.isDraft
																		? t('standard.standardList.draftLabel')
																		: null}
																</span>
																{flexRender(cell.column.columnDef.cell, cell.getContext())}
															</div>
														</StyledTitleCell>
													) : (
														//@ts-expect-error
														<td key={cell.id} className={cell.column.columnDef.className || ''}>
															{flexRender(cell.column.columnDef.cell, {
																...cell.getContext(),
																standardId,
																originator: tableData[+row.id]?.originatorId,
															})}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</StyledTable>
						)}
						{paginator &&
						paginator.pageCount !== 1 &&
						!filtering &&
						paginator.status !== PaginationCollectionControllerStatus.LOADING ? (
							<StyledTablePagination>
								<ReactPaginate
									disabledClassName="disabled"
									pageCount={paginator.pageCount}
									pageRangeDisplayed={3}
									marginPagesDisplayed={1}
									previousLabel={
										<MirroredIcon>
											<TableArrow />
										</MirroredIcon>
									}
									nextLabel={<TableArrow />}
									onPageChange={({ selected }) => handleGoToPage(selected)}
									containerClassName="pagination"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakClassName="page-item break-button"
									breakLinkClassName="page-link"
									activeClassName="active"
									forcePage={paginator.currentPage}
								/>
							</StyledTablePagination>
						) : null}
					</>
				) : (
					<EmptyTableSearch emptySearchText={emptySearchText} />
				)}
			</>
		);
	}
);
