import { makeAppointerFromAppointerDTO } from '@asd-stan/user/api/appointer/appointer.factory';
import { Appointer } from '@asd-stan/user/domain/appointer.entity';
import { getAppointerRepo } from '@asd-stan/user/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

export class AppointerService {
	private _appointers: Appointer[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	private get _appointersRepo() {
		return getAppointerRepo();
	}

	public async getAppointers() {
		const appointers = await this._appointersRepo.getAppointers();

		runInAction(() => {
			this._appointers = appointers;
		});
	}

	public async createAppointer(name: string): Promise<Appointer> {
		const response = await this._appointersRepo.createAppointer(name);
		const newAppointer = makeAppointerFromAppointerDTO(response);

		runInAction(() => {
			const newAppointer = makeAppointerFromAppointerDTO(response);
			this._appointers = [...this._appointers, newAppointer];
		});

		return newAppointer;
	}

	get appointers() {
		return this._appointers;
	}
}
