import styled from 'styled-components';

export const StyledDropzoneContainer = styled.div<{ $disabled?: boolean }>`
	flex-grow: 1;
	margin-top: 16px;

	& > label {
		color: ${({ theme }) => theme.fontColors.darkGrey};
	}

	.remove-file-btn {
		display: flex;
		justify-content: flex-end;
	}
`;

export const StyledDropzone = styled.div<{ $disabled?: boolean; $hasTitle?: boolean }>`
	text-align: center;
	margin-top: ${props => (props.$hasTitle ? '8px' : '16px')};
	padding: 32px;
	border-radius: 8px;
	width: 100%;
	height: 198px;
	border: ${({ theme }) => theme.borders.fileUploader};
	background-color: ${props =>
		props.$disabled ? props.theme.bgColors.primary : props.theme.bgColors.fileUploader};
	cursor: ${props => (props.$disabled ? 'auto' : 'pointer')};

	path {
		fill: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.accent};
	}

	span {
		color: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.accent};
	}

	& .dropzone-text {
		margin: 12px 0;
		color: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.darkGrey};
		font-size: 13px;
		font-weight: 500;

		& > p {
			margin-top: 12px;
			color: ${({ theme }) => theme.fontColors.lightGrey};
		}

		.dropzone-error {
			margin: 12px auto;
			border-radius: 4px;
			width: fit-content;
			padding: 4px 16px;
			background-color: rgba(234, 62, 83, 0.08);
			color: ${({ theme }) => theme.fontColors.error};
			word-break: break-word;
		}
	}

	&.active {
		border: ${({ theme }) => theme.borders.buttonBorder};
		background: linear-gradient(0deg, rgba(11, 147, 227, 0.08) 0%, rgba(11, 147, 227, 0.08) 100%),
			#f7fafb;
	}
`;

export const StyledDroopedFiles = styled.ul<{ $singleFile?: boolean; $hasTitle?: boolean }>`
	width: 100%;
	margin-top: ${({ $hasTitle }) => ($hasTitle ? '8px' : '0')};

	.file-item:first-child {
		.file-list {
			border-top: none;
		}
	}

	& .file-list {
		position: relative;
		padding: ${props => (props.$singleFile ? '0' : '16px 0')};
		border-top: ${props => (props.$singleFile ? 'none' : props.theme.borders.divider)};

		& .file-container {
			display: flex;
			align-items: center;
			border-radius: 8px;
			padding: 12px 16px;
			background-color: ${({ theme }) => theme.bgColors.fileUploader};
			border: 1px solid #dae4e8;

			.file-icon {
				flex-shrink: 0;
			}

			& div:nth-child(even) {
				width: 100%;
				margin-left: 16px;
			}

			& div:nth-child(odd):not(.loader-spinner, .loader) {
				display: flex;
				align-items: center;
			}

			& .file-name {
				color: ${({ theme }) => theme.fontColors.darkGrey};
				font-size: 16px;
				font-weight: 500;
			}

			& .file-properties {
				margin-top: 4px;
				color: ${({ theme }) => theme.fontColors.lightGrey};
				font-size: 11px;
				font-weight: 400;
			}
		}

		& .loader {
			position: absolute;
			right: 60px;

			& > div {
				height: 100%;
				width: 100%;
			}
		}
	}
`;
