import { WorkingGroup } from '@asd-stan/working-group/domain/working-group.entity';
import { makeAutoObservable } from 'mobx';

interface DomainArgs {
	code: string;
	name: string;
	workingGroups: WorkingGroup[];
}

export class Domain {
	private _name: string;
	private _code: string;
	private _workingGroups: WorkingGroup[];

	constructor(args: DomainArgs) {
		makeAutoObservable(this);

		this._name = args.name;
		this._code = args.code;
		this._workingGroups = args.workingGroups;
	}

	//getters

	get code() {
		return this._code;
	}

	get name() {
		return this._name;
	}

	get workingGroups() {
		return this._workingGroups;
	}

	// getters end
}
