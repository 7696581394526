import { useTranslation } from 'react-i18next';

import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInput } from '@components/form-input/form-input';
import { FormRadio } from '@components/form-radio/form-radio';
import { Field } from 'formik';

import { StyledPublish } from './publish.styled';

export const Publish = ({ hasFonto }: { hasFonto: boolean }) => {
	const { t } = useTranslation();

	return (
		<StyledPublish>
			{hasFonto && (
				<div className="radioSection">
					<div className="radio">
						<Field
							type="radio"
							name="publicationData.source"
							component={FormRadio}
							label={t('standard.singleStandard.stageUpdateModal.publish.draft')}
							value="draft"
						/>
					</div>
					<div className="radio">
						<Field
							disabled
							type="radio"
							name="publicationData.source"
							component={FormRadio}
							label={t('standard.singleStandard.stageUpdateModal.publish.fonto')}
							value="fonto"
						/>
					</div>
				</div>
			)}
			<Field
				component={FormInput}
				title={t('standard.singleStandard.stageUpdateModal.publish.name')}
				name="publicationData.name"
				mandatory
				maxLength={200}
				maxLengthWithError
				fullWidth
			/>
			<Field
				component={FormDatePicker}
				label={t('standard.singleStandard.stageUpdateModal.publish.publicationDate')}
				mandatory
				monthYearPicker
				maxDate={new Date()}
				placeholder="Choose publication date"
				name="publicationData.publicationDate"
			/>
			<Field
				component={FormInputParagraph}
				title={t('standard.singleStandard.stageUpdateModal.publish.abstract')}
				name="publicationData.abstract"
				maxLength={5000}
			/>
		</StyledPublish>
	);
};
