import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import {
	CreatedPositionDTO,
	PositionDTO,
	PositionListDTO,
} from '@asd-stan/user/api/position/position.dto';
import { makePositionFromPositionDTO } from '@asd-stan/user/api/position/position.factory';
import { Position } from '@asd-stan/user/domain/position.entity';

const ALL_POSITIONS = gql`
	query {
		allPositions {
			id
			name
		}
	}
`;

const CREATE_POSITION = gql`
	mutation ($name: String!) {
		createPosition(name: $name) {
			id
			name
		}
	}
`;

export class PositionRepo {
	async getPositions(): Promise<Position[]> {
		const { data } = await stanWorksClient.query<PositionListDTO>({
			query: ALL_POSITIONS,
		});

		return data.allPositions.map(dto => makePositionFromPositionDTO(dto));
	}

	async createPosition(name: string): Promise<PositionDTO> {
		const { data } = await stanWorksClient.mutate<CreatedPositionDTO>({
			mutation: CREATE_POSITION,
			variables: {
				name: name,
			},
		});

		return { id: data?.createPosition.id, name: data?.createPosition.name } as Position;
	}
}
