import { observer } from 'mobx-react';
import { useState } from 'react';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import { RemarkContainer } from '@asd-stan/standard/components/remark-textarea/remark-container';
import { RemarkTextarea } from '@asd-stan/standard/components/remark-textarea/remark-textarea';
import { FormikValues, useFormikContext } from 'formik';

export const DraftRemarks: React.FC = observer(() => {
	const draftService = getDraftService();
	const currentUserService = getCurrentUserService();

	const [isNeedClearValue, setIsNeedClearValue] = useState<boolean>(false);

	const { values, errors, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();

	const handleSendEvent = async () => {
		if (errors.remark || !values.remark) {
			return;
		}

		setIsNeedClearValue(true);

		const currentDate = new Date();

		const day = String(currentDate.getDate()).padStart(2, '0');
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const year = String(currentDate.getFullYear());

		const formattedDate = `${day}/${month}/${year}`;

		await draftService.addDraftRemark({
			name: `${currentUserService.currentUser!.firstName} ${
				currentUserService.currentUser!.lastName
			}`,
			text: values.remark,
			date: formattedDate,
			stageUpdate: null,
		});
		setIsNeedClearValue(false);
		await setFieldValue('remark', '');
		handleSubmit();
	};

	return (
		<>
			{(draftService.remarks === null || draftService.remarks?.length === 0) &&
				(draftService.singleDraft === null || draftService.singleDraft?.remarks?.length === 0) && (
					<RemarkContainer remark={null} removeEvent={null} />
				)}
			{draftService.singleDraft?.remarks
				? draftService.singleDraft?.remarks?.map((remark, index) => (
						<RemarkContainer
							key={index}
							remark={remark}
							fromSingleStandard={!remark.name}
							removeEvent={async () => {
								await draftService.removeDraftRemark(index);
								handleSubmit();
							}}
						/>
				  ))
				: draftService.remarks?.map((remark, index) => (
						<RemarkContainer
							key={index}
							remark={remark}
							fromSingleStandard={!remark.name}
							removeEvent={async () => {
								await draftService.removeDraftRemark(index);
								handleSubmit();
							}}
						/>
				  ))}
			<RemarkTextarea isNeedClearValue={isNeedClearValue} sendEvent={handleSendEvent} />
		</>
	);
});
