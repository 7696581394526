export const roles = [
	{ value: 'TAC', label: 'TAC' },
	{ value: 'MC', label: 'MC' },
	{ value: 'ES', label: 'ES' },
	{ value: 'Expert', label: 'Expert' },
	{ value: 'Board member', label: 'Board member' },
];

export const domainRoles = [
	{ value: 'Expert', label: 'Expert' },
	{ value: 'FP', label: 'FP' },
	{ value: 'DTC', label: 'DTC' },
	{ value: 'Domain Secretary', label: 'Domain Secretary' },
	{ value: 'None', label: 'None' },
];

export const workingGroupRoles = [
	{ value: 'Expert', label: 'Expert' },
	{ value: 'WGS', label: 'WGS' },
	{ value: 'WGC', label: 'WGC' },
];
