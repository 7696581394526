import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptySearch } from '@assets/asd-stan-works/table/empty-search.svg';
import { Flex } from '@components/utility/flex';
import { MarkedText } from '@components/utility/marked-text';

import { StyledEmptyTable } from '@components/content-table/table.styled';

interface EmptyTableSearchProps {
	emptySearchText?: string | JSX.Element;
	style?: React.CSSProperties;
	dashboard?: boolean;
}

export const EmptyTableSearch: React.FC<EmptyTableSearchProps> = ({
	emptySearchText,
	style,
	dashboard,
}) => {
	const { t } = useTranslation();

	return (
		<StyledEmptyTable style={style} $dashboard={dashboard}>
			<Flex $align="center" $direction="column">
				<EmptySearch />
				<p>
					<MarkedText>{t('common.emptySearch')}</MarkedText>
				</p>
				<p>{emptySearchText}</p>
			</Flex>
		</StyledEmptyTable>
	);
};
