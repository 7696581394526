import { scrollStyles } from '@components/utility/shared-styles';
import styled, { css } from 'styled-components';

const DashboardTable = css`
	tbody {
		display: block;
		overflow: auto;
		width: 100%;
		height: 400px;
		${scrollStyles}
	}

	thead tr,
	tbody tr {
		display: table;
		width: 100%;
		table-layout: fixed;
	}
`;

export const StyledTable = styled.table<{ $dashboard?: boolean }>`
	& {
		border: ${({ theme }) => theme.borders.divider};
		border-radius: 12px;
		width: 100%;
		border-collapse: collapse;
		overflow: hidden;

		${({ $dashboard }) => ($dashboard ? DashboardTable : '')}

		tbody {
			background-color: ${({ theme }) => theme.fontColors.white};
			color: ${({ theme }) => theme.fontColors.textGrey};
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%;
		}

		td,
		th {
			overflow: hidden;
			text-overflow: ellipsis;
			border-top: ${({ theme, $dashboard }) => ($dashboard ? 'none' : theme.borders.divider)};
			border-bottom: ${({ theme }) => theme.borders.divider};
			max-width: 174px;
			padding: 16px;
			width: fit-content;
			position: relative;

			& {
				& > div {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: clip;
				}
			}
		}

		& thead {
			border-radius: 12px;
			background-color: ${({ theme }) => theme.bgColors.tableHeader};
			color: ${({ theme }) => theme.fontColors.lightGrey};
			font-size: 11px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			text-transform: uppercase;
			cursor: pointer;

			& div {
				border: none;
				display: flex;
				align-items: center;

				& > svg {
					margin-left: 6px;
					width: 12px;
					height: 12px;
				}
			}
		}

		.action-buttons-container {
			display: flex;

			& > div:not(:first-child) {
				margin-left: 10px;
			}

			.continue-edit-button {
				stroke: #0b93e3;

				&:disabled {
					color: ${({ theme }) => theme.fontColors.white};
					stroke: ${({ theme }) => theme.fontColors.white};
				}

				&:hover {
					color: ${({ theme }) => theme.fontColors.white};

					svg {
						stroke: ${({ theme }) => theme.fontColors.white};
					}
				}
			}

			.delete-button {
				svg {
					fill: #0b93e3;
				}

				&:disabled {
					svg {
						fill: ${({ theme }) => theme.fontColors.white};
					}
				}

				&:hover:not(:disabled) {
					svg {
						fill: ${({ theme }) => theme.fontColors.white};
					}
				}
			}
		}
	}
`;

export const StyledTablePagination = styled.div`
	& > * {
		margin: 24px auto;
		display: flex;
		justify-content: center;

		.page-item {
			padding: 0 5px;
			cursor: pointer;
			min-width: 29px;
			width: auto;
			height: 29px;
			border-radius: 4px;
			border: ${({ theme }) => theme.borders.divider};
			background: ${({ theme }) => theme.fontColors.white};
			font-size: 14px;
			font-weight: 600;
			line-height: 150%;

			& .page-link {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				width: 100%;
				height: 100%;
			}

			&.break-button {
				pointer-events: none;
			}

			&.disabled {
				opacity: 0.5;
			}

			&:not(:first-child) {
				margin-left: 4px;
			}

			& svg {
				fill: ${({ theme }) => theme.fontColors.lightGrey};
			}

			&.active {
				color: ${({ theme }) => theme.fontColors.accent};
				border: ${({ theme }) => theme.borders.buttonBorder};
			}
		}
	}
`;

export const StyledTableLoader = styled.div<{ $dashboard?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: ${({ $dashboard }) => ($dashboard ? '451.5px' : '50vh')};

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;

export const StyledEmptyTable = styled.div<{ $dashboard?: boolean }>`
	& {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: ${({ $dashboard }) => ($dashboard ? '451.5px' : 'calc(90vh - 138px)')};

		& > div {
			& > p {
				margin-top: 24px;
				color: ${({ theme }) => theme.fontColors.lightGrey};
				line-height: 150%;

				& > span {
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
	}
`;
