import ReactDatePicker from 'react-datepicker';

import { ErrorMessage, FieldProps, getIn } from 'formik';
import moment from 'moment';

import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { StyledFormDatePicker } from './form-date-picker.styled';

import { StyledFieldErrorMessage } from '@components/utility/field-error-message.styled';

interface Props {
	placeholder?: string;
	mandatory?: boolean;
	label: string;
	disabled?: boolean;
	onChange?: (field: string, value: Date | null) => void;
	monthYearPicker?: boolean;
	maxDate?: Date;
	minDate?: Date;
	fullWidth?: boolean;
	smallerWidth?: boolean;
	showError?: boolean;
	isClearable?: boolean;
	showTimeSelect?: boolean;
	timeIntervals?: number;
	minTime?: Date;
}

export const FormDatePicker = ({
	label,
	placeholder,
	monthYearPicker,
	maxDate,
	minDate,
	disabled,
	field,
	form,
	fullWidth,
	smallerWidth,
	showError,
	onChange,
	mandatory = true,
	isClearable,
	showTimeSelect,
	timeIntervals,
	minTime,
}: Props & FieldProps) => {
	const value = getIn(form.values, field.name);

	return (
		<StyledFormDatePicker
			disabled={disabled}
			$fullWidth={fullWidth}
			$smallerWidth={smallerWidth}
			$error={!!(getIn(form.touched, field.name) && getIn(form.errors, field.name))}
			$mandatory={mandatory}>
			<div className="label">{label}</div>
			<label className="input">
				<CalendarIcon
					className="icon"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
					}}
				/>
				<ReactDatePicker
					disabled={disabled}
					onChange={date => {
						if (minTime) {
							if (moment(date).isBefore(minTime)) {
								form.setFieldValue(field.name, minTime);
							} else {
								form.setFieldValue(field.name, date);
							}
						} else {
							form.setFieldValue(field.name, date);
						}
						form.setFieldError(field.name, undefined);
						onChange?.(field.name, date);
					}}
					dateFormat={monthYearPicker ? 'MM/yyyy' : showTimeSelect ? 'dd/MM/yyyy HH:mm' : undefined}
					timeFormat="HH:mm"
					minDate={minDate || minTime}
					maxDate={maxDate}
					{...(minTime &&
						moment(minTime).isSame(moment(value), 'day') && {
							minTime: new Date(moment(minTime).toString()),
							maxTime: new Date(moment().endOf('day').toString()),
						})}
					placeholderText={placeholder}
					showMonthYearPicker={monthYearPicker}
					selected={value}
					isClearable={isClearable}
					showTimeSelect={showTimeSelect}
					timeIntervals={timeIntervals}
					popperModifiers={[
						{
							name: 'arrow',
							options: {
								padding: ({ popper, reference }) => ({
									right: Math.min(popper.width, reference.width) - 24,
								}),
							},
						},
					]}
				/>
			</label>
			{showError ? <ErrorMessage name={field.name} component={StyledFieldErrorMessage} /> : null}
		</StyledFormDatePicker>
	);
};
