import { WorkingGroup } from "@asd-stan/working-group/domain/working-group.entity";
import { Row } from "@tanstack/react-table";
import { FC, useState } from "react";
import { ExpertsMoreBtn, TableRow } from "./experts-components.styled";
import { Expert } from "@asd-stan/standard/domain/standard-detailed.entity";

const MAX_WORKING_GROUPS = 5;

interface Props {
    row: Row<Expert>;
}

export const ExpertsTableRowWorkingGroups: FC<Props> = ({row}) => {
    const [showMoreWorkingGroups, setShowMoreWorkingGroups] = useState(false);

    const wgRaw: WorkingGroup[] = row.getValue('workingGroups');
    const workingGroups = showMoreWorkingGroups ? wgRaw : wgRaw.slice(0, MAX_WORKING_GROUPS);

    const toggleWorkingGroups = () => setShowMoreWorkingGroups(prev => !prev);

    return (
        <div>
            {workingGroups
                .map((wg, idx) => (
                    <TableRow title={`${wg.code} ${wg.name}`} key={`${idx}-${wg.code}`}>{wg.code} {wg.name}</TableRow>
                ))}
            {!showMoreWorkingGroups && wgRaw.length > MAX_WORKING_GROUPS ? <ExpertsMoreBtn onClick={toggleWorkingGroups}>...</ExpertsMoreBtn> : null}
        </div>
    )
}