import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GearsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/gears-icon.svg';
import { PageTitle } from '@components/page-title/page-title';
import { Flex } from '@components/utility/flex';

import {
	IconWrapper,
	MainBlock,
	TextBlock,
} from '@components/under-construction/under-construction.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

interface Props {
	title?: string;
}

export const UnderConstruction: FC<Props> = ({ title }) => {
	const { t } = useTranslation();

	const mainContent = (
		<MainBlock>
			<IconWrapper>
				<GearsIcon height={37} width={48} fill="#0B93E3" />
			</IconWrapper>
			<TextBlock>
				<span className="under-construction-header">{t('common.underConstruction.title')}</span>
				<span className="under-construction-text">{t('common.underConstruction.text')}</span>
			</TextBlock>
		</MainBlock>
	);

	return title ? (
		<ContentContainer style={{ marginTop: 24 }}>
			<Flex $justify="space-between" $align="center">
				<PageTitle title={title} />
			</Flex>
			{mainContent}
		</ContentContainer>
	) : (
		mainContent
	);
};
