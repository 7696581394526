import styled from 'styled-components';

export const StyledFormDatePicker = styled.div<{
	disabled?: boolean;
	$fullWidth?: boolean;
	$smallerWidth?: boolean;
	$error: boolean;
	$mandatory?: boolean;
}>`
	margin-top: 16px;
	width: ${props => (props.$fullWidth ? '100%' : props.$smallerWidth ? '372px' : '400px')};
	position: relative;

	@media (min-width: 1500px) {
		width: ${props => (props.$fullWidth ? '100%' : '49%')};
	}

	&:last-child {
		margin-bottom: 0;
	}

	.label {
		display: block;
		font-size: 13px;
		font-weight: 500;
		margin-bottom: 6px;

		&::after {
			content: '*';
			color: #0b93e3;
			display: ${({ $mandatory }) => ($mandatory ? 'inline' : 'none')};
		}
	}

	.input {
		position: relative;
		display: block;
		height: 42px;
		padding: 12px 40px 12px 16px;
		border-radius: 8px;
		border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};
		width: 100%;
		background: ${({ disabled }) =>
			disabled ? 'linear-gradient(0deg, #f0f4f6 0%, #f0f4f6 100%), #fff' : 'transparent'};

		.react-datepicker-wrapper {
			width: 100%;

			.react-datepicker__close-icon {
				width: auto;
				height: auto;
				top: 50%;
				transform: translateY(-50%);

				&::after {
					background: none;
					color: #abb3bb;
					font-size: 16px;
					width: auto;
					height: auto;
				}
			}
		}

		input {
			&::placeholder {
				color: #abb3bb;
			}

			&:disabled {
				background-color: transparent;
			}
		}
	}

	.icon {
		position: absolute;
		right: 16px;
		bottom: 21px;
		transform: translateY(50%);
	}
`;
