import { WGMember } from '../domain/wg-member.entity';

interface WGMembersDto {
	workingGroupParticipationsByCode: {
		user: {
			id: number;
			firstName: string;
			lastName: string;
			email: string;
			company: {
				name: string;
			};
			positions: {
				name: string;
			}[];
		};
		roles: string[];
	}[];
	workingGroupParticipationsCountByCode: number;
}

export const mapWGMembers = ({
	workingGroupParticipationsByCode,
	workingGroupParticipationsCountByCode,
}: WGMembersDto): {
	data: WGMember[];
	totalCount: number;
} => ({
	data: workingGroupParticipationsByCode.map(
		({ roles, user: { firstName, lastName, email, company, positions, id } }) => ({
			id,
			firstName,
			lastName,
			email,
			company: company.name,
			domainRoles: roles,
			positions: positions.map(({ name }) => name),
		})
	),
	totalCount: workingGroupParticipationsCountByCode,
});
