import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {
	userHasRolesAndParticipatesInDomain,
	userHasRoles,
} from '@asd-stan/helpers/app-utils';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';

interface ProtectedRouteProps {
	redirectPath?: string;
	children?: React.ReactElement;
	allowedRoles: string[];
	rolesShouldParticipateInDomain?: string[];
}

export const ProtectedStandardsRoute: React.FC<ProtectedRouteProps> = observer(
	({ redirectPath = '/forbidden', children, allowedRoles, rolesShouldParticipateInDomain }) => {
		const currentUserService = getCurrentUserService();

		let isAllowed;

		const buildRedirectLink = () => {
			if (currentUserService.userRoles?.some(role => role === SystemRole.EXPERT)) {

				if (
					currentUserService.domainParticipations.length > 0 &&
					currentUserService.userRoles?.length > 0
				) {
					let newRedirectPath = '/forbidden';

					currentUserService.domainParticipations.some(participation => {
						if (participation.seeAllDomain) {
							newRedirectPath = `/standards/${participation.domain.code}`;
							return true;
						} else {
							if (participation.domain.workingGroups?.length > 0) {
								newRedirectPath = `/standards/${participation.domain.code}/${participation.domain.workingGroups[0].code}`;
								return true;
							}
						}

						return false;
					});

					return newRedirectPath;
				}
			}

			return redirectPath;
		};

		if (rolesShouldParticipateInDomain) {
			isAllowed = userHasRolesAndParticipatesInDomain(
				allowedRoles,
				currentUserService.userRoles!,
				rolesShouldParticipateInDomain,
				currentUserService.domainParticipations
			);
		} else {
			isAllowed = userHasRoles(allowedRoles, currentUserService.userRoles!);
		}

		if (!isAllowed) {
			return <Navigate to={buildRedirectLink()} replace />;
		}

		return children ? children : <Outlet />;
	}
);
