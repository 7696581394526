import React from 'react';

import { StyledSecondaryCell } from './secondary-cell.styled';

interface SecondaryCellProps {
	value: string;
}

export const SecondaryCell: React.FC<SecondaryCellProps> = ({ value }) => {
	return <StyledSecondaryCell>{value}</StyledSecondaryCell>;
};
