import { Money } from '@asd-stan/standard/domain/catalog-item.entity';

interface CurrencySymbols {
	[key: string]: string;
}

const currencies: CurrencySymbols = {
	USD: '$',
	EUR: '€',
};

export const getCurrencySymbol = (currencyCode: string) => currencies[currencyCode] || currencyCode;
export const getFormattedPrice = (price: Money) => {
	const currencySymbol = getCurrencySymbol(price.currency);
	const formattedAmount = price.amount.toFixed(2);
	return currencySymbol + formattedAmount;
};
