import { BallotStats, VotingDecisionResult } from '../domain/ballot.entity';

interface BallotStatsDto {
	question: {
		id: number;
		title: string;
	};
	result: 'pending' | 'approved' | 'disapproved' | 'abstention';
	totalVoted: number;
	allowedToVote: number;
	approve: number;
	disapprove: number;
	abstain: number;
}

export const mapBallotStats = (ballotStats: BallotStatsDto[]): BallotStats[] => {
	return ballotStats.map(({ question, result, ...rest }) => ({
		questionId: question.id,
		questionTitle: question.title,
		result: result as VotingDecisionResult,
		...rest,
	}));
};
