import { observer } from 'mobx-react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import {
	ActiveStageOption,
	ActiveStages,
} from '@asd-stan/standard/components/single-standard/stage-update/active-stages/active-stages';
import { AddNewStage } from '@asd-stan/standard/components/single-standard/stage-update/add-new-stage/add-new-stage';
import { Additional } from '@asd-stan/standard/components/single-standard/stage-update/additional/additional';
import {
	StageStepper,
	StepProps,
} from '@asd-stan/standard/components/single-standard/stage-update/stage-stepper/stage-stepper';
import { UpdateStageForm } from '@asd-stan/standard/domain/stage-update.entity';
import { StandardStage } from '@asd-stan/standard/domain/standard-stage.entity';
import {
	getStagesService,
	getStandardDetailsService,
} from '@asd-stan/standard/infrastructure/getters';
import { Button } from '@components/button/button';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import { Publish } from './publish/publish';
import { ButtonsContainer, CloseBtn, StageContainer, StageHeader } from './stage-update.styled';

const StageUpdateForm = ({
	newStageOptions,
	activeStageOptions,
	priceAdded,
	fileAdded,
	hasFonto,
	onCancel: handleStageUpdateCancel,
}: {
	newStageOptions: StandardStage[];
	activeStageOptions: ActiveStageOption[];
	priceAdded: boolean;
	fileAdded: boolean;
	hasFonto: boolean;
	onCancel: () => void;
}) => {
	const { t } = useTranslation();
	const { handleSubmit, values, errors, isSubmitting } = useFormikContext<UpdateStageForm>();
	const [activeStep, setActiveStep] = useState(1);
	const ableToPublish = values.stages.newStages.some(
		code => !!newStageOptions.find(stage => stage.code === code)?.immediatelyPublication
	);

	const steps: StepProps[] = [
		{
			label: t('standard.singleStandard.stageUpdateModal.stepper.addNewStage'),
			step: 1,
		},
		{
			label: t('standard.singleStandard.stageUpdateModal.stepper.activeStages'),
			step: 2,
		},
		{
			label: t('standard.singleStandard.stageUpdateModal.stepper.additional'),
			step: 3,
		},
	];

	if (ableToPublish) {
		steps.push({
			label: t('standard.singleStandard.stageUpdateModal.stepper.publish'),
			step: 4,
		});
	}

	const renderStepContainer = () => {
		if (activeStep === 1) {
			return (
				<AddNewStage
					priceAdded={priceAdded}
					fileAdded={fileAdded}
					newStageOptions={newStageOptions}
				/>
			);
		}

		if (activeStep === 2) {
			return <ActiveStages activeStageOptions={activeStageOptions} />;
		}

		if (activeStep === 3) {
			return (
				<Additional newStageOptions={newStageOptions} activeStageOptions={activeStageOptions} />
			);
		}

		return <Publish hasFonto={hasFonto} />;
	};

	const setPreviousStep = () => setActiveStep(prev => prev - 1);
	const setNextStep = () => setActiveStep(prev => prev + 1);

	const backButtonProps =
		activeStep > 1
			? {
					title: t('standard.singleStandard.stageUpdateModal.buttons.back'),
					onClick: setPreviousStep,
			  }
			: {
					title: t('standard.singleStandard.stageUpdateModal.buttons.cancel'),
					onClick: handleStageUpdateCancel,
			  };

	const getNextButtonProps = () => {
		if (activeStep === 1) {
			return {
				title: t('standard.singleStandard.stageUpdateModal.buttons.next'),
				onClick: setNextStep,
			};
		}

		if (activeStep === 2) {
			return {
				title: t('standard.singleStandard.stageUpdateModal.buttons.next'),
				onClick: setNextStep,
				disabled:
					!values.stages.newStages.length &&
					values.stages.activeStages.length === activeStageOptions.length,
			};
		}

		if (activeStep === 3 && ableToPublish) {
			return {
				title: t('standard.singleStandard.stageUpdateModal.buttons.next'),
				onClick: setNextStep,
				disabled: !!errors.comment,
			};
		}

		if (activeStep === 3) {
			return {
				title: t('standard.singleStandard.stageUpdateModal.buttons.update'),
				type: 'submit' as const,
				disabled: !!errors.comment,
			};
		}

		return {
			title: t('standard.singleStandard.stageUpdateModal.buttons.update'),
			type: 'submit' as const,
			disabled: !!(errors.publicationData?.name || errors.publicationData?.abstract),
		};
	};

	const nextButtonProps = getNextButtonProps();

	return (
		<StageContainer onSubmit={handleSubmit}>
			<StageHeader>
				{activeStep > 1
					? t('standard.singleStandard.stageUpdateModal.titleSecondary')
					: t('standard.singleStandard.stageUpdateModal.title')}
			</StageHeader>
			<CloseBtn onClick={handleStageUpdateCancel}>
				<CloseIcon />
			</CloseBtn>
			<StageStepper steps={steps} activeStep={activeStep} />
			{renderStepContainer()}
			<ButtonsContainer>
				<Button fullWidth secondary {...backButtonProps} disabled={isSubmitting} />
				<Button
					key={nextButtonProps.type}
					fullWidth
					{...nextButtonProps}
					disabled={nextButtonProps.disabled || isSubmitting}
				/>
			</ButtonsContainer>
		</StageContainer>
	);
};

export const StageUpdate = observer(({ id }: { id: number }) => {
	const navigate = useNavigate();
	const standardDetailsService = getStandardDetailsService();
	const toasterService = getToasterService();
	const modalService = getModalService();
	const stagesService = getStagesService();

	const standard = standardDetailsService.standard!;
	const stageList = stagesService.stageList;

	const activeStageOptions = standard.transaction.stages.map(({ code }) => code);
	const hasFonto = standard.editorId !== null;
	const fileAdded = !!standard.file;

	const closeModal = () => modalService.closeModal();

	const handleSubmit = async (values: UpdateStageForm) => {
		const ableToPublish = values.stages.newStages.some(
			code => !!stageList.find(stage => stage.code === code)!.immediatelyPublication
		);
		try {
			await standardDetailsService.updateStage(id, values, ableToPublish);
			const notificationTranslationKey = ableToPublish
				? 'standard.singleStandard.toaster.successfullyPublished'
				: 'standard.singleStandard.toaster.successfullStageUpdate';
			toasterService.showToast(
				ToasterStatus.success,
				<Trans
					i18nKey={notificationTranslationKey}
					values={{ regNumber: `${standard.registrationNumber}` }}
				/>
			);
			navigate(`/standards/detailed/${id}/stageAndStatus`);
			closeModal();
			await stagesService.getNewStageOptions(standard.form, id);
		} catch (err) {
			console.error(err);
			closeModal();
		}
	};

	const initialValues: UpdateStageForm = {
		comment: '',
		stages: {
			newStages: [],
			activeStages: activeStageOptions,
		},
		targetDate: new Date(),
		publicationData: {
			abstract: '',
			name: `ASD-STAN ${standard.form} ${standard.registrationNumber} ${standard.edition}`,
			publicationDate: new Date(),
			source: 'draft',
		},
		notify: {
			email: false,
			onScreen: false,
			users: [],
		},
	};

	const validationSchema = Yup.object().shape({
		comment: Yup.string().max(100),
		publicationData: Yup.object().when('stages.newStages', {
			is: (newStages: string[]) =>
				newStages.some(
					code => !!stageList.find(stage => stage.code === code)!.immediatelyPublication
				),
			then: schema =>
				schema.shape({
					abstract: Yup.string().max(5000, ' '),
					name: Yup.string().max(200).required(),
				}),
		}),
	});

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validateOnChange>
			<StageUpdateForm
				newStageOptions={stageList.filter(({ code }) =>
					stagesService.newStageOptions.some(c => c === code)
				)}
				activeStageOptions={standard.transaction.stages.map(({ code, name }) => ({
					code,
					name,
				}))}
				priceAdded={!!standardDetailsService.priceHistory?.standardPriceCount}
				fileAdded={fileAdded}
				hasFonto={hasFonto}
				onCancel={closeModal}
			/>
		</Formik>
	);
});
