import React from 'react';

import { ReactComponent as DownloadIcon } from '@asd-stan/ui-kit/assets/icons/download.svg';
import { ReactComponent as FileIcon } from '@asd-stan/ui-kit/assets/icons/uploaded-file-icon.svg';

import { StyledDownloadFile } from './download-file.styled';

interface DownloadFileProps {
	file: string;
	title?: string;
	hideFileIcon?: boolean;
	hideTitle?: boolean;
	notFromAssets?: boolean;
}

export const DownloadFile: React.FC<DownloadFileProps> = (
	{
		file,
		title,
		hideTitle,
		hideFileIcon,
		notFromAssets,
	}) => {
	const fileTitle = title ? title : file;

	const attachToDOMAndDownload = (fileUrl: string) => {
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = title ? title : file;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const downloadFile = () => {
		if (notFromAssets) {
			fetch(file)
				.then(response => response.blob())
				.then(blob => {
					const fileUrl = window.URL.createObjectURL(blob);
					attachToDOMAndDownload(fileUrl);
				})
		} else {
			const fileUrl = `/assets/${file}`;

			attachToDOMAndDownload(fileUrl);
		}
	};

	return (
		<StyledDownloadFile>
			{!hideFileIcon ? <FileIcon /> : null}
			{!hideTitle ? fileTitle : null}
			<button type="button" onClick={downloadFile}>
				<DownloadIcon />
			</button>
		</StyledDownloadFile>
	);
};
