import React from 'react';

import { ReactComponent as Icon } from '@asd-stan/ui-kit/assets/icons/button-close-icon.svg';

import { StyledButtonClose } from '@asd-stan/ui-kit/components/button/button.styled';

interface ButtonCloseProps {
	onClick: () => void;
	disabled?: boolean;
}

export const ButtonClose: React.FC<ButtonCloseProps> = ({ onClick, disabled = false }) => {
	return (
		<StyledButtonClose $disabled={disabled}>
			<button className="button-close" type="button" onClick={onClick} disabled={disabled}>
				<Icon />
			</button>
		</StyledButtonClose>
	);
};
