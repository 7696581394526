import React from 'react';

import { StyledToggle } from '@asd-stan/ui-kit/components/toggle/toggle.styled';

interface ToggleProps {
	label?: string;
	checked?: boolean;
	onChange?: () => void;
	topMargin?: boolean;
	disabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
	label,
	checked,
	onChange,
	topMargin,
	disabled = false,
}) => {
	return (
		<StyledToggle $topMargin={topMargin} $disabled={disabled}>
			<label onClick={e => e.stopPropagation()}>
				<input onChange={onChange} checked={checked} type="checkbox" disabled={disabled}></input>
				<i></i>
				{label}
			</label>
		</StyledToggle>
	);
};
