import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { stanWorksRouter } from '@asd-stan/router/routes';
import { ThemeProvider } from 'styled-components';

const theme = {
	fontColors: {
		textGrey: '#525259',
		lightGrey: '#ABB3BB',
		white: '#FFF',
		accent: '#0B93E3',
		darkGrey: '#333336',
		buttonHover: '#036BA8',
		green: '#1DC606',
		cancelled: '#5B5B5B',
		error: '#EA3E53',
	},
	bgColors: {
		primary: '#EDF2F3',
		sidebar: '#041630',
		scrollToTop: 'rgba(4, 22, 48, 0.8)',
		green: '#1DC60614',
		cancelled: '#5B5B5B14',
		lightGrey: '#ABB3BB',
		accent: '#0B93E3',
		sidebarIcon: '#1D2D45',
		tableHeader: '#F4F7F8',
		selectOption: '#E8E9F4',
		scrollTrack: '#DAE4E8',
		fileUploader: '#F7FAFB',
		buttonHover: '#036BA8',
		white: '#FFF',
		error: '#FFEFF1',
		warning: '#E3A70B',
		warningMessage:
			'linear-gradient(0deg, rgba(227, 167, 11, 0.08) 0%, rgba(227, 167, 11, 0.08) 100%), #FFF',
		errorMessage: 'rgba(234, 62, 83, 0.08)',
		replaceContainer: 'rgba(11, 147, 227, 0.08)',
	},
	borders: {
		divider: '1px solid #DAE4E8',
		buttonBorder: '1px solid #0B93E3',
		tabBorder: '2px solid #0B93E3',
		error: '1px solid #EA3E53',
		fileUploader: '1px dashed #DAE4E8',
		radio: '2px solid #DAE4E8',
		warning: '1px solid #E3A70B',
		warningMessage: '1px solid rgba(227, 167, 11, 0.32)',
		replaceContainer: '1px solid rgba(11, 147, 227, 0.32)',
	},
	toaster: {
		success: '6px solid #0BE314',
		error: '6px solid #EA3E53',
		warning: '6px solid yellow',
		export: '6px solid #f0b404',
	},
	button: {
		accent: '#E2F2FC',
		active: 'inset 0 0 3px #0B93E3',
		primaryHover: '#036BA8',
		secondaryHover: '#ABB3BB',
		disabled: '#ABB3BB',
		fonto: '#A46C87',
		fontoHover: '#983F6A',
	},
};

export const App: React.FC = () => {
	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<RouterProvider router={stanWorksRouter} />
			</ThemeProvider>
		</div>
	);
};
