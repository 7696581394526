import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SavedFile, VotingDecision, WithTimestamp } from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { useInfiniteRequest } from '@asd-stan/helpers/use-infinite-request';
import { Avatar } from '@components/avatar/avatar';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';

import { ReactComponent as AbstainIcon } from '../../../assets/abstain.svg';
import { ReactComponent as ApprovedIcon } from '../../../assets/approved.svg';
import { ReactComponent as DisapprovedIcon } from '../../../assets/disapproved.svg';
import { Field } from '../../../field';
import { FileField } from '../../../file-field';
import {
	StyledDecision,
	StyledVoteListLoading,
	StyledVoteSection,
} from '../../../single-ballot.styled';
import { transformDate } from '../../../transformDate';

const numOfAttachmentToShow = 3;

const Attachments = ({ attachments }: { attachments: WithTimestamp<SavedFile>[] }) => {
	const { t } = useTranslation();
	const ballotService = getBallotService();
	const [expanded, setExpanded] = useState(false);

	if (!attachments.length) {
		return null;
	}

	const attachmentsToSwow = expanded ? attachments : attachments.slice(0, numOfAttachmentToShow);

	return (
		<div className="attachments">
			<Flex $justify="space-between">
				<h6>{t('ballot.singleBallot.attachments')}</h6>
				{attachments.length > numOfAttachmentToShow && !expanded && (
					<span className="seeAll" onClick={() => setExpanded(true)}>
						{t('ballot.singleBallot.seeAll')} +{attachments.length - numOfAttachmentToShow}
					</span>
				)}
			</Flex>
			{attachmentsToSwow.map(attachment => (
				<FileField
					key={attachment.value.id}
					attachment={attachment}
					onDownloadClick={ballotService.downloadVoteAttachmentFile.bind(ballotService)}
				/>
			))}
		</div>
	);
};

const usePageScrolledToBottom = (callback: () => void) => {
	useEffect(() => {
		const handleScroll = () => {
			const pageHeight = Math.max(
				document.body.scrollHeight,
				document.body.offsetHeight,
				document.documentElement.clientHeight,
				document.documentElement.scrollHeight,
				document.documentElement.offsetHeight
			);
			if (window.innerHeight + document.documentElement.scrollTop !== pageHeight) {
				return;
			}
			callback();
		};
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [callback]);
};

const votingDecisionMap: Record<
	VotingDecision,
	{
		translationKey: string;
		icon: JSX.Element;
	}
> = {
	[VotingDecision.Approve]: { translationKey: 'approve', icon: <ApprovedIcon /> },
	[VotingDecision.Disapprove]: { translationKey: 'disapprove', icon: <DisapprovedIcon /> },
	[VotingDecision.Abstain]: { translationKey: 'abstention', icon: <AbstainIcon /> },
};

const limit = 3;

export const VoteList = ({ ballotId }: { ballotId: number }) => {
	const { t } = useTranslation();
	const ballotService = getBallotService();
	const {
		data: voteList,
		isLoading: voteListLoading,
		loadMore: loadMoreVotes,
		hasMore: hasMoreVotes,
	} = useInfiniteRequest(
		async (limit: number, offset: number) =>
			await ballotService.getVoteList(ballotId, limit, offset),
		limit
	);

	usePageScrolledToBottom(
		useCallback(() => {
			if (!hasMoreVotes || voteListLoading) {
				return;
			}
			loadMoreVotes();
		}, [loadMoreVotes, hasMoreVotes, voteListLoading])
	);

	return (
		<>
			{voteList.map(
				({ doneAt, doneBy, value: { organizationName, id, decisions, attachments } }) => (
					<StyledVoteSection key={id}>
						<Flex className="user" $align="center">
							<Avatar width="36px" height="36px" />
							<div className="userInfo">
								<div className="userName">
									{doneBy.firstName} {doneBy.lastName} <span>{organizationName}</span>
								</div>
								<div className="lastUpdate">Last update {transformDate(doneAt, true)}</div>
							</div>
						</Flex>
						{decisions.map(({ comment, decision, question, id }, index) => (
							<div className="block" key={id}>
								<Flex $justify="space-between">
									<div className="question">
										{t('ballot.singleBallot.question')} {index + 1}: {question.title}
									</div>
									<StyledDecision $decision={decision}>
										<div className="icon">{votingDecisionMap[decision].icon}</div>
										{t(
											`ballot.singleBallot.votingDecision.${votingDecisionMap[decision].translationKey}`
										)}
									</StyledDecision>
								</Flex>
								{comment.length > 0 && (
									<Field
										label={question.comment || t('ballot.singleBallot.descriptionComment')}
										value={comment}
										fullWidth
									/>
								)}
							</div>
						))}
						<Attachments attachments={attachments} />
					</StyledVoteSection>
				)
			)}
			{voteListLoading && (
				<StyledVoteListLoading className="voteListLoading">
					<Loading height={40} width={40} />
				</StyledVoteListLoading>
			)}
		</>
	);
};
