import { makeAutoObservable } from 'mobx';

export interface DraftArgs {
	id: number;
	generalData: {
		registrationNumber: string | null;
		form: string | null;
		edition: string | null;
		cenWiNumber: string | null;
	};
	titles: {
		en: string;
	};
}

export class Draft {
	id: number;
	registrationNumber: string | null;
	form: string | null;
	edition: string | null;
	cenWiNumber: string | null;
	localizedTitle: string;

	constructor(args: DraftArgs) {
		makeAutoObservable(this);

		this.id = args.id;
		this.registrationNumber = args.generalData.registrationNumber;
		this.form = args.generalData.form;
		this.edition = args.generalData.edition;
		this.cenWiNumber = args.generalData.cenWiNumber;
		this.localizedTitle = args.titles.en;
	}
}
