import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { BreadCrumbs } from '@asd-stan/ui-kit/components/bread-crumbs/bread-crumbs';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { MarkedText } from '@asd-stan/ui-kit/components/utility/marked-text';
import { CancelInvite } from '@asd-stan/user/components/invite-users/modals/cancel-invite';
import { DomainInputGroup } from '@asd-stan/user/components/layout/input-group/domain-input-group';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { UserInvitation, WorkingGroupParticipation } from '@asd-stan/user/domain/user-invitation';
import { getAppointerService, getUserService } from '@asd-stan/user/infrastructure/getters';
import InviteUsersSchema from '@asd-stan/user/validation-schemas/invite-users.schema';
import { PageTitle } from '@components/page-title/page-title';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';

import { Company } from './forms/company';
import { Domain } from './forms/domain';
import { General } from './forms/general';
import { StyledInviteUsers } from './invite-users.styled';

import { StyledButtonGroup } from '@asd-stan/ui-kit/components/utility/button-group.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';

interface DomainInput {
	domain: Option;
	domainRoles: Option | Option[];
	seeAllDomain: boolean | undefined;
	WGS: Option[] | undefined;
	WGC: Option[] | undefined;
	workingGroupExpert: Option[] | undefined;
}

export const InviteUsers: React.FC = observer(() => {
	const modalService = getModalService();
	const toasterService = getToasterService();
	const appointerService = getAppointerService();
	const userService = getUserService();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [isSendingInvite, setIsSendingInvite] = useState<boolean>(false);

	useEffect(() => {
		const onLoadPage = async () => {
			appointerService.getAppointers();
		};
		onLoadPage();
	}, [appointerService]);

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		appointer: { label: '', value: '' },
		systemRoles: [{ label: '', value: '' }],
		company: { label: '', value: '' },
		companyCountry: { label: '', value: '' },
		position: [{ label: '', value: '' }],
		domains: [],
	};

	const onSubmit = async (values: FormikValues, { setFieldError }: FormikHelpers<any>) => {
		const invitation = makeUserInvitation(values);
		setIsSendingInvite(true);
		try {
			await userService.sendInvitation(invitation);

			toasterService.showToast(
				ToasterStatus.success,
				<Trans
					i18nKey="user.userInvite.toaster.successfulInvite"
					values={{ userName: `${values.firstName} ${values.lastName}` }}>
					<MarkedText $color="#ABB3BB">User</MarkedText>{' '}
					<MarkedText $color="#ABB3BB">is successfully invited</MarkedText>
				</Trans>
			);

			navigate(-1);
		} catch (error) {
			if (error instanceof Error && error.message === 'alreadyExists.email') {
				setFieldError('email', t('api.errors.alreadyExists.email'));
				return;
			}
		} finally {
			setIsSendingInvite(false);
		}
	};

	const makeUserInvitation = (values: FormikValues): UserInvitation => {
		const workingGroupParticipations: WorkingGroupParticipation[] = [];
		const codeRolesMap: { [key: string]: string[] } = {};

		/* create map from each field */

		values.domains.forEach((domain: DomainInput) => {
			if (Array.isArray(domain.WGS)) {
				domain.WGS.forEach(participation => {
					if (participation.value !== '') {
						if (!codeRolesMap[participation.value]) {
							codeRolesMap[participation.value] = ['WGS'];
						} else {
							codeRolesMap[participation.value].push('WGS');
						}
					}
				});
			}

			if (Array.isArray(domain.WGC)) {
				domain.WGC.forEach(participation => {
					if (participation.value !== '') {
						if (!codeRolesMap[participation.value]) {
							codeRolesMap[participation.value] = ['WGC'];
						} else {
							codeRolesMap[participation.value].push('WGC');
						}
					}
				});
			}

			if (Array.isArray(domain.workingGroupExpert)) {
				domain.workingGroupExpert.forEach(participation => {
					if (participation.value !== '') {
						if (!codeRolesMap[participation.value]) {
							codeRolesMap[participation.value] = ['Expert'];
						} else {
							codeRolesMap[participation.value].push('Expert');
						}
					}
				});
			}
		});

		/* Convert the codeRolesMap into workingGroupParticipations array */
		Object.keys(codeRolesMap).forEach(code => {
			workingGroupParticipations.push({
				code,
				roles: codeRolesMap[code],
			});
		});

		return {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			phone:
				values.phone.toString().charAt(0) === '+'
					? values.phone.toString()
					: values.phone.length !== 0
					  ? `+${values.phone}`
					  : null,
			appointerId: parseInt(values.appointer.value),
			systemRoles: values.systemRoles.map((role: Option) => role.value),
			companyId: values.company && parseInt(values.company.value),
			companyCountryId: values.companyCountry && parseInt(values.companyCountry.value),
			positionIds: values.position[0].value
				? values.position.map((position: Option) => parseInt(position.value))
				: [],
			domainParticipations: values.domains
				.map((domain: DomainInput) => {
					if (Array.isArray(domain.domainRoles)) {
						return {
							code: domain.domain.value,
							roles: domain.domainRoles.map((role: Option) => role.value),
							seeAllDomain: domain.seeAllDomain,
						};
					}
					return undefined;
				})
				.filter((participation: [] | undefined) => participation !== undefined),
			workingGroupParticipations,
		};
	};

	const handleCancel = (values: FormikValues) => {
		modalService.openModal(<CancelInvite userName={`${values.firstName} ${values.lastName}`} />);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={InviteUsersSchema}
			validateOnChange
			validateOnBlur={false}>
			{({ values, handleSubmit, validateForm }) => {
				return (
					<StyledInviteUsers>
						<ContentContainer>
							<BreadCrumbs />
							<Flex $justify="space-between" $align="center">
								<PageTitle title={t('user.userInvite.title')} />
								<StyledButtonGroup>
									<Button
										secondary
										disabled={isSendingInvite}
										title={t('user.userInvite.buttons.cancel')}
										onClick={() => handleCancel(values)}
									/>
									<Button
										title={t('user.userInvite.buttons.save')}
										disabled={isSendingInvite}
										onClick={() => {
											validateForm().then(errors => {
												if (errors && !(Object.keys(errors).length === 0)) {
													toasterService.showToast(
														ToasterStatus.error,
														t('user.userInvite.toaster.incorrectFields')
													);
												}
											});

											handleSubmit();
										}}
									/>
								</StyledButtonGroup>
							</Flex>
							<Form>
								<InputGroup title={t('user.userInvite.generalTitle')} children={<General />} />
								<InputGroup title={t('user.userInvite.companyTitle')} children={<Company />} />
								<DomainInputGroup>
									<Domain key={JSON.stringify(values.systemRoles)} />
								</DomainInputGroup>
							</Form>
						</ContentContainer>
					</StyledInviteUsers>
				);
			}}
		</Formik>
	);
});
