import React from 'react';

import { StyledCounter } from './counter.styled';

interface CounterProps {
	count: number;
	name: string;
}

export const Counter: React.FC<CounterProps> = ({ count, name }) => {

	const splitNumberByThousands = (number: number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}

	return (
		<StyledCounter>
			{splitNumberByThousands(count)} {name}
		</StyledCounter>
	);
};
