import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInput } from '@components/form-input/form-input';
import { Field } from 'formik';

export const ENFields = observer(() => {
	const { t } = useTranslation();
	const currentUserService = getCurrentUserService();

	const disabled = !currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES]);

	return (
		<>
			<Field
				component={FormInput}
				name="enData.name"
				title={t('standard.createNWP.enFields.name')}
				showError
				useSubmitOnBlur
				fullWidth
				disabled={disabled}
			/>
			<Field
				component={FormDatePicker}
				name="enData.publicationDate"
				label={t('standard.createNWP.enFields.date')}
				placeholder="Choose date"
				mandatory={false}
				showError
				useSubmitOnBlur
				fullWidth
				monthYearPicker
				isClearable
				disabled={disabled}
			/>
			<Field
				component={FormInputParagraph}
				name="enData.title"
				title={t('standard.createNWP.enFields.titleField')}
				showError
				useSubmitOnBlur
				fullWidth
				maxLength={1000}
				disabled={disabled}
			/>
		</>
	);
});
