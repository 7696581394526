import { observer } from 'mobx-react';
import React from 'react';

import { FileUploadController } from '@asd-stan/file/domain/file-upload-controller';
import { FileDropzone } from '@asd-stan/ui-kit/components/file-dropzone/file-dropzone';
import { Field } from 'formik';

const fileTypes = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg': ['.svg'],
	'application/xml': ['.xml'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'text/csv': ['.csv'],
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'image/tiff': ['.tiff'],
	'image/vnd.dwg': ['.dwg'],
	'image/bmp': ['.bmp'],
};

export const StandardDraft: React.FC = observer(() => {
	const fileUploadController = new FileUploadController();

	return (
		<Field
			name="standardDraft"
			component={FileDropzone}
			fileTypes={fileTypes}
			maxSize={20971520}
			maxFiles={1}
			controller={fileUploadController}
			useSubmitOnDrop
			isFilePrivate
		/>
	);
});
