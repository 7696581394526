import { StyledFormError } from './form-error.styled';

interface ErrorProps {
	title: string;
}

export const FormError: React.FC<ErrorProps> = ({ title }) => {
	return (
		<StyledFormError>
			<div className="error">{title}</div>
		</StyledFormError>
	);
};
