import styled from 'styled-components';

export const StyledPublish = styled.div`
	width: 100%;
	margin-top: 24px;

	& > div:first-child {
		margin-top: 0;
		width: 100%;
	}

	.radio {
		margin-bottom: 12px;

		label {
			font-size: 14px;
		}
	}

	.radioSection {
		margin-bottom: 24px;
	}
`;
