import { makeAutoObservable } from 'mobx';

interface Modal {
	open: boolean;
	body: JSX.Element | null;
}

export class ModalService {
	modal: Modal = {
		open: false,
		body: null,
	};

	constructor() {
		makeAutoObservable(this);
	}

	openModal(content?: JSX.Element) {
		this.modal.open = true;
		this.modal.body = content || null;
		document.body.style.overflow = "hidden"
	}
	closeModal() {
		this.modal.open = false;
		this.modal.body = null;
		document.body.style.overflow = "auto"
	}
}
