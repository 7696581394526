import {
    StandardStage,
    StandardStageArgs,
    StandardStageUpdateItem,
    StandardStageUpdateItemParsed,
} from '../domain/standard-stage.entity';

enum StageField {
    id = 'id',
    name = 'name',
    code = 'code',
    recommendedStages = 'recommendedStages',
}

export enum StandardFormType {
    TR = 'TR',
    prEN = 'prEN'
}

export const makeStandardStageFromArgs = (args: StandardStageArgs): Readonly<StandardStage> => {
    return new Proxy<StandardStage>(new StandardStage(args), {
        set: (obj, prop: StageField, value) => {
            if (prop === StageField.recommendedStages) {
                throw new Error('You can\'t set property "recommendedStages". Pls use "setRecommendedStages()" instead');
            }
            (obj as {[key: string]: any})[prop] = value;
            return true
        }
    })
}

export const mapStageHistoryChanges = (args: StandardStageUpdateItem[]): StandardStageUpdateItemParsed[] => args.map(arg => ({
    ...arg,
    history: JSON.parse(arg.history)
}))
