import React from 'react';
import { useTranslation } from 'react-i18next';

import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { FormTextarea } from '@asd-stan/ui-kit/components/form-textarea/form-texarea';
import { Field } from 'formik';

export const Titles: React.FC = () => {
	const url = window.location.href;
	const { t } = useTranslation();
	const standardService = getStandardService();

	return (
		<>
			<Field
				component={FormTextarea}
				name="standardTitleEN"
				title={t('standard.createNWP.titles.standardTitleEN')}
				placeholder={t('standard.createNWP.titles.standardTitleENPlaceholder')}
				fullWidth
				mandatory={!standardService.singleStandard?.isMigrated}
				showError
				maxLength={1000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			<Field
				component={FormTextarea}
				name="standardTitleDE"
				title={t('standard.createNWP.titles.standardTitleDE')}
				placeholder={t('standard.createNWP.titles.standardTitleDEPlaceholder')}
				fullWidth
				maxLength={1000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			<Field
				component={FormTextarea}
				name="standardTitleFR"
				title={t('standard.createNWP.titles.standardTitleFR')}
				placeholder={t('standard.createNWP.titles.standardTitleFRPlaceholder')}
				fullWidth
				maxLength={1000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
		</>
	);
};
