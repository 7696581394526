import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadedFile } from '@asd-stan/file/domain/file-upload-controller';
import { ReactComponent as FileIcon } from '@asd-stan/ui-kit/assets/icons/uploaded-file-icon.svg';
import { ButtonClose } from '@asd-stan/ui-kit/components/button/button-close';
import { Loader } from '@asd-stan/ui-kit/components/loader/loader';
import { formatBytes } from '@components/file-dropzone/utils';

import { StyledDroopedFiles } from './file-dropzone.styled';

export const checkIfFileBeingUploaded = (file: UploadedFile) => !!file.uploadId;

export const DroppedFile = ({
	file,
	disabled,
	onDelete: handleFileDelete,
	children,
}: {
	file: UploadedFile;
	disabled?: boolean;
	onDelete(): void;
	children?: React.ReactNode;
}) => {
	const { t } = useTranslation();
	const loading = checkIfFileBeingUploaded(file);

	return (
		<div className="file-item">
			<li className="file-list">
				<div className="file-container">
					<div className="file-icon">
						<FileIcon />
					</div>
					<div>
						<p className="file-name">{file.title}</p>
						<p className="file-properties">
							{formatBytes(file.size)}
							{loading ? (
								<>
									<span> {' \u2219 '}</span> {t('common.uploader.pleaseWait')}
								</>
							) : null}
						</p>
					</div>
					{loading ? <Loader /> : null}
					{!disabled && (
						<div className="remove-file-btn">
							<ButtonClose onClick={handleFileDelete} />
						</div>
					)}
				</div>
				{children}
			</li>
		</div>
	);
};

export type CustomFilesView = ({
	files,
	disabled,
}: {
	files: UploadedFile[];
	disabled?: boolean;
	handleFileDelete(fileToDelete: UploadedFile): void;
}) => JSX.Element;

export const DroppedFiles = ({
	files,
	singleFile,
	disabled,
	hasTitle,
	onFileDelete: handleFileDelete,
	customFilesView,
}: {
	files: UploadedFile[];
	singleFile?: boolean;
	extraFields?: (index: number) => ReactElement;
	disabled?: boolean;
	hasTitle?: boolean;
	onFileDelete(fileToDelete: UploadedFile): void;
	customFilesView?: CustomFilesView;
}) => {
	return (
		<StyledDroopedFiles $singleFile={singleFile} $hasTitle={hasTitle}>
			{customFilesView
				? customFilesView({ files, disabled, handleFileDelete })
				: files.map((file, index) => (
						<DroppedFile
							key={file.title + index}
							onDelete={() => handleFileDelete(file)}
							file={file}
							disabled={disabled}
						/>
				  ))}
		</StyledDroopedFiles>
	);
};
