import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';

import { StyledToaster } from '@asd-stan/ui-kit/components/toaster/toaster.styled';

export const Toaster: React.FC = observer(() => {
	const toasterService = getToasterService();
	const toast = toasterService.toast;
	const lastStatusRef = useRef<null | ToasterStatus>(null);
	if (toast?.status) {
		lastStatusRef.current = toast.status;
	}

	return (
		<StyledToaster
			$isOpen={toast !== null}
			$status={toast?.status || lastStatusRef.current || ToasterStatus.success}>
			{toast?.message}
		</StyledToaster>
	);
});
