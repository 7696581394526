import { getWgRepo } from '../infrastructure/getters';

export class WGService {
	private get _wgRepo() {
		return getWgRepo();
	}

	getWGMembersByCode(code: string, limit: number, offset: number) {
		return this._wgRepo.getWGMembersrByCode(code, limit, offset);
	}
}
