import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MeetingForm as MeetingFormType } from '@asd-stan/meeting/domain/meeting.entity';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { BreadCrumbs } from '@components/bread-crumbs/bread-crumbs';
import { Button } from '@components/button/button';
import { checkIfFileBeingUploaded } from '@components/file-dropzone/dropped-files';
import { PageTitle } from '@components/page-title/page-title';
import { Flex } from '@components/utility/flex';
import { Form, Formik } from 'formik';

import { Files } from './forms/files';
import { General } from './forms/general';
import { StyledMeetingForm } from './meeting-form.styled';
import { meetingValidationSchema } from './meeting-validation-schema';

import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

const defaultInitialValues: MeetingFormType = {
	domains: [],
	workingGroups: [],
	title: '',
	type: null,
	startTime: null,
	endTime: null,
	link: '',
	location: '',
	files: [],
	standard: null,
};

export const MeetingForm = ({
	title,
	onSubmit: handleSubmit,
	initialValues,
}: {
	title: string;
	onSubmit: (values: MeetingFormType) => void;
	initialValues?: MeetingFormType;
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const toasterService = getToasterService();

	const createForm = !initialValues;

	return (
		<Formik
			initialValues={initialValues ?? defaultInitialValues}
			onSubmit={handleSubmit}
			validationSchema={meetingValidationSchema}>
			{({ submitForm, values, isSubmitting, validateForm }) => (
				<StyledMeetingForm>
					<ContentContainer>
						<BreadCrumbs />
						<Flex $justify="space-between" $align="center">
							<PageTitle title={title} />
							<StyledButtonGroup>
								<Button
									secondary
									title={t('meeting.createMeeting.cancel')}
									onClick={() => navigate(-1)}
								/>
								<Button
									disabled={
										isSubmitting || values.files.some(({ file }) => checkIfFileBeingUploaded(file))
									}
									title={t('meeting.createMeeting.save')}
									onClick={async () => {
										validateForm().then(errors => {
											if (errors && !(Object.keys(errors).length === 0)) {
												toasterService.showToast(
													ToasterStatus.error,
													t('meeting.createMeeting.errors.validationErrorToast')
												);
											}
										});
										submitForm();
									}}
								/>
							</StyledButtonGroup>
						</Flex>
						<Form>
							<General />
							{createForm && <Files />}
						</Form>
					</ContentContainer>
				</StyledMeetingForm>
			)}
		</Formik>
	);
};
