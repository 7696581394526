import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardDomain, WorkingGroup } from '@asd-stan/standard/domain/standard-detailed.entity';

import {
	StandardDisplayContainer,
	StandardDisplayDomainContainer,
	StandardDisplayDomainRow,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
	StandardDisplayRow,
} from '../../../single-standard.styled';

interface Props {
	domains?: StandardDomain[];
	workingGroups?: WorkingGroup[];
	isWorkingGroupNull?: boolean;
}

export const DomainsWgroups: React.FC<Props> = props => {
	const { t } = useTranslation();

	const renderGroups = (domainCode: string) => {
		const workingGroups = props.workingGroups
			? props.workingGroups.filter(group => group.code.includes(domainCode))
			: [];
		if (!workingGroups.length && !props.isWorkingGroupNull) {
			return (
				<StandardDisplayFieldValueAbsent key={`${domainCode}`}>
					{t('standard.singleStandardPage.general.workingGroupsAbsent')}
				</StandardDisplayFieldValueAbsent>
			);
		}
		if (props.isWorkingGroupNull) {
			return (
				<StandardDisplayFieldValue key={`${domainCode}`}>
					{t('standard.singleStandardPage.general.workingGroupsNull')}
				</StandardDisplayFieldValue>
			);
		}

		return workingGroups.map(group => (
			<StandardDisplayFieldValue key={`${group.code}-${group.name}`}>
				{group.code} {group.name}
			</StandardDisplayFieldValue>
		));
	};

	const renderedDomainsWithGroups = () => {
		if (!props.domains?.length) {
			return (
				<StandardDisplayDomainRow>
					<StandardDisplayFieldValueAbsent>
						{t('standard.singleStandardPage.general.domainAbsent')}
					</StandardDisplayFieldValueAbsent>
					<StandardDisplayFieldValueAbsent>
						{t('standard.singleStandardPage.general.workingGroupsAbsent')}
					</StandardDisplayFieldValueAbsent>
				</StandardDisplayDomainRow>
			);
		}

		return props.domains.map(domain => (
			<StandardDisplayDomainRow key={`${domain.code}-${domain.name}`}>
				<StandardDisplayFieldValue>
					{domain.code} {domain.name}
				</StandardDisplayFieldValue>
				<StandardDisplayContainer style={{ marginTop: 0 }}>
					{renderGroups(domain.code)}
				</StandardDisplayContainer>
			</StandardDisplayDomainRow>
		));
	};

	return (
		<StandardDisplayDomainContainer>
			<StandardDisplayRow>
				<StandardDisplayFieldHeader>
					{t('standard.singleStandardPage.general.domains')}
				</StandardDisplayFieldHeader>
				<StandardDisplayFieldHeader>
					{t('standard.singleStandardPage.general.workingGroups')}
				</StandardDisplayFieldHeader>
			</StandardDisplayRow>
			{renderedDomainsWithGroups()}
		</StandardDisplayDomainContainer>
	);
};
