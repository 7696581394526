import { useCallback, useEffect, useState } from 'react';

type FetchFunction<T> = () => Promise<T>;

type State<T> =
	| {
			data: null;
			isLoading: true;
			error: null;
	  }
	| {
			data: T;
			isLoading: false;
			error: null;
	  }
	| {
			data: null;
			isLoading: false;
			error: any;
	  };

type UseRequestResult<T> = State<T> & {
	invalidate(): void;
};

export const useRequest = <Data>(
	fetchFunction: FetchFunction<Data>,
	options?: {
		disabled?: boolean;
		onSuccess?(data: Data): void;
	}
): UseRequestResult<Data> => {
	const [state, setState] = useState<State<Data>>({
		data: null,
		isLoading: true,
		error: null,
	});

	const disabled = !!options?.disabled;
	const successCb = options?.onSuccess;

	const fetchData = useCallback(async () => {
		setState({
			data: null,
			isLoading: true,
			error: null,
		});
		try {
			const fetchedData = await fetchFunction();
			setState({
				data: fetchedData,
				isLoading: false,
				error: null,
			});
			successCb?.(fetchedData);
		} catch (error: any) {
			console.error(error);
			setState({
				data: null,
				isLoading: false,
				error: error,
			});
		}
	}, [fetchFunction]);

	useEffect(() => {
		if (disabled) {
			return;
		}
		fetchData();
	}, [fetchData, disabled]);

	return { ...state, invalidate: fetchData };
};
