import styled from 'styled-components';

export const StyledTooltip = styled.div<{ $show: boolean; $width?: string }>`
	& {
		cursor: pointer;
		align-self: center;
		position: relative;
		margin-left: 8px;

		& .tooltip-icon {
			width: 16px;
			height: 16px;
		}

		& .tooltip {
			position: absolute;
			bottom: 20px;
			text-align: center;
			width: ${props => (props.$width ? `${props.$width}px` : 'fit-content')};
			pointer-events: none;
			background: ${({ theme }) => theme.bgColors.sidebar};
			color: ${({ theme }) => theme.fontColors.white};
			visibility: ${props => (props.$show ? 'visible' : 'hidden')};
			padding: 4px 8px;
			border-radius: 4px;
			font-size: 12px;
			font-style: normal;
			line-height: 150%;
		}
	}
`;
