import { inputStyles, scrollStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const StyledFormSelect = styled.div<{
	$error: boolean;
	$fullWidth?: boolean;
	$smallerWidth?: boolean;
}>`
	${inputStyles};
	width: ${props => (props.$fullWidth ? '100%' : props.$smallerWidth ? '372px' : '400px')};

	@media (min-width: 1500px) {
		width: ${props => (props.$fullWidth ? '100%' : '49%')};
	}

	& .Select__single-value {
		margin-right: 20px;
	}

	& .Select {
		&__menu-list {
			max-height: 200px;
			& .Select__option {
				div {
					line-height: 150%;
				}
				&:first-child {
					padding-right: 80px;
				}
			}
		}

		&__placeholder {
			color: ${({ theme }) => theme.fontColors.lightGrey};
		}

		&__indicator {
			& > svg {
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				width: 12px;
				height: 12px;
				fill: ${({ theme }) => theme.fontColors.lightGrey};
			}
		}

		&__value-container {
			line-height: 1.1;
			padding-right: 20px;
			min-height: 42px;
			max-height: 112px;
			overflow-y: auto;

			${scrollStyles}
		}

		&__control {
			margin-top: 8px;
			padding-right: 30px;
			min-width: 264px;
			border-radius: 8px;
			box-shadow: none;
			border: ${props => (props.$error ? props.theme.borders.error : props.theme.borders.divider)};

			&:hover {
				border: ${props =>
					props.$error ? props.theme.borders.error : props.theme.borders.divider};
			}

			& .Select__indicators {
				position: absolute;
				top: 8px;
				right: 8px;
			}

			&--is-disabled {
				background-color: ${({ theme }) => theme.bgColors.primary};
			}

			&--menu-is-open {
				.Select__indicator {
					& > svg {
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						transform: rotate(180deg);
					}
				}
			}
		}

		&__indicator-separator {
			display: none;
		}

		&__multi-value {
			margin-top: 6px;
			margin-bottom: 6px;
			margin-left: 8px;
			border-radius: 6px;
			background-color: ${({ theme }) => theme.bgColors.selectOption};

			&__remove {
				& > svg {
					fill: ${({ theme }) => theme.fontColors.lightGrey};
				}

				&:hover {
					background-color: inherit;

					& > svg {
						fill: ${({ theme }) => theme.fontColors.darkGrey};
					}
				}
			}

			&__label {
				height: 24px;
				padding: 6px 8px;
				font-size: 13px;
			}
		}

		&__option {
			border-radius: 8px;
			padding: 10px;
			overflow-x: hidden;
			text-overflow: ellipsis;

			&--is-focused {
				background-color: #0b93e314;
			}

			&--is-selected {
				background-color: #0b93e314;
				color: ${({ theme }) => theme.fontColors.textGrey};
			}
			label {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&__clear-indicator {
			padding-right: 4px;
		}

		&__menu-list {
			margin: 10px;

			${scrollStyles};
		}
	}

	@media (min-width: 1500px) {
		width: ${props => (props.$fullWidth ? '100%' : '49%')};
	}
`;

export const StyledCreateLabel = styled.span`
	color: ${({ theme }) => theme.fontColors.darkGrey};
	font-size: 14px;
	font-weight: 500;
	white-space: nowrap;

	& > span {
		color: ${({ theme }) => theme.fontColors.accent};
		position: absolute;
		right: 5px;
	}
`;

export const StyledCheckboxOption = styled.div`
	.container {
		display: flex;
		align-items: center;

		label {
			overflow: clip;
			text-overflow: ellipsis;
		}

		i.avatar {
			border-radius: 24px;
			margin-right: 10px;
		}
		& > input[type='checkbox'] {
			margin-right: 10px;
		}

		img {
			border-radius: 24px;
			margin-right: 10px;
		}

		& > span {
			margin: 0 5px;
			padding: 4px 6px;
			font-weight: 600;
			font-size: 12px;
			background: rgba(218, 228, 232, 0.4);
			border-radius: 4px;
		}
	}

	.customContainer {
		color: ${({ theme }) => theme.fontColors.textGrey};
	}
`;
