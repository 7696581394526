import { Classification } from '@asd-stan/standard/domain/classification.entity';
import { IcsCode } from '@asd-stan/standard/domain/ics-code.entity';
import { Tag } from '@asd-stan/standard/domain/tag.entity';

import { StandardDetailed } from '../domain/standard-detailed.entity';
import { Standard } from '../domain/standard.entity';

import { StandardAdditionalInformationDTO, StandardDTO, StandardDetailedDTO } from './standard.dto';

export const makeStandardFromStandardDTO = (dto: StandardDTO): Standard => {
	return new Standard({
		id: dto.id,
		stanNumber: dto.stanNumber,
		registrationNumber: dto.registrationNumber,
		form: dto.form,
		edition: dto.edition,
		cenWiNumber: dto.cenWiNumber,
		status: dto.status,
		localizedTitle: dto.localizedTitle,
		isDraft: dto.isDraft,
		originator: dto.originator,
		transaction: dto.transaction,
	});
};

export const makeStandardAdditionalInformationFromStandardAdditionalInformationDTO = (
	dto: StandardAdditionalInformationDTO
): Partial<Standard> => {
	return {
		tags: dto.tags.map(tag => new Tag({ value: tag.value })),
		classifications: dto.classifications.map(
			(classification: Classification) =>
				new Classification({
					id: classification.id,
					number: classification.number,
					name: classification.name,
				})
		),
		icsCodes: dto.icsCodes.map(
			(icsCode: IcsCode) =>
				new IcsCode({
					code: icsCode.code,
					name: icsCode.name,
					description: icsCode.description,
				})
		),
	};
};

export const makeStandardDetailedFromStandardDetailedDTO = (
	dto: StandardDetailedDTO
): StandardDetailed => {
	return new StandardDetailed(dto);
};
