import styled from 'styled-components';

export const StyledLabel = styled.label`
	& {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
