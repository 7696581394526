import { inputStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const StyledFormInput = styled.div<{
	$error: boolean;
	$warning?: boolean;
	$fullWidth?: boolean;
	$smallerWidth?: boolean;
	$showCheckbox?: boolean;
	$disabled?: boolean;
	$staticWidth?: boolean;
}>`
	${inputStyles};
	width: ${props => (props.$fullWidth ? '100%' : props.$smallerWidth ? '372px' : '400px')};
	margin-bottom: ${props => props.$showCheckbox && '8px'};

	input {
		margin-top: 8px;
		padding: 12px 16px;
		height: 42px;
		width: 100%;
		color: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.darkGrey};
		font-size: 13px;
		border-radius: 8px;
		border: ${props =>
			props.$error
				? props.theme.borders.error
				: props.$warning
				  ? props.theme.borders.warning
				  : props.theme.borders.divider};

		background-color: ${props => props.$disabled && props.theme.bgColors.primary};

		&::placeholder {
			color: ${({ theme }) => theme.fontColors.lightGrey};
		}

		// Hide arrows for number inputs
		// Chrome, Safari, Edge, Opera
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		// Firefox
		&[type='number'] {
			-moz-appearance: textfield;
		}
	}

	.error-message {
		margin-top: 8px;
		font-size: 11px;
		font-weight: 500;
		color: ${({ theme }) => theme.fontColors.error};
	}

	@media (min-width: 1500px) {
		${props => !props.$staticWidth && `width: ${props.$fullWidth ? '100%' : '49%'}`}
	}
`;
