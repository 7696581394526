import { Domain } from '@asd-stan/domain/domain/domain.entity';
import { getDomainRepo } from '@asd-stan/domain/infrastructure/getters';
import { WorkingGroup } from '@asd-stan/working-group/domain/working-group.entity';
import { makeAutoObservable, runInAction } from 'mobx';

export class DomainService {
	private _domains: Domain[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	private get _domainRepo() {
		return getDomainRepo();
	}

	public async getDomains() {
		const domains = await this._domainRepo.getDomains();
		runInAction(() => {
			this._domains = domains;
		});
		return domains;
	}

	get domains() {
		return this._domains;
	}

	private get _domainWorkingGroupsMap() {
		const domainWorkingGroups: Record<string, WorkingGroup[]> = {};
		this._domains.forEach(domain => {
			domainWorkingGroups[domain.code] = domain.workingGroups;
		});
		return domainWorkingGroups;
	}

	getDomainWorkingGroups(domainCode: string) {
		return this._domainWorkingGroupsMap[domainCode];
	}

	getDomainMembersByCode(code: string, limit: number, offset: number) {
		return this._domainRepo.getDomainMembersrByCode(code, limit, offset);
	}
}
