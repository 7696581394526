import { Page } from '@asd-stan/helpers/pagination-collection-controller';
import moment from 'moment';

import {
	BallotListFilter,
	BallotListItem,
	BallotResult,
	BallotStatus,
} from '../domain/ballot.entity';

interface BallotListItemDto {
	id: number;
	title: string;
	description: string;
	status: 'active' | 'pending' | 'closed';
	openingDate: string;
	closingDate: string;
	result: 'pending' | 'approved' | 'disapproved' | 'notValid';
	resultComment: string;
	createdBy: {
		id: number;
	};
}

interface BallotListDto {
	ballotList: BallotListItemDto[];
	ballotCount: number;
}

export const mapBallotList = ({
	ballotCount,
	ballotList,
}: BallotListDto): Page<BallotListItem> => ({
	data: ballotList.map(({ createdBy, status, result, ...ballotList }) => ({
		...ballotList,
		status: status as BallotStatus,
		result: result as BallotResult,
		cratedById: createdBy.id,
	})),
	totalNumber: ballotCount,
});

interface BallotListFilterDto {
	search?: string | null;
	orderField?: null | string;
	orderDirection?: 'DESC' | 'ASC';
	standardId?: number;
	openingDate?: null | {
		from?: null | string;
		to?: null | string;
	};
	results?: null | ('pending' | 'approved' | 'disapproved' | 'notValid')[];
}

const dateFormat = 'YYYY-MM-DD';

export const mapBallotListFilter = ({
	openingDate,
	results,
	...filter
}: Partial<BallotListFilter>): BallotListFilterDto => ({
	...filter,
	openingDate: {
		from: openingDate?.from ? moment(openingDate.from).format(dateFormat) : null,
		to: openingDate?.to ? moment(openingDate.to).format(dateFormat) : null,
	},
	results: results
		? Object.entries(results).reduce<('pending' | 'approved' | 'disapproved' | 'notValid')[]>(
				(acc, [key, value]) => {
					if (value) {
						acc.push(key as BallotResult);
					}
					return acc;
				},
				[]
		  )
		: null,
});
