import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@asd-stan/helpers/customHooks';
import {
	getStandardPublicService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { Position } from '@asd-stan/user/domain/position.entity';
import {
	getCompanyService,
	getCountryService,
	getPositionService,
} from '@asd-stan/user/infrastructure/getters';
import { FormInputPhone } from '@components/form-input-phone/form-input-phone';
import { FormInput } from '@components/form-input/form-input';
import { FormSelect, Option } from '@components/form-select/form-select';
import { FormSelectCreatable } from '@components/form-select/form-select-creatable';
import { Flex } from '@components/utility/flex';
import { Field, FormikValues, useFormikContext } from 'formik';

import { ErrorFormContainer } from '../error-form-container/error-form-container';

interface NewOriginatorFormProps {
	isSaveClicked: boolean;
	existOriginator: boolean;
	setExistOriginator: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewOriginatorForm: React.FC<NewOriginatorFormProps> = observer(
	({ isSaveClicked, existOriginator, setExistOriginator }) => {
		const { t } = useTranslation();
		const { values, setFieldValue, setFieldError, setFieldTouched, handleSubmit } =
			useFormikContext<FormikValues>();

		const countryService = getCountryService();
		const companyService = getCompanyService();
		const positionService = getPositionService();
		const standardService = getStandardService();
		const standardPublicService = getStandardPublicService();

		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [loadingCompanyOptions, setLoadingCompanyOptions] = useState<boolean>(false);
		const [loadingPositionOptions, setLoadingPositionOptions] = useState<boolean>(false);
		const [loadingCountryOptions, setLoadingCountryOptions] = useState<boolean>(false);

		const [isFormSaved, setIsFormSaved] = useState<boolean>(false);
		const [isWarningEmail, setIsWarningEmail] = useState<boolean>(false);
		const [isWarningPhone, setIsWarningPhone] = useState<boolean>(false);
		const [haveOriginator, setHaveOriginator] = useState<boolean>(false);
		const [loadingExistOriginatorsOptions, setLoadingExistOriginatorsOptions] =
			useState<boolean>(false);

		const companyOptions = companyService.companies.map(company => {
			return { label: company.name, value: company.id.toString() };
		});

		const positionOptions = positionService.positions.map(position => {
			return { label: position.name, value: position.id.toString() };
		});

		const countryOptions = countryService.countries.map(country => {
			return { label: country.name, value: country.id.toString() };
		});

		const existOriginatorsOptions = standardService.originatorList.map(originator => {
			return {
				value: {
					id: originator.id,
					userId: originator.userId,
					firstName: originator.firstName,
					lastName: originator.lastName,
					phone: originator.phone === null ? '' : originator.phone,
					email: originator.email,
					company: originator.company && {
						label: originator.company.name,
						value: originator.company.id,
					},
					country: originator.country && {
						label: originator.country.name,
						value: originator.country.id,
					},
					positions: originator.positions,
				},
				label: (
					<>
						<b style={{ fontWeight: 500 }}>{`${originator.firstName} ${originator.lastName}`}</b>
						{`, ${originator.email}, ${
							originator.phone === null ? '' : `${originator.phone},`
						} ${originator.company?.name}, ${originator.country?.name} ${
							originator.positions && originator.positions.length > 0
								? ', ' + originator.positions.map(originator => ` ${originator.name}`)
								: ''
						}`}
					</>
				),
				customLabel: `${originator.firstName} ${originator.lastName}`,
				customOption: (
					<div style={{ paddingTop: '10px' }}>
						<p>{`${originator.email}${
							originator.phone === null ? '' : `, ${originator.phone}`
						}`}</p>
						<p>
							{`${originator.company?.name}, ${originator.country?.name} ${
								originator.positions && originator.positions.length > 0
									? ', ' + originator.positions.map(originator => originator.name)
									: ''
							}`}
						</p>
					</div>
				),
			};
		});

		const onCompanyChange = async (name: string, option: Option) => {
			await setFieldValue(name, option);
			setFieldValue('newOriginator.companyCountry', { label: '', value: '' });
			handleSubmit();
		};

		useEffect(() => {
			const loadCompanyOptions = async () => {
				if (!existOriginator) {
					setLoadingCompanyOptions(true);
					await companyService.getCompanies();
					setLoadingCompanyOptions(false);
				}
			};
			const loadPositionOptions = async () => {
				if (!existOriginator) {
					setLoadingPositionOptions(true);
					await positionService.getPositions();
					setLoadingPositionOptions(false);
				}
			};

			setHaveOriginator(false);
			setFieldValue('existOriginator', null);
			loadCompanyOptions();
			loadPositionOptions();
		}, [companyService, existOriginator, positionService, setFieldValue]);

		useEffect(() => {
			if (!values.newOriginator.company) {
				return;
			}

			const updateCountry = async () => {
				if (values.newOriginator.company.value) {
					setLoadingCountryOptions(true);
					await countryService.getCountriesByCurrentCompany(
						parseInt(values.newOriginator.company.value)
					);
					setLoadingCountryOptions(false);
				}
			};

			updateCountry();
		}, [countryService, values.newOriginator.company]);

		useEffect(() => {
			if (!standardService) {
				return;
			}

			if (!values.newOriginator.firstName && !values.newOriginator.lastName) {
				setFieldValue('existOriginator', null);
				return setHaveOriginator(false);
			}

			const fetchOriginator = async () => {
				setLoadingExistOriginatorsOptions(true);
				await standardService.getOriginatorList({
					firstName: values.newOriginator.firstName,
					lastName: values.newOriginator.lastName,
					email: '',
					phone: '',
					company: '',
				});
				setLoadingExistOriginatorsOptions(false);
				setHaveOriginator(!!(standardService.originatorList.length > 0));
			};

			if (values.existOriginator === null) {
				fetchOriginator();
			}
		}, [
			standardService,
			values.existOriginator,
			values.newOriginator.firstName,
			values.newOriginator.lastName,
		]);

		const checkOriginatorEmail = useDebounce(async () => {
			await standardPublicService.checkOriginator({ email: values.newOriginator.email });
			if (standardPublicService.emailOriginator) {
				setFieldValue('existEmailOriginatorCredentials', {
					value: {
						id: standardPublicService.emailOriginator.id,
						userId: standardPublicService.emailOriginator.userId,
						firstName: standardPublicService.emailOriginator.firstName,
						lastName: standardPublicService.emailOriginator.lastName,
						phone: standardPublicService.emailOriginator.phone,
						email: standardPublicService.emailOriginator.email,
						company: standardPublicService.emailOriginator.company && {
							label: standardPublicService.emailOriginator.company.name,
							value: standardPublicService.emailOriginator.company.id,
						},
						country: standardPublicService.emailOriginator.country && {
							label: standardPublicService.emailOriginator.country.name,
							value: standardPublicService.emailOriginator.country.id,
						},
						positions: standardPublicService.emailOriginator.positions,
					},
					label: (
						<>
							<b
								style={{
									fontWeight: 500,
								}}>{`${standardPublicService.emailOriginator.firstName} ${standardPublicService.emailOriginator.lastName}`}</b>
							{`, ${standardPublicService.emailOriginator.email}, ${standardPublicService
								.emailOriginator?.phone}, ${standardPublicService.emailOriginator.company
								?.name}, ${standardPublicService.emailOriginator.country?.name} ${
								standardPublicService.emailOriginator.positions &&
								standardPublicService.emailOriginator.positions.length > 0
									? ', ' + standardPublicService.emailOriginator.positions.join(' ,')
									: ''
							}`}
						</>
					),
					customLabel: `${standardPublicService.emailOriginator.firstName} ${standardPublicService.emailOriginator.lastName}`,
					customOption: (
						<div style={{ paddingTop: '10px' }}>
							<p>{`${standardPublicService.emailOriginator.email}, ${standardPublicService.emailOriginator.phone}`}</p>
							<p>
								{`${standardPublicService.emailOriginator.company?.name}, ${standardPublicService
									.emailOriginator?.country?.name} ${
									standardPublicService.emailOriginator.positions &&
									standardPublicService.emailOriginator.positions.length > 0
										? ', ' + standardPublicService.emailOriginator.positions.join(' ,')
										: ''
								}`}
							</p>
						</div>
					),
				});
			}
			setIsWarningEmail(!!standardPublicService.emailOriginator);
		}, 1000);

		useEffect(() => {
			if (!values.newOriginator.email) {
				return setIsWarningEmail(false);
			}

			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
			if (!emailRegex.test(values.newOriginator.email)) {
				setIsWarningEmail(false);
				return;
			}

			checkOriginatorEmail();
		}, [setFieldValue, standardPublicService, values.newOriginator.email]);

		const checkOriginatorPhone = useDebounce(async () => {
			await standardPublicService.checkOriginator({
				phone:
					values.newOriginator.phone.toString().charAt(0) === '+'
						? values.newOriginator.phone.toString()
						: values.newOriginator.phone.length !== 0
						  ? `+${values.newOriginator.phone}`
						  : null,
			});
			if (standardPublicService.phoneOriginator) {
				setFieldValue('existPhoneOriginatorCredentials', {
					value: {
						id: standardPublicService.phoneOriginator.id,
						userId: standardPublicService.phoneOriginator.userId,
						firstName: standardPublicService.phoneOriginator.firstName,
						lastName: standardPublicService.phoneOriginator.lastName,
						phone: standardPublicService.phoneOriginator.phone,
						email: standardPublicService.phoneOriginator.email,
						company: standardPublicService.phoneOriginator.company && {
							label: standardPublicService.phoneOriginator.company.name,
							value: standardPublicService.phoneOriginator.company.id,
						},
						country: standardPublicService.phoneOriginator.country && {
							label: standardPublicService.phoneOriginator.country.name,
							value: standardPublicService.phoneOriginator.country.id,
						},
						positions: standardPublicService.phoneOriginator.positions,
					},
					label: (
						<>
							<b
								style={{
									fontWeight: 500,
								}}>{`${standardPublicService.phoneOriginator.firstName} ${standardPublicService.phoneOriginator.lastName}`}</b>
							{`, ${standardPublicService.phoneOriginator.email}, ${standardPublicService
								.phoneOriginator?.phone}, ${standardPublicService.phoneOriginator.company
								?.name}, ${standardPublicService.phoneOriginator.country?.name} ${
								standardPublicService.phoneOriginator.positions &&
								standardPublicService.phoneOriginator.positions.length > 0
									? ', ' + standardPublicService.phoneOriginator.positions.join(' ,')
									: ''
							}`}
						</>
					),
					customLabel: `${standardPublicService.phoneOriginator.firstName} ${standardPublicService.phoneOriginator.lastName}`,
					customOption: (
						<div style={{ paddingTop: '10px' }}>
							<p>{`${standardPublicService.phoneOriginator.email}, ${standardPublicService.phoneOriginator.phone}`}</p>
							<p>
								{`${standardPublicService.phoneOriginator.company?.name}, ${standardPublicService
									.phoneOriginator?.country?.name} ${
									standardPublicService.phoneOriginator.positions &&
									standardPublicService.phoneOriginator.positions.length > 0
										? ', ' + standardPublicService.phoneOriginator.positions.join(' ,')
										: ''
								}`}
							</p>
						</div>
					),
				});
				setIsWarningPhone(!!standardPublicService.phoneOriginator);
			}
		}, 1000);

		useEffect(() => {
			if (!standardPublicService || !values.newOriginator.phone) {
				return;
			}
			if (!values.newOriginator.phone) {
				return;
			}
			if (values.newOriginator.phone.length < 8) {
				setIsWarningPhone(false);
				return;
			}

			checkOriginatorPhone();
		}, [setFieldValue, standardPublicService, values.newOriginator.phone]);

		useEffect(() => {
			if (values.newOriginator.email && isFormSaved) {
				setFieldTouched(
					'newOriginator.email',
					!!standardPublicService.emailOriginator && isWarningEmail
				);
				setFieldError(
					'newOriginator.email',
					standardPublicService.emailOriginator && isWarningEmail
						? t('standard.createNWP.originator.emailExist')
						: ''
				);
			}
		}, [
			isFormSaved,
			isWarningEmail,
			setFieldError,
			setFieldTouched,
			standardPublicService.emailOriginator,
			values.newOriginator.email,
		]);

		useEffect(() => {
			if (values.newOriginator.phone && isFormSaved) {
				setFieldTouched(
					'newOriginator.phone',
					!!standardPublicService.phoneOriginator && isWarningPhone
				);
				setFieldError(
					'newOriginator.phone',
					standardPublicService.phoneOriginator && isWarningPhone
						? t('standard.createNWP.originator.phoneExist')
						: ''
				);
			}
		}, [
			isFormSaved,
			isWarningPhone,
			setFieldError,
			setFieldTouched,
			standardPublicService.emailOriginator,
			standardPublicService.phoneOriginator,
			values.newOriginator.phone,
		]);

		useEffect(() => {
			if (isSaveClicked) {
				setIsFormSaved(true);
			}
		}, [isSaveClicked]);

		const closeWarning = (isEmail: boolean, closeOriginator?: boolean) => {
			setHaveOriginator(false);
			setFieldValue('existOriginator', '');
			if (isEmail) {
				setFieldValue('existEmailOriginatorCredentials', null);
			} else {
				setFieldValue('existPhoneOriginatorCredentials', null);
			}
			if (closeOriginator) {
				setFieldValue('existPhoneOriginatorCredentials', null);
			}
		};

		const handleApplyOriginator = async () => {
			if (!values.existOriginator) {
				return;
			}

			await setFieldValue('originator', {
				label: (
					<>
						<b
							style={{
								fontWeight: 500,
							}}>{`${values.existOriginator.value.firstName} ${values.existOriginator.value.lastName}`}</b>
						{`, ${values.existOriginator.value.email}, ${values.existOriginator.value.phone}, ${values.existOriginator.value.company?.label}, ${values.existOriginator.value.country?.label}`}
					</>
				),
				value: {
					userId: values.existOriginator.value.id,
					firstName: values.existOriginator.value.firstName,
					lastName: values.existOriginator.value.lastName,
					phone:
						values.existOriginator.value.phone === null ? '' : values.existOriginator.value.phone,
					email: values.existOriginator.value.email,
					companyId:
						values.existOriginator.value.company && values.existOriginator.value.company.id,
					countryId:
						values.existOriginator.value.country && values.existOriginator.value.country.id,
					positions: values.existOriginator.value.positions.map(
						(position: Position) => position.id
					),
				},
			});

			closeWarning(true, true);
			setExistOriginator(true);
			handleSubmit();
		};

		const handleChangeEmail = async (field: string, value: string) => {
			await setFieldValue(field, value);
			setIsWarningEmail(false);
		};

		const handleChangeName = (field: string, value: string) => {
			setFieldValue(field, value);
			setFieldValue('existOriginator', null);
		};

		const handleChangePhone = async (field: string, value: string) => {
			await setFieldValue(field, value);
			setIsWarningPhone(false);
		};

		const handleApplyCredentials = async (isEmail: boolean) => {
			if (isEmail && !values.existEmailOriginatorCredentials) {
				return;
			}
			if (!isEmail && !values.existPhoneOriginatorCredentials) {
				return;
			}

			await setFieldValue(
				'isAsdOriginator',
				isEmail
					? values.existEmailOriginatorCredentials.value.userId === null
						? true
						: false
					: values.existPhoneOriginatorCredentials.value.userId === null
					  ? true
					  : false
			);

			await setFieldValue('originator', {
				label: (
					<>
						<b
							style={{
								fontWeight: 500,
							}}>{`${
							isEmail
								? values.existEmailOriginatorCredentials.value.firstName
								: values.existPhoneOriginatorCredentials.value.firstName
						} ${
							isEmail
								? values.existEmailOriginatorCredentials.value.lastName
								: values.existPhoneOriginatorCredentials.value.lastName
						}`}</b>
						{`, ${
							isEmail
								? values.existEmailOriginatorCredentials.value.email
								: values.existPhoneOriginatorCredentials.value.email
						}, ${
							isEmail
								? values.existEmailOriginatorCredentials.value.phone
								: values.existPhoneOriginatorCredentials.value.phone
						}, ${
							isEmail
								? values.existEmailOriginatorCredentials.value.company?.label
								: values.existPhoneOriginatorCredentials.value.company?.label
						}, ${
							isEmail
								? values.existEmailOriginatorCredentials.value.country?.label
								: values.existPhoneOriginatorCredentials.value.country?.label
						}`}
					</>
				),
				value: isEmail
					? {
							userId:
								values.existEmailOriginatorCredentials.value.id ??
								values.existEmailOriginatorCredentials.value.userId,
							firstName: values.existEmailOriginatorCredentials.value.firstName,
							lastName: values.existEmailOriginatorCredentials.value.lastName,
							phone:
								values.existEmailOriginatorCredentials.value.phone === null
									? ''
									: values.existEmailOriginatorCredentials.value.phone,
							email: values.existEmailOriginatorCredentials.value.email,
							companyId:
								values.existEmailOriginatorCredentials.value.company &&
								values.existEmailOriginatorCredentials.value.company.id,
							countryId:
								values.existEmailOriginatorCredentials.value.country &&
								values.existEmailOriginatorCredentials.value.country.id,
							positions:
								values.existEmailOriginatorCredentials.value.positions &&
								values.existEmailOriginatorCredentials.value.positions.map(
									(position: Position) => position.id
								),
					  }
					: {
							userId:
								values.existPhoneOriginatorCredentials.value.id ??
								values.existPhoneOriginatorCredentials.value.userId,
							firstName: values.existPhoneOriginatorCredentials.value.firstName,
							lastName: values.existPhoneOriginatorCredentials.value.lastName,
							phone:
								values.existPhoneOriginatorCredentials.value.phone === null
									? ''
									: values.existPhoneOriginatorCredentials.value.phone,
							email: values.existPhoneOriginatorCredentials.value.email,
							companyId:
								values.existPhoneOriginatorCredentials.value.company &&
								values.existPhoneOriginatorCredentials.value.company.id,
							countryId:
								values.existPhoneOriginatorCredentials.value.country &&
								values.existPhoneOriginatorCredentials.value.country.id,
							positions:
								values.existPhoneOriginatorCredentials.value.positions &&
								values.existPhoneOriginatorCredentials.value.positions.map(
									(position: Position) => position.id
								),
					  },
			});

			closeWarning(isEmail);
			setExistOriginator(true);
			handleSubmit();
		};

		const handleCreateNewPosition = async (value: string) => {
			if (value.length > 255) {
				setFieldError(
					'newOriginator.position',
					t('user.userInvite.fieldErrors.length', { number: '1-255' })
				);
				return;
			}

			setIsLoading(true);

			const newPosition = await positionService.createPosition(value);

			if (values.newOriginator.position !== undefined) {
				setFieldValue('newOriginator.position', [
					...values.newOriginator.position,
					{ label: newPosition.name, value: newPosition.id.toString() },
				]);
			} else {
				setFieldValue('newOriginator.position', [
					{ label: newPosition.name, value: newPosition.id.toString() },
				]);
			}

			setIsLoading(false);
		};

		return (
			<Flex $wrap $justify="space-between">
				<Field
					component={FormInput}
					name="newOriginator.firstName"
					title={t('standard.createNWP.originator.firstName')}
					placeholder={t('user.userInvite.firstNamePlaceholder')}
					mandatory={!standardService.singleStandard?.isMigrated}
					showError
					smallerWidth
					useSubmitOnBlur
					onChange={handleChangeName}
				/>
				<Field
					component={FormInput}
					name="newOriginator.lastName"
					title={t('standard.createNWP.originator.lastName')}
					mandatory={!standardService.singleStandard?.isMigrated}
					showError
					smallerWidth
					useSubmitOnBlur
					onChange={handleChangeName}
				/>
				{haveOriginator && (
					<ErrorFormContainer
						showClose
						isError={false}
						title={t('standard.createNWP.originator.originatorNameExist')}
						handleClose={() => closeWarning(true, true)}
						handleApply={handleApplyOriginator}
						description={
							<>
								{t('standard.createNWP.originator.originatorSelectDescription')}
								<Field
									component={FormSelect}
									name="existOriginator"
									fullWidth
									options={existOriginatorsOptions}
									isLoading={loadingExistOriginatorsOptions}
								/>
							</>
						}
					/>
				)}
				<Field
					component={FormInput}
					name="newOriginator.email"
					title={t('standard.createNWP.originator.email')}
					placeholder="example@gmail.com"
					mandatory={!standardService.singleStandard?.isMigrated}
					isWarning={isWarningEmail}
					showError
					smallerWidth
					useSubmitOnBlur
					onChange={handleChangeEmail}
				/>
				<Field
					component={FormInputPhone}
					smallerWidth
					name="newOriginator.phone"
					title={t('standard.createNWP.originator.phone')}
					placeholder="+11 1111 111111"
					isWarning={isWarningPhone}
					type="number"
					showError
					useSubmitOnBlur
					onChange={handleChangePhone}
				/>
				{isWarningEmail && standardPublicService.emailOriginator && (
					<ErrorFormContainer
						isError={!!isFormSaved}
						handleApply={() => handleApplyCredentials(true)}
						title={t('standard.createNWP.originator.originatorExist')}
						description={
							<>
								{standardPublicService.emailOriginator?.email},{' '}
								{standardPublicService.emailOriginator?.phone === null
									? ''
									: `${standardPublicService.emailOriginator?.phone}, `}
								{standardPublicService.emailOriginator?.firstName},{' '}
								{standardPublicService.emailOriginator?.lastName},{' '}
								{standardPublicService.emailOriginator?.company?.name},{' '}
								{standardPublicService.emailOriginator?.country?.name}
								{standardPublicService.emailOriginator?.positions &&
								standardPublicService.emailOriginator.positions.length > 0
									? ', ' +
									  standardPublicService.emailOriginator.positions
											.map(item => item.name)
											.join(' ,')
									: ''}{' '}
								{t('standard.createNWP.originator.originatorDescription')}
							</>
						}
					/>
				)}
				{isWarningPhone && standardPublicService.phoneOriginator && (
					<ErrorFormContainer
						isError={!!isFormSaved}
						handleApply={() => handleApplyCredentials(false)}
						title={t('standard.createNWP.originator.originatorExist')}
						description={
							<>
								{standardPublicService.phoneOriginator?.email},{' '}
								{standardPublicService.phoneOriginator?.phone === null
									? ''
									: `${standardPublicService.phoneOriginator?.phone}, `}
								{standardPublicService.phoneOriginator?.firstName},{' '}
								{standardPublicService.phoneOriginator?.lastName},{' '}
								{standardPublicService.phoneOriginator?.company?.name},{' '}
								{standardPublicService.phoneOriginator?.country?.name}
								{standardPublicService.phoneOriginator?.positions &&
								standardPublicService.phoneOriginator.positions.length > 0
									? ', ' +
									  standardPublicService.phoneOriginator.positions
											.map(item => item.name)
											.join(' ,')
									: ''}{' '}
								{t('standard.createNWP.originator.originatorDescription')}
							</>
						}
					/>
				)}
				<Field
					component={FormSelect}
					name="newOriginator.company"
					title={t('standard.createNWP.originator.company')}
					options={companyOptions}
					onChange={onCompanyChange}
					mandatory={!standardService.singleStandard?.isMigrated}
					showError
					smallerWidth
					useSubmitOnChange
					isLoading={loadingCompanyOptions}
				/>
				<Field
					key={`${values.newOriginator.company && values.newOriginator.company.value}`}
					component={FormSelect}
					name="newOriginator.companyCountry"
					title={t('standard.createNWP.originator.country')}
					options={countryOptions}
					mandatory={!standardService.singleStandard?.isMigrated}
					disabled={!values.newOriginator.company}
					showError
					smallerWidth
					isLoading={loadingCountryOptions}
					useSubmitOnChange
				/>
				<Field
					component={FormSelectCreatable}
					name="newOriginator.position"
					title={t('standard.createNWP.originator.position')}
					options={positionOptions}
					onCreateOption={handleCreateNewPosition}
					isMulti
					fullWidth
					showError
					isLoading={isLoading || loadingPositionOptions}
					useSubmitOnChange
				/>
			</Flex>
		);
	}
);
