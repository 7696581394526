import styled from 'styled-components';

export const StyledFilterCheckboxGroup = styled.div`
	.search {
		border: 1px solid #dae4e8;
		border-radius: 8px;
		height: 36px;
		padding: 0 16px;
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		input {
			font-size: 13px;

			&::placeholder {
				color: #abb3bb;
			}
		}

		svg {
			margin-right: 6px;
		}
	}

	.checkboxes {
		max-height: 180px;
		overflow: auto;

		&::-webkit-scrollbar {
			border-radius: 10px;
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme.bgColors.scrollTrack};
			margin: 4px 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.fontColors.accent};
			border-radius: 8px;
		}
	}

	.checkboxes > label {
		margin-bottom: 8px;
		line-height: normal;
	}
`;
