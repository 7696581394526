export enum StandardStatusDTO {
	WIP = 'WIP',
	AN = 'AN',
	PB = 'PB',
	RT = 'RT',
	DC = 'DC',
	SD = 'SD',
	ENWIP = 'ENWIP',
	EN = 'EN',
	SR = 'SR',
}
