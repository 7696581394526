import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { DomainListDTO } from '@asd-stan/domain/api/domain.dto';
import { makeDomainFromDomainDTO } from '@asd-stan/domain/api/domain.factory';
import { Domain } from '@asd-stan/domain/domain/domain.entity';

import { mapDomainMembers } from './domain-members.mapper';

const ALL_DOMAINS = gql`
	query {
		allDomains {
			name
			code
			workingGroups {
				code
				name
			}
		}
	}
`;

const DOMAIN_MEMBERS_BY_CODE = gql`
	query ($code: String!, $limit: Int!, $offset: Int!) {
		domainParticipationsByCode(code: $code, limit: $limit, offset: $offset) {
			user {
				id
				firstName
				lastName
				email
				company {
					name
				}
				positions {
					name
				}
			}
			roles
		}
		domainParticipationsCountByCode(code: $code)
	}
`;

export class DomainRepo {
	async getDomains(): Promise<Domain[]> {
		const { data } = await stanWorksClient.query<DomainListDTO>({
			query: ALL_DOMAINS,
		});

		return data.allDomains.map(dto => makeDomainFromDomainDTO(dto));
	}

	async getDomainMembersrByCode(code: string, limit: number, offset: number) {
		const { data } = await stanWorksClient.query({
			query: DOMAIN_MEMBERS_BY_CODE,
			variables: {
				code,
				limit,
				offset,
			},
		});

		return mapDomainMembers(data);
	}
}
