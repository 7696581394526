import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';

import Background from '@asd-stan/ui-kit/assets/sign-in-bg.png';
import { Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { Logo } from '@asd-stan/ui-kit/components/logo/logo';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';

import { StyledAuthLayout } from './auth-layout.styled';

interface AuthLayoutProps {
	children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
	const { t } = useTranslation();

	const { accessToken }: Storage = localStorage;

	const options: Option[] = [
		{ value: 'en', label: t('auth.authLayout.enLabel') },
		{ value: 'fr', label: t('auth.authLayout.frLabel') },
	];

	const [selectedLanguage, setSelectedLanguage] = useState<Option>({
		value: 'en',
		label: t('auth.authLayout.enLabel'),
	});

	const handleLanguageChange = (selectedOption: SingleValue<Option>) => {
		setSelectedLanguage(selectedOption as Option);
	};

	if (accessToken) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<StyledAuthLayout>
			<Flex>
				<div className="container">
					<Flex $justify="space-between" $align="center" className="header">
						<Logo color="#0B93E3" />
						<Select<Option>
							options={options}
							value={selectedLanguage}
							onChange={handleLanguageChange}
							classNamePrefix="react-select"
							isSearchable={false}
						/>
					</Flex>
					<Flex $align="center" $direction="column" className="form">
						{children}
					</Flex>
				</div>
				<div className="image-wrapper">
					<img className="backgroundImage" src={Background} alt="auth-background" />
				</div>
			</Flex>
		</StyledAuthLayout>
	);
};
