import * as yup from 'yup';
import i18n from '@asd-stan/i18n/i18n';

export const SingleStandardScheme = yup.object().shape({
    remark: yup.string().max(1000, ' ').min(1),
})

export const StageUpdateScheme = yup.object().shape({
  comment: yup.string().max(1000, ' ').min(1),
  targetDate: yup.date() || null,
})

export const SingleStandardAttachmentScheme = yup.object().shape({
    attachments: yup.array().of(
      yup.object().shape({
          attachmentDescription: yup
            .string()
            .min(50, i18n.t('standard.createNWP.validationErrors.tooShort', { length: 50 }))
            .max(500, ' ')
            .required(i18n.t('standard.createNWP.validationErrors.required')),
          attachmentType: yup
            .object()
            .shape({
                value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
                label: yup.string(),
            })
            .required(i18n.t('standard.createNWP.validationErrors.required')),
      })
    ),
})
