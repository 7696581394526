import { injector } from '@asd-stan/injector/injector';
import { AppointerRepo } from '@asd-stan/user/api/appointer/appointer.repo';
import { CompanyRepo } from '@asd-stan/user/api/company/company.repo';
import { CountryRepo } from '@asd-stan/user/api/country/country.repo';
import { PositionRepo } from '@asd-stan/user/api/position/position.repo';
import { UserRepo } from '@asd-stan/user/api/user.repo';
import { AppointerService } from '@asd-stan/user/domain/appointer.service';
import { CompanyService } from '@asd-stan/user/domain/company.service';
import { CountryService } from '@asd-stan/user/domain/country.service';
import { PositionService } from '@asd-stan/user/domain/position.service';
import { UserService } from '@asd-stan/user/domain/user.service';
import {
	APPOINTER_REPO,
	APPOINTER_SERVICE,
	COMPANY_REPO,
	COMPANY_SERVICE,
	COUNTRY_REPO,
	COUNTRY_SERVICE,
	POSITION_REPO,
	POSITION_SERVICE,
	USER_PUBLIC_SERVICE,
	USER_REPO,
	USER_SERVICE,
} from '@asd-stan/user/infrastructure/constants';
import { UserPublicService } from '@asd-stan/user/public-contract/user.public-service';

injector.set(USER_REPO, new UserRepo());
injector.set(USER_SERVICE, new UserService());
injector.set(USER_PUBLIC_SERVICE, new UserPublicService());

injector.set(COMPANY_REPO, new CompanyRepo());
injector.set(COMPANY_SERVICE, new CompanyService());

injector.set(COUNTRY_REPO, new CountryRepo());
injector.set(COUNTRY_SERVICE, new CountryService());

injector.set(APPOINTER_REPO, new AppointerRepo());
injector.set(APPOINTER_SERVICE, new AppointerService());

injector.set(POSITION_REPO, new PositionRepo());
injector.set(POSITION_SERVICE, new PositionService());
