import React, { useState } from 'react';

import { ReactComponent as TooltipIcon } from '@asd-stan/ui-kit/assets/icons/tooltip-icon.svg';

import { StyledTooltip } from '@asd-stan/ui-kit/components/tooltip/tooltip.styled';

interface ToolTipProps {
	text: string;
	width?: string;
	children?: React.ReactElement | string;
}

export const Tooltip: React.FC<ToolTipProps> = ({ children, text, width, ...rest }) => {
	const [show, setShow] = useState<boolean>(false);

	return (
		<StyledTooltip $show={show} $width={width}>
			<div className="tooltip">
				{text}
				<span className="tooltip-arrow" />
			</div>
			<div
				className="tooltip-icon"
				{...rest}
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}>
				{children ? children : <TooltipIcon />}
			</div>
		</StyledTooltip>
	);
};
