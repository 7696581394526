import React, { useEffect, useState } from 'react';
import { GroupBase, MultiValue, Options, SingleValue, components } from 'react-select';
import { LoadOptions, withAsyncPaginate } from 'react-select-async-paginate';
import type { ComponentProps, UseAsyncPaginateParams } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import type { CreatableProps } from 'react-select/creatable';

import { generatePlaceholder } from '@asd-stan/helpers/app-utils';
import { AdditionalType } from '@components/form-select/form-select-async-pagination';
import { Tooltip } from '@components/tooltip/tooltip';
import { Flex } from '@components/utility/flex';
import { MarkedText } from '@components/utility/marked-text';
import { ErrorMessage, FieldProps } from 'formik';

import { StyledCheckboxOption, StyledCreateLabel, StyledFormSelect } from './form-select.styled';

import { StyledFieldErrorMessage } from '@components/utility/field-error-message.styled';

export interface Option {
	value: string;
	label: string | string[];
}

interface FormSelectAsyncProps {
	fullWidth?: boolean;
	title: string;
	mandatory?: boolean;
	tooltip?: string;
	disabled?: boolean;
	showError?: boolean;
	isMulti?: boolean;
	placeholder?: string;
	isLoading?: boolean;
	options?: Option[];
	defaultOptions?: Option[] | boolean;
	loadOptions: LoadOptions<Option, GroupBase<Option>, unknown>;
	onChange?: (name: string, option: Option | Option[]) => void;
	onCreateOption?: (value: string) => void;
	isOptionDisabled?: ((option: Option, selectValue: Options<Option>) => boolean) | undefined;
	showCheckboxOption?: boolean;
	closeMenuOnSelect?: boolean;
	hideSelectedOptions?: boolean;
	setTouchedOnBlur?: boolean;
	useSubmitOnChange?: boolean;
}

type AsyncPaginateCreatableProps<
	OptionType,
	Group extends GroupBase<OptionType>,
	Additional,
	IsMulti extends boolean,
> = CreatableProps<OptionType, IsMulti, Group> &
	UseAsyncPaginateParams<OptionType, Group, Additional> &
	ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
	OptionType,
	Group extends GroupBase<OptionType>,
	Additional,
	IsMulti extends boolean = false,
>(
	props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => React.ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

export const FormSelectAsyncCreatablePagination: React.FC<FormSelectAsyncProps & FieldProps> = ({
	form,
	field,
	fullWidth,
	title,
	mandatory,
	tooltip,
	disabled,
	showError,
	isMulti,
	placeholder,
	isLoading,
	defaultOptions,
	loadOptions,
	onChange,
	isOptionDisabled,
	onCreateOption,
	showCheckboxOption,
	closeMenuOnSelect = true,
	hideSelectedOptions = true,
	setTouchedOnBlur = false,
	useSubmitOnChange = false,
}) => {
	const [value, setValue] = useState<Option>(field.value);

	const defaultAdditional: AdditionalType = {
		page: 1,
	};

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	const handleChange = (option: MultiValue<Option> | SingleValue<Option>) => {
		if (onChange) {
			onChange(field.name, option as Option);
			setValue(prevState => field.value);
		} else {
			form.setFieldValue(field.name, option);
			setValue(field.value);
		}
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (isMulti && useSubmitOnChange) {
			form.handleSubmit();
		}
	};

	const CheckboxOption = (props: any) => {
		return (
			<StyledCheckboxOption>
				<components.Option {...props}>
					<div className="container">
						{showCheckboxOption && (
							<input type="checkbox" checked={props.isSelected} onChange={() => null} />
						)}
						<label>{props.label}</label>
						{props.data.roles && props.data.roles}
					</div>
				</components.Option>
			</StyledCheckboxOption>
		);
	};

	return (
		<StyledFormSelect
			$error={!!(form.touched[field.name] && form.errors[field.name])}
			$fullWidth={fullWidth}>
			<Flex>
				<label title={title} className={disabled ? 'disabled' : ''}>
					{title}
					{mandatory && <MarkedText>*</MarkedText>}
				</label>
				{tooltip ? <Tooltip text={tooltip} /> : null}
			</Flex>
			<CreatableAsyncPaginate
				isDisabled={disabled}
				isOptionDisabled={isOptionDisabled}
				classNamePrefix="Select"
				formatCreateLabel={(userInput: string) => (
					<StyledCreateLabel>
						{userInput}
						<span className="new-value-label">(New value)</span>
					</StyledCreateLabel>
				)}
				isMulti={isMulti}
				placeholder={generatePlaceholder(title, placeholder)}
				onCreateOption={onCreateOption}
				onBlur={handleBlur}
				additional={defaultAdditional}
				onChange={handleChange}
				isClearable={!isMulti}
				value={value}
				components={{
					Option: CheckboxOption,
				}}
				isLoading={isLoading}
				defaultOptions={defaultOptions}
				loadOptions={loadOptions}
				hideSelectedOptions={isMulti ? false : hideSelectedOptions && hideSelectedOptions}
				closeMenuOnSelect={
					form.values[field.name] && form.values[field.name].value === 'None'
						? false
						: isMulti
						  ? false
						  : closeMenuOnSelect && closeMenuOnSelect
				}
			/>
			{showError ? (
				<ErrorMessage
					name={field.name}
					children={(errorMessage: any) => {
						if (typeof errorMessage === 'string')
							return <StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>;
						if (typeof errorMessage === 'object') {
							if (!isMulti) {
								return (
									<StyledFieldErrorMessage className="error-message">
										{Object.values(errorMessage)[0] as string}
									</StyledFieldErrorMessage>
								);
							} else {
								return (
									<StyledFieldErrorMessage className="error-message">
										{errorMessage[0].value}
									</StyledFieldErrorMessage>
								);
							}
						}
					}}
				/>
			) : null}
		</StyledFormSelect>
	);
};
