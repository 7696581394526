import uuid from 'react-uuid';

import { UserAccess, UserInfo } from '@asd-stan/user/api/single-user.mapper';

interface MeDto {
	domainParticipationsByUserId: [
		{
			domain: {
				code: string;
				name: string;
			};
			workingGroupParticipations: {
				workingGroup: {
					code: string;
					name: string;
				};
				roles: string[];
			}[];
			roles: string[];
			seeAllDomain: boolean;
		},
	];
	me: {
		access: UserAccess;
		id: number;
		firstName: string;
		lastName: string;
		email: string;
		phone: string | null;
		systemRoles: string[];
		appointedBy: {
			id: number;
			name: string;
		};
		company: null | {
			id: number;
			name: string;
		};
		country: null | {
			id: number;
			name: string;
		};
		positions: {
			id: number;
			name: string;
		}[];
	};
}

export const mapMe = ({
	domainParticipationsByUserId,
	me: {
		id,
		access,
		firstName,
		lastName,
		email,
		phone,
		appointedBy,
		systemRoles,
		company,
		country,
		positions,
	},
}: MeDto): UserInfo => {
	return {
		id,
		access: access,
		fields: {
			firstName,
			lastName,
			email,
			phone: phone || '',
			appointedBy: {
				label: appointedBy.name,
				value: appointedBy.id,
			},
			systemRoles: systemRoles.map(value => ({ value: value, label: value })),
			company: company
				? {
						value: company.id,
						label: company.name,
				  }
				: null,
			country: country
				? {
						value: country.id,
						label: country.name,
				  }
				: null,
			positions: positions.map(({ id, name }) => ({ value: id, label: name })),
			domains: domainParticipationsByUserId.map(
				({ domain, roles, seeAllDomain, workingGroupParticipations }) => ({
					id: uuid(),
					domain: { label: domain.name, value: domain.code },
					seeAllDomain,
					domainRoles: roles.map(r => ({ label: r, value: r })),
					WGC: workingGroupParticipations
						.filter(({ roles }) => roles.includes('WGC'))
						.map(({ workingGroup: wg }) => ({ label: wg.name, value: wg.code })),
					WGS: workingGroupParticipations
						.filter(({ roles }) => roles.includes('WGS'))
						.map(({ workingGroup: wg }) => ({ label: wg.name, value: wg.code })),
					workingGroupExpert: workingGroupParticipations
						.filter(({ roles }) => roles.includes('Expert'))
						.map(({ workingGroup: wg }) => ({ label: wg.name, value: wg.code })),
				})
			),
		},
	};
};
