import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getModalService } from '@asd-stan/shell/infrastructure/getters';
import { getStandardDetailsService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { Button } from '@components/button/button';
import { EmptyTableSearch } from '@components/content-table/empty-table-search';
import { Input } from '@components/input/input';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';

import { ReactComponent as EditIcon } from './assets/edit.svg';
import { StyledModal, StyledPriceHistorySection } from './price-history-section.styled';

import { StyledTable } from '@components/content-table/table.styled';

const priceRegEx = /^\d{0,6}(?:\.\d{0,2})?$/;

const Modal = ({
	title,
	handleSubmit,
	defaultValue,
}: {
	title: string;
	handleSubmit(price: number): void;
	defaultValue?: number;
}) => {
	const { t } = useTranslation();
	const modalService = getModalService();
	const [nextPrice, setNextPrice] = useState(defaultValue === undefined ? '' : `${defaultValue}`);

	const price = Number(nextPrice);
	const disabled = Number.isNaN(price) || nextPrice.length === 0 || (price > 0 && price < 0.5);

	return (
		<StyledModal>
			<h4>{title}</h4>
			<Input
				placeholder={t('standard.singleStandard.priceHistory.enterPrice')}
				value={nextPrice}
				onChange={e => {
					const nextPrice = e.target.value;
					if (priceRegEx.test(nextPrice)) {
						setNextPrice(nextPrice);
					}
				}}
			/>
			<div className="hint">{t('standard.singleStandard.priceHistory.hint')}</div>
			<div className="buttons">
				<Button
					secondary
					title={t('standard.singleStandard.priceHistory.cancel')}
					onClick={() => modalService.closeModal()}
				/>
				<Button
					disabled={disabled}
					title={t('standard.singleStandard.priceHistory.confirm')}
					onClick={() => {
						handleSubmit(+nextPrice);
						modalService.closeModal();
					}}
				/>
			</div>
		</StyledModal>
	);
};

export const PriceHistorySection: FC = observer(() => {
	const { t } = useTranslation();
	const modalService = getModalService();
	const standardService = getStandardDetailsService();
	const params = useParams();
	const id = Number(params.id);
	const priceHistory = standardService.priceHistory;

	const updatePrice = async (amount: number) => {
		await standardService.updatePrice(id, amount);
	};

	const addPrice = async (amount: number) => {
		await standardService.addPrice(id, amount);
	};

	const openEditModal = (currentPrice: number) =>
		modalService.openModal(
			<Modal
				title={t('standard.singleStandard.priceHistory.editPrice')}
				handleSubmit={updatePrice}
				defaultValue={currentPrice}
			/>
		);

	const openAddModal = () =>
		modalService.openModal(
			<Modal title={t('standard.singleStandard.priceHistory.addPrice')} handleSubmit={addPrice} />
		);

	return (
		<StyledPriceHistorySection>
			{priceHistory === null ? (
				<div className="loading">
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</div>
			) : priceHistory.standardPriceList.length === 0 ? (
				<EmptyTableSearch
					style={{ height: 'calc(100vh - 270px)' }}
					emptySearchText={
						<Button onClick={openAddModal} title="Add new price" className="addPrice" />
					}
				/>
			) : (
				<StyledTable>
					<thead>
						<tr>
							<th>{t('standard.singleStandard.priceHistory.price')}</th>
							<th>{t('standard.singleStandard.priceHistory.year')}</th>
							<th>{t('standard.singleStandard.priceHistory.actions')}</th>
						</tr>
					</thead>
					<tbody>
						{priceHistory?.standardPriceList.map(({ amount, year }, index) => (
							<tr key={year}>
								<td>€{amount.toFixed(2)}</td>
								<td>{year}</td>
								<td>
									{index === 0 && (
										<div className="edit">
											<button className="editIcon" onClick={() => openEditModal(amount)}>
												<EditIcon />
											</button>
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</StyledTable>
			)}
		</StyledPriceHistorySection>
	);
});
