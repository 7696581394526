import styled from 'styled-components';

export const StyledEditUser = styled.div`
	height: fit-content;
	margin-bottom: 50px;

	.access {
		margin-top: 4px;
		display: flex;
		font-size: 13px;
		font-weight: 500;
		line-height: 150%;
		align-items: center;

		.indicator {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #d9dc32;
			margin-right: 6px;
			font-weight: 500;

			&.active {
				background-color: #1dc606;
			}
		}

		.resendIcon {
			margin-left: 6px;
			cursor: pointer;
		}

		.resendTimer {
			color: #abb3bb;
			font-weight: 500;
			margin-left: 6px;
		}
	}
`;

export const UpdateUserLoader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 50vh;

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;
