import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { isRoleAllowToSeeDomain } from '@asd-stan/helpers/app-utils';
import { ReactComponent as Plus } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/plus-icon.svg';
import { BorderButton } from '@asd-stan/ui-kit/components/button/border-button';
import { ButtonClose } from '@asd-stan/ui-kit/components/button/button-close';
import { FormCheckbox } from '@asd-stan/ui-kit/components/form-checkbox/form-checkbox';
import { FormSelect, Option } from '@asd-stan/ui-kit/components/form-select/form-select';
import { Toggle } from '@asd-stan/ui-kit/components/toggle/toggle';
import { Tooltip } from '@asd-stan/ui-kit/components/tooltip/tooltip';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { MarkedText } from '@asd-stan/ui-kit/components/utility/marked-text';
import { domainRoles } from '@asd-stan/user/components/invite-users/forms/select-data/data';
import { Field, FieldArray, FormikValues, useFormikContext } from 'formik';

import { StyledDomain } from '@asd-stan/user/components/layout/input-group/input-group.styled';

export const Domain: React.FC = () => {
	const [assignToAllDomains, setAssignToAllDomains] = useState<boolean>(false);
	const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();
	const { t } = useTranslation();

	const domainService = getDomainService();

	const domainOptions = domainService.domains.map(domain => {
		return { label: `${domain.code} ${domain.name}`, value: domain.code };
	});

	const getDomainOptions = (currentDomain: FormikValues) => {
		const newDomainOptions = domainOptions.filter(option => {
			return !values.domains.some((domain: FormikValues) => {
				return (
					domain.domain?.value === option?.value &&
					domain.domain?.value !== currentDomain.domain?.value
				);
			});
		});

		return newDomainOptions.length > 0 ? newDomainOptions : domainOptions;
	};

	const createOptionsFromDomainWorkGroups = (domainCode: string) => {
		const workGroups = domainService.getDomainWorkingGroups(domainCode);

		return workGroups?.map(workGroup => {
			return {
				label: `${workGroup.code} ${workGroup.name}`,
				value: workGroup.code,
			};
		});
	};

	const onDomainChange = (name: string, option: Option | Option[]) => {
		const nameParts = name.split('.');
		const index = nameParts[1];

		setFieldValue(`domains.${index}.WGC`, []);
		setFieldValue(`domains.${index}.workingGroupExpert`, []);
		setFieldValue(`domains.${index}.WGS`, []);
		setFieldValue(name, option);
	};

	const onDomainRoleChange = (name: string, option: Option | Option[]) => {
		const nameParts = name.split('.');
		const index = nameParts[1];

		if (Array.isArray(option)) {
			if (option[option.length - 1]?.value === 'None') {
				setFieldValue(name, option[option.length - 1]);

				setFieldValue(`domains.${index}.WGC`, []);
				setFieldValue(`domains.${index}.workingGroupExpert`, []);
				setFieldTouched(`domains.${index}.workingGroupExpert`, false);
				setFieldValue(`domains.${index}.WGS`, []);
			} else {
				setFieldValue(name, option);
			}
		} else {
			if (option.value !== 'None') {
				setFieldValue(name, [option]);
			}
		}
	};

	const showDomainRolesBySystemRoles = () => {
		const selectedRoles = values?.systemRoles.map((option: Option) => option.value);

		if (
			selectedRoles?.includes('Board member') &&
			!selectedRoles.includes('ES') &&
			!selectedRoles.includes('Expert') &&
			!selectedRoles.includes('MC')
		) {
			return domainRoles.filter(
				(role: Option) => role.value === 'DTC' || role.value === 'Expert' || role.value === 'FP'
			);
		}

		return domainRoles;
	};

	const renderExtraFields = (index: number) => {
		const selectedRoles = values.systemRoles.map((option: Option) => option?.value);

		if (isRoleAllowToSeeDomain(values)) {
			if (
				selectedRoles.includes('Board member') &&
				!selectedRoles.includes('ES') &&
				!selectedRoles.includes('Expert') &&
				!selectedRoles.includes('MC')
			) {
				return (
					<Flex $direction="column">
						<Field
							component={FormSelect}
							name={`domains.${index}.WGC`}
							title={t('user.userInvite.WGCField')}
							placeholder={t('user.userInvite.WGCPlaceholder')}
							options={
								createOptionsFromDomainWorkGroups(values.domains[index]?.domain?.value) || []
							}
							fullWidth
							isMulti
						/>
						<Field
							component={FormSelect}
							name={`domains.${index}.workingGroupExpert`}
							title={t('user.userInvite.expertField')}
							placeholder={t('user.userInvite.expertPlaceholder')}
							options={
								createOptionsFromDomainWorkGroups(values.domains[index]?.domain?.value) || []
							}
							fullWidth
							isMulti
						/>
					</Flex>
				);
			} else {
				if (values.domains[index]?.domainRoles?.value === 'None') {
					return (
						<Field
							component={FormSelect}
							name={`domains.${index}.WGS`}
							title={t('user.userInvite.WGSField')}
							placeholder={t('user.userInvite.WGSPlaceholder')}
							options={
								createOptionsFromDomainWorkGroups(values.domains[index]?.domain?.value) || []
							}
							isMulti
							fullWidth
							mandatory
							showError
						/>
					);
				} else {
					return (
						<>
							{selectedRoles?.length === 1 &&
							selectedRoles[0] === 'Expert' &&
							values.domains[index]?.domainRoles?.length === 1 &&
							values.domains[index]?.domainRoles[0]?.value === 'Expert' ? (
								<div className="checkbox-container">
									<Field
										component={FormCheckbox}
										name={`domains.${index}.seeAllDomain`}
										type="checkbox"
										label={t('user.userInvite.seeAllDomain')}
									/>
									<Tooltip width="250" text={t('user.userInvite.tooltips.seeAllDomain')} />
								</div>
							) : null}
							<Flex $direction="column">
								<Field
									component={FormSelect}
									name={`domains.${index}.WGC`}
									title={t('user.userInvite.WGCField')}
									placeholder={t('user.userInvite.WGCPlaceholder')}
									options={
										createOptionsFromDomainWorkGroups(values.domains[index]?.domain?.value) || []
									}
									isMulti
									fullWidth
								/>
								<Field
									component={FormSelect}
									name={`domains.${index}.workingGroupExpert`}
									title={t('user.userInvite.expertField')}
									placeholder={t('user.userInvite.expertPlaceholder')}
									options={
										createOptionsFromDomainWorkGroups(values.domains[index]?.domain?.value) || []
									}
									mandatory={
										selectedRoles.length === 1 &&
										selectedRoles[0] === 'Expert' &&
										values.domains[index]?.domainRoles.length === 1 &&
										values.domains[index]?.domainRoles[0]?.value === 'Expert' &&
										!values.domains[index]?.seeAllDomain
									}
									showError
									isMulti
									fullWidth
								/>
								<Field
									component={FormSelect}
									name={`domains.${index}.WGS`}
									title={t('user.userInvite.WGSField')}
									placeholder={t('user.userInvite.WGSPlaceholder')}
									options={
										createOptionsFromDomainWorkGroups(values.domains[index]?.domain?.value) || []
									}
									isMulti
									fullWidth
								/>
							</Flex>
						</>
					);
				}
			}
		}
	};

	const handleAssignToAllDomains = () => {
		if (domainOptions.length === 0) return;
		if (!assignToAllDomains) {
			const currentDomainMap: { [name: string]: any } = {};

			values.domains.forEach((domain: any) => {
				currentDomainMap[domain.domain?.value] = domain;
			});

			const updatedDomains = domainOptions.map(domainOption => {
				if (currentDomainMap[domainOption?.value]) {
					const existingDomain: any = currentDomainMap[domainOption.value];

					return {
						id: uuid(),
						domain: { value: domainOption?.value, label: domainOption?.label },
						domainRoles: existingDomain.domainRoles || [{ value: '', label: '' }],
						seeAllDomain: existingDomain.seeAllDomain || false,
						WGS: existingDomain.WGS || [{ value: '', label: '' }],
						WGC: existingDomain.WGC || [{ value: '', label: '' }],
						workingGroupExpert: existingDomain.workingGroupExpert || [{ value: '', label: '' }],
					};
				} else {
					return {
						id: uuid(),
						domain: { value: domainOption?.value, label: domainOption?.label },
						domainRoles: [{ value: '', label: '' }],
						seeAllDomain: false,
						WGS: [{ value: '', label: '' }],
						WGC: [{ value: '', label: '' }],
						workingGroupExpert: [{ value: '', label: '' }],
					};
				}
			});

			setFieldValue('domains', updatedDomains);
		} else {
			const updatedDomains = values.domains.filter((domain: any) => {
				return (
					domain.domainRoles[0]?.value !== '' ||
					domain.WGS[0]?.value !== '' ||
					domain.workingGroupExpert[0]?.value !== '' ||
					domain.seeAllDomain
				);
			});

			setFieldValue('domains', updatedDomains);
		}

		setAssignToAllDomains(!assignToAllDomains);
		setFieldTouched('domains', false);
	};

	const addDomain = () => {
		setFieldValue('domains', [
			...values.domains,
			{
				id: uuid(),
				domain: { value: '', label: '' },
				domainRoles: [{ value: '', label: '' }],
				seeAllDomain: false,
				WGS: [{ value: '', label: '' }],
				WGC: [{ value: '', label: '' }],
				workingGroupExpert: [{ value: '', label: '' }],
			},
		]);
	};

	const deleteDomain = (id: string) => {
		setAssignToAllDomains(false);
		const updatedDomains = values.domains.filter((domain: any) => domain.id !== id);
		setFieldValue('domains', updatedDomains);
	};

	return (
		<Flex $direction="column">
			{values.domains.length === 0 ? (
				<p>
					<Trans i18nKey="user.userInvite.noDomainsMessage">
						No Domain created yet, click <MarkedText>Add Domain</MarkedText> button to add Domain
					</Trans>
				</p>
			) : (
				<>
					<div className="checkbox-container">
						<Toggle
							checked={assignToAllDomains}
							onChange={handleAssignToAllDomains}
							label={t('user.userInvite.assignToAllDomains')}
						/>
						<Tooltip width="250" text={t('user.userInvite.tooltips.assignToAllDomains')} />
					</div>
					<FieldArray name="domains">
						{() =>
							values.domains.map((domain: any, index: number) => {
								return (
									<StyledDomain key={domain.id}>
										<Flex $align="flex-end" $justify="space-between">
											<p>
												{t('user.userInvite.domain')} {index + 1}
											</p>
											<ButtonClose onClick={() => deleteDomain(domain.id)} />
										</Flex>
										<Flex $justify="space-between">
											<Field
												key={`domain-${domain.id}`}
												component={FormSelect}
												name={`domains.${index}.domain`}
												title={t('user.userInvite.domainField')}
												placeholder={t('user.userInvite.domainPlaceholder')}
												options={getDomainOptions(domain)}
												mandatory
												showError
												disabled={assignToAllDomains}
												onChange={onDomainChange}
											/>
											<Field
												component={FormSelect}
												name={`domains.${index}.domainRoles`}
												title={t('user.userInvite.domainRoleField')}
												options={showDomainRolesBySystemRoles()}
												tooltip={t('user.userInvite.tooltips.domainRole')}
												isMulti={
													domain['domainRoles'] !== undefined
														? domain['domainRoles']?.value !== 'None'
														: false
												}
												onChange={onDomainRoleChange}
												showCheckboxOption
												closeMenuOnSelect={false}
												mandatory
												showError
												hideSelectedOptions
											/>
										</Flex>
										{domain.domain?.value !== '' ? renderExtraFields(index) : null}
									</StyledDomain>
								);
							})
						}
					</FieldArray>
				</>
			)}
			{values.domains?.length >= domainOptions.length ? null : (
				<BorderButton
					className="add-domain-button"
					icon={<Plus />}
					title={t('user.userInvite.buttons.addDomain')}
					onClick={addDomain}
				/>
			)}
		</Flex>
	);
};
