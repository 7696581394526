import { Company } from '@asd-stan/user/domain/company.entity';
import { getCompanyRepo } from '@asd-stan/user/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

export class CompanyService {
	private _companies: Company[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	private get _companyRepo() {
		return getCompanyRepo();
	}

	public async getCompanies() {
		const companies = await this._companyRepo.getCompanies();

		runInAction(() => {
			this._companies = companies;
		});
	}

	public async getCompaniesByCurrentCountry(id: number) {
		const companies = await this._companyRepo.getCompanyByCountryId(id);

		runInAction(() => {
			this._companies = companies;
		});
	}

	get companies() {
		return this._companies;
	}
}
