import {
	CellContext,
	ColumnDef,
	ColumnSort,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Expert, WGS } from '@asd-stan/standard/domain/standard-detailed.entity';
import { ReactComponent as FilterAsc } from '@asd-stan/ui-kit/assets/asd-stan-works/table/table-filter-a-z.svg';
import { ReactComponent as FilterDefault } from '@asd-stan/ui-kit/assets/asd-stan-works/table/table-filter-default.svg';
import { ReactComponent as FilterDesc } from '@asd-stan/ui-kit/assets/asd-stan-works/table/table-filter-z-a.svg';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';

import {
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
} from '../../single-standard.styled';

import {
	ExpertsContainer,
	ShowMoreBtn,
	ShowMoreBtnWrapper,
	StyledTableBody,
	StyledTableHead,
	WGSWrapper,
} from './experts-components.styled';
import { ExpertsTableDomains } from './experts-table-row-domains';
import { ExpertsTableRowWorkingGroups } from './experts-table-row-working-groups';
import { ProjectLeader } from './exprets-row/expert-row';
import { WGSUser } from './wgs/wgs';

import { StyledTable } from '@components/content-table/table.styled';
import { StyledEmptyTable } from '@components/content-table/table.styled';

const MAX_ROWS = 5;

interface Props {
	data: Expert[];
	wgs: WGS | null;
}

export const Experts: FC<Props> = props => {
	const { t } = useTranslation();
	const [showMoreRecords, setShowMoreRecords] = useState(false);

	const togglePagination = () => {
		setShowMoreRecords(prevValue => !prevValue);
		if (!showMoreRecords) {
			table.setPageSize(props.data.length);
		} else {
			table.setPageSize(MAX_ROWS);
		}
	};

	const [sorting, setSorting] = useState<ColumnSort[]>([]);

	const columns: ColumnDef<Expert, string>[] = [
		{
			header: t('standard.singleStandardPage.experts.tableName'),
			accessorKey: 'fullName',
			cell: ({ row }: CellContext<Expert, string>) => <ProjectLeader row={row} />,
		},
		{
			header: t('standard.singleStandardPage.experts.tableDomains'),
			accessorKey: 'domains',
			cell: ({ row }: CellContext<Expert, string>) => <ExpertsTableDomains row={row} />,
		},
		{
			header: t('standard.singleStandardPage.experts.tableWorkingGroups'),
			accessorKey: 'workingGroups',
			cell: ({ row }: CellContext<Expert, string>) => <ExpertsTableRowWorkingGroups row={row} />,
		},
	];

	const table = useReactTable<Expert>({
		data: props.data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		state: {
			sorting: sorting,
		},
		columns: columns,
		onSortingChange: prevState => setSorting(prevState),
	});

	useEffect(() => {
		table.setPageSize(MAX_ROWS);
	}, [table]);

	if (!props.data.length && !props.wgs) {
		return null;
	}

	return (
		<InputGroup title={t('standard.singleStandardPage.experts.title')}>
			<ExpertsContainer $hasExperts={props.data.length === 0}>
				{props.data.length === 0 ? null : table.getFilteredRowModel().rows.length > 0 ? (
					<>
						<StyledTable
							style={{ outline: '1px solid #DAE4E8', border: 'none', position: 'relative' }}>
							{props.data.length > MAX_ROWS ? (
								<ShowMoreBtnWrapper>
									<ShowMoreBtn onClick={togglePagination}>
										{showMoreRecords
											? t('standard.singleStandardPage.experts.hide')
											: t('standard.singleStandardPage.experts.showMore')}
									</ShowMoreBtn>
								</ShowMoreBtnWrapper>
							) : null}
							<StyledTableHead>
								{table.getHeaderGroups().map(headerGroup => (
									<tr key={headerGroup.id}>
										{headerGroup.headers.map((header, index) => (
											<th key={header.id} onClick={header.column.getToggleSortingHandler()}>
												{header.isPlaceholder ? null : (
													<div>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{index === 0 &&
															(header.column.getIsSorted() ? (
																header.column.getIsSorted() === 'desc' ? (
																	<FilterDesc />
																) : (
																	<FilterAsc />
																)
															) : (
																<FilterDefault />
															))}
													</div>
												)}
											</th>
										))}
									</tr>
								))}
							</StyledTableHead>
							<StyledTableBody>
								{table.getRowModel().rows.map(row => (
									<tr key={row.id}>
										{row.getVisibleCells().map(cell => (
											<td key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										))}
									</tr>
								))}
							</StyledTableBody>
						</StyledTable>
					</>
				) : (
					<StyledEmptyTable />
				)}
				{props.wgs ? (
					<WGSWrapper>
						<StandardDisplayFieldHeader>
							{t('standard.singleStandardPage.experts.secretariat')}
						</StandardDisplayFieldHeader>
						<StandardDisplayFieldValue style={{ marginTop: 16 }}>
							<WGSUser wgs={props.wgs} />
						</StandardDisplayFieldValue>
					</WGSWrapper>
				) : null}
			</ExpertsContainer>
		</InputGroup>
	);
};
