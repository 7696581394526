import { User } from '@asd-stan/user/domain/user.entity';
import { makeAutoObservable } from 'mobx';

interface UserPublicEntityArgs {
	user: User;
}

export class UserPublic {
	private _user: User;

	constructor(args: UserPublicEntityArgs) {
		makeAutoObservable(this);

		this._user = args.user;
	}

	// getters

	get id() {
		return this._user.id;
	}
	get firstName() {
		return this._user.firstName;
	}
	get lastName() {
		return this._user.lastName;
	}
	get fullName() {
		return this._user.fullName;
	}
	get phone() {
		return this._user.phone;
	}
	get email() {
		return this._user.email;
	}
	get available() {
		return this._user.available;
	}
	get appointer() {
		return this._user.appointer;
	}
	get invitedBy() {
		return this._user.invitedBy;
	}
	get country() {
		return this._user.country;
	}
	get company() {
		return this._user.company;
	}
	get systemRoles() {
		return this._user.systemRoles;
	}
	get createdAt() {
		return this._user.createdAt;
	}
	get updatedAt() {
		return this._user.updatedAt;
	}

	// getters end
}
