import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import en from './locales/en.json';

export const resources = {
	en: {
		translation: en,
	},
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: i18n.language,
		defaultNS: 'translation',
		fallbackLng: 'en',
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		resources,
	});

export default i18n;
