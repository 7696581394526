import styled from 'styled-components';

export const StyledUserList = styled.div`
	margin-top: 24px;
	background-color: ${({ theme }) => theme.bgColors.primary};

  .roles {
    overflow: visible;
  }
`;
