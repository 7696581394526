import { FC, useState } from "react";
import { Row } from "@tanstack/react-table";

import { ExpertsMoreBtn, TableRow } from "./experts-components.styled";
import { Domain, Expert } from "@asd-stan/standard/domain/standard-detailed.entity";

const MAX_DOMAINS = 5;

interface Props {
    row: Row<Expert>;
}

export const ExpertsTableDomains: FC<Props> = ({row}) => {
    const [showMoreDomains, setShowMoreDomains] = useState(false);

    const domainsRaw: Domain[] = row.getValue('domains');
    const domains = showMoreDomains ? domainsRaw : domainsRaw.slice(0, MAX_DOMAINS);

    const toggleDomains = () => setShowMoreDomains(prev => !prev);

    return (
        <div>
            {domains
                .map((domain, idx) => (
                    <TableRow title={`${domain.code} ${domain.name}`} key={`${idx}-${domain.code}`}>{domain.code} {domain.name}</TableRow>
                ))}
            {!showMoreDomains && domainsRaw.length > MAX_DOMAINS ? <ExpertsMoreBtn onClick={toggleDomains}>...</ExpertsMoreBtn> : null}
        </div>
    )
}