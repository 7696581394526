import i18n from '@asd-stan/i18n/i18n';
import * as yup from 'yup';

export const meetingValidationSchema = yup.object().shape({
	title: yup.string().max(1000, '').required(i18n.t('meeting.createMeeting.errors.required')),
	type: yup.object().required(i18n.t('meeting.createMeeting.errors.required')),
	startTime: yup.date().required(i18n.t('meeting.createMeeting.errors.required')),
	link: yup
		.string()
		.test(
			'url test',
			i18n.t('meeting.createMeeting.errors.url'),
			value => !value || /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})+(\/.*)?$/.test(value!)
		)
		.max(1000, i18n.t('meeting.createMeeting.errors.max1000')),
	location: yup.string().max(1000, ' ').required(i18n.t('meeting.createMeeting.errors.required')),
	files: yup.array().of(
		yup.object().shape({
			description: yup.string().max(500, ' '),
		})
	),
});
