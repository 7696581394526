import { styled } from 'styled-components';
import { CSS } from 'styled-components/dist/types';

export const StyledTableHead = styled.thead`
	border-top-style: hidden;
`;

export const StageAndStatusWrapper = styled.div`
	margin-top: 16px;
`;

export const StageTable = styled.div`
	min-width: 698px;
	& {
		border: ${({ theme }) => theme.borders.divider};
		border-radius: 12px;
		width: 100%;
		border-collapse: collapse;
		overflow: hidden;

		td,
		th {
			overflow: visible;
			border-top: ${({ theme }) => theme.borders.divider};
			border-bottom: ${({ theme }) => theme.borders.divider};
			max-width: 174px;
			padding: 16px;
			width: fit-content;

			& {
				& > div {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: clip;
				}
			}
		}
	}
`;

export const StyledTableHeader = styled.div`
	background-color: ${({ theme }) => theme.bgColors.tableHeader};
	color: ${({ theme }) => theme.fontColors.lightGrey};
	border-bottom: ${({ theme }) => theme.borders.divider};
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-transform: uppercase;
	cursor: pointer;

	& div {
		display: flex;
		align-items: center;

		& > svg {
			margin-left: 6px;
			width: 12px;
			height: 12px;
		}
	}
`;

export const StyledTableHeaderGroup = styled.div`
	justify-content: stretch;
	& {
		& > div {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: clip;
		}
	}
`;

export const StyledTableHeaderPart = styled.div`
	flex: 1;
	padding: 16px;
	min-width: 120px;
	&:first-child {
		max-width: 120px;
	}
`;

export const StyledTableBody = styled.div`
	background-color: ${({ theme }) => theme.fontColors.white};
	color: ${({ theme }) => theme.fontColors.textGrey};
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
`;

export const StyledTableRowWrapper = styled.div`
	border-bottom: ${({ theme }) => theme.borders.divider};
	&:last-child {
		border-bottom: none;
	}
`;

export const StyledTableRow = styled.div`
	display: flex;
	justify-content: stretch;
`;

export const StyledTableRowCell = styled.div`
	flex: 1;
	padding: 16px;
	min-width: 120px;
	&:first-child {
		max-width: 120px;
	}
`;

export const TableActions = styled.div`
	display: flex;
	gap: 10px;
`;

export const ActionButton = styled.button`
	background: rgba(11, 147, 227, 0.12);
	padding: 8px;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	&.history-btn-active {
		background: #0b93e3;
		& > svg,
		path {
			fill: white;
		}
	}
	& > svg {
		width: 16px;
		height: 16px;
	}
`;

export const ActionButtonWrapper = styled.div`
	background: rgba(11, 147, 227, 0.12);
	padding: 5px;
	width: 32px;
	height: 32px;
	border-radius: 4px;
`;

export const TooltipStyles: CSS.Properties = {
	background: '#041630',
	borderRadius: '4px',
	padding: '4px 8px',
	fontSize: '12px',
};
