import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { link, stanWorksClient } from '@asd-stan/config/api';

import { AccessToken } from '../domain/access-token.entity';
import { Auth } from '../domain/auth.entity';

const CONFIRM_INVITE = gql`
	mutation ($token: String!) {
		confirmInvite(token: $token) {
			id
			email
		}
	}
`;

const RESEND_CONFIRM_INVITE = gql`
	mutation ($token: String!) {
		resendConfirmInvite(token: $token) {
			id
			email
			phone
		}
	}
`;

const CREATE_PASSWORD = gql`
	mutation ($token: String!, $password: String!) {
		createPassword(token: $token, password: $password) {
			token
			expiresAt
			refreshToken {
				token
				expiresAt
			}
		}
	}
`;

const LOGIN = gql`
	mutation ($email: String!, $password: String!) {
		login(email: $email, password: $password, accountType: "systemUser") {
			token
			expiresAt
			refreshToken {
				token
				expiresAt
			}
		}
	}
`;

const CONFIRM_LOGIN = gql`
	mutation ($token: String!, $code: String!) {
		confirmLogin(token: $token, code: $code) {
			token
			expiresAt
			refreshToken {
				token
				expiresAt
			}
		}
	}
`;

const REFRESH_TOKEN = gql`
	mutation ($token: String!) {
		refreshToken(token: $token) {
			token
			expiresAt
			refreshToken {
				token
				expiresAt
			}
		}
	}
`;

const RESEND_CODE = gql`
	mutation ($token: String!) {
		resendCode(token: $token) {
			token
		}
	}
`;

const CONFIRM_EMAIL_TOKEN_VALID = gql`
	query ($token: String!) {
		confirmEmailTokenValid(token: $token)
	}
`;

const INVITE_TOKEN_VALID = gql`
	query ($token: String!) {
		inviteTokenValid(token: $token)
	}
`;

const INIT_RESET_PASSWORD = gql`
	mutation ($email: String!) {
		initResetPassword(email: $email, accountType: "systemUser")
	}
`;

const CHECK_RESET_PASSWORD_TOKEN_VALID = gql`
	query ($token: String!) {
		resetPasswordTokenValid(token: $token)
	}
`;

const RESET_PASSWORD = gql`
	mutation ($token: String!, $password: String!) {
		resetPassword(token: $token, password: $password)
	}
`;

export class AuthRepo {
	async confirmInvite(token: string): Promise<Auth> {
		const { data } = await stanWorksClient.mutate({
			mutation: CONFIRM_INVITE,
			variables: {
				token: token,
			},
		});
		return data.confirmInvite;
	}

	async resendConfirmInvite(token: string): Promise<Auth> {
		const { data } = await stanWorksClient.mutate({
			mutation: RESEND_CONFIRM_INVITE,
			variables: {
				token: token,
			},
		});
		return data.resendConfirmInvite;
	}

	async createPassword(token: string, password: string): Promise<AccessToken> {
		const { data } = await stanWorksClient.mutate({
			mutation: CREATE_PASSWORD,
			variables: {
				token: token,
				password: password,
			},
		});
		return data.createPassword;
	}

	async login(email: string, password: string): Promise<AccessToken> {
		const { data } = await stanWorksClient.mutate({
			mutation: LOGIN,
			variables: {
				email: email,
				password: password,
			},
		});

		return data.login;
	}

	async confirmLogin(token: string, code: string): Promise<AccessToken> {
		const { data } = await stanWorksClient.mutate({
			mutation: CONFIRM_LOGIN,
			variables: {
				token: token,
				code: code,
			},
		});

		return data.confirmLogin;
	}

	async refreshToken(token: string): Promise<AccessToken> {
		const refreshLink = setContext(async (_, { headers }) => {
			return {
				headers: {
					...headers,
				},
			};
		});

		const refreshClient = new ApolloClient({
			cache: new InMemoryCache(),
			link: refreshLink.concat(link),
			defaultOptions: {
				watchQuery: {
					fetchPolicy: 'no-cache',
				},
				query: {
					fetchPolicy: 'no-cache',
				},
			},
		});

		const { data } = await refreshClient.mutate({
			mutation: REFRESH_TOKEN,
			variables: {
				token: token,
			},
		});

		return data.refreshToken;
	}

	async resendCode(token: string): Promise<string> {
		const { data } = await stanWorksClient.mutate({
			mutation: RESEND_CODE,
			variables: {
				token: token,
			},
		});
		return data.resendCode.token;
	}

	async confirmEmailTokenValid(token: string | null): Promise<boolean> {
		if (!token) {
			return false;
		}
		const { data } = await stanWorksClient.query({
			query: CONFIRM_EMAIL_TOKEN_VALID,
			variables: {
				token: token,
			},
		});
		return data.confirmEmailTokenValid;
	}

	async inviteTokenValid(token: string | null): Promise<boolean> {
		if (!token) {
			return false;
		}
		const { data } = await stanWorksClient.query({
			query: INVITE_TOKEN_VALID,
			variables: {
				token: token,
			},
		});
		return data.inviteTokenValid;
	}

	async initResetPassword(email: string): Promise<boolean> {
		const { data, errors } = await stanWorksClient.mutate({
			mutation: INIT_RESET_PASSWORD,
			variables: {
				email,
			},
		});

		if (errors) {
			throw new Error();
		}

		return data.initResetPassword;
	}

	async checkResetPasswordTokenValid(token: string): Promise<boolean> {
		const { data } = await stanWorksClient.mutate({
			mutation: CHECK_RESET_PASSWORD_TOKEN_VALID,
			variables: {
				token,
			},
		});
		return data.resetPasswordTokenValid;
	}

	async resetPassword(token: string, password: string) {
		const { data } = await stanWorksClient.mutate({
			mutation: RESET_PASSWORD,
			variables: {
				token,
				password,
			},
		});
		return data.resetPassword;
	}
}
