import React, { HTMLInputTypeAttribute, useEffect, useRef } from 'react';

import { generatePlaceholder, getValueByFieldName } from '@asd-stan/helpers/app-utils';
import { Tooltip } from '@asd-stan/ui-kit/components/tooltip/tooltip';
import { Flex } from '@components/utility/flex';
import { ErrorMessage, FieldProps } from 'formik';

import { StyledFieldErrorMessage } from '@asd-stan/ui-kit/components/utility/field-error-message.styled';
import { StyledTextArea } from '@components/form-textarea/form-texarea.styled';

interface InputProps {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	mandatory?: boolean;
	errors: string;
	title: string;
	name: string;
	fullWidth?: boolean;
	maxLength?: number;
	isNeedClearValue?: boolean;
	showError?: boolean;
	hint?: string;
	setTouchedOnBlur?: boolean;
	tooltip?: string;
	description?: JSX.Element;
	onChange?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
	useSubmitOnBlur?: boolean;
	disableMaxLength?: boolean;
	disabled?: boolean;
}

export const FormInputParagraph: React.FC<InputProps & FieldProps> = ({
	title,
	placeholder,
	mandatory,
	field,
	form,
	fullWidth,
	maxLength,
	isNeedClearValue,
	showError,
	hint,
	setTouchedOnBlur = false,
	tooltip,
	description,
	onChange,
	useSubmitOnBlur,
	disableMaxLength = false,
	disabled,
}) => {
	const textAreaRef = useRef<HTMLDivElement | null>(null);
	const textAreaElement = textAreaRef.current;

	if (textAreaElement && !disabled) {
		textAreaElement.setAttribute('contentEditable', 'plaintext-only');
	}

	useEffect(() => {
		if (field.value) {
			if (textAreaRef.current) {
				textAreaRef.current.innerHTML = field.value.trim();
			}
		}
	}, []);

	useEffect(() => {
		if (isNeedClearValue && textAreaRef.current) {
			textAreaRef.current.innerHTML = '';
		}
	}, [isNeedClearValue]);

	const handleChange = (e: React.SyntheticEvent<HTMLDivElement>) => {
		const text = e.currentTarget.innerText;
		// e.currentTarget.innerText = text; TODO: Check this place
		form.setFieldValue(field.name, text.trim());

		if (
			e.nativeEvent.type === 'input' &&
			e.currentTarget.innerText.length === 0 &&
			(e.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
		) {
			form.setFieldValue(field.name, '');
			e.currentTarget.innerHTML = '';
		}

		if (onChange) {
			onChange(e);
		}
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (field.value && field.value.length > 0) {
			await form.setFieldValue(field.name, field.value.trim());
		}
		if (textAreaRef.current) {
			textAreaRef.current.innerHTML = textAreaRef.current.innerHTML.trim();
		}
		if (useSubmitOnBlur) {
			if (disableMaxLength) {
				return await form.handleSubmit();
			}
			if (maxLength && field.value && field.value?.length > maxLength) {
				return;
			}
			await form.handleSubmit();
		}
	};

	return (
		<StyledTextArea
			$disabled={disabled}
			$error={
				!!(
					getValueByFieldName(form.touched, field.name) &&
					getValueByFieldName(form.errors, field.name)
				)
			}
			$fullWidth={fullWidth}>
			<Flex>
				<label title={title}>
					{title}
					{mandatory ? <span>*</span> : null}
					{hint ? <span className="field-hint"> {hint}</span> : null}
				</label>
				{tooltip ? <Tooltip width="250" text={tooltip} /> : null}
			</Flex>
			{description}
			<div
				ref={textAreaRef}
				onInput={handleChange}
				className="input-container"
				style={{ marginTop: !title ? '0px !important' : 'aut' }}
				contentEditable={!disabled}
				data-placeholder={generatePlaceholder(title, placeholder)}
				{...field}
				onBlur={handleBlur}
			/>
			{maxLength && field.value?.length > maxLength ? (
				<div className="error-message">
					{field.value?.length} / {maxLength}
				</div>
			) : null}
			{showError ? (
				maxLength && form.errors[field.name] && field.value?.length ? (
					<ErrorMessage
						name={field.name}
						children={errorMessage => (
							<StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>
						)}
					/>
				) : (
					<ErrorMessage
						name={field.name}
						children={errorMessage => {
							if (typeof errorMessage === 'string')
								return <StyledFieldErrorMessage>{errorMessage}</StyledFieldErrorMessage>;
							if (typeof errorMessage === 'object') {
								return (
									<StyledFieldErrorMessage className="error-message">
										{Object.values(errorMessage)[0] as string}
									</StyledFieldErrorMessage>
								);
							}
						}}
					/>
				)
			) : null}
		</StyledTextArea>
	);
};
