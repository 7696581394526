import styled from 'styled-components';

export const StyledEmailSent = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.body {
		width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px;
		border-radius: 16px;
		background: #fff;

		& > * {
			margin-bottom: 24px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.title {
			font-size: 40px;
			font-weight: 600;
			line-height: normal;
		}

		p {
			color: #abb3bb;
			font-size: 14px;
			font-weight: 500;
			line-height: 150%;

			strong {
				color: #525259;
			}
		}

		.resend {
			color: #0b93e3;
			font-weight: 500;
			line-height: 150%;
			font-size: 14px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.resend-timer {
			color: #abb3bb;
			font-weight: 500;
			line-height: 150%;
			font-size: 14px;
		}
	}
`;
