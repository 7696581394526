import { inputStyles } from '@components/utility/shared-styles';
import styled from 'styled-components';

export const StyledFormInputPhone = styled.div<{
	$warning?: boolean;
	$smallerWidth: boolean;
	$error: boolean;
	$fullWidth?: boolean;
	$disabled?: boolean;
}>`
	${inputStyles};
	width: ${props => (props.$fullWidth ? '100%' : props.$smallerWidth ? '372px' : '400px')};

	label {
		color: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.darkGrey};
	}

	& .react-tel-input .form-control:focus {
		box-shadow: none;
		border: ${props =>
			props.$error
				? props.theme.borders.error
				: props.$warning
				  ? props.theme.borders.warning
				  : props.theme.borders.divider};
	}

	& .react-tel-input .form-control::placeholder {
		color: ${({ theme }) => theme.fontColors.lightGrey};
	}

	& .flag-dropdown {
		width: 80px;
		height: 40px;

		& .arrow {
			left: 42px;
		}

		& .selected-flag {
			width: 100%;
			height: 100%;
			top: 9px;
			padding-left: 16px;
			border-right: ${({ theme }) => theme.borders.divider};
		}

		& .react-tel-input .selected-flag:before {
			transition: none;
		}
	}

	& .react-tel-input .selected-flag:focus:before,
	.react-tel-input .selected-flag.open:before {
		border: none;
		box-shadow: none;
	}

	& input.form-control {
		margin-top: 8px;
		padding: 16px 20px 16px 96px;
		width: 100%;
		height: 42px;
		color: ${props =>
			props.$disabled ? props.theme.fontColors.lightGrey : props.theme.fontColors.darkGrey};
		background-color: ${props => props.$disabled && props.theme.bgColors.primary};
		font-size: 13px;
		border-radius: 8px;
		border: ${props =>
			props.$error
				? props.theme.borders.error
				: props.$warning
				  ? props.theme.borders.warning
				  : props.theme.borders.divider};
	}

	@media (min-width: 1500px) {
		width: ${props => (props.$fullWidth ? '100%' : '49%')};
	}
`;
