import i18n from '@asd-stan/i18n/i18n';
import * as yup from 'yup';

export const catalogModalScheme = yup.object().shape({
	name: yup
		.string()
		.max(200, i18n.t('common.fieldErrors.length', { length: '1-200' }))
		.required(i18n.t('standard.createNWP.validationErrors.required')),
	title: yup
		.string()
		.max(1000, i18n.t('common.fieldErrors.length', { length: '1-1000' }))
		.required(i18n.t('standard.createNWP.validationErrors.required')),
	price: yup
		.number()
		.transform(n => (Number.isNaN(n) ? 0.1 : n))
		.test(
			'price test',
			i18n.t('standard.catalog.modal.priceHint'),
			value => value !== undefined && (value === 0 || value >= 0.5)
		),
	publicationDate: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
	description: yup
		.string()
		.max(5000, i18n.t('common.fieldErrors.length', { length: '1-5000' }))
		.nullable(),
	form: yup.object().required(i18n.t('standard.createNWP.validationErrors.required')),
	registrationNumber: yup
		.string()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	types: yup.array().min(1, i18n.t('standard.createNWP.validationErrors.required')),
	edition: yup
		.string()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	languages: yup.array().min(1, i18n.t('standard.createNWP.validationErrors.required')),
	domains: yup.array().min(1, i18n.t('standard.createNWP.validationErrors.required')),
	scope: yup
		.string()
		.max(5000, ' ')
		.required(i18n.t('standard.createNWP.validationErrors.required')),
});

export const catalogModalSchemeWithOptionalFields = yup.object().shape({
	name: yup
		.string()
		.max(200, i18n.t('common.fieldErrors.length', { length: '1-200' }))
		.required(i18n.t('standard.createNWP.validationErrors.required')),
	title: yup
		.string()
		.max(1000, i18n.t('common.fieldErrors.length', { length: '1-1000' }))
		.required(i18n.t('standard.createNWP.validationErrors.required')),
	price: yup
		.number()
		.transform(n => (Number.isNaN(n) ? 0.1 : n))
		.test(
			'price test',
			i18n.t('standard.catalog.modal.priceHint'),
			value => value !== undefined && (value === 0 || value >= 0.5)
		),
	publicationDate: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
	description: yup
		.string()
		.max(5000, i18n.t('common.fieldErrors.length', { length: '1-5000' }))
		.nullable(),
	registrationNumber: yup
		.string()
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	types: yup.array(),
	edition: yup.string().max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	languages: yup.array(),
	domains: yup.array(),
	scope: yup.string().max(5000, ' '),
});
