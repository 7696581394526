import { DomainRepo } from '@asd-stan/domain/api/domain.repo';
import { DomainAccessService } from '@asd-stan/domain/domain/domain-access.service';
import { DomainService } from '@asd-stan/domain/domain/domain.service';
import {
	DOMAIN_ACCESS_SERVICE,
	DOMAIN_REPO,
	DOMAIN_SERVICE,
} from '@asd-stan/domain/infrastructure/constants';
import { injector } from '@asd-stan/injector/injector';

export const getDomainRepo = () => {
	return injector.get<DomainRepo>(DOMAIN_REPO);
};

export const getDomainService = () => {
	return injector.get<DomainService>(DOMAIN_SERVICE);
};

export const getDomainAccessService = () => {
	return injector.get<DomainAccessService>(DOMAIN_ACCESS_SERVICE);
};