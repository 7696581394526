import { injector } from '@asd-stan/injector/injector';
import { AppointerRepo } from '@asd-stan/user/api/appointer/appointer.repo';
import { CompanyRepo } from '@asd-stan/user/api/company/company.repo';
import { CountryRepo } from '@asd-stan/user/api/country/country.repo';
import { PositionRepo } from '@asd-stan/user/api/position/position.repo';
import { UserRepo } from '@asd-stan/user/api/user.repo';
import { AppointerService } from '@asd-stan/user/domain/appointer.service';
import { CompanyService } from '@asd-stan/user/domain/company.service';
import { CountryService } from '@asd-stan/user/domain/country.service';
import { PositionService } from '@asd-stan/user/domain/position.service';
import { UserService } from '@asd-stan/user/domain/user.service';
import {
	APPOINTER_REPO,
	APPOINTER_SERVICE,
	COMPANY_REPO,
	COMPANY_SERVICE,
	COUNTRY_REPO,
	COUNTRY_SERVICE,
	POSITION_REPO,
	POSITION_SERVICE,
	USER_REPO,
	USER_SERVICE,
} from '@asd-stan/user/infrastructure/constants';

export const getUserRepo = () => {
	return injector.get<UserRepo>(USER_REPO);
};

export const getUserService = () => {
	return injector.get<UserService>(USER_SERVICE);
};

export const getUserPublicService = () => {
	return injector.get<UserService>(USER_SERVICE);
};

export const getCompanyRepo = () => {
	return injector.get<CompanyRepo>(COMPANY_REPO);
};

export const getCompanyService = () => {
	return injector.get<CompanyService>(COMPANY_SERVICE);
};

export const getCountryRepo = () => {
	return injector.get<CountryRepo>(COUNTRY_REPO);
};

export const getCountryService = () => {
	return injector.get<CountryService>(COUNTRY_SERVICE);
};

export const getAppointerRepo = () => {
	return injector.get<AppointerRepo>(APPOINTER_REPO);
};

export const getAppointerService = () => {
	return injector.get<AppointerService>(APPOINTER_SERVICE);
};

export const getPositionRepo = () => {
	return injector.get<PositionRepo>(POSITION_REPO);
};

export const getPositionService = () => {
	return injector.get<PositionService>(POSITION_SERVICE);
};
