import * as yup from 'yup';

const testPasswordRules = (value: string) => {
	return /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value) && /[!%@#]/.test(value);
};

const createPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.required('required')
		.min(8, 'rules')
		.matches(/^[a-zA-Z0-9!%@#]+$/, 'rules')
		.test('rules', 'rules', testPasswordRules),
	confirmPassword: yup
		.string()
		.required('required')
		.min(8, 'rules')
		.matches(/^[a-zA-Z0-9!%@#]+$/, 'rules')
		.test('rules', 'rules', testPasswordRules)
		.test('password-match', 'passwordNotMatch', function (value) {
			return this.parent.password === value;
		})
		.oneOf([yup.ref('password')], 'passwordNotMatch'),
});

export default createPasswordSchema;
