import { injector } from '@asd-stan/injector/injector';
import { CatalogRepo } from '@asd-stan/standard/api/catalog.repo';
import { CatalogService } from '@asd-stan/standard/domain/catalog.service';
import { StagesService } from '@asd-stan/standard/domain/stages.service';
import { StandardDetailsService } from '@asd-stan/standard/domain/standard-detailed.service';

import { StandardRepo } from '../api/standard.repo';
import { StandardService } from '../domain/standard.service';
import { StandardPublicService } from '../public-contract/standard.public-service';

import {
	CATALOG_REPO,
	CATALOG_SERVICE,
	SINGLE_STANDARD_SERVICE,
	STAGES_SERVICE,
	STANDARD_PUBLIC_SERVICE,
	STANDARD_REPO,
	STANDARD_SERVICE,
} from './constants';

injector.set(STANDARD_REPO, new StandardRepo());
injector.set(STANDARD_SERVICE, new StandardService());
injector.set(SINGLE_STANDARD_SERVICE, new StandardDetailsService());
injector.set(STANDARD_PUBLIC_SERVICE, new StandardPublicService());

injector.set(CATALOG_SERVICE, new CatalogService());
injector.set(CATALOG_REPO, new CatalogRepo());

injector.set(STAGES_SERVICE, new StagesService());
