import { IObservableArray, makeAutoObservable, runInAction } from 'mobx';

export interface Page<T> {
	data: T[];
	totalNumber: number;
}

export enum PaginationCollectionControllerStatus {
	INITIALIZED = 'INITIALIZED',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
}

type ObtainFn<T, F> = (limit: number, offset: number, filter: Partial<F>) => Promise<Page<T>>;

class PaginationCollectionControllerRequest<T, F> {
	private _isAborted: boolean = false;

	// methods

	request = async (
		obtainFn: ObtainFn<T, F>,
		limit: number,
		offset: number,
		filter: Partial<F>,
		onSuccess: (page: Page<T>, pageNumber: number) => void,
		onError: () => void,
		pageNumber: number
	): Promise<void> => {
		try {
			const result = await obtainFn(limit, offset, filter);

			if (!this._isAborted) {
				onSuccess(result, pageNumber);
			}
		} catch (e) {
			onError();
		}
	};

	abort() {
		this._isAborted = true;
	}

	// methods end
}

export class PaginationCollectionController<T, F> {
	private _data: T[] = [];
	private _status: PaginationCollectionControllerStatus =
		PaginationCollectionControllerStatus.INITIALIZED;
	private _pageSize: number;
	private _defaultPageSize: number;
	private _currentPage: number;
	private _obtainFn: ObtainFn<T, F>;
	private _totalNumber: number = 0;
	private _filter: Partial<F>;
	private _request: PaginationCollectionControllerRequest<T, F> | undefined = undefined;
	private _initialFilter: Partial<F>;

	constructor(
		pageSize: number,
		obtainFn: ObtainFn<T, F>,
		initialFilter: Partial<F> = {},
		currentPage: number = 0
	) {
		this._defaultPageSize = pageSize;
		this._pageSize = pageSize;
		this._obtainFn = obtainFn;
		this._currentPage = currentPage;
		this._initialFilter = initialFilter;
		this._filter = initialFilter;

		makeAutoObservable(this);
	}

	get data(): T[] {
		return this._data;
	}

	get status(): PaginationCollectionControllerStatus {
		return this._status;
	}

	get currentPage(): number {
		return this._currentPage;
	}

	get canGoNext(): boolean {
		return this._currentPage >= this.pageCount;
	}

	get canGoPrevious(): boolean {
		return this._currentPage <= 1;
	}

	get pageCount(): number {
		return Math.ceil(this._totalNumber / this._pageSize);
	}

	get pageSize(): number {
		return this._pageSize;
	}

	get totalNumber(): number {
		return this._totalNumber;
	}

	get loading() {
		return this._status === PaginationCollectionControllerStatus.LOADING;
	}

	get filter() {
		return this._filter;
	}

	get initialFilter() {
		return this._initialFilter;
	}

	//methods
	private _setData = async (page: Page<T>, pageNumber: number) => {
		// const loadedPage = await this._obtainFn(this._pageSize, (this._currentPage - 1) * this._pageSize, this._filter);

		runInAction(() => {
			this._totalNumber = page.totalNumber;
			(this._data as IObservableArray).replace(page.data);
		});

		this._status = PaginationCollectionControllerStatus.LOADED;
	};

	private _setStatusToLoaded = () => {
		this._status = PaginationCollectionControllerStatus.LOADED;
	};

	goToNextPage = async (): Promise<void> => {
		this.goToPage(this._currentPage + 1);
	};

	goToPreviousPage = async (): Promise<void> => {
		return this.goToPage(this._currentPage - 1);
	};

	loadCurrentPage = async (): Promise<void> => {
		return this.goToPage(this._currentPage);
	};

	setPageSize = (pageSize: number) => {
		runInAction(() => {
			this._pageSize = pageSize;
		});
		this.goToPage(0);
	};

	goToPage = async (pageNumber: number): Promise<void> => {
		this._status = PaginationCollectionControllerStatus.LOADING;
		this._request?.abort();

		this._request = new PaginationCollectionControllerRequest<T, F>();
		this._currentPage = pageNumber;
		this._request.request(
			this._obtainFn,
			this._pageSize,
			this._currentPage * this._pageSize,
			this._filter,
			this._setData,
			this._setStatusToLoaded,
			pageNumber
		);
	};

	/**
	 * Adds a new filter to existing ones and loads the first page
	 **/

	addFilter = (filter: Partial<F>) => {
		this._status = PaginationCollectionControllerStatus.LOADING;
		this._filter = { ...this._filter, ...filter };
		this.goToPage(0);
	};

	/**
	 * Adds a filter without changing page
	 **/

	addFilterOnCurrentPage = (sorting: Partial<F>) => {
		this._status = PaginationCollectionControllerStatus.LOADING;
		this._filter = { ...this._filter, ...sorting };
		this.goToPage(this._currentPage);
	};

	resetFilter = () => {
		this._filter = this._initialFilter;
		this._currentPage = 1;
		this.setPageSize(this._defaultPageSize);
	};
}
