import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Flex } from '@asd-stan/ui-kit/components/utility/flex';

import { SidebarItemIcon, SidebarItemTitle, StyledSidebarItem } from './sidebar-item.styled';

interface SidebarItemProps {
	icon?: ReactNode;
	name: string;
	link?: string;
	className?: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({ icon, name, link, className }) => {
	return (
		<StyledSidebarItem className={className}>
			<NavLink to={link || '#'} className={({ isActive }) => (isActive ? 'active' : '')}>
				<Flex>
					<SidebarItemIcon>{icon}</SidebarItemIcon>
					<SidebarItemTitle>{name}</SidebarItemTitle>
				</Flex>
			</NavLink>
		</StyledSidebarItem>
	);
};
