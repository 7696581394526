import { styled } from 'styled-components';

export const TitleWrapper = styled.div`
	margin: 22px 0 0 0;
	max-width: 420px;
`;

export const StageSelectorTitle = styled.div`
	color: #abb3bb;
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
`;

export const StageSelectorTitleBlack = styled.span`
	color: #333336;
`;

export const CommentTitle = styled.div`
	display: inline-block;
	width: 100%;
	color: black;
	font-size: 16px;
	font-weight: 500;
	margin: 5px 0;
`;

export const DataSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 13px;

	.checkbox {
		align-self: flex-start;
	}
`;

export const DatePickerContainer = styled.div<{ disabled: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 400px;

	label {
		display: block;
	}

	& .react-datepicker-wrapper {
		background: ${({ disabled }) => (disabled ? '#f0f4f6' : 'transparent')};
		border: solid 1px #dae4e8;
		border-radius: 8px;
		overflow: hidden;
		padding: 7px 8px;
		width: 100%;
	}
`;

export const DatePickerTitle = styled.div`
	color: #abb3bb;
	font-size: 13px;
	font-weight: 500;
`;

export const DatePickerAsterisk = styled.span`
	color: #0b93e3;
`;

export const StyledUserSelect = styled.div<{ $disabled: boolean }>`
	position: relative;
	margin-top: 8px;

	.label {
		font-weight: 500;
		font-size: 13px;
		line-height: 15.86px;
		margin-bottom: 8px;
	}

	.input {
		border-radius: 8px;
		border: 1px solid #dae4e8;
		padding: 8px 40px 8px 16px;
		min-height: 44px;
		position: relative;
		cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
		display: flex;
		align-items: center;
		gap: 8px;
		flex-wrap: wrap;
		background: ${({ $disabled }) =>
			$disabled ? 'linear-gradient(0deg, #f0f4f6 0%, #f0f4f6 100%), #fff' : 'transparent'};

		.selected-user {
			padding: 6px 8px;
			border-radius: 6px;
			background-color: #e8e9f4;
			display: flex;
			align-items: center;

			svg {
				display: block;
				margin-left: 6px;
			}
		}

		.no-values {
			color: #abb3bb;
		}
	}

	.arrow {
		display: block;
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
	}

	.popup {
		position: absolute;
		top: calc(100% + 8px);
		border-radius: 8px;
		border: 1px solid #dae4e8;
		background: #fff;
		width: 100%;
		box-shadow: 0px 12px 24px 0px #1e242d29;
		padding: 8px 4px 8px 8px;
		max-height: 300px;
		display: flex;
		flex-direction: column;
		z-index: 1;

		.popup-body {
			height: 100%;
			flex-grow: 1;
			overflow: auto;
			padding-right: 4px;

			&::-webkit-scrollbar {
				border-radius: 10px;
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background-color: ${({ theme }) => theme.bgColors.scrollTrack};
				margin: 4px 0;
			}

			&::-webkit-scrollbar-thumb {
				background-color: ${({ theme }) => theme.fontColors.accent};
				border-radius: 8px;
			}
		}

		.loading {
			padding: 8px 0;
		}

		.empty {
			padding: 8px 0;
			text-align: center;
			color: #abb3bb;
		}

		.domain {
			border-bottom: 1px solid #dae4e8;

			&:last-child {
				border-bottom: none;
			}
		}

		.domain-title {
			font-size: 14px;
			display: flex;
			align-items: center;
			position: relative;
			padding: 8px 20px 8px 0;
			cursor: pointer;
			line-height: normal;

			svg {
				position: absolute;
				right: 8px;
				top: 50%;
				transform: translateY(-50%);
				transition: transform 0.3s ease-out;
				display: block;

				&.opened {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}

		.user {
			padding: 8px;
			border-radius: 8px;
			display: flex;
			cursor: pointer;

			&:hover {
				background-color: #0b93e314;
			}

			.user-left {
				display: flex;
				align-items: center;
				flex-shrink: 0;
				align-self: start;
			}

			.avatar {
				margin-right: 8px;
				display: flex;
				align-items: center;
			}

			.name {
				flex-shrink: 0;
			}

			.roles {
				display: flex;
				margin-left: 8px;
				gap: 10px;
				flex-wrap: wrap;

				.role {
					font-size: 12px;
					line-height: 18px;
					font-weight: 600;
					padding: 4px 6px;
					background-color: #dae4e866;
					border-radius: 8px;
				}
			}
		}
	}
`;
