import { makePositionFromPositionDTO } from '@asd-stan/user/api/position/position.factory';
import { Position } from '@asd-stan/user/domain/position.entity';
import { getPositionRepo } from '@asd-stan/user/infrastructure/getters';
import { makeAutoObservable, runInAction } from 'mobx';

export class PositionService {
	private _positions: Position[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	private get _positionRepo() {
		return getPositionRepo();
	}

	public async getPositions() {
		const positions = await this._positionRepo.getPositions();

		runInAction(() => {
			this._positions = positions;
		});
	}

	public async createPosition(name: string): Promise<Position> {
		const response = await this._positionRepo.createPosition(name);
		const newPosition = makePositionFromPositionDTO(response);

		runInAction(() => {
			this._positions = [...this._positions, newPosition];
		});

		return newPosition;
	}

	get positions() {
		return this._positions;
	}
}
