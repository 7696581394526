import React from 'react';

import { Avatar } from '@components/avatar/avatar';
import { WGSContainer } from './wgs.styled';
import { WGS } from '@asd-stan/standard/domain/standard-detailed.entity';

interface Props {
	wgs: WGS
}

export const WGSUser: React.FC<Props> = ({ wgs }) => {
	return (
		<WGSContainer>
			<Avatar />
			<p>{wgs.fullName}</p>
		</WGSContainer>
	);
};
