import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
	checkIfNeedToParseHTML,
	checkIfNeedToShowLink,
	checkIfNeedToSplit,
} from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/standard-change.helper';
import { StandardStageUpdateItemParsed } from '@asd-stan/standard/domain/standard-stage.entity';
import { ReactComponent as Arrow } from '@asd-stan/ui-kit/assets/icons/arrow-right-blue.svg';
import { DateTime } from 'luxon';

import { GeneralLinkStyles } from '../../single-standard-page-section/single-standard.styled';

import { LinkContainer } from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/page-blocks/justification/justification-components.styled';
import {
	NewBadge,
	OldBadge,
	StandardChangeComparison,
	StandardChangeHeader,
	StandardChangeHeaderHighlights,
	StandardChangeItemBody,
	StandardChangeItemNew,
	StandardChangeItemOld,
	StandardChangeItemWrapper,
	StandardChangeListItemDisplay,
	StandardChangeValues,
} from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/standard-change-item/standard-change-item.styled';

interface Props {
	data: StandardStageUpdateItemParsed;
}

const classForOldItem = (searchFrom: string | null, searchedText: string) => {
	if (!searchFrom) {
		return '';
	}

	const found = searchFrom.includes(searchedText.trim());

	return !found ? 'change-removed' : '';
};

const classForNewItem = (searchFrom: string | null, searchedText: string) => {
	if (!searchFrom) {
		return '';
	}

	const found = searchFrom.includes(searchedText);

	return !found ? 'change-added' : '';
};

export const StandardChangeItem: FC<Props> = ({ data }) => {
	const { t } = useTranslation();
	const date = DateTime.fromISO(data.date).toFormat('LLL dd, yyyy');
	const time = DateTime.fromISO(data.date).toFormat('hh:mm a');

	const renderItem = (text: string | number | Array<any>, isOld: boolean) => {
		if (text && typeof text === 'number') {
			return <>{text}</>;
		}

		if (text && typeof text === 'string' && text.length) {
			if (checkIfNeedToParseHTML(data.property)) {
				return (
					<>
						<LinkContainer dangerouslySetInnerHTML={{ __html: text }} />
					</>
				);
			}

			if (checkIfNeedToSplit(data.property)) {
				return <>{text}</>;
			}

			if (
				checkIfNeedToShowLink(data.property) &&
				!Array.isArray(data.history.from) &&
				!Array.isArray(data.history.to)
			) {
				return (
					<Link
						target="_blank"
						style={GeneralLinkStyles}
						to={`/standards/detailed/${isOld ? data.history.from.id : data.history.to.id}/general`}>
						{text}
					</Link>
				);
			}

			const splitText = text.split(',');
			if (splitText.length > 1) {
				return splitText.map(textItem => (
					<StandardChangeListItemDisplay
						key={textItem}
						className={
							isOld
								? classForOldItem(
										Array.isArray(data.history.to) ? null : data.history.to.label,
										textItem
								  )
								: classForNewItem(
										Array.isArray(data.history.from) ? null : data.history.from.label,
										textItem
								  )
						}>
						{textItem}
					</StandardChangeListItemDisplay>
				));
			}

			return <>{text}</>;
		}

		if (text && typeof text === 'object' && text.length) {
			if (checkIfNeedToShowLink(data.property)) {
				return text.map(textItem => (
					<StandardChangeListItemDisplay
						key={textItem}
						className={
							isOld
								? Array.isArray(data.history.to) &&
								  data.history.to.find(item => item.label === textItem.label)
									? ''
									: 'change-removed'
								: Array.isArray(data.history.from) &&
								    data.history.from.find(item => item.label === textItem.label)
								  ? ''
								  : 'change-added'
						}>
						<Link target="_blank" to={`/standards/detailed/${textItem.id}/general`}>
							{textItem.label}
						</Link>
					</StandardChangeListItemDisplay>
				));
			} else {
				return text.map(textItem => (
					<StandardChangeListItemDisplay
						key={textItem}
						className={
							isOld
								? classForOldItem(
										Array.isArray(data.history.to) ? null : data.history.to.label,
										textItem
								  )
								: classForNewItem(
										Array.isArray(data.history.from) ? null : data.history.from.label,
										textItem
								  )
						}>
						{textItem}
					</StandardChangeListItemDisplay>
				));
			}
		}

		return <>{t('standard.singleStandard.stageAndStatus.table.item.noItem')}</>;
	};

	const renderBody = () => {
		return (
			<StandardChangeComparison>
				<StandardChangeItemOld>
					<StandardChangeValues>
						{renderItem(
							Array.isArray(data.history.from) ? data.history.from : data.history.from.label,
							true
						)}
					</StandardChangeValues>
					<OldBadge>{t('standard.singleStandard.stageAndStatus.table.item.old')}</OldBadge>
				</StandardChangeItemOld>
				<Arrow />
				<StandardChangeItemNew>
					<StandardChangeValues>
						{renderItem(
							Array.isArray(data.history.to) ? data.history.to : data.history.to.label,
							false
						)}
					</StandardChangeValues>
					<NewBadge>{t('standard.singleStandard.stageAndStatus.table.item.new')}</NewBadge>
				</StandardChangeItemNew>
			</StandardChangeComparison>
		);
	};

	return (
		<StandardChangeItemWrapper>
			<StandardChangeHeader>
				<StandardChangeHeaderHighlights>
					{data.user.firstName} {data.user.lastName}
				</StandardChangeHeaderHighlights>
				{t('standard.singleStandard.stageAndStatus.table.item.updated')}
				<StandardChangeHeaderHighlights>
					{t(`standard.singleStandard.stageAndStatus.table.item.titleLabel.${data.property}`, {
						index: data.history.index,
					})}
				</StandardChangeHeaderHighlights>
				{date} {t('standard.singleStandard.stageAndStatus.table.item.at')} {time}
			</StandardChangeHeader>
			<StandardChangeItemBody>{renderBody()}</StandardChangeItemBody>
		</StandardChangeItemWrapper>
	);
};
