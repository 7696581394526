import styled from 'styled-components';

export const StyledFilterWrapper = styled.div`
	margin-top: 12px;

	.head {
		margin-bottom: 12px;

		.title {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 11px;
			line-height: 16.5px;

			span {
				color: #0b93e3;
			}
		}

		.open {
			cursor: pointer;
			transform: rotate(180deg);
			transition: transform 0.3s ease-out;
		}
	}

	&.opened {
		border-bottom: 1px solid #dae4e8;
		padding-bottom: 12px;

		.open {
			transform: rotate(360deg);
		}
	}
`;
