import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { ReactComponent as ArrowBack } from '@assets/icons/arrow-back.svg';
import { Button } from '@components/button/button';
import { FormError } from '@components/form-error/form-error';
import { FormInputPassword } from '@components/form-input-password/form-input-password';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { Field, Form, Formik } from 'formik';

import validationSchema from '../../validation-schemas/create-password.schema';
import { AuthLayout } from '../layout/auth-layout/auth-layout';

import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { StyledLinkExpired, StyledResetSuccess } from './reset-password.styled';

enum PageStatus {
	Loading = 'loading',
	Success = 'success',
	InvalidToken = 'invalidToken',
	Pending = 'pending',
}

interface ResetPasswordForm {
	password: string;
	confirmPassword: string;
}

const initialValues: ResetPasswordForm = {
	password: '',
	confirmPassword: '',
};

export const ResetPassword: React.FC = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const authService = getAuthService();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Loading);

	useEffect(() => {
		(async () => {
			if (!token) {
				setPageStatus(PageStatus.InvalidToken);
				return;
			}
			const valid = await authService.checkResetPasswordTokenValid(token);
			setPageStatus(valid ? PageStatus.Pending : PageStatus.InvalidToken);
		})();
	}, []);

	const handleSubmit = async ({ password }: ResetPasswordForm) => {
		try {
			if (!token) {
				return;
			}
			await authService.resetPassword(token, password);
			setPageStatus(PageStatus.Success);
		} catch (err) {
			console.error(err);
		}
	};

	switch (pageStatus) {
		case PageStatus.Loading:
			return (
				<AuthLayout>
					<h1>{t('auth.resetPassword.title')}</h1>
					<div>
						<Loading />
					</div>
				</AuthLayout>
			);

		case PageStatus.Success:
			return (
				<AuthLayout>
					<StyledResetSuccess>
						<div className="icon">
							<SuccessIcon />
						</div>
						<h6>{t('auth.resetPassword.successTitle')}</h6>
						<div className="action">
							<Button
								title={t('auth.resetPassword.successAction')}
								onClick={() => navigate('/signin')}
							/>
						</div>
					</StyledResetSuccess>
				</AuthLayout>
			);

		case PageStatus.InvalidToken:
			return (
				<AuthLayout>
					<StyledLinkExpired>
						<div className="icon">
							<MailIcon />
						</div>
						<h6>{t('auth.resetPassword.expiredTitle')}</h6>
						<p>
							{t('auth.resetPassword.expired1')}
							<br />
							{t('auth.resetPassword.expired2')}
						</p>
						<Link to="/init-reset-password">{t('auth.resetPassword.forgot')}</Link>
					</StyledLinkExpired>
				</AuthLayout>
			);

		case PageStatus.Pending:
			return (
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
					validateOnBlur={false}
					validateOnChange={false}>
					{({ errors, handleSubmit, isSubmitting }) => (
						<AuthLayout>
							<h1>{t('auth.resetPassword.title')}</h1>
							{(errors.password || errors.confirmPassword) && (
								<FormError
									title={t(
										`auth.createPassword.errors.${
											errors.password ? errors.password : errors.confirmPassword
										}`
									)}
								/>
							)}
							<Form>
								<Field
									component={FormInputPassword}
									name="password"
									title={t('auth.createPassword.passwordTitle')}
									placeholder={t('auth.createPassword.passwordDescription')}
									handleSubmit={handleSubmit}
								/>
								<Field
									component={FormInputPassword}
									name="confirmPassword"
									title={t('auth.createPassword.confirmPasswordTitle')}
									placeholder={t('auth.createPassword.confirmPasswordDescription')}
									handleSubmit={handleSubmit}
								/>
							</Form>
							<ul>
								{t('auth.createPassword.passwordRulesTitle')}
								<li>{t('auth.createPassword.passwordRuleCharacters')}</li>
								<li>{t('auth.createPassword.passwordRuleLetters')}</li>
								<li>{t('auth.createPassword.passwordRuleNumber')}</li>
								<li>{t('auth.createPassword.passwordRuleSpecial')}</li>
							</ul>
							<Button
								title={t('auth.createPassword.createButton')}
								onClick={handleSubmit}
								disabled={isSubmitting}
								fullWidth
							/>
							<Link to="/signin" className="backLink">
								<Flex $align="center">
									<ArrowBack />
									{t('auth.resetPassword.backLink')}
								</Flex>
							</Link>
						</AuthLayout>
					)}
				</Formik>
			);
	}
});
