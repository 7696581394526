import { styled } from 'styled-components';

export const StyledFormError = styled.div`
	width: 100%;
	padding: 12px 16px;
	border-radius: 8px;
	border: 1px solid rgba(234, 62, 83, 0.4);
	background: ${({ theme }) => theme.bgColors.error};
	color: ${({ theme }) => theme.fontColors.error};
	font-weight: 500;
	line-height: 150%;
`;
