import React from 'react';
import { useLocation, useMatches } from 'react-router-dom';

import { StyledBreadCrumbs } from './bread-crumbs.styled';

export const BreadCrumbs: React.FC = () => {
	let matches = useMatches();
	const location = useLocation();

	let crumbs = matches
		.filter((match: any) => Boolean(match.handle?.crumb))
		.map((match: any) => match.handle.crumb(match.data, match.params));
	return (
		<StyledBreadCrumbs>
			{crumbs.map((crumb, index) => {
				const current = crumb.props.to === location.pathname;

				return (
					<React.Fragment key={`${index}-crumb`}>
						<span className={current ? 'current' : ''}>{crumb}</span>
						{index + 1 < crumbs.length ? ' / ' : null}
					</React.Fragment>
				);
			})}
		</StyledBreadCrumbs>
	);
};
