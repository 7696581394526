import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getModalService } from '@asd-stan/shell/infrastructure/getters';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { MarkedText } from '@asd-stan/ui-kit/components/utility/marked-text';

import { StyledModalButtonsContainer } from '@asd-stan/user/components/invite-users/modals/modal-buttons-container.styled';

interface CancelInviteProps {
	userName?: string;
}

export const CancelInvite: React.FC<CancelInviteProps> = ({ userName }) => {
	const modalService = getModalService();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleKeepEditing = () => {
		modalService.closeModal();
	};

	const handleDiscardChanges = () => {
		modalService.closeModal();
		navigate(-1);
	};

	return (
		<div>
			<h1>{t('user.userInvite.modal.cancelTitle')}</h1>
			<p>
				<Trans i18nKey="user.userInvite.modal.cancelText" values={{ userName }}>
					Are you sure you want to discard all changes in <MarkedText $color="#525259"></MarkedText>{' '}
					user invite?
				</Trans>
			</p>
			<StyledModalButtonsContainer>
				<Button
					secondary
					title={t('user.userInvite.buttons.discard')}
					onClick={handleDiscardChanges}
				/>
				<Button title={t('user.userInvite.buttons.keepEditing')} onClick={handleKeepEditing} />
			</StyledModalButtonsContainer>
		</div>
	);
};
