export enum Form {
	prEN = 'prEN',
	STAN = 'STAN',
	TR = 'TR',
	EN = 'EN',
}

export const forms = [
	{ value: 'prEN', label: 'prEN' },
	{ value: 'EN', label: 'EN' },
	{ value: 'TR', label: 'TR' },
	// { value: 'STAN', label: 'STAN' },
	// { value: 'FprEN', label: 'FprEN' },
	{ value: 'prEN_EN', label: 'prEN & EN' },
];

export const revisions = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];

export const attachmentTypes = [
	{ value: 'previous_norm', label: 'Previous norm' },
	{ value: 'in_new_document', label: 'Attachment in new document' },
	{ value: 'normative_reference', label: 'Normative reference' },
	{ value: 'other', label: 'Other' },
];

export const statuses = [
	{ value: 'WIP', label: 'Work in progress' },
	{ value: 'AN', label: 'Cancelled' },
	{ value: 'PB', label: 'Published' },
	{ value: 'RT', label: 'Withdrawn' },
	{ value: 'DC', label: 'Declassified' },
	{ value: 'SD', label: 'Superseded' },
	{ value: 'ENWIP', label: 'Transformation to EN in progress' },
	{ value: 'EN', label: 'Published as EN' },
	{ value: 'SR', label: 'SR in progress' },
];
