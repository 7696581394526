import { styled } from 'styled-components';

export const StyledAuthLayout = styled.div`
	background-color: ${({ theme }) => theme.bgColors.white};

	.container {
		display: grid;
		min-height: 100vh;
		margin: auto;
	}

	.form {
		max-width: 400px;
		& > div {
			margin-top: 24px;
			margin-left: 0px;
		}
	}

	form {
		align-self: stretch;

		& > div:nth-child(even) {
			margin-top: 0px;
		}
	}

	.header {
		min-width: 400px;
		margin-top: 50px;
		align-self: flex-start;

		& > a > svg {
			margin: 0;
		}
	}

	.react-select__control {
		height: 48px;
		font-weight: 600;
	}

	.react-select__indicator-separator {
		display: none;
	}

	& {
		& span {
			color: ${({ theme }) => theme.fontColors.lightGrey};
			font-weight: 500;
			line-height: 150%;
			text-align: center;
			margin-top: 12px;
		}

		& a {
			margin-top: 12px;
			color: ${({ theme }) => theme.fontColors.darkGrey};
			font-weight: 500;
			line-height: 150%;

			&.backLink {
				margin-top: 26px;
			}

			& svg {
				margin-right: 8px;
			}
		}

		& h1 {
			color: ${({ theme }) => theme.fontColors.darkGrey};
			font-size: 40px;
			font-weight: 600;
		}
		.image-wrapper {
			padding: 20px;
			& img {
				min-width: 50vw;
				height: calc(100vh - 40px);
				border-radius: 16px;
				transform: scaleX(-1);
			}
		}

		& ul {
			color: ${({ theme }) => theme.fontColors.lightGrey};
			line-height: 150%;
			font-weight: 500;
			align-self: flex-start;

			& > li {
				margin-left: 20px;
				list-style: disc;
			}
		}
	}
`;
