import { injector } from '@asd-stan/injector/injector';
import { CatalogRepo } from '@asd-stan/standard/api/catalog.repo';
import { CatalogService } from '@asd-stan/standard/domain/catalog.service';
import { StandardDetailedService } from '@asd-stan/standard/domain/standard-detailed.service';

import { StandardRepo } from '../api/standard.repo';
import { StagesService } from '../domain/stages.service';
import { StandardService } from '../domain/standard.service';
import { StandardPublicService } from '../public-contract/standard.public-service';

import {
	CATALOG_REPO,
	CATALOG_SERVICE,
	SINGLE_STANDARD_SERVICE,
	STAGES_SERVICE,
	STANDARD_PUBLIC_SERVICE,
	STANDARD_REPO,
	STANDARD_SERVICE,
} from './constants';

export const getStandardRepo = () => {
	return injector.get<StandardRepo>(STANDARD_REPO);
};

export const getStandardService = () => {
	return injector.get<StandardService>(STANDARD_SERVICE);
};

export const getStandardDetailsService = () => {
	return injector.get<StandardDetailedService>(SINGLE_STANDARD_SERVICE);
};

export const getStandardPublicService = () => {
	return injector.get<StandardPublicService>(STANDARD_PUBLIC_SERVICE);
};

export const getCatalogService = () => {
	return injector.get<CatalogService>(CATALOG_SERVICE);
};

export const getCatalogRepo = () => {
	return injector.get<CatalogRepo>(CATALOG_REPO);
};

export const getStagesService = () => {
	return injector.get<StagesService>(STAGES_SERVICE);
};
