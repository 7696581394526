import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from '@asd-stan/helpers/use-in-click-outside';
import { ReactComponent as Icon } from '@assets/asd-stan-works/icons/filter-icon.svg';
import { Button } from '@components/button/button';
import { Form, Formik } from 'formik';

import { FiltersPopup, StyledTableFilter } from './table-filter.styled';

type Props<T extends object> = {
	children?: React.ReactNode;
	values?: T;
	emptyValues?: T;
	onSubmit?(filter: T): void;
	disabled?: boolean;
	leftPopupPosition?: boolean;
};

export const TableFilter = <T extends object>({
	children,
	values,
	emptyValues,
	onSubmit: handleSubmit,
	disabled,
	leftPopupPosition,
}: Props<T>) => {
	const { t } = useTranslation();
	const [opened, setOpened] = useState(false);
	const popupRef = useRef<HTMLDivElement>(null);

	const handlePopupOpen = () => setOpened(o => !o);

	useOnClickOutside(popupRef, () => setOpened(false));

	const ableToBeOpened = children && values && handleSubmit && emptyValues;

	return (
		<StyledTableFilter ref={popupRef}>
			<button disabled={disabled || !ableToBeOpened} title="Filter" onClick={handlePopupOpen}>
				<Icon />
				<p>{t('common.listFilter.buttonText')}</p>
			</button>
			{opened && ableToBeOpened && (
				<Formik
					initialValues={values!}
					onSubmit={v => {
						handleSubmit!(v);
						setOpened(false);
					}}>
					{({ setValues, handleSubmit }) => (
						<FiltersPopup as={Form} $leftPosition={leftPopupPosition}>
							<h6>{t('common.listFilter.title')}</h6>
							{(() => {
								return null;
							})()}
							<div>{children}</div>
							<div className="buttons">
								<Button
									secondary
									title={t('common.listFilter.clear')}
									onClick={() => {
										setValues(emptyValues);
										handleSubmit();
									}}
								/>
								<Button title={t('common.listFilter.apply')} type="submit" />
							</div>
						</FiltersPopup>
					)}
				</Formik>
			)}
		</StyledTableFilter>
	);
};
