import React from 'react';
import { useParams } from 'react-router-dom';

import { StyledNavLink, StyledTabListContainer } from './tab-list.styled';

interface TabListProps {
	tabLists: Array<TabListType | null>;
	isDraft: boolean;
	basePath?: string;
}

export interface TabListType {
	tabKey: string;
	title: string;
}

export const TabList: React.FC<TabListProps> = ({ tabLists, isDraft, basePath }: TabListProps) => {
	const params = useParams();

	return (
		<StyledTabListContainer>
			{tabLists.map(TabList => {
				if (TabList !== null) {
					return (
						<StyledNavLink
							key={TabList.tabKey}
							to={
								basePath
									? `${basePath}/${TabList.tabKey}`
									: `/standards/${isDraft ? 'draft' : 'detailed'}/${params.id}/${TabList.tabKey}`
							}>
							{TabList.title}
						</StyledNavLink>
					);
				}
				return null;
			})}
		</StyledTabListContainer>
	);
};
