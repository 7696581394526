import { Participation } from '@asd-stan/current-user/domain/current-user.service';
import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { Domain } from '@asd-stan/domain/domain/domain.entity';
import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { WorkingGroup } from '@asd-stan/working-group/domain/working-group.entity';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';

export interface AccessibleDomainWorkingGroup {
	code: string;
	name: string;
	link?: string;
}

export interface AccessibleDomain {
	code: string;
	name: string;
	workingGroups: AccessibleDomainWorkingGroup[];
	seeAllDomain?: boolean;
}

export class DomainAccessService {
	private _accessibleDomains: AccessibleDomain[] = [];
	private _isLoading: boolean = true;

	constructor() {
		makeAutoObservable(this);
	}

	// getters
	private get _currentUserService() {
		return getCurrentUserService();
	}

	private get _domainService() {
		return getDomainService();
	}

	get accessibleDomains() {
		return this._accessibleDomains;
	}

	get isLoading() {
		return this._isLoading;
	}

	//getters end
	//methods

	private makeAccessibleDomainFromDomain(domains: Domain[]) {
		return domains.map(domain => {
			return { code: domain.code, name: domain.name, workingGroups: domain.workingGroups };
		});
	}

	private makeAccessibleDomainWgFromWg(workingGroups: WorkingGroup[]) {
		return workingGroups.map(wg => {
			return { code: wg.code, name: wg.name, domain: wg.domain, link: undefined };
		});
	}

	public setLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	public async createDomainMap() {
		if (this._currentUserService.isOnlyExpert) {
			const accessibleDomains: AccessibleDomain[] =
				this._currentUserService.domainParticipations.map((participation: Participation) => {

					const domain: AccessibleDomain = {
						code: participation.domain.code,
						name: participation.domain.name,
						workingGroups: [
							...this.makeAccessibleDomainWgFromWg(participation.domain.workingGroups || []),
						],
					};

					if (participation.seeAllDomain) {
						domain.seeAllDomain = participation.seeAllDomain;
					}

					if (!domain.workingGroups || domain.workingGroups.length === 0) {
						domain.workingGroups = [
							{
								name: i18n.t('common.sidebar.standards.addNew'),
								code: '',
								link: `standards/new-standard`,
							},
						];
					}

					return domain;
				});
			runInAction(() => {
				this._accessibleDomains = accessibleDomains;
			});
		} else {
			await this._domainService.getDomains();
			runInAction(() => {
				this._accessibleDomains = this.makeAccessibleDomainFromDomain([
					...this._domainService.domains,
				]);
			});
		}
	}
}
