import styled from 'styled-components';

export const StatusCell = styled.select<{ $isPublished?: boolean }>`
  text-transform: capitalize;
  padding: 4px 6px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;

  &:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    opacity: 1;
    cursor: auto;
  }

  svg {
    margin-left: 8px;
  }

  & {
    color: ${(props) => (props.$isPublished ? props.theme.fontColors.green : props.theme.fontColors.cancelled)};
    background: ${(props) => (props.$isPublished ? props.theme.bgColors.green : props.theme.bgColors.cancelled)};
  }

  &.published, option.published {
    color: ${(props) => props.theme.fontColors.green};
    background: ${(props) => props.theme.bgColors.green};
  }

  &.unpublished, option.unpublished {
    color: ${({ theme }) => theme.fontColors.cancelled};
    background: ${({ theme }) => theme.bgColors.cancelled};
  }
`;
