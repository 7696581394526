import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { ReactComponent as Dropdown } from '@asd-stan/ui-kit/assets/icons/dropdown.svg';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { ReactComponent as Logout } from '@assets/icons/logout.svg';
// import { ReactComponent as Setting } from '@assets/icons/setting.svg';
import { ReactComponent as User } from '@assets/icons/user.svg';
import { Avatar } from '@components/avatar/avatar';
import { Loading } from '@components/loading/loading';

import { StyledDropdown, StyledUserControl } from './user-control.styled';

interface UserControlProps {
	title?: string;
	loading?: boolean;
}

export const UserControl: React.FC<UserControlProps> = ({ title, loading }) => {
	const navigate = useNavigate();
	const authService = getAuthService();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggleOpen = () => setIsOpen(!isOpen);

	const handleLogout = () => {
		navigate('/signin');
		authService.handleLogout();
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsOpen(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (loading) {
		return (
			<StyledUserControl>
				<Loading />
			</StyledUserControl>
		);
	}

	return (
		<StyledUserControl onClick={toggleOpen}>
			<Avatar width="36px" height="36px" />
			<Flex $align="center" $justify="space-between">
				<p>{title}</p>
				<Dropdown className={`${isOpen ? 'open' : ''}`} />
			</Flex>
			<StyledDropdown $display={isOpen}>
				<li onClick={() => navigate('/user-list/me')}>
					<Flex $align="center">
						<User />
						Profile
					</Flex>
				</li>
				{/* <li>
					<Flex $align="center">
						<Setting />
						Settings
					</Flex>
				</li> */}
				<li onClick={handleLogout}>
					<Flex $align="center">
						<Logout />
						Log out
					</Flex>
				</li>
			</StyledDropdown>
		</StyledUserControl>
	);
};
