import { UploadedFile } from '@asd-stan/file/domain/file-upload-controller';

export class FileRepo {
	async uploadFile(file: File, isPrivate?: boolean): Promise<UploadedFile> {
		const storedObject = localStorage.getItem('accessToken')!;

		const formData = new FormData();
		formData.append('file', file, file.name);
		if (isPrivate) {
			formData.append('is_public', '0');
		}
		formData.append('storage', 's3');

		const response = await fetch(process.env.REACT_APP_FILE_STORAGE_URL!, {
			method: 'POST',
			body: formData,
			headers: {
				authorization: storedObject ? `Bearer ${storedObject}` : '',
			},
		});

		const result = await response.json();

		return { path: result.path, fileId: result.id, title: file.name, size: file.size, id: null };
	}
}
