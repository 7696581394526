import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {
	userHasRolesAndParticipatesInDomain,
	userHasRoles,
} from '@asd-stan/helpers/app-utils';

interface ProtectedRouteProps {
	redirectPath?: string;
	children?: React.ReactElement;
	allowedRoles: string[];
	rolesShouldParticipateInDomain?: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
	({ redirectPath = '/forbidden', children, allowedRoles, rolesShouldParticipateInDomain }) => {
		const currentUserService = getCurrentUserService();

		let isAllowed;

		if (rolesShouldParticipateInDomain) {
			isAllowed = userHasRolesAndParticipatesInDomain(
				allowedRoles,
				currentUserService.userRoles!,
				rolesShouldParticipateInDomain,
				currentUserService.domainParticipations
			);
		} else {
			isAllowed = userHasRoles(allowedRoles, currentUserService.userRoles!);
		}

		if (!isAllowed) {
			return <Navigate to={redirectPath} replace />;
		}

		return children ? children : <Outlet />;
	}
);
