import { styled } from 'styled-components';

export const StyledConfirmEmailLayout = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	height: 100vh;
	max-width: 500px;

	& {
		& * {
			margin: 10px 0;
		}

		& > div {
			width: 500px;
			border-radius: 16px;
			padding: 0px 50px 25px;
			gap: 10px;
			background-color: ${({ theme }) => theme.bgColors.white};
		}

		& h1 {
			font-size: 40px;
			font-weight: 600;
			color: ${({ theme }) => theme.fontColors.darkGrey};
		}

		& p {
			line-height: 21px;
			color: ${({ theme }) => theme.fontColors.lightGrey};
			& strong {
				font-weight: 500;
				color: black;
			}
		}
	}
`;
