import styled from 'styled-components';

export const Flex = styled.div<{
	$cursor?: string;
	$direction?: string;
	$justify?: string;
	$align?: string;
	$wrap?: boolean;
}>`
	display: flex;
	flex-direction: ${props => props.$direction || 'row'};
	justify-content: ${props => props.$justify && props.$justify};
	align-items: ${props => props.$align && props.$align};
	flex-wrap: ${props => (props.$wrap ? 'wrap' : 'nowrap')};
	cursor: ${props => (props.$cursor ? props.$cursor : 'inherit')};
`;
