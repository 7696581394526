import { styled } from 'styled-components';

export const MainBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
		gap: 24px;
    height: calc(100vh / 1.4);
`

export const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
		& > .under-construction-header {
				color: #0B93E3;
				font-size: 16px;
				font-weight: 600;
		}
		
		& > .under-construction-text {
				color: #ABB3BB;
				font-size: 14px;
				font-weight: 400;
		}
`

export const IconWrapper = styled.div`
		padding: 16px;
		width: 80px;
		height: 80px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(11, 147, 227, 0.08);
		& > svg > path {
				fill: #0B93E3
		}
`
