import { scrollStyles } from '@components/utility/shared-styles';
import styled from 'styled-components';

export const StyledStandardList = styled.div`
	margin-top: 20px;

	td.form {
		max-width: 83px;
	}

	td.title {
		max-width: 250px;

		& > div {
			white-space: normal;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
		}
	}

	.program-management-container {
		/* max-height: 370px; */
		margin-top: 14px;
		margin-bottom: 24px;
		/* @media (max-width: 1440px), (max-height: 768px) {
			max-height: 260px;
		} */

		.domain-member-container {
			background-color: ${({ theme }) => theme.bgColors.white};
			/* padding-left: 24px;
			padding-top: 16px;
			padding-right: 8px;
			margin-right: 16px; */
			height: 310px;
			padding: 16px;
			flex: 1 1 60%;
			border-radius: 4px;

			.title {
				font-weight: 600;
				font-size: 20px;
				line-height: 24.4px;
				color: ${({ theme }) => theme.fontColors.darkGrey};
			}

			.domain-member-list {
				padding: 8px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				/* max-height: 312px; */
				overflow-y: auto;
				${scrollStyles};
				${({ theme }) => theme.fontColors.scrollTrack};
				height: calc(100% - 24px);

				/* @media (max-width: 1440px), (max-height: 768px) {
					max-height: 200px;
				} */

				.domain-member {
					position: relative;
					padding: 12px 0;

					&:not(:last-child):after {
						position: absolute;
						content: '';
						height: 0;
						width: 100%;
						left: 0;
						bottom: 0;
						border-bottom: ${({ theme }) => theme.borders.divider};
					}

					.user-info {
						margin-left: 12px;

						.name {
							font-size: 16px;
							font-weight: 600;
							line-height: 24px;
							color: ${({ theme }) => theme.fontColors.darkGrey};
						}

						.email {
							font-size: 13px;
							font-weight: 500;
							line-height: 19.5px;
							color: ${({ theme }) => theme.fontColors.lightGrey};
						}
					}

					.domain-roles {
						display: flex;
						gap: 10px;
					}

					.domain-role {
						font-size: 13px;
						font-weight: 600;
						line-height: 24px;
						background-color: rgba(218, 228, 232, 0.4);
						color: ${({ theme }) => theme.fontColors.textGrey};
						padding: 4px 6px;
						border-radius: 4px;
					}

					.wrapper {
						margin-top: 8px;
						margin-left: 44px;
						gap: 8px;

						& > div {
							flex-grow: 1;
							flex-shrink: 1;

							font-size: 13px;
							font-weight: 500;
							line-height: 19.5px;

							& > .label {
								color: ${({ theme }) => theme.fontColors.lightGrey};
								margin-right: 2px;
							}

							& > .company {
								color: ${({ theme }) => theme.fontColors.darkGrey};
							}
						}
					}
				}

				.empty {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: -12px;

					& > p {
						margin-top: 24px;
						color: ${({ theme }) => theme.fontColors.lightGrey};
						line-height: 150%;

						& > span {
							font-size: 16px;
							font-weight: 600;
						}
					}

					.empty-text {
						margin-top: 12px;
					}

					.button {
						margin-top: 24px;
					}
				}
			}
		}

		.progress-statistic-container {
			background-color: ${({ theme }) => theme.bgColors.white};
			padding: 16px 24px;
			flex: 1 1 40%;
			border-radius: 4px;

			.title {
				font-weight: 600;
				font-size: 20px;
				line-height: 24.4px;
				color: ${({ theme }) => theme.fontColors.darkGrey};
			}

			.chart-container {
				display: flex;
				align-items: center;
				@media (max-width: 1440px), (max-height: 768px) {
					align-items: start;
				}

				.doughnut-container {
					height: 275px;

					@media (max-width: 1600px) {
						height: 200px;
						width: 200px;
					}

					@media (max-width: 1440px), (max-height: 768px) {
						height: 160px;
						width: 160px;
					}
				}

				#legend-container {
					& > ul {
						padding-right: 8px;
						@media (max-width: 1440px), (max-height: 768px) {
							max-height: 200px;
							overflow-y: scroll;
							${scrollStyles};
							scrollbar-width: thin;
							scrollbar-color: ${({ theme }) => theme.fontColors.accent}
								${({ theme }) => theme.fontColors.scrollTrack};
						}

						& > li {
							display: flex;
							align-items: center;

							&:not(:last-child) {
								margin-bottom: 12px;
							}

							& > p {
								margin-left: 8px;
								font-size: 12px;
								font-weight: 600;
								line-height: 14.64px;
							}

							& > span {
								max-width: 16px;
								width: 100%;
								height: 16px;
							}
						}
					}
				}
			}
		}
	}

	.table-container {
		& > div {
			height: auto;
		}
	}
`;

export const StyledButtonWrapper = styled.div`
	button {
		padding: 0;
		font-size: 14px;
		font-weight: 600;
		line-height: 17.08px;
		background-color: transparent !important;
		color: ${({ theme }) => theme.fontColors.accent};
		margin-right: 10px;

		&:disabled {
			cursor: auto;
			color: ${({ theme }) => theme.fontColors.textGrey};
			opacity: 0.7;
		}

		&:active {
			box-shadow: none;
		}
	}
`;

export const ExportToasterBody = styled.div`
	font-size: 13px;
	display: flex;
	align-items: center;
	color: #abb3bb;

	p {
		flex-grow: 1;
		margin: 0 15px;

		.file-name {
			color: #333336;
			font-weight: 500;
		}
	}

	.cancel {
		cursor: pointer;
	}
`;

export const StyledExportLoading = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
