import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import { DraftRemarks } from '@asd-stan/standard/components/nwp/forms/draft-remarks';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { SingleStandardScheme } from '@asd-stan/standard/validation-schemas/single-standard.schema';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { Formik, FormikValues } from 'formik';

import { AdditionalInformation } from './page-blocks/additional-information/additional-information';
import { ENData } from './page-blocks/enData/enData-draft-tab-section';
import { Experts } from './page-blocks/experts/experts';
import { General } from './page-blocks/general/general';
import { Justification } from './page-blocks/justification/justification';
import { Originator } from './page-blocks/originator/originator';
import { StandardDraft } from './page-blocks/standard-draft/standard-draft';
import { Titles } from './page-blocks/titles/titles';
import { StyledLoaderContainer } from './single-standard.styled';

const initialValues = {
	remark: '',
};

export const SingleDraftPageTabSection: React.FC = observer(() => {
	const { t } = useTranslation();
	const standardService = getStandardService();
	const draftService = getDraftService();
	const currentUserService = getCurrentUserService();

	if (!draftService.singleDraft) {
		return (
			<StyledLoaderContainer>
				<Flex $align="center" $justify="center" $direction="column">
					<LogoIcon />
					<Loading horizontal />
				</Flex>
			</StyledLoaderContainer>
		);
	}

	const onRemarksSubmit = (values: FormikValues) => {
		if (!draftService.singleDraft || !draftService.remarks) {
			return;
		}

		standardService.updateDraft(
			draftService.singleDraft!.id,
			standardService.makeDraftCreation(
				draftService.singleDraft,
				null,
				draftService.singleDraft.remarks
					? draftService.singleDraft.remarks.map(remark => ({ text: remark.text }))
					: []
			)
		);
	};

	return (
		<>
			<InputGroup title={t('standard.singleStandardPage.general.title')}>
				<General
					form={draftService.singleDraft.generalData?.form}
					edition={draftService.singleDraft.generalData?.edition}
					stanNumber={draftService.singleDraft.generalData?.stanNumber}
					registrationNumber={draftService.singleDraft.generalData?.registrationNumber}
					isRevision={draftService.singleDraft.generalData?.isRevision}
					revision={draftService.singleDraft.generalData?.revision}
					languages={draftService.singleDraft.generalData?.languages}
					pages={draftService.singleDraft.generalData?.pages}
					isWorkingGroupNull={draftService.singleDraft.generalData?.isWorkingGroupNull}
					workingGroups={draftService.singleDraft.generalData?.workingGroups}
					standardTypes={draftService.singleDraft.generalData?.standardTypes}
					domains={draftService.singleDraft.generalData?.domains}
					scope={draftService.singleDraft.generalData?.scope}
					projectLeader={draftService.singleDraft.generalData?.leader}
					cenWiNumber={draftService.singleDraft.generalData?.cenWiNumber}
				/>
			</InputGroup>
			{draftService.singleDraft.originator
				? (draftService.singleDraft.originator.id ||
						draftService.singleDraft.originator.userId) && (
						<Originator
							user={currentUserService.currentUser}
							originator={draftService.singleDraft.originator}
						/>
				  )
				: null}
			<StandardDraft
				standardId={standardService.singleStandard?.id!}
				data={draftService.singleDraft.draft}
			/>
			<Justification
				standardName={`${draftService.singleDraft?.generalData?.form} ${draftService.singleDraft?.generalData?.registrationNumber} ${draftService.singleDraft?.generalData?.edition}`}
				data={draftService.singleDraft.justification}
			/>
			<AdditionalInformation
				tags={draftService.singleDraft.additionalInformation?.tags}
				classifications={draftService.singleDraft.additionalInformation?.classifications}
				ICSCodes={draftService.singleDraft.additionalInformation?.icsCodes}
			/>
			{draftService.singleDraft && draftService.singleDraft.titles && (
				<Titles titles={draftService.singleDraft.titles} />
			)}
			{draftService.singleDraft.experts && (
				<Experts
					data={draftService.singleDraft.experts.experts}
					wgs={draftService.singleDraft.experts.wgs}
				/>
			)}
			{draftService.singleDraft.enData && <ENData data={draftService.singleDraft.enData} />}
			<Formik
				validationSchema={SingleStandardScheme}
				initialValues={initialValues}
				onSubmit={onRemarksSubmit}>
				<InputGroup title={t('standard.createNWP.remarks.title')} children={<DraftRemarks />} />
			</Formik>
		</>
	);
});
