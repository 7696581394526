import { Trans } from 'react-i18next';

import { UpdateStageForm } from '@asd-stan/standard/domain/stage-update.entity';
import { Toggle } from '@components/toggle/toggle';
import { useFormikContext } from 'formik';

import {
	StageItem,
	StagePickerContainer,
	StageSelectorTitle,
	StageSelectorTitleBlack,
	TitleWrapper,
} from '@asd-stan/standard/components/single-standard/stage-update/active-stages/active-stages.styled';

export interface ActiveStageOption {
	code: string;
	name: string;
}

export const ActiveStages = ({
	activeStageOptions,
}: {
	activeStageOptions: ActiveStageOption[];
}) => {
	const { values, setFieldValue } = useFormikContext<UpdateStageForm>();

	const options = activeStageOptions.map(({ code, name }) => {
		const active = values.stages.activeStages.includes(code);
		const disabled = !values.stages.newStages.length && values.stages.activeStages.length === 1;

		const handleChange = () => {
			if (active) {
				if (disabled) {
					return;
				}

				setFieldValue(
					'stages.activeStages',
					values.stages.activeStages.filter(c => code !== c)
				);
				return;
			}
			setFieldValue('stages.activeStages', [...values.stages.activeStages, code]);
		};

		return {
			code,
			name,
			active,
			disabled,
			handleChange,
		};
	});

	return (
		<>
			<TitleWrapper>
				<StageSelectorTitle>
					<Trans
						i18nKey="standard.singleStandard.stageUpdateModal.activeStages.title"
						values={{
							stagesAmount: `${options.length}`,
							stagesList: `${options.map(({ code, name }) => `${code} ${name}`).join(', ')}`,
						}}>
						<StageSelectorTitleBlack />
					</Trans>
				</StageSelectorTitle>
			</TitleWrapper>
			<StagePickerContainer>
				{options.map(({ code, name, active, handleChange }) => {
					return (
						<StageItem onClick={handleChange} className={active ? 'active' : ''} key={code}>
							{code} {name}
							<Toggle checked={active} onChange={handleChange} />
						</StageItem>
					);
				})}
			</StagePickerContainer>
		</>
	);
};
