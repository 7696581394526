import React from 'react';

import { ReactComponent as DownloadIcon } from '@asd-stan/ui-kit/assets/icons/download.svg';
import { ReactComponent as FileIcon } from '@asd-stan/ui-kit/assets/icons/uploaded-file-icon.svg';

import { StyledDownloadFile } from './download-file.styled';

interface DownloadFileProps {
	fetchMethod: () => Promise<string>;
	title: string;
	hideFileIcon?: boolean;
	hideTitle?: boolean;
	notFromAssets?: boolean;
}

export const DownloadPrivateFile: React.FC<DownloadFileProps> = (
	{
		fetchMethod,
		title,
		hideTitle,
		hideFileIcon,
		notFromAssets,
	}) => {

	const attachToDOMAndDownload = (fileUrl: string) => {
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = title;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const downloadFile = async () => {
		const file = await fetchMethod();
		if (notFromAssets) {
			fetch(file)
				.then(response => response.blob())
				.then(blob => {
					const fileUrl = window.URL.createObjectURL(blob);
					attachToDOMAndDownload(fileUrl);
				})
		} else {
			const fileUrl = `/assets/${file}`;

			attachToDOMAndDownload(fileUrl);
		}
	};

	return (
		<StyledDownloadFile>
			{!hideFileIcon ? <FileIcon /> : null}
			{!hideTitle ? title : null}
			<button type="button" onClick={downloadFile}>
				<DownloadIcon />
			</button>
		</StyledDownloadFile>
	);
};
