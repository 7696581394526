import { styled } from 'styled-components';

export const AttachmentsMainWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const AttachmentsLoader = styled.div`
	width: 100%;
	height: 163px;
	> div {
		height: 100%;
		margin: 0 auto;
	}

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;

export const AttachmentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 12px;
	align-items: flex-end;
`;

export const AttachmentsListBlock = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 24px;
	width: 100%;
`;

export const AttachmentsListItem = styled.div`
	display: flex;
	flex-direction: column;
	border: solid 1px #dae4e8;
	border-radius: 6px;
	padding: 8px 16px 8px 16px;
`;

export const AttachmentsListHeader = styled.div`
	margin-bottom: 14px;
	display: flex;
	justify-content: space-between;
`;

export const AttachmentsListAuthor = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

export const AttachmentsListActions = styled.div`
	display: flex;
	gap: 16px;
`;

export const AttachmentsDisplayField = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 4px;
`;

export const AttachmentsFieldHeader = styled.div`
	color: #abb3bb;
	font-size: 11px;
	text-transform: uppercase;
	flex: 1;
	align-items: center;
`;

export const AttachmentsFieldValue = styled.div<{ $isAbsent?: boolean }>`
	color: ${props => (props.$isAbsent ? '#abb3bb' : '#525259')};
	font-size: 13px;
	font-weight: ${props => (props.$isAbsent ? '400' : '600')};
	margin-top: 4px;
	flex: 1;
	flex-wrap: wrap;
	word-break: break-word;
	line-height: 19.5px;
`;

export const AttachmentsDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const AttachmentDeleteButton = styled.button`
	background: transparent;
`;

export const NoAttachmentBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
`;

export const NoAttachmentTextBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	& > .no-attachment-header {
		color: #0b93e3;
		font-size: 16px;
		font-weight: 600;
	}

	& > .no-attachment-text {
		color: #abb3bb;
		font-size: 14px;
		font-weight: 400;
	}
`;

export const NoAttachmentIconWrapper = styled.div`
	padding: 16px;
	width: 80px;
	height: 80px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(11, 147, 227, 0.08);
	& > svg > path {
		fill: #0b93e3;
	}
`;
