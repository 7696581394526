import {
	ColumnDef,
	ColumnSort,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import {
	PaginationCollectionController,
	PaginationCollectionControllerStatus,
} from '@asd-stan/helpers/pagination-collection-controller';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { CatalogItem } from '@asd-stan/standard/domain/catalog-item.entity';
import { CatalogCollectionFilter } from '@asd-stan/standard/domain/catalog.service';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as TableArrow } from '@assets/asd-stan-works/table/table-arrow.svg';
import { ReactComponent as FilterAsc } from '@assets/asd-stan-works/table/table-filter-a-z.svg';
import { ReactComponent as FilterDefault } from '@assets/asd-stan-works/table/table-filter-default.svg';
import { ReactComponent as FilterDesc } from '@assets/asd-stan-works/table/table-filter-z-a.svg';
import { EmptyTableSearch } from '@components/content-table/empty-table-search';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { MirroredIcon } from '@components/utility/mirrored-icon';

import {
	StyledTable,
	StyledTableLoader,
	StyledTablePagination,
} from '@components/content-table/table.styled';

interface CatalogTableProps {
	columns: ColumnDef<CatalogItem, string>[];
	emptySearchText?: string;
	paginator: PaginationCollectionController<CatalogItem, CatalogCollectionFilter>;
	tableData: CatalogItem[];
}

export const CatalogListTable: React.FC<CatalogTableProps> = observer(
	({ columns, tableData, emptySearchText, paginator }) => {
		const [sorting, setSorting] = useState<ColumnSort[]>([]);
		const [rowSelection, setRowSelection] = useState({});
		const toasterService = getToasterService();
		const { t } = useTranslation();

		const table = useReactTable({
			data: tableData,
			columns: columns,
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			state: {
				sorting: sorting,
				rowSelection: rowSelection,
			},
			onRowSelectionChange: setRowSelection,
			enableRowSelection: true,
			onSortingChange: prevState => setSorting(prevState),
			manualSorting: true,
		});

		const sortingItem = sorting[0];

		const addSortingToFilter = (sorting: ColumnSort[]) => {
			if (sorting.length > 0) {
				const { id, desc } = sorting[0];

				if (desc) {
					paginator.addFilterOnCurrentPage({ orderField: id, orderDirection: 'DESC' });
				} else {
					paginator.addFilterOnCurrentPage({ orderField: id, orderDirection: 'ASC' });
				}
			} else {
				paginator.addFilterOnCurrentPage({
					orderField: paginator.initialFilter.orderField,
					orderDirection: paginator.initialFilter.orderDirection,
				});
			}
		};

		const handleGoToPage = (pageNumber: number) => {
			setRowSelection({});
			return paginator.goToPage(pageNumber);
		};

		const navigateToCatalogItem = (idx: number, published: boolean) => {
			if (published) {
				window.open(`${process.env.REACT_APP_STAN_SHOP_URL}/catalog/item/${tableData[idx].id}`);
			} else {
				toasterService.showToast(
					ToasterStatus.error,
					t('standard.catalog.toaster.pageDoesNotExist')
				);
			}
		};

		useEffect(() => {
			addSortingToFilter(sorting);
		}, [sortingItem?.id, sortingItem?.desc]);

		return (
			<>
				{table.getFilteredRowModel().rows.length > 0 ||
				paginator.status === PaginationCollectionControllerStatus.LOADING ? (
					<>
						{paginator.status === PaginationCollectionControllerStatus.LOADING ? (
							<StyledTableLoader>
								<Flex $align="center" $justify="center" $direction="column">
									<LogoIcon />
									<Loading horizontal />
								</Flex>
							</StyledTableLoader>
						) : (
							<StyledTable>
								<thead>
									{table.getHeaderGroups().map(headerGroup => (
										<tr key={headerGroup.id}>
											{headerGroup.headers.map(header => {
												return (
													<th
														key={header.id}
														className={header.id}
														onClick={header.column.getToggleSortingHandler()}>
														{header.isPlaceholder ? null : (
															<div>
																{flexRender(header.column.columnDef.header, header.getContext())}
																{header.column.getCanSort() &&
																	(header.column.getIsSorted() ? (
																		header.column.getIsSorted() === 'desc' ? (
																			<FilterDesc />
																		) : (
																			<FilterAsc />
																		)
																	) : (
																		<FilterDefault />
																	))}
															</div>
														)}
													</th>
												);
											})}
										</tr>
									))}
								</thead>
								<tbody>
									{table.getRowModel().rows.map(row => {
										const itemId = tableData[+row.id]?.id;
										return (
											<tr
												key={row.id}
												style={{ cursor: 'pointer' }}
												onClick={() =>
													navigateToCatalogItem(Number(row.id), row.original.published)
												}>
												{row.getVisibleCells().map(cell => {
													return (
														//@ts-expect-error
														<td key={cell.id} className={cell.column.columnDef.className || ''}>
															{flexRender(cell.column.columnDef.cell, {
																...cell.getContext(),
																itemId,
															})}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</StyledTable>
						)}
						{paginator &&
						paginator.pageCount !== 1 &&
						paginator.status !== PaginationCollectionControllerStatus.LOADING ? (
							<StyledTablePagination>
								<ReactPaginate
									disabledClassName="disabled"
									pageCount={paginator.pageCount}
									pageRangeDisplayed={3}
									marginPagesDisplayed={1}
									previousLabel={
										<MirroredIcon>
											<TableArrow />
										</MirroredIcon>
									}
									nextLabel={<TableArrow />}
									onPageChange={({ selected }) => handleGoToPage(selected)}
									containerClassName="pagination"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakClassName="page-item break-button"
									breakLinkClassName="page-link"
									activeClassName="active"
									forcePage={paginator.currentPage}
								/>
							</StyledTablePagination>
						) : null}
					</>
				) : (
					<EmptyTableSearch emptySearchText={emptySearchText} />
				)}
			</>
		);
	}
);
