import { MyVote, SingleBallot } from '@asd-stan/ballot/domain/ballot.entity';

import { General as GeneralSection } from './sections/general';
import { Voting } from './sections/voting/voting';

export const General = ({
	ballotId,
	ballot,
	vote,
	onVoteSubmit,
}: {
	ballotId: number;
	ballot: SingleBallot;
	vote: MyVote | null;
	onVoteSubmit(): void;
}) => {
	return (
		<>
			<GeneralSection ballot={ballot} />
			<Voting ballotId={ballotId} ballot={ballot} vote={vote} onVoteSubmit={onVoteSubmit} />
		</>
	);
};
