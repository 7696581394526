import styled from 'styled-components';

export const StyledRadio = styled.div<{ disabled: boolean }>`
	width: fit-content;

	label {
		display: flex;
		align-items: center;
		cursor: pointer;
		color: ${({ theme, disabled }) =>
			disabled ? theme.fontColors.lightGrey : theme.fontColors.darkGrey};
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	input {
		display: none;
		visibility: hidden;
	}

	i {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		cursor: pointer;
		background-color: ${({ theme }) => theme.bgColors.white};
		border: 2px solid #dae4e8;
		display: inline-block;
		position: relative;
		margin-right: 12px;
	}

	i::after {
		content: '';
		width: 8px;
		height: 8px;
		background-color: ${({ theme }) => theme.bgColors.white};
		position: absolute;
		border-radius: 50%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: opacity 0.2s;
	}

	input:checked + i {
		border: 2px solid ${({ theme }) => theme.bgColors.accent};
		background-color: ${({ theme }) => theme.bgColors.accent};
	}

	input:checked + i::after {
		opacity: 1;
	}
`;
