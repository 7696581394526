import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase, OptionsOrGroups } from 'react-select';

import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { Option } from '@components/form-select/form-select';
import { FormSelectAsyncCreatablePagination } from '@components/form-select/form-select-async-creatable-pagination';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { Field, FormikValues, useFormikContext } from 'formik';

const LIMIT = 6;

export const AdditionalInformation = observer(() => {
	const { t } = useTranslation();
	const { values, setFieldValue, setFieldError } = useFormikContext<FormikValues>();

	const standardService = getStandardService();

	//TODO: Fix it when we gonna refactor it and add pagination controller

	// useEffect(() => {
	// 	const onPageLoad = async () => {
	// 		standardService.getAllAdditionalInformation();
	// 	};
	//
	// 	onPageLoad();
	// }, [standardService]);

	const calculateOffset = (page: number) => {
		return (page - 1) * LIMIT;
	};

	const handleCreateNewTag = async (value: string) => {
		if (value.length > 255 || value.length < 2) {
			setFieldError('tags', t('user.userInvite.fieldErrors.length', { number: '2-255' }));
			return;
		}

		const newTag = { label: value.trim(), value: value.trim() };

		setFieldValue('tags', [...values.tags, newTag]);
	};

	const handleLoadTags = async (
		search: string,
		prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page);

		const count = await standardService.getTags(LIMIT, offset, search);

		const updateTags = standardService.tags!.map(tag => {
			return {
				label: tag.value,
				value: tag.value,
			};
		});

		const hasMore = page < Math.ceil(count / 6);

		return {
			options: updateTags,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const handleLoadClassifications = async (
		search: string,
		prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page);

		const count = await standardService.getClassifications(LIMIT, offset, search);
		const updateClassifications = standardService.classifications!.map(classification => {
			return {
				label: `${classification.number} ${classification.name}`,
				value: classification.id.toString(),
			};
		});
		const hasMore = page < Math.ceil(count / 6);

		return {
			options: updateClassifications,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const handleLoadIcsCodes = async (
		search: string,
		prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page);

		const count = await standardService.getIcsCodes(LIMIT, offset, search);

		const updateIcsCodes = standardService.icsCodes?.map(icsCode => {
			return {
				label: `${icsCode.code} ${icsCode.name}`,
				value: icsCode.code,
			};
		});

		const hasMore = page < Math.ceil(count / 6);
		return {
			options: updateIcsCodes,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	return (
		<>
			<Field
				component={FormSelectAsyncCreatablePagination}
				name="tags"
				title={t('standard.createNWP.additionalInformation.tags')}
				onCreateOption={handleCreateNewTag}
				fullWidth
				isMulti
				defaultOptions
				loadOptions={handleLoadTags}
				showError
				showCheckboxOption
				useSubmitOnChange
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="classification"
				title={t('standard.createNWP.additionalInformation.classification')}
				defaultOptions
				loadOptions={handleLoadClassifications}
				fullWidth
				isMulti
				showError
				showCheckboxOption
				useSubmitOnChange
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="ICSCodes"
				title={t('standard.createNWP.additionalInformation.ICSCodes')}
				defaultOptions
				loadOptions={handleLoadIcsCodes}
				fullWidth
				isMulti
				showError
				showCheckboxOption
				useSubmitOnChange
			/>
		</>
	);
});
