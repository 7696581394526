import { injector } from '@asd-stan/injector/injector';

import { BallotRepo } from '../api/ballot.repo';
import { BallotService } from '../domain/ballot.service';

import { BALLOT_REPO, BALLOT_SERVICE } from './constants';

export const getBallotRepo = () => {
	return injector.get<BallotRepo>(BALLOT_REPO);
};

export const getBallotService = () => {
	return injector.get<BallotService>(BALLOT_SERVICE);
};
