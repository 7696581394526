import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import i18n from '@asd-stan/i18n/i18n';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import * as yup from 'yup';

const currentUserService = getCurrentUserService();

export const NWPSchema = yup.object().shape({
	//general section
	form: yup
		.object()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.shape({
			value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
			label: yup
				.string()
				.required(i18n.t('standard.createNWP.validationErrors.required'))
				.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
		}),
	registrationNumber: yup
		.string()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	revisionId: yup
		.object()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.shape({
			value: yup.string(),
			label: yup
				.string()
				.required(i18n.t('standard.createNWP.validationErrors.required'))
				.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
		}),
	edition: yup
		.string()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	standard: yup
		.object()
		.nullable()
		.when('revisionId', revision => {
			if (revision[0] === null) {
				return yup.object().shape({
					label: yup
						.string()
						.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
				});
			}
			if (revision[0].value === 'yes') {
				if (!currentUserService.currentUser?.systemRoles.includes(SystemRole.EXPERT)) {
					return yup
						.object()
						.required(i18n.t('standard.createNWP.validationErrors.required'))
						.shape({
							label: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
						});
				}
			}
			return yup.object().shape({
				label: yup
					.string()
					.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
			});
		}),
	cenWiNumber: yup
		.string()
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' }))
		.nullable(),
	stanNumber: yup
		.string()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
	languages: yup
		.array()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.of(
			yup
				.object()
				.required(i18n.t('standard.createNWP.validationErrors.required'))
				.shape({
					value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
					label: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
				})
		)
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.min(1, i18n.t('standard.createNWP.validationErrors.required')),
	typeId: yup.array().of(
		yup
			.object()
			.required(i18n.t('standard.createNWP.validationErrors.required'))
			.shape({
				value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
				label: yup
					.string()
					.required(i18n.t('standard.createNWP.validationErrors.required'))
					.max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
			})
	),

	pages: yup.number(),
	scope: yup
		.string()
		.max(5000, ' ')
		.required(i18n.t('standard.createNWP.validationErrors.required')),
	domainCodes: yup
		.array()
		.required(i18n.t('standard.createNWP.validationErrors.required'))
		.when([], () => {
			if (
				!currentUserService.currentUser?.systemRoles.includes(SystemRole.ES) &&
				!currentUserService.currentUser?.systemRoles.includes(SystemRole.DIRECTOR)
			) {
				return yup.array().of(
					yup.object().shape({
						value: yup.string(),
						label: yup.string(),
					})
				);
			}

			return yup
				.array()
				.required(i18n.t('standard.createNWP.validationErrors.required'))
				.of(
					yup
						.object()
						.required(i18n.t('standard.createNWP.validationErrors.required'))
						.shape({
							value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
							label: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
						})
				)
				.min(1, i18n.t('standard.createNWP.validationErrors.required'));
		}),
	workingGroupCodes: yup.lazy(value =>
		Array.isArray(value)
			? yup
					.array()
					.required(i18n.t('standard.createNWP.validationErrors.required'))
					.when([], () => {
						if (
							!currentUserService.currentUser?.systemRoles.includes(SystemRole.ES) &&
							!currentUserService.currentUser?.systemRoles.includes(SystemRole.DIRECTOR)
						) {
							return yup.array().of(
								yup.object().shape({
									value: yup.string(),
									label: yup.string(),
								})
							);
						}

						return yup
							.array()
							.required(i18n.t('standard.createNWP.validationErrors.required'))
							.of(
								yup
									.object()
									.required(i18n.t('standard.createNWP.validationErrors.required'))
									.shape({
										value: yup
											.string()
											.required(i18n.t('standard.createNWP.validationErrors.required')),
										label: yup
											.string()
											.required(i18n.t('standard.createNWP.validationErrors.required')),
									})
							)
							.min(1, i18n.t('standard.createNWP.validationErrors.required'));
					})
			: yup.object().shape({
					value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
					label: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
			  })
	),

	leaderId: yup.object().nullable().shape({
		value: yup.string(),
	}),
	//end general

	//originator section

	originator: yup.object().when('radioForm', value => {
		if (value[0] === true) {
			return yup
				.object()
				.required(i18n.t('user.userInvite.fieldErrors.required'))
				.shape({
					value: yup.object().required(i18n.t('user.userInvite.fieldErrors.required')),
				});
		}

		return yup.object();
	}),
	newOriginator: yup.object().when('radioForm', value => {
		if (value[0] === false) {
			return yup.object().shape({
				firstName: yup
					.string()
					.max(50, i18n.t('user.userInvite.fieldErrors.length', { number: '1-50' }))
					.required(i18n.t('user.userInvite.fieldErrors.required')),
				lastName: yup
					.string()
					.max(50, i18n.t('user.userInvite.fieldErrors.length', { number: '1-50' }))
					.required(i18n.t('user.userInvite.fieldErrors.required')),
				email: yup
					.string()
					.email(i18n.t('user.userInvite.fieldErrors.email'))
					.required(i18n.t('user.userInvite.fieldErrors.required'))
					.matches(/@[^.]*\./, i18n.t('user.userInvite.fieldErrors.email')),
				phone: yup.string(),
				company: yup
					.object()
					.nullable()
					.required(i18n.t('user.userInvite.fieldErrors.required'))
					.shape({
						value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
						label: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
					}),
				companyCountry: yup
					.object()
					.nullable()
					.shape({
						value: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
						label: yup.string().required(i18n.t('user.userInvite.fieldErrors.required')),
					}),
				position: yup.array().of(
					yup.object().shape({
						value: yup.string(),
						label: yup.string(),
					})
				),
			});
		}
		return yup.object().shape({
			firstName: yup
				.string()
				.max(50, i18n.t('user.userInvite.fieldErrors.length', { number: '1-50' })),
			lastName: yup
				.string()
				.max(50, i18n.t('user.userInvite.fieldErrors.length', { number: '1-50' })),
			email: yup
				.string()
				.email(i18n.t('user.userInvite.fieldErrors.email'))
				.matches(/@[^.]*\./, i18n.t('user.userInvite.fieldErrors.email')),
			phone: yup.string(),
			position: yup.array().of(
				yup.object().shape({
					value: yup.string(),
					label: yup.string(),
				})
			),
		});
	}),
	//end originator

	//justification section

	purpose: yup.string().max(5000).min(1),
	revisionJustification: yup
		.string()
		.nullable()
		.when(['revisionId'], revision => {
			if (revision[0] === null) {
				return yup.string().nullable().max(5000, ' ');
			}
			if (revision[0] !== null && revision[0].value === 'yes') {
				return yup
					.string()
					.nullable()
					.required(i18n.t('standard.createNWP.validationErrors.required'))
					.max(5000, ' ');
			}
			return yup.string().nullable().max(5000, ' ');
		}),
	technicalChanges: yup.array().when('revisionId', revision => {
		if (revision[0] === null) {
			return yup.array().of(yup.object());
		}
		if (revision[0] !== null && revision[0].value === 'yes') {
			return yup.array().of(
				yup.object().shape({
					title: yup
						.string()
						.required(i18n.t('standard.createNWP.validationErrors.required'))
						.max(
							200,
							i18n.t('standard.createNWP.validationErrors.tooShort', {
								length: 200,
							})
						),
					change: yup
						.string()
						.required(i18n.t('standard.createNWP.validationErrors.required'))
						.max(2000, ' '),
				})
			);
		}
		return yup.array().of(yup.object());
	}),
	externalConsideredStandardsWH: yup.string().max(500, ' '),
	patentReferencesWH: yup
		.string()
		.when(['knownPatentIssue', 'patentFile'], (values, schema, field) => {
			const [knownPatentIssue, patentFile] = values;
			if (knownPatentIssue === true) {
				if (Array.isArray(patentFile) && patentFile.length === 0) {
					return yup
						.string()
						.required(i18n.t('standard.createNWP.validationErrors.required'))
						.max(5000, ' ');
				}
				return yup.string().max(5000, ' ');
			}
			return yup.string().max(5000, ' ');
		}),

	//end justification section

	//additional information
	tags: yup.array().of(
		yup.object().shape({
			value: yup.string().required(),
			label: yup.string().required(),
		})
	),
	//end additional information

	//titles
	standardTitleEN: yup
		.string()
		.max(1000, ' ')
		.required(i18n.t('standard.createNWP.validationErrors.required')),
	standardTitleDE: yup.string().max(1000, ' '),
	standardTitleFR: yup.string().max(1000, ' '),
	//end title

	//experts

	//end experts

	remark: yup.string().max(1000, ' ').min(1),

	enData: yup.object().shape({
		name: yup.string().max(255, i18n.t('user.userInvite.fieldErrors.length', { number: '1-255' })),
		title: yup.string().max(1000, ''),
	}),

	attachments: yup.array().of(
		yup.object().shape({
			attachmentDescription: yup
				.string()
				.min(50, i18n.t('standard.createNWP.validationErrors.tooShort', { length: 50 }))
				.max(500, ' ')
				.required(i18n.t('standard.createNWP.validationErrors.required')),
			attachmentType: yup
				.object()
				.shape({
					value: yup.string().required(i18n.t('standard.createNWP.validationErrors.required')),
					label: yup.string(),
				})
				.required(i18n.t('standard.createNWP.validationErrors.required')),
		})
	),
});

export const emptySchema = yup.object().shape({
	remark: yup.string().max(1000, ' ').min(1),
});
