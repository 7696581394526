import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	BallotForm as BallotFormType,
	BallotStatus,
	ValidatedBallotForm,
} from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService } from '@asd-stan/ballot/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { Button } from '@components/button/button';

import { BallotForm, SubmitButton } from '../ballot-form/ballot-form';

export const CreateBallot = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ballotService = getBallotService();
	const toasterService = getToasterService();

	const handleSubmit = async (values: BallotFormType) => {
		try {
			await ballotService.createBallot(values as ValidatedBallotForm);
			toasterService.showToast(ToasterStatus.success, t('ballot.createBallot.success'));
			navigate('/ballots');
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<BallotForm
			title={t('ballot.createBallot.title')}
			onSubmit={handleSubmit}
			actions={
				<>
					<Button secondary title={t('ballot.createBallot.cancel')} onClick={() => navigate(-1)} />
					<SubmitButton title={t('ballot.createBallot.save')} submitStatus={BallotStatus.Pending} />
					<SubmitButton
						title={t('ballot.createBallot.launch')}
						submitStatus={BallotStatus.Active}
					/>
				</>
			}
		/>
	);
});
