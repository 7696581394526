export enum StandardStatus {
	WIP = 'WIP – Work in progress',
	AN = 'AN – Cancelled',
	PB = 'PB – Published',
	RT = 'RT – Withdrawn',
	DC = 'DC – Declassified',
	SD = 'SD – Superseded',
	ENWIP = 'Transformation to EN in progress',
	EN = 'EN – Published as EN',
	SR = 'SR in progress',
}
