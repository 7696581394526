import { CurrentUser } from '@asd-stan/current-user/domain/current-user.entity';
import { getCurrentUserRepo } from '@asd-stan/current-user/infrastructure/getters';
import { Domain } from '@asd-stan/domain/domain/domain.entity';
import { hasOnlyExpertRole, userHasDomainRoles, userHasWGRoles } from '@asd-stan/helpers/app-utils';
import { SingleUser } from '@asd-stan/user/api/single-user.mapper';
import { DomainRole } from '@asd-stan/user/domain/domain-role.enum';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { User } from '@asd-stan/user/domain/user.entity';
import { WorkingGroup } from '@asd-stan/working-group/domain/working-group.entity';
import { makeAutoObservable, runInAction } from 'mobx';

export interface WGParticipation {
	workingGroup: WorkingGroup;
	roles?: string[];
}

export interface Participation {
	seeAllDomain: boolean;
	domain: Domain;
	workingGroupParticipations?: WGParticipation[];
	roles?: string[];
}

export class CurrentUserService {
	private _currentUser: null | CurrentUser = null;
	private _domainParticipations: Participation[] = [];
	private _isOnlyExpert: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	// getters
	private get _currentUserRepo() {
		return getCurrentUserRepo();
	}

	get currentUser() {
		return this._currentUser;
	}

	get userRoles() {
		return this._currentUser?.systemRoles;
	}

	get domainParticipations() {
		return this._domainParticipations;
	}

	get isOnlyExpert() {
		return this._isOnlyExpert;
	}

	// getters end
	// methods

	public clearCurrentUser() {
		this._currentUser = null;
		this._domainParticipations = [];
		this._isOnlyExpert = false;
	}

	async getMe(): Promise<User> {
		const response = await this._currentUserRepo.getMe();

		runInAction(() => {
			if (hasOnlyExpertRole(response.user.systemRoles)) {
				this._isOnlyExpert = true;
			}

			this._currentUser = { ...response.user };
			this._domainParticipations = response.participations;
		});

		return response.user;
	}

	getUser(id: number) {
		return this._currentUserRepo.getUser(id);
	}

	async updateMe(user: SingleUser) {
		await this._currentUserRepo.updateMe(user);
		await this.getMe();
	}

	hasRole(role: SystemRole | SystemRole[]) {
		if (!this.userRoles) {
			return false;
		}
		if (Array.isArray(role)) {
			return role.some(r => this.userRoles?.includes(r));
		}
		return this.userRoles.includes(role);
	}

	hasOnlyRole(role: SystemRole) {
		if (!this.userRoles) {
			return false;
		}
		return this.userRoles.length === 1 && this.userRoles.includes(role);
	}

	hasDomainRole(role: DomainRole | DomainRole[]) {
		return userHasDomainRoles(role, this.domainParticipations);
	}

	hasOnlyRoleWithinDomain(role: DomainRole, domainCode: string) {
		const participation = this.domainParticipations.find(
			({ domain }) => domain.code === domainCode
		);
		return participation?.roles?.length === 1 && participation.roles[0] === role;
	}

	hasWorkingGroupRole(role: string | string[]) {
		return userHasWGRoles(role, this.domainParticipations);
	}
}
