import { useTranslation } from 'react-i18next';

import { WithTimestamp } from '@asd-stan/ballot/domain/ballot.entity';
import { Flex } from '@components/utility/flex';

import { StyledBallotField } from './single-ballot.styled';
import { transformDate } from './transformDate';

type Timestamp = Omit<WithTimestamp<any>, 'value'>;

export const Field = ({
	label,
	value,
	fullWidth,
	timestamp,
	showEmptyValuePlaceholder,
}: {
	label: React.ReactNode;
	value?: React.ReactNode;
	fullWidth?: boolean;
	timestamp?: Timestamp;
	showEmptyValuePlaceholder?: boolean;
}) => {
	const { t } = useTranslation();

	const parseTimestamp = (timestamp: Timestamp) =>
		`${t('ballot.singleBallot.timestamp')} ${timestamp.doneBy.firstName} ${
			timestamp.doneBy.lastName
		}, ${transformDate(timestamp.doneAt, true)}`;

	return (
		<StyledBallotField $fullWidth={fullWidth || !!timestamp}>
			<Flex $justify="space-between">
				<span className="label">{label}</span>{' '}
				{timestamp && <div className="timestamp">{parseTimestamp(timestamp)}</div>}
			</Flex>
			{value ? (
				<div className="value">{value}</div>
			) : showEmptyValuePlaceholder ? (
				<div className="emptyValuePlaceholder">
					{t('ballot.singleBallot.noValuePlaceholder', { fieldName: label })}
				</div>
			) : null}
		</StyledBallotField>
	);
};
