import { styled } from 'styled-components';

export const StyledRemarkTextarea = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-top: ${({ theme }) => theme.borders.divider};
	gap: 16px;

	i.avatar {
		margin-top: 16px;
	}

	button {
		margin-top: 16px;
	}

	.input-container {
		margin-top: 0;
	}

	> * {
		flex-grow: 1;
	}

	> *:first-child,
	> *:last-child {
		flex-grow: 0;
	}
`;
