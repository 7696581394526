import { useTranslation } from 'react-i18next';

import { StyledField } from './field.styled';

export const Field = ({
	label,
	value,
	fullWidth,
}: {
	label: string;
	value?: React.ReactNode;
	fullWidth?: boolean;
}) => {
	const { t } = useTranslation();

	const valueClassNames = ['value'];

	if (!value) {
		valueClassNames.push('no-value');
	}

	return (
		<StyledField style={{ gridColumn: fullWidth ? 'span 2' : 'unset' }}>
			<div className="label">{label}</div>
			<div className={valueClassNames.join(' ')}>
				{value ? value : t('common.field.noValue', { fieldName: label })}
			</div>
		</StyledField>
	);
};
