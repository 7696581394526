import styled from 'styled-components';

export const StyledRouteContainer = styled.div<{ $fullWidth?: boolean }>`
	margin-left: ${props => props.$fullWidth ? 0 : '297px'};
	height: calc(100vh - 60px);
	> div {
		height: 100%;
		margin: 0 auto;
	}

	svg {
		width: 148px;
		height: 23px;
		margin-bottom: 32px;
	}

	svg > path {
		fill: #0b93e3;
	}

	img {
		width: 40px;
		height: 40px;
	}
`;
