import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {userHasDomainRoles, userHasRoles, userHasWGRoles} from '@asd-stan/helpers/app-utils';
import { DomainRole } from '@asd-stan/user/domain/domain-role.enum';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';

interface ProtectedRouteProps {
	redirectPath?: string;
	children?: React.ReactElement;
	allowedRoles: string[];
}

export const ProtectedCatalogRoute: React.FC<ProtectedRouteProps> = observer(
	({ redirectPath = '/forbidden', children, allowedRoles }) => {
		const currentUserService = getCurrentUserService();

		let isAllowed;

		if (userHasRoles(SystemRole.BOARD_MEMBER, currentUserService.userRoles!)) {
			isAllowed = userHasDomainRoles(DomainRole.FP, currentUserService.domainParticipations);
		} else if (userHasRoles(SystemRole.EXPERT, currentUserService.userRoles!)) {
			isAllowed = userHasDomainRoles(
				[DomainRole.DS, DomainRole.WGS, DomainRole.FP],
				currentUserService.domainParticipations
			) || userHasWGRoles('WGS', currentUserService.domainParticipations);
		} else {
			isAllowed = userHasRoles(allowedRoles, currentUserService.userRoles!);
		}

		if (!isAllowed) {
			return <Navigate to={redirectPath} replace />;
		}

		return children ? children : <Outlet />;
	}
);
