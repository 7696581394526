import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { downloadFileByUrl } from '@asd-stan/helpers/download-file-by-url';
import { DomainRole } from '@asd-stan/user/domain/domain-role.enum';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';

import { getBallotRepo } from '../infrastructure/getters';

import { BallotListFilter, ValidatedBallotForm, ValidatedVotingForm } from './ballot.entity';

export class BallotService {
	get _ballotRepo() {
		return getBallotRepo();
	}

	get _currentUserService() {
		return getCurrentUserService();
	}

	checkIfCurrentUserIsAbleToCreateBallot() {
		return (
			this._currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES]) ||
			(this._currentUserService.hasRole([SystemRole.EXPERT, SystemRole.MC]) &&
				this._currentUserService.hasWorkingGroupRole(DomainRole.WGS))
		);
	}

	createBallot(ballotValues: ValidatedBallotForm) {
		return this._ballotRepo.createBallot(ballotValues);
	}

	getBallotList(limit: number, offset: number, filter: Partial<BallotListFilter>) {
		return this._ballotRepo.getBallotList(limit, offset, filter);
	}

	getSingleBallotToUpdate(id: number) {
		return this._ballotRepo.getSingleBallotToUpdate(id);
	}

	getSingleBallot(id: number) {
		return this._ballotRepo.getSingleBallot(id);
	}

	checkIfCurrentUserIsAbleToUpdateBallot(ballotCreatorId: number) {
		if (this._currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES])) {
			return true;
		}
		if (!this._currentUserService.currentUser) {
			return false;
		}
		return (
			this._currentUserService.hasRole([SystemRole.EXPERT, SystemRole.MC]) &&
			this._currentUserService.hasWorkingGroupRole(DomainRole.WGS) &&
			this._currentUserService.currentUser.id === ballotCreatorId
		);
	}

	updateBallot(id: number, ballotValues: ValidatedBallotForm) {
		return this._ballotRepo.updateBallot(id, ballotValues);
	}

	deleteBallot(id: number) {
		return this._ballotRepo.deleteBallot(id);
	}

	async downloadBallotAttachmentFile(id: number, fileName: string) {
		const fileUrl = await this._ballotRepo.getBallotAttachmentFileUrl(id);
		await downloadFileByUrl(fileUrl, fileName);
	}

	checkIfCurrentUserIsAbleToVote(selectedVotersIds: number[]) {
		if (this._currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES])) {
			return true;
		}
		if (!this._currentUserService.currentUser) {
			return false;
		}
		const currentUserId = this._currentUserService.currentUser.id;
		return !!currentUserId && selectedVotersIds.includes(currentUserId);
	}

	createVote(ballotId: number, voteValues: ValidatedVotingForm) {
		return this._ballotRepo.createVote(ballotId, voteValues);
	}

	updateVote(id: number, ballotId: number, voteValues: ValidatedVotingForm) {
		return this._ballotRepo.updateVote(id, ballotId, voteValues);
	}

	getVoteList(ballotId: number, limit: number, offset: number) {
		return this._ballotRepo.getVoteList(ballotId, limit, offset);
	}

	async downloadVoteAttachmentFile(id: number, fileName: string) {
		const fileUrl = await this._ballotRepo.getVoteAttachmentFileUrl(id);
		await downloadFileByUrl(fileUrl, fileName);
	}

	getBallotStats(ballotId: number) {
		return this._ballotRepo.getBallotStats(ballotId);
	}

	getDashboardBallotList(limit: number, offset: number, filter: Partial<BallotListFilter>) {
		return this._ballotRepo.getBallotList(limit, offset, { ...filter, myDashboard: true });
	}
}
