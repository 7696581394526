import i18n from '@asd-stan/i18n/i18n';
import * as yup from 'yup';

export const createBallotValidationSchema = yup.object().shape({
	title: yup
		.string()
		.max(500, i18n.t('ballot.createBallot.errors.max500'))
		.required(i18n.t('ballot.createBallot.errors.required')),
	description: yup.string().max(5000, ''),
	openingDate: yup.string().required(i18n.t('ballot.createBallot.errors.required')),
	closingDate: yup.string().required(i18n.t('ballot.createBallot.errors.required')),
	selectedVoters: yup.array().test({
		message: i18n.t('ballot.createBallot.errors.required'),
		test: arr => !!arr?.length,
	}),
	result: yup.object().shape({}).required(i18n.t('ballot.createBallot.errors.required')),
	resultComment: yup.string().max(5000, ''),
	questions: yup.array().of(
		yup.object().shape({
			title: yup
				.string()
				.max(500, i18n.t('ballot.createBallot.errors.max500'))
				.required(i18n.t('ballot.createBallot.errors.required')),
			comment: yup.string().max(500, i18n.t('ballot.createBallot.errors.max500')),
		})
	),
});
