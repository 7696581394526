import styled from 'styled-components';

export const StyledTechnicalChanges = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	.container-header {
		margin-top: 16px;
	}

	h1 {
		word-wrap: break-word;
		margin-top: 12px;
		color: ${({ theme }) => theme.fontColors.textGrey};
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
	}

	p {
		color: ${({ theme }) => theme.fontColors.lightGrey};
		font-size: 11px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		text-transform: uppercase;
	}

	button {
		align-items: center;
	}
`;
