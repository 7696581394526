import React from 'react';

import { StyledRadio } from './radio.styled';

export interface RadioProps {
	label?: string;
	checked?: boolean;
	onChange?: (e: React.ChangeEvent) => void;
	name?: string;
	value?: string;
	disabled?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
	label,
	checked,
	onChange,
	name,
	value,
	disabled = false,
}) => {
	return (
		<StyledRadio disabled={disabled}>
			<label>
				<input
					type="radio"
					checked={checked}
					onChange={onChange}
					name={name}
					value={value}
					disabled={disabled}
				/>
				<i />
				{label}
			</label>
		</StyledRadio>
	);
};
