import { CellContext } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { Domain } from '@asd-stan/domain/domain/domain.entity';
import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import {
	isOriginatorInStandard,
	userHasRolesAndParticipatesInDomain,
} from '@asd-stan/helpers/app-utils';
import { PaginationCollectionControllerStatus } from '@asd-stan/helpers/pagination-collection-controller';
import { useRequest } from '@asd-stan/helpers/use-request';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { mapStandardStatusDTOToStandardStatus } from '@asd-stan/standard/api/standard-status.mapper';
import { StandardListTable } from '@asd-stan/standard/components/standard-list/standard-list-table';
import { Form } from '@asd-stan/standard/domain/enums';
import { StandardStage } from '@asd-stan/standard/domain/standard-stage.entity';
import { StandardStatus } from '@asd-stan/standard/domain/standard-status.entity';
import { Standard } from '@asd-stan/standard/domain/standard.entity';
import { StandardCollectionFilter } from '@asd-stan/standard/domain/standard.service';
import { getStagesService, getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { WorkingGroup } from '@asd-stan/working-group/domain/working-group.entity';
import { getWgService } from '@asd-stan/working-group/infrastructure/getters';
import { ReactComponent as ExportIcon } from '@assets/asd-stan-works/icons/export-icon.svg';
import { ReactComponent as Plus } from '@assets/asd-stan-works/icons/plus-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-pencil.svg';
import { ReactComponent as TrashBitIcon } from '@assets/icons/trash-bin.svg';
import { Avatar } from '@components/avatar/avatar';
import { Button } from '@components/button/button';
import { ButtonWithSettings } from '@components/button/button-with-settings';
import { ActionsCell } from '@components/content-table/cells/actions-cell';
import { SecondaryCell } from '@components/content-table/cells/secondary-cell';
import { IdsFilter, allRows } from '@components/content-table/content-table';
import { TableCheckbox } from '@components/content-table/table-checkbox/table-checkbox';
import { TableControl } from '@components/content-table/table-control/table-control';
import { PageTitle } from '@components/page-title/page-title';
import { FilterCheckboxGroup } from '@components/table-filter/filter-checkbox-group/filter-checkbox-group';
import { Flex } from '@components/utility/flex';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';

import { Members } from './members';

import {
	ExportToasterBody,
	StyledButtonWrapper,
	StyledStandardList,
} from '@asd-stan/standard/components/standard-list/standard-list.styled';
import { StyledAccentButton } from '@components/button/button.styled';
import { MarkedTextCell } from '@components/content-table/cells/marked-text-cell.styled';
import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

Chart.register(ArcElement, Tooltip, Legend);

const ExportStandards = observer(
	({ selectedStandardsIds, title }: { selectedStandardsIds: IdsFilter; title: string }) => {
		const { t } = useTranslation();
		const standardService = getStandardService();
		const toasterService = getToasterService();
		const [progress, setProgress] = useState(0);
		const fileNameRef = useRef(standardService.getExportFileName(title));

		const fileName = fileNameRef.current;

		useEffect(() => {
			const abortController = new AbortController();
			const signal = abortController.signal;

			try {
				standardService.exportStandards(
					selectedStandardsIds,
					fileName,
					setProgress,
					() => toasterService.hideToast(),
					signal
				);
			} catch (err: any) {
				console.error(err);
			}

			return () => {
				abortController.abort();
			};
		}, []);

		return (
			<ExportToasterBody>
				<div style={{ height: 20, width: 20 }}>
					<CircularProgressbar
						value={progress}
						maxValue={100}
						styles={buildStyles({
							pathColor: '#0B93E3',
							trailColor: '#DAE4E8',
						})}
					/>
				</div>
				<p>
					<span className="file-name">{fileName}</span> {t('standard.standardList.isExporting')}
				</p>
				<span className="cancel" onClick={() => toasterService.hideToast()}>
					{t('standard.standardList.cancelExport')}
				</span>
			</ExportToasterBody>
		);
	}
);

const formFilterOptions = [
	{ label: 'prEN', name: 'forms.prEN' },
	{ label: 'TR', name: 'forms.TR' },
	{ label: 'EN', name: 'forms.EN' },
];

export const StandardFilteringForm = ({ stageList }: { stageList: StandardStage[] }) => (
	<>
		<FilterCheckboxGroup
			title="Stage"
			checkboxes={stageList.map(({ code, name }) => ({
				label: `${code} ${name}`,
				name: `stages.[${code}]`,
			}))}
		/>
		<FilterCheckboxGroup title="Form" checkboxes={formFilterOptions} />
	</>
);

export const StandardList: React.FC = observer(() => {
	const [filtering, setFiltering] = useState<string>('');
	const [selectedStandardsIds, setSelectedStandardsIds] = useState<IdsFilter>([]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const standardService = getStandardService();
	const draftService = getDraftService();
	const currentUserService = getCurrentUserService();
	const stagesService = getStagesService();
	const toasterService = getToasterService();
	const wgService = getWgService();
	const params = useParams();
	const location = useLocation();
	const { domain, workingGroup } = params;
	const domainService = getDomainService();
	const [title, setTitle] = useState<string>('');
	const { data: stages, isLoading: stagesLoading } = useRequest(
		useCallback(() => stagesService.getStages({ showHistoricalStages: true }), [])
	);

	const goToNewStandard = async () => {
		navigate('/standards/new-standard');
	};

	const getLastColumn = () => {
		const assignButtons = (
			<>
				<Button
					disabled={
						!userHasRolesAndParticipatesInDomain(
							[SystemRole.DIRECTOR, SystemRole.ES],
							currentUserService.userRoles!,
							[SystemRole.MC, SystemRole.BOARD_MEMBER],
							currentUserService.domainParticipations
						)
					}
					title={t('standard.standardList.buttons.assign')}
					onClick={() => {}}
				/>
			</>
		);

		const draftButtons = (id: number, originator: number) => {
			return (
				<div className="action-buttons-container">
					<StyledAccentButton>
						<button
							disabled={
								!userHasRolesAndParticipatesInDomain(
									[SystemRole.DIRECTOR, SystemRole.ES],
									currentUserService.userRoles!,
									[SystemRole.MC, SystemRole.BOARD_MEMBER],
									currentUserService.domainParticipations
								) && !isOriginatorInStandard(originator, currentUserService.currentUser)
							}
							className="continue-edit-button"
							onClick={e => {
								e.stopPropagation();
								navigate(`/standards/edit/${id}`);
							}}>
							{t('standard.standardList.buttons.continueEdit')} {<EditIcon />}
						</button>
					</StyledAccentButton>
					<StyledAccentButton $iconButton>
						<button disabled className="delete-button">
							{<TrashBitIcon />}
						</button>
					</StyledAccentButton>
				</div>
			);
		};

		switch (location.pathname) {
			case '/standards/draft':
				return {
					header: t('standard.standardList.action'),
					accessorKey: 'status',
					enableSorting: false,
					cell: (row: { standardId: number; originator: number }) => {
						return <ActionsCell buttons={[draftButtons(row.standardId, row.originator)]} />;
					},
				};
			case '/standards/assign':
				return {
					header: t('standard.standardList.action'),
					accessorKey: 'status',
					enableSorting: false,
					cell: <ActionsCell buttons={[assignButtons]} />,
				};
			default:
				return {
					header: t('standard.standardList.status'),
					accessorKey: 'status',
					cell: ({ row }: CellContext<Standard, string>) => {
						const value = mapStandardStatusDTOToStandardStatus(row.getValue('status'));
						if (value) {
							return (
								<MarkedTextCell>
									<div>{value}</div>
								</MarkedTextCell>
							);
						}
					},
				};
		}
	};

	const columns = [
		{
			id: 'select',
			header: () => {
				return (
					<TableCheckbox
						{...{
							checked: selectedStandardsIds === allRows,
							indeterminate: selectedStandardsIds.length > 0,
							onChange: () => {
								if (selectedStandardsIds === allRows) {
									setSelectedStandardsIds([]);
									return;
								}
								if (selectedStandardsIds.length === 0) {
									setSelectedStandardsIds(allRows);
									return;
								}
								setSelectedStandardsIds([]);
							},
						}}
					/>
				);
			},
			cell: ({ row }: CellContext<Standard, string>) => {
				const id: number = row.original.id;
				const checked = selectedStandardsIds === allRows || selectedStandardsIds.includes(id);
				return (
					<TableCheckbox
						{...{
							checked,
							onChange: () => {
								setSelectedStandardsIds(rows => {
									if (checked) {
										if (rows === allRows) {
											return [id];
										}
										return rows.filter(r => r !== id);
									}
									if (rows === allRows) {
										return rows;
									}
									return [...rows, id];
								});
							},
						}}
					/>
				);
			},
		},
		{
			header: t('standard.standardList.form'),
			accessorKey: 'form',
			className: 'form',
		},
		{
			header: t('standard.standardList.stanNumber'),
			accessorKey: 'registrationNumber',
			cell: ({ row }: CellContext<Standard, string>) => (
				<SecondaryCell value={row.getValue('registrationNumber')} />
			),
		},
		{
			header: t('standard.standardList.standardTitle'),
			accessorKey: 'localizedTitle',
			className: 'title',
		},
		{
			header: t('standard.standardList.edition'),
			accessorKey: 'edition',
		},
		{
			header: t('standard.standardList.cenWiNumber'),
			accessorKey: 'cenWiNumber',
			cell: ({ row }: CellContext<Standard, string>) => (
				<SecondaryCell value={row.getValue('cenWiNumber')} />
			),
		},
		getLastColumn(),
	];

	useEffect(() => {
		standardService.standards.resetFilter();
		draftService.getDraftsCount();
		stagesService.getStageList();
	}, []);

	useEffect(() => {
		standardService.standards.resetFilter();
		setSelectedStandardsIds([]);

		switch (location.pathname) {
			case '/standards/assign':
				return standardService.standards.addFilter({
					domain: null,
					workingGroup: null,
					isAssigned: false,
				});
			case '/standards/draft':
				return standardService.standards.addFilter({
					domain: null,
					workingGroup: null,
					isDraft: true,
				});
			default:
				standardService.standards.addFilter({
					domain: params.domain,
					workingGroup: params.workingGroup ? `${params.domain}/${params.workingGroup}` : null,
					isDraft: false,
					isAssigned: true,
				});
		}

		if (params.domain && !params.workingGroup) {
			const foundDomain = domainService.domains.find(
				(element: Domain) => element.code === params.domain
			);
			setTitle(foundDomain ? `${foundDomain?.code} ${foundDomain?.name}` : '');
		}

		if (params.domain && params.workingGroup) {
			const foundDomain = domainService.domains.find(
				(element: Domain) => element.code === params.domain
			);
			const foundWorkingGroup = foundDomain?.workingGroups.find(
				(element: WorkingGroup) => element.code === `${params.domain}/${params.workingGroup}`
			);
			setTitle(foundWorkingGroup ? `${foundWorkingGroup?.code} ${foundWorkingGroup?.name}` : '');
		}

		standardService.standards.loadCurrentPage();
	}, [domain, workingGroup, location.pathname]);

	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		standardService.standards.setPageSize(parseInt(e.target.value));
	};

	const generateTitle = () => {
		return params.domain === 'draft'
			? t('standard.standardList.titleDrafts')
			: t('standard.standardList.titleStandards');
	};

	const availableStatuses = [
		{
			status: StandardStatus.EN.toString(),
			backgroundColor: 'rgba(94, 217, 77, 1)',
		},
		{
			status: StandardStatus.PB.toString(),
			backgroundColor: 'rgba(29, 198, 6, 1)',
		},
		{
			status: 'CS – Coversheet',
			backgroundColor: 'rgba(44, 128, 218, 1)',
		},
		{
			status: StandardStatus.ENWIP.toString(),
			backgroundColor: 'rgba(11, 147, 227, 1)',
		},
		{
			status: StandardStatus.SD.toString(),
			backgroundColor: 'rgba(94, 79, 162, 1)',
		},
		{
			status: StandardStatus.WIP.toString(),
			backgroundColor: 'rgba(229, 121, 0, 1)',
		},
		{
			status: 'OH – On hold',
			backgroundColor: 'rgba(218, 166, 0, 1)',
		},
		{
			status: StandardStatus.AN.toString(),
			backgroundColor: 'rgba(91, 91, 91, 1)',
		},
		{
			status: StandardStatus.RT.toString(),
			backgroundColor: 'rgba(162, 79, 159, 1)',
		},
		{
			status: StandardStatus.DC.toString(),
			backgroundColor: 'rgba(11, 178, 126, 1)',
		},
	];

	const chartData = [5, 15, 5, 5, 30, 15, 15, 2, 3, 5];
	const chartLabel = {
		id: 'doughnutLabel',
		afterDraw(chart: Chart) {
			const {
				ctx,
				chartArea: { height, width, top },
			} = chart;
			ctx.save();
			if (chart.getActiveElements().length === 0) {
				return;
			}

			const element = chart.getActiveElements()[0];

			ctx.font = 'bold 30px Instrument Sans';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillStyle = 'rgba(11, 147, 227, 1)';
			ctx.fillText(`${chartData[element.index]}%`, width / 2, height / 2 + top);
			ctx.restore();
		},
	};

	const getOrCreateLegendList = (chart: any, id: string) => {
		const legendContainer = document.getElementById(id);
		let listContainer = legendContainer?.querySelector('ul');

		if (!listContainer) {
			listContainer = document.createElement('ul');
			legendContainer?.appendChild(listContainer);
		}

		return listContainer;
	};

	const htmlLegendPlugin = {
		id: 'htmlLegend',
		afterUpdate(chart: any, args: any, options: any) {
			const ul = getOrCreateLegendList(chart, options.containerID);
			while (ul.firstChild) {
				ul.firstChild.remove();
			}

			const items = chart.options.plugins.legend.labels.generateLabels(chart);

			items.forEach((item: any) => {
				const li = document.createElement('li');

				li.onclick = () => {
					chart.toggleDataVisibility(item.index);
					chart.update();
				};

				const boxSpan = document.createElement('span');
				boxSpan.style.background = item.fillStyle;

				const textContainer = document.createElement('p');
				textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

				const text = document.createTextNode(item.text);
				textContainer.appendChild(text);

				li.appendChild(boxSpan);
				li.appendChild(textContainer);
				ul.appendChild(li);
			});
		},
	};

	const chart = {
		data: {
			labels: availableStatuses.map(item => item.status),
			datasets: [
				{
					data: chartData,
					backgroundColor: availableStatuses.map(item => item.backgroundColor),
					borderWidth: 0,
				},
			],
		},
		options: {
			cutout: '60%',
			responsive: true,
			maintainAspectRatio: false,
			radius: '80%',
			plugins: {
				tooltip: {
					enabled: false,
				},
				htmlLegend: {
					containerID: 'legend-container',
				},
				legend: {
					display: false,
				},
				title: {
					display: false,
				},
			},
		},
		plugins: [chartLabel, htmlLegendPlugin],
	};

	const filterEmptyValues: StandardCollectionFilter = useMemo(
		() => ({
			forms: {
				[Form.EN]: false,
				[Form.prEN]: false,
				[Form.STAN]: false,
				[Form.TR]: false,
			},
			stages: stagesService.stageList.reduce((acc, stage) => {
				return { ...acc, [stage.code]: false };
			}, {}),
		}),
		[stagesService.stageList.length]
	);

	const membersFetchFn = useCallback(
		(limit: number, offset: number) => {
			const domain = params.domain!;
			const workingGroup = params.workingGroup;

			if (workingGroup) {
				return wgService.getWGMembersByCode(`${domain}/${workingGroup}`, limit, offset);
			}

			return domainService.getDomainMembersByCode(domain, limit, offset);
		},
		[params.domain, params.workingGroup]
	);

	return (
		<StyledStandardList>
			<ContentContainer>
				{params.domain !== undefined && !['assign', 'draft'].includes(params.domain as string) ? (
					<>
						<PageTitle title={title} />
						<Flex
							$justify="space-between"
							className="program-management-container"
							style={{ width: '60%' }}>
							<div className="domain-member-container">
								<Flex $align="center" $justify="space-between">
									<div className="title">
										{t(
											`standard.standardList.${params.workingGroup ? 'wgMembers' : 'domainMembers'}`
										)}
									</div>
								</Flex>
								<Members
									key={`${params.domain} ${params.workingGroup}`}
									fetchFunction={membersFetchFn}
									isWg={!!params.workingGroup}
								/>
							</div>
							{/* <div className="progress-statistic-container">
								<Flex $align="center" $justify="space-between">
									<div className="title">Progress statistics</div>
									<StyledButtonWrapper>
										<Button disabled type="button" title="See details" onClick={() => {}} />
									</StyledButtonWrapper>
								</Flex>
								<div className="chart-container">
									<div className="doughnut-container">
										<Doughnut data={chart.data} options={chart.options} plugins={chart.plugins} />
									</div>
									<div id="legend-container"></div>
								</div>
							</div> */}
						</Flex>
					</>
				) : null}
				<Flex $justify="space-between" $align="center">
					<PageTitle
						title={generateTitle()}
						count={
							standardService.standards.status === PaginationCollectionControllerStatus.LOADING
								? undefined
								: standardService.standards.totalNumber
						}
						countName={t('standard.standardList.count')}
					/>
					<StyledButtonGroup>
						{/* <ButtonWithSettings */}
						<Button
							secondary
							icon={<ExportIcon />}
							disabled={!standardService.standards.data.length || toasterService.isExporting}
							title={t('standard.standardList.buttons.export')}
							onClick={async () => {
								toasterService.showToast(
									ToasterStatus.export,
									<ExportStandards
										selectedStandardsIds={selectedStandardsIds}
										title={title || 'All standards'}
									/>
								);
							}}
						/>
						{userHasRolesAndParticipatesInDomain(
							[SystemRole.EXPERT, SystemRole.DIRECTOR, SystemRole.ES],
							currentUserService.userRoles!,
							[SystemRole.MC, SystemRole.BOARD_MEMBER],
							currentUserService.domainParticipations
						) ? (
							<Button
								icon={<Plus fill="#fff" />}
								title={t('standard.standardList.buttons.addNew')}
								onClick={goToNewStandard}
							/>
						) : null}
					</StyledButtonGroup>
				</Flex>
				<TableControl
					paginator={standardService.standards}
					pageSize={standardService.standards.pageSize}
					onPageSizeChange={onPageSizeChange}
					minSearchLength={2}
					filtersDisabled={stagesLoading}
					filterValues={standardService.standards.filter}
					filterEmptyValues={filterEmptyValues}
					onFilterSubmit={standardService.standards.addFilter}
					filterForm={<StandardFilteringForm stageList={stages ?? []} />}
					onFilterOrSearchChange={() => setSelectedStandardsIds([])}
				/>
				<div
					className={
						params.domain !== undefined && !['assign', 'draft'].includes(params.domain as string)
							? 'table-container'
							: ''
					}>
					<StandardListTable
						tableData={[...standardService.standards.data]}
						columns={columns}
						filtering={filtering}
						setFiltering={setFiltering}
						paginator={standardService.standards}
						emptySearchText={t('standard.standardList.emptySearchText')}
					/>
				</div>
			</ContentContainer>
		</StyledStandardList>
	);
});
