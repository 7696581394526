interface Entity {
	id: number;
}

export const getOne = <T extends { id: number }>(dataSet: Array<T>, id: number): T => {
	// @ts-expect-error
	return dataSet.find(data => data.id === id);
};

export const getAll = <T extends { id: number }>(
	dataSet: Array<T>,
	ids: Array<number>
): Array<T> => {
	const arrayToReturn: Array<T> = [];

	dataSet.forEach(data => {
		if (ids.includes(data.id)) {
			arrayToReturn.push(data);
		}
	});

	return arrayToReturn;
};

export const updateAll = <T extends { id: number }>(
	dataSet: Array<T>,
	ids: Array<number>,
	dataPart: Partial<T>
): Array<T> => {
	for (let i = 0; i < dataSet.length; i++) {
		if (ids.includes(dataSet[i].id)) {
			dataSet[i] = Object.assign<T, Partial<T>>(dataSet[i], dataPart);
		}
	}

	return getAll(dataSet, ids);
};

export const delay = async (ms: number): Promise<void> => {
	return new Promise<void>(resolve => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
};
