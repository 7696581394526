import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

export const StyledTabListContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const StyledNavLink = styled(NavLink)`
	display: block;
	color: ${props => props.theme.fontColors.lightGrey};
	padding: 16px 24px;
	font-weight: 600;
	font-size: 14px;
	position: relative;

	&.active {
		color: ${props => props.theme.fontColors.darkGrey};
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 2px;
			border-bottom: ${props => props.theme.borders.tabBorder};
			animation: slideIn 1s forwards;
		}
	}

	@keyframes slideIn {
		to {
			width: 100%;
		}
	}
`;
