import React, { ReactNode, useState } from 'react';

import { ReactComponent as Dropdown } from '@asd-stan/ui-kit/assets/icons/dropdown.svg';
import { StyledAnimatedIconWrapper } from '@asd-stan/ui-kit/components/utility/animated-icon-wrapper';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';

import { StyledInputGroup } from './input-group.styled';

interface InputGroupProps {
	children: ReactNode;
	subtitle?: string;
	title?: string;
	titleComponent?: ReactNode;
	nonCollapsable?: boolean
}

export const InputGroup: React.FC<InputGroupProps> = ({ title = "", titleComponent, children, subtitle, nonCollapsable }) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const handleClick = () => {
		if (nonCollapsable) {
			return;
		}

		setIsOpen(!isOpen);
	};

	return (
		<StyledInputGroup>
			<Flex $cursor={nonCollapsable ? "default" : "pointer"} $justify="space-between" $align="center" onClick={handleClick}>
				{titleComponent ? titleComponent : <h4>{title}</h4>}
				<StyledAnimatedIconWrapper open={isOpen}>
					{!nonCollapsable ? <Dropdown fill="#525259" /> : null}
				</StyledAnimatedIconWrapper>
			</Flex>
			{isOpen && (
				<>
					{subtitle && <p>{subtitle}</p>}
					<Flex $wrap $justify="space-between">
						{children}
					</Flex>
				</>
			)}
		</StyledInputGroup>
	);
};
