import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@asd-stan/helpers/customHooks';
import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { ReactComponent as Search } from '@assets/icons/search.svg';
import { TableFilter } from '@components/content-table/table-control/table-filter/table-filter';
import { Input } from '@components/input/input';
import { Flex } from '@components/utility/flex';

import { StyledTableControl, TableSelect } from './table-control.styled';

interface TableControlProps<T extends {}> {
	pageSize: number;
	onPageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	paginator: PaginationCollectionController<any, any>;
	minSearchLength?: number;
	filterForm?: React.ReactNode;
	filterValues?: T;
	filterEmptyValues?: T;
	onFilterSubmit?(values: T): void;
	filtersDisabled?: boolean;
	hideFilter?: boolean;
	hideSearch?: boolean;
	onFilterOrSearchChange?(): void;
}

const pageSizeOptions = [10, 20, 50, 100];

export const TableControl = observer(
	<T extends {}>({
		pageSize,
		onPageSizeChange,
		paginator,
		minSearchLength = 3,
		filterForm,
		filterValues,
		filterEmptyValues,
		onFilterSubmit: handleFilterSubmit,
		filtersDisabled,
		hideFilter,
		hideSearch,
		onFilterOrSearchChange: handleFilterOrSearchChange,
	}: TableControlProps<T>) => {
		const [search, setSearch] = useState<string>('');
		const { t } = useTranslation();

		const handleSearch = useDebounce(
			() => paginator?.addFilter(search.length > 0 ? { search } : { search: null }),
			500
		);

		useEffect(() => {
			if (search.length === 0 || search.length >= minSearchLength) {
				handleSearch();
				handleFilterOrSearchChange?.();
			}
		}, [search]);

		return (
			<StyledTableControl>
				{hideFilter ? (
					<div />
				) : (
					<TableFilter
						children={filterForm}
						values={filterValues}
						emptyValues={filterEmptyValues}
						onSubmit={filter => {
							handleFilterSubmit?.(filter);
							handleFilterOrSearchChange?.();
						}}
						disabled={filtersDisabled}
					/>
				)}
				<Flex>
					{!hideSearch && (
						<Input
							className="search"
							value={search}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
							icon={<Search />}
							placeholder={t('user.userList.searchPlaceholder')}
						/>
					)}
					<TableSelect>
						<span>{t('user.userList.searchFilter')}</span>
						<div>
							<select value={pageSize} onChange={onPageSizeChange}>
								{pageSizeOptions.map(pageSizeElement => {
									return (
										<option key={pageSizeElement} value={pageSizeElement}>
											{pageSizeElement}
										</option>
									);
								})}
							</select>
						</div>
					</TableSelect>
				</Flex>
			</StyledTableControl>
		);
	}
);
