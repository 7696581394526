import { observer } from 'mobx-react';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const RouteContainer: React.FC = observer(() => {

	return (
		<>
			<Outlet />
		</>
	);
});
