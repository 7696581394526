import { Page } from '@asd-stan/helpers/pagination-collection-controller';

import { MeetingListFilter, MeetingListItem, MeetingType } from '../domain/meeting.entity';

interface MeetingList {
	meetingList: {
		id: number;
		title: string;
		type: MeetingType;
		domains: {
			code: string;
			name: string;
		}[];
		workingGroups: {
			code: string;
			name: string;
		}[];
		startTime: string;
		location: string;
	}[];
	meetingCount: number;
}

export const mapMeetingList = ({
	meetingCount,
	meetingList,
}: MeetingList): Page<MeetingListItem> => ({
	data: meetingList.map(({ id, domains, workingGroups, type, title, startTime, location }) => ({
		id,
		domains,
		workingGroups,
		type,
		title,
		startTime: new Date(startTime.split('+')[0]),
		location,
	})),
	totalNumber: meetingCount,
});

interface MeetingListFilterDto {
	search?: null | string;
	orderField?: null | string;
	orderDirection?: 'DESC' | 'ASC';
	domains?: null | string[];
	workingGroups?: null | string[];
}

export const mapMeetingListFilter = ({
	search,
	domains,
	workingGroups,
	...filter
}: MeetingListFilter): MeetingListFilterDto => ({
	// search,
	...filter,
	domains:
		domains &&
		Object.entries(domains)
			.filter(([_, value]) => !!value)
			.map(([key]) => key),
	workingGroups:
		workingGroups &&
		Object.entries(workingGroups)
			.filter(([_, value]) => !!value)
			.map(([key]) => key),
});
