import { observer } from 'mobx-react';
import { useState } from 'react';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { RemarkContainer } from '@asd-stan/standard/components/remark-textarea/remark-container';
import { RemarkTextarea } from '@asd-stan/standard/components/remark-textarea/remark-textarea';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { FormikValues, useFormikContext } from 'formik';

export const Remarks: React.FC = observer(() => {
	const standardService = getStandardService();
	const currentUserService = getCurrentUserService();

	const [isNeedClearValue, setIsNeedClearValue] = useState<boolean>(false);

	const { values, errors, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();

	const handleSendEvent = async () => {
		if (errors.remark || !values.remark) {
			return;
		}

		setIsNeedClearValue(true);

		const currentDate = new Date();

		const day = String(currentDate.getDate()).padStart(2, '0');
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const year = String(currentDate.getFullYear());

		const formattedDate = `${day}/${month}/${year}`;

		await standardService.addRemark({
			name: `${currentUserService.currentUser!.firstName} ${
				currentUserService.currentUser!.lastName
			}`,
			text: values.remark,
			date: formattedDate,
			stageUpdate: null,
		});
		setIsNeedClearValue(false);
		await setFieldValue('remark', '');
		handleSubmit();
	};

	return (
		<>
			{standardService.remarks.length === 0 &&
				(standardService.singleStandard === null ||
					(standardService.singleStandard?.remarks &&
						standardService.singleStandard?.remarks.length === 0)) && (
					<RemarkContainer remark={null} removeEvent={null} />
				)}
			{standardService.remarks.map((remark, index) => (
				<RemarkContainer
					key={index}
					remark={remark}
					removeEvent={async () => {
						await standardService.removeRemark(index);
						handleSubmit();
					}}
				/>
			))}
			<RemarkTextarea isNeedClearValue={isNeedClearValue} sendEvent={handleSendEvent} />
		</>
	);
});
