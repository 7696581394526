import { makeAutoObservable } from 'mobx';

interface AuthArgs {
	id: number;
	email: string;
	phone: string;
}

export class Auth {
	private _id: number;
	private _email: string;
	private _phone: string;

	constructor(args: AuthArgs) {
		makeAutoObservable(this);

		this._id = args.id;
		this._email = args.email;
		this._phone = args.phone;
	}

	//getters

	get id() {
		return this._id;
	}
	get email() {
		return this._email;
	}

	get phone() {
		return this._phone;
	}

	//getters end
	//methods

	//methods end
}
