import styled from 'styled-components';

export const StyledFormCheckbox = styled.div`
	& {
		color: ${({ theme }) => theme.fontColors.darkGrey};
		display: flex;
		align-items: center;

		& > span {
			cursor: pointer;
			margin-left: 8px;
		}

		& > input[type='checkbox'] {
			cursor: pointer;
			accent-color: ${({ theme }) => theme.bgColors.accent};
		}
	}
`;
