import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { Button } from '@components/button/button';
import { FormError } from '@components/form-error/form-error';
import { FormInputPassword } from '@components/form-input-password/form-input-password';
import { Loading } from '@components/loading/loading';
import { Field, Form, Formik, FormikValues } from 'formik';

import validationSchema from '../../validation-schemas/create-password.schema';
import { AuthLayout } from '../layout/auth-layout/auth-layout';

export const CreatePassword: React.FC = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const authService = getAuthService();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	const [loading, setLoading] = useState(true);
	const [loadingRequest, setLoadingRequest] = useState(false);

	const initialValues = {
		password: '',
		confirmPassword: '',
	};

	useEffect(() => {
		const onLoadPage = async () => {
			const result = await authService.inviteTokenValid(token);
			if (!result) {
				return navigate('/expired');
			}
			return setLoading(false);
		};

		onLoadPage();
	}, [authService, navigate, token]);

	const onSubmit = async (values: FormikValues) => {
		if (!token) {
			return navigate('/expired');
		}
		try {
			setLoadingRequest(true);
			await authService.createPassword(token, values.password);
			navigate('/dashboard');
			setLoadingRequest(false);
		} catch (error) {
			return navigate('/expired');
		}
	};

	if (loading) {
		return (
			<AuthLayout>
				<h1>{t('auth.createPassword.title')}</h1>
				<div>
					<Loading />
				</div>
			</AuthLayout>
		);
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ errors, handleSubmit }) => (
				<AuthLayout>
					<h1>{t('auth.createPassword.title')}</h1>
					{(errors.password || errors.confirmPassword) && (
						<FormError
							title={t(
								`auth.createPassword.errors.${
									errors.password ? errors.password : errors.confirmPassword
								}`
							)}
						/>
					)}
					<Form>
						<Field
							component={FormInputPassword}
							name="password"
							title={t('auth.createPassword.passwordTitle')}
							placeholder={t('auth.createPassword.passwordDescription')}
							handleSubmit={handleSubmit}
						/>
						<Field
							component={FormInputPassword}
							name="confirmPassword"
							title={t('auth.createPassword.confirmPasswordTitle')}
							placeholder={t('auth.createPassword.confirmPasswordDescription')}
							handleSubmit={handleSubmit}
						/>
					</Form>
					<ul>
						{t('auth.createPassword.passwordRulesTitle')}
						<li>{t('auth.createPassword.passwordRuleCharacters')}</li>
						<li>{t('auth.createPassword.passwordRuleLetters')}</li>
						<li>{t('auth.createPassword.passwordRuleNumber')}</li>
						<li>{t('auth.createPassword.passwordRuleSpecial')}</li>
					</ul>
					<Button
						title={t('auth.createPassword.createButton')}
						onClick={handleSubmit}
						disabled={loadingRequest}
						fullWidth
					/>
				</AuthLayout>
			)}
		</Formik>
	);
});
