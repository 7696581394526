export const USER_REPO = 'USER_REPO';
export const USER_SERVICE = 'USER_SERVICE';
export const USER_PUBLIC_SERVICE = 'USER_PUBLIC_SERVICE';

export const COMPANY_REPO = 'COMPANY_REPO';
export const COMPANY_SERVICE = 'COMPANY_SERVICE';

export const COUNTRY_REPO = 'COUNTRY_REPO';
export const COUNTRY_SERVICE = 'COUNTRY_SERVICE';

export const APPOINTER_REPO = 'APPOINTER_REPO';
export const APPOINTER_SERVICE = 'APPOINTER_SERVICE';

export const POSITION_REPO = 'POSITION_REPO';
export const POSITION_SERVICE = 'POSITION_SERVICE';

export const WORKING_GROUP_REPO = 'WORKING_GROUP_REPO';
export const WORKING_GROUP_SERVICE = 'WORKING_GROUP_SERVICE';
