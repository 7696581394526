import { SystemRoleDTO } from '@asd-stan/user/api/system-role.dto';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';

export const mapSystemRoleDTOToSystemRole = (dto: SystemRoleDTO[]): SystemRole[] => {
	const roleMap: Record<SystemRoleDTO, SystemRole> = {
		[SystemRoleDTO.ADMIN]: SystemRole.ADMIN,
		[SystemRoleDTO.TAC]: SystemRole.TAC,
		[SystemRoleDTO.ES]: SystemRole.ES,
		[SystemRoleDTO.MC]: SystemRole.MC,
		[SystemRoleDTO.EXPERT]: SystemRole.EXPERT,
		[SystemRoleDTO.BOARD_MEMBER]: SystemRole.BOARD_MEMBER,
		[SystemRoleDTO.DIRECTOR]: SystemRole.DIRECTOR,
	};

	const systemRoles = dto.map(item => roleMap[item]);
	return systemRoles;
};
