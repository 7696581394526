import { FC } from 'react';

import { CheckboxContainer, CheckboxInput, Checkmark } from '@components/checkbox/checkbox.styled';

interface Props {
	checked: boolean;
	onChange: (checked: boolean) => void;
	label?: string | JSX.Element;
}

export const Checkbox: FC<Props> = ({ checked, onChange, label }) => (
	<CheckboxContainer onClick={e => e.stopPropagation()}>
		<Checkmark checked={checked} />
		{label}
		<CheckboxInput type="checkbox" checked={checked} onChange={ev => onChange(ev.target.checked)} />
	</CheckboxContainer>
);
