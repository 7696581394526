import { FC } from 'react';
import { useTranslation } from "react-i18next";
import {
  AttachmentDeleteContainer,
  ButtonsContainer,
  AttachmentDeleteHeader,
  StageSelectorTitle,
} from './attachment-delete.styled';
import { Button } from "@components/button/button";

interface Props {
    onCancel: () => void;
    onDelete: () => Promise<void>
}

export const AttachmentDelete: FC<Props> = ({ onCancel, onDelete }) => {
    const { t } = useTranslation();

    return (
        <AttachmentDeleteContainer>
            <AttachmentDeleteHeader>{t('standard.singleStandard.attachments.deleteModal.title')}</AttachmentDeleteHeader>
            <StageSelectorTitle>{t('standard.singleStandard.attachments.deleteModal.subTitle')}</StageSelectorTitle>
            <ButtonsContainer>
                <Button fullWidth secondary title={t('standard.singleStandard.attachments.deleteModal.cancel')} onClick={onCancel} />
                <Button fullWidth title={t('standard.singleStandard.attachments.deleteModal.confirm')} onClick={onDelete} />
            </ButtonsContainer>
        </AttachmentDeleteContainer>
    )
}
