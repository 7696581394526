import styled from 'styled-components';

export const MarkedTextCell = styled.div`
	display: flex;
	align-items: flex-end;
	overflow: visible;

	& > div {
		width: fit-content;
		border-radius: 8px;
		margin-right: 8px;
		color: ${({ theme }) => theme.fontColors.accent};
		padding: 4px 6px;
		background-color: #0b93e314;
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	& > span {
		margin-bottom: 5px;
		color: ${({ theme }) => theme.fontColors.accent};
	}
`;
