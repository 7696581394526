import { injector } from '@asd-stan/injector/injector';

import { DraftRepo } from '../api/draft.repo';
import { DraftService } from '../domain/draft.service';

import { DRAFT_REPO, DRAFT_SERVICE } from './constants';

export const getDraftRepo = () => {
	return injector.get<DraftRepo>(DRAFT_REPO);
};

export const getDraftService = () => {
	return injector.get<DraftService>(DRAFT_SERVICE);
};
