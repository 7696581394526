import { scrollStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const StandardChangeItemWrapper = styled.div`
	border-radius: 12px;
	overflow: hidden;
	border: ${({ theme }) => theme.borders.divider};
	background: white;
	flex-shrink: 0;
`;

export const StandardChangeHeader = styled.div`
	background-color: ${({ theme }) => theme.bgColors.tableHeader};
	color: ${({ theme }) => theme.fontColors.lightGrey};
	border-bottom: ${({ theme }) => theme.borders.divider};
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding: 12px 16px;
`;

export const StandardChangeHeaderHighlights = styled.div`
	color: black;
	font-weight: 600;
	text-transform: capitalize;
`;

export const StandardChangeItemBody = styled.div`
	padding: 20px 16px;
`;

export const StandardChangeComparison = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

export const StandardChangeItemOld = styled.div`
	background: rgba(218, 228, 232, 0.32);
	border-radius: 8px;
	padding: 12px;
	flex: 1;
	position: relative;
	display: flex;
	gap: 8px;
	justify-content: space-between;
	align-items: center;
`;

export const StandardChangeItemNew = styled.div`
	background: rgba(218, 228, 232, 0.32);
	border-radius: 8px;
	padding: 12px;
	flex: 1;
	position: relative;
	display: flex;
	gap: 8px;
	justify-content: space-between;
	align-items: center;
`;

export const OldBadge = styled.div`
	background: rgba(227, 167, 11, 0.16);
	border-radius: 8px;
	text-align: center;
	color: #e3a70b;
	font-weight: 600;
	font-size: 10px;
	height: 23px;
	width: 25px;
`;

export const NewBadge = styled.div`
	background: rgba(9, 185, 16, 0.16);
	border-radius: 8px;
	text-align: center;
	color: #09b910;
	font-weight: 600;
	font-size: 10px;
	height: 23px;
	width: 29px;
`;

export const StandardChangeListItemDisplay = styled.div`
	background: #e8e9f4;
	border-radius: 6px;
	padding: 6px 8px;
	> a {
		line-height: 2;
		color: #0b93e3;
		text-decoration: underline;
		white-space: normal;
	}
	&.change-added {
		color: #09b910;
		> a {
			color: #09b910;
		}
	}
	&.change-removed {
		color: #ea3e53;
		> a {
			color: #ea3e53;
		}
	}
`;

export const StandardChangeValues = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	max-height: 120px;
	overflow-y: auto;
	${scrollStyles};
`;
