import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploadController, UploadedFile } from '@asd-stan/file/domain/file-upload-controller';
import {
	meetingFileTypes,
	meetingMaxFileSizeBytes,
} from '@asd-stan/meeting/components/meeting-form/forms/files';
import { meetingValidationSchema } from '@asd-stan/meeting/components/meeting-form/meeting-validation-schema';
import {
	MeetingForm,
	SingleMeeting,
	ValidatedMeetingForm,
} from '@asd-stan/meeting/domain/meeting.entity';
import { getMeetingService } from '@asd-stan/meeting/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { Button } from '@components/button/button';
import { DroppedFile, checkIfFileBeingUploaded } from '@components/file-dropzone/dropped-files';
import { FileDropzone } from '@components/file-dropzone/file-dropzone';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { StyledModal, StyledUploadedFile } from '../../single-meeting.styled';

const DeleteFileModal = ({
	onClose: handleClose,
	deleteFile,
}: {
	onClose(): void;
	deleteFile(): Promise<void>;
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		await deleteFile();
		handleClose();
	};

	return (
		<StyledModal>
			<div className="header">{t('meeting.singleMeeting.deleteFileModal.title')}</div>
			<div className="title">{t('meeting.singleMeeting.deleteFileModal.message')}</div>
			<div className="buttons">
				<Button
					fullWidth
					secondary
					title={t('meeting.singleMeeting.deleteFileModal.cancel')}
					onClick={handleClose}
					disabled={loading}
				/>
				<Button
					fullWidth
					title={t('meeting.singleMeeting.deleteFileModal.confirm')}
					onClick={handleDelete}
					disabled={loading}
				/>
			</div>
		</StyledModal>
	);
};

const fileTypesToPreview = [
	'image/jpg',
	'image/jpeg',
	'image/svg',
	'image/png',
	'image/bmp',
	'image/vnd.dwg',
	'image/tiff',
];

const useFilePreviews = (fileIds: number[]) => {
	const meetingService = getMeetingService();
	const [files, setFiles] = useState<null | (string | null)[]>(null);

	useEffect(() => {
		(async () => {
			const files = await Promise.all(fileIds.map(id => meetingService.getFile(id)));
			setFiles(
				files.map(file =>
					fileTypesToPreview.includes(file.type) ? URL.createObjectURL(file) : null
				)
			);
		})();

		return () => {
			files?.forEach(file => file && URL.revokeObjectURL(file));
		};
	}, [fileIds.join(', ')]);

	return files;
};

const dateFormat = 'D MMM YYYY';

export const Files = ({
	id,
	meeting,
	onFilesUpadate: hanleFilesUpdate,
}: {
	id: number;
	meeting: SingleMeeting;
	onFilesUpadate(): void;
}) => {
	const { t } = useTranslation();
	const meetingService = getMeetingService();
	const toasterService = getToasterService();
	const modalService = getModalService();
	const fileUploadContrallerRef = useRef<null | FileUploadController>(null);

	if (fileUploadContrallerRef.current === null) {
		fileUploadContrallerRef.current = new FileUploadController();
	}

	const previews = useFilePreviews(meeting.form.files.map(({ file }) => file.id));
	console.log(previews);

	const filesMeta = meeting.filesMeta;

	const handleSubmit = async (values: MeetingForm) => {
		try {
			await meetingService.updateMeeting(id, values as ValidatedMeetingForm);
			hanleFilesUpdate();
		} catch (err: any) {
			console.error(err);
		}
	};

	const ableToUpdate = meetingService.checkIfCurrentUserIsAbleToUpdateMeeting();

	return (
		<InputGroup nonCollapsable title={t('standard.singleStandard.attachments.title')}>
			<Formik
				onSubmit={handleSubmit}
				initialValues={meeting.form as MeetingForm}
				validationSchema={meetingValidationSchema}>
				{({ setFieldValue, values, validateForm, submitForm, isSubmitting, errors }) => (
					<Form style={{ width: '100%' }}>
						{ableToUpdate && (
							<Field
								name="files"
								component={FileDropzone}
								fileTypes={meetingFileTypes}
								maxSize={meetingMaxFileSizeBytes}
								controller={fileUploadContrallerRef.current}
								maxFiles={0}
								isFilePrivate
								onFilesChange={(files: UploadedFile[]) => {
									return setFieldValue(
										'files',
										files.map(file => ({
											file,
											description: values.files.find(f => f.file === file)?.description || '',
										}))
									);
								}}
								initialValue={meeting.form.files.map(({ file }) => file)}
								customFilesView={({
									disabled,
									handleFileDelete,
								}: {
									files: UploadedFile[];
									disabled?: boolean;
									handleFileDelete(fileToDelete: UploadedFile): void;
								}) => {
									const newlyUploadedFiles = values.files
										.map((file, index) => ({ file, index }))
										.filter(({ file }) => !file.file.id);
									return (
										<>
											{newlyUploadedFiles.map(({ file: { file }, index }) => (
												<DroppedFile
													key={file.title + index}
													file={file}
													onDelete={() => handleFileDelete(file)}
													disabled={disabled}>
													<Field
														component={FormInputParagraph}
														name={`files[${index}].description`}
														title={t('meeting.createMeeting.fileDescription')}
														showError
														fullWidth
														maxLength={500}
													/>
												</DroppedFile>
											))}
											{newlyUploadedFiles.length > 0 && (
												<Flex $justify="flex-end">
													<Button
														disabled={
															isSubmitting ||
															values.files.some(({ file }) => checkIfFileBeingUploaded(file))
														}
														title="Confirm"
														onClick={async () => {
															validateForm().then(errors => {
																if (errors && !(Object.keys(errors).length === 0)) {
																	toasterService.showToast(
																		ToasterStatus.error,
																		t('meeting.createMeeting.errors.validationErrorToast')
																	);
																}
															});
															submitForm();
														}}
													/>
												</Flex>
											)}
										</>
									);
								}}
							/>
						)}
						{previews === null ? (
							<Flex $justify="center" style={{ marginTop: 24 }}>
								<Loading height={36} width={36} />
							</Flex>
						) : (
							values.files
								.filter(({ file }) => !!file.id)
								.map(({ file: { id, title }, description }, index) => {
									const meta = filesMeta.find(m => m.id === id)!;
									const previewUrl = previews[index];
									return (
										<StyledUploadedFile key={id}>
											<div className="file">
												{previewUrl ? (
													<img
														alt="preview"
														src={previewUrl}
														width={48}
														height={48}
														className="file-img"
													/>
												) : (
													<FileIcon className="file-img" />
												)}
												<div className="file-right">
													<div className="file-title">{title}</div>
													<div className="file-meta">
														{t('ballot.singleBallot.sharerdBy')} {meta.createdBy.firstName}{' '}
														{meta.createdBy.lastName}, {moment(meta.createdAt).format(dateFormat)}
													</div>
												</div>
												<div className="actions">
													{ableToUpdate && (
														<DeleteIcon
															onClick={() => {
																modalService.openModal(
																	<DeleteFileModal
																		onClose={() => modalService.closeModal()}
																		deleteFile={async () => {
																			try {
																				setFieldValue(
																					'files',
																					values.files.filter(({ file }) => file.id !== id)
																				);
																				await submitForm();
																				hanleFilesUpdate();
																			} catch (err: any) {
																				console.error(err);
																			}
																		}}
																	/>
																);
															}}
														/>
													)}
													<DownloadIcon onClick={() => meetingService.donwloadFile(id!, title)} />
												</div>
											</div>
											{!!description && (
												<div className="description">
													<div className="label">{t('meeting.createMeeting.fileDescription')}</div>
													<div className="value">{description}</div>
												</div>
											)}
										</StyledUploadedFile>
									);
								})
						)}
					</Form>
				)}
			</Formik>
		</InputGroup>
	);
};
