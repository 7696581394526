import { makeAutoObservable, runInAction } from 'mobx';

export enum ToasterStatus {
	error = 'error',
	success = 'success',
	warning = 'warning',
	export = 'export',
}

interface Toast {
	status: ToasterStatus;
	message: JSX.Element | string;
}

export class ToasterService {
	toast: null | Toast = null;
	isExporting = false;

	constructor() {
		makeAutoObservable(this);
	}

	// methods

	showToast = (status: ToasterStatus, message: JSX.Element | string) => {
		runInAction(() => {
			this.toast = {
				message: message,
				status: status,
			};
			if (status === ToasterStatus.export) {
				this.isExporting = true;
				return;
			}
			const timer = setTimeout(() => {
				runInAction(() => {
					this.hideToast();
				});
			}, 4000);
			return () => clearTimeout(timer);
		});
	};

	hideToast = () => {
		this.isExporting = false;
		this.toast = null;
	};

	// methods end
}
