import styled from 'styled-components';

export const StyledRadioForm = styled.div<{ $checked?: boolean }>`
	width: 100%;

	border: 1px solid ${props => (props.$checked ? props.theme.bgColors.accent : 'white')};
	border-radius: 8px;
	padding: 16px 12px;
	margin: 8px 0px;

	> :not(:first-child) {
		padding-left: 30px;
	}
`;
