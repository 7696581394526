import { Loading } from '@components/loading/loading';
import { Logo } from '@components/logo/logo';
import { Flex } from '@components/utility/flex';

import { StyledConfirmEmailLayout } from './confirm-email-layout.styled';

interface ConfirmEmailLayoutProps {
	loading?: boolean;
	children: React.ReactNode;
}

export const ConfirmEmailLayout: React.FC<ConfirmEmailLayoutProps> = ({ loading, children }) => {
	return (
		<StyledConfirmEmailLayout>
			<Flex $justify="center" $align="center" $direction="column">
				<Logo color="#0B93E3" />
				{loading ? <Loading /> : children}
			</Flex>
		</StyledConfirmEmailLayout>
	);
};
