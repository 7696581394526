import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import {
	isOriginatorInStandard,
	userHasRoles,
	userHasRolesAndParticipatesInDomain,
} from '@asd-stan/helpers/app-utils';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';

import { StyledLoaderContainer } from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/single-standard.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

interface ProtectedRouteProps {
	redirectPath?: string;
	children?: React.ReactElement;
	allowedRoles: string[];
	shouldParticipateInDomain?: string[];
	shouldBeOriginator?: string[];
}

export const ProtectedSingleDraftRoute: React.FC<ProtectedRouteProps> = observer(
	({
		redirectPath = '/forbidden',
		children,
		allowedRoles,
		shouldBeOriginator,
		shouldParticipateInDomain,
	}) => {
		const currentUserService = getCurrentUserService();
		const draftService = getDraftService();
		const standardService = getStandardService();
		const params = useParams();
		const [isLoading, setIsLoading] = useState(true);

		useEffect(() => {
			const onLoadPage = async () => {
				setIsLoading(true);
				await draftService.getDraftById(Number(params.id));
				setIsLoading(false);
			};

			onLoadPage();
		}, [params.id, draftService]);

		let isAllowed;

		if (shouldBeOriginator) {
			isAllowed = isOriginatorInStandard(
				standardService.singleStandard,
				currentUserService.currentUser!
			);
		}

		if (!isAllowed && shouldParticipateInDomain) {
			isAllowed = userHasRolesAndParticipatesInDomain(
				allowedRoles,
				currentUserService.userRoles!,
				shouldParticipateInDomain,
				currentUserService.domainParticipations
			);
		} else if (!isAllowed) {
			isAllowed = userHasRoles(allowedRoles, currentUserService.userRoles!);
		}

		if (isLoading) {
			return (
				<ContentContainer>
					<StyledLoaderContainer>
						<Flex $align="center" $justify="center" $direction="column">
							<LogoIcon />
							<Loading horizontal />
						</Flex>
					</StyledLoaderContainer>
				</ContentContainer>
			);
		}

		if (!isAllowed) {
			return <Navigate to={redirectPath} replace />;
		}

		return children ? children : <Outlet />;
	}
);
