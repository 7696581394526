import { FileRepo } from '@asd-stan/file/api/file.repo';
import { FileUploadController } from '@asd-stan/file/domain/file-upload-controller';
import { FILE_REPO, FILE_SERVICE } from '@asd-stan/file/infrastructure/constants';
import { injector } from '@asd-stan/injector/injector';

export const getFileRepo = () => {
	return injector.get<FileRepo>(FILE_REPO);
};

export const getFileService = () => {
	return injector.get<FileUploadController>(FILE_SERVICE);
};
