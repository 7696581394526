import { StandardCollectionFilter } from '../domain/standard.service';

export const mapStandardListFilter = ({ forms, stages, ...filters }: StandardCollectionFilter) => ({
	...filters,
	forms: forms
		? Object.entries(forms).reduce<string[]>((acc, [key, value]) => {
				if (value) {
					acc.push(key);
				}
				return acc;
		  }, [])
		: undefined,
	stages: stages
		? Object.entries(stages).reduce<string[]>((acc, [key, value]) => {
				if (value) {
					acc.push(key);
				}
				return acc;
		  }, [])
		: undefined,
});
