import { injector } from '@asd-stan/injector/injector';

import { AuthRepo } from '../api/auth.repo';
import { AuthService } from '../domain/auth.service';

import { AUTH_REPO, AUTH_SERVICE } from './constants';

export const getAuthRepo = () => {
	return injector.get<AuthRepo>(AUTH_REPO);
};

export const getAuthService = () => {
	return injector.get<AuthService>(AUTH_SERVICE);
};
