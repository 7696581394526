import React from 'react';

import { ReactComponent as NotFoundAvatar } from '@assets/not-found-avatar.svg';
import { ReactComponent as UserAvatar } from '@assets/user-avatar.svg';

interface AvatarProps {
	width?: string;
	height?: string;
	uri?: string | null;
	notFound?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
	width = '32px',
	height = '32px',
	uri,
	notFound = false,
}) => {
	const { storageAvatar }: Storage = localStorage;

	if (uri) {
		return <img src={uri} alt="avatar" />;
	}

	return (
		<i className="avatar">
			{storageAvatar ? (
				<img src={storageAvatar} alt="avatar" />
			) : notFound ? (
				<NotFoundAvatar width={width} height={height} style={{ display: 'block' }} />
			) : (
				<UserAvatar width={width} height={height} style={{ display: 'block' }} />
			)}
		</i>
	);
};
