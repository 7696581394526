import i18n from '@asd-stan/i18n/i18n';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
	organization: yup
		.object()
		.shape({
			value: yup.number().required(i18n.t('ballot.singleBallot.errors.required')),
		})
		.required(i18n.t('ballot.singleBallot.errors.required')),
	decisions: yup.array().of(
		yup.object().shape({
			decision: yup.string().required(i18n.t('ballot.singleBallot.errors.required')),
		})
	),
});
