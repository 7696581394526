import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { useOnClickOutside } from '@asd-stan/helpers/use-in-click-outside';
import { useRequest } from '@asd-stan/helpers/use-request';
import { UpdateStageForm } from '@asd-stan/standard/domain/stage-update.entity';
import { User } from '@asd-stan/user/domain/user.entity';
import { getUserService } from '@asd-stan/user/infrastructure/getters';
import { Avatar } from '@components/avatar/avatar';
import { Checkbox } from '@components/checkbox/checkbox';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { useFormikContext } from 'formik';

import { StyledUserSelect } from './additional.styled';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

const Domain = ({
	code,
	name,
	selectedUsers,
	onUserSelectedChange: handleUserSelectedChange,
	onAllUsersSelectedChange: handleAllUsersSelectedChange,
}: {
	code: string;
	name: string;
	selectedUsers: User[];
	onUserSelectedChange(user: User): void;
	onAllUsersSelectedChange(users: User[], selected: boolean): void;
}) => {
	const { t } = useTranslation();
	const userService = getUserService();
	const [opened, setOpened] = useState(false);
	const hasBeenOpenedRef = useRef(false);
	if (!hasBeenOpenedRef.current && opened) {
		hasBeenOpenedRef.current = true;
	}
	const { data: participants, isLoading: isParticipantsLoading } = useRequest(
		useCallback(() => userService.getDomainParticipants([code]), []),
		{
			disabled: !hasBeenOpenedRef.current,
		}
	);

	const allUsersSelected =
		!!participants &&
		participants.length > 0 &&
		participants.every(({ id }) => selectedUsers.some(u => u.id === id));

	return (
		<div className="domain">
			<div className="domain-title" onClick={() => setOpened(o => !o)}>
				<Checkbox
					checked={allUsersSelected}
					onChange={() => {
						if (!participants) {
							setOpened(o => !o);
							return;
						}
						if (!allUsersSelected) {
							setOpened(true);
						}
						handleAllUsersSelectedChange(participants, !allUsersSelected);
					}}
				/>
				{code} {name}
				<ArrowIcon className={opened ? 'opened' : undefined} />
			</div>
			{opened &&
				(isParticipantsLoading ? (
					<Flex $justify="center" className="loading">
						<Loading width={25} height={25} />
					</Flex>
				) : !!participants && participants.length ? (
					participants.map(user => {
						const { id, firstName, lastName, systemRoles } = user;
						return (
							<div className="user" key={id} onClick={() => handleUserSelectedChange(user)}>
								<div className="user-left">
									<Checkbox
										checked={selectedUsers.some(u => u.id === id)}
										onChange={() => handleUserSelectedChange(user)}
									/>
									<div className="avatar">
										<Avatar width="24px" height="24px" />
									</div>
									<div className="name">
										{firstName} {lastName}
									</div>
								</div>
								<div className="roles">
									{systemRoles.map(role => (
										<div className="role" key={role}>
											{role}
										</div>
									))}
								</div>
							</div>
						);
					})
				) : (
					<div className="empty">
						{t('standard.singleStandard.stageUpdateModal.additional.noUsers')}
					</div>
				))}
		</div>
	);
};

const fieldName = 'notify.users';

export const UserSelect = () => {
	const { t } = useTranslation();
	const domainService = getDomainService();
	const [opened, setOpened] = useState(false);
	const userSelectRef = useRef<HTMLDivElement>(null);
	const { values, setFieldValue } = useFormikContext<UpdateStageForm>();
	const { data: domains, isLoading: isDomainsLoading } = useRequest(
		useCallback(() => domainService.getDomains(), [])
	);

	const selectedUsers = values.notify.users;

	const setSelectedUsers = (value: User[]) => setFieldValue(fieldName, value);

	const handleUserSelectedChange = (user: User) => {
		if (selectedUsers.some(({ id }) => id === user.id)) {
			setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id));
			return;
		}
		setSelectedUsers([...selectedUsers, user]);
	};

	const handleAllUsersInDomainSelectedChange = (usersInDomain: User[], selected: boolean) => {
		if (selected) {
			const nextSelectedUsers = selectedUsers.slice();
			usersInDomain.forEach(u => {
				if (nextSelectedUsers.some(({ id }) => id === u.id)) {
					return;
				}
				nextSelectedUsers.push(u);
			});
			setSelectedUsers(nextSelectedUsers);
			return;
		}
		setSelectedUsers(selectedUsers.filter(({ id }) => !usersInDomain.some(u => u.id === id)));
	};

	useOnClickOutside(userSelectRef, () => setOpened(false));

	const disabled = !values.notify.email && !values.notify.onScreen;

	return (
		<StyledUserSelect $disabled={disabled}>
			<div className="label">
				{t('standard.singleStandard.stageUpdateModal.additional.sendNotificationTo')}
			</div>
			<div ref={userSelectRef}>
				<div
					className="input"
					onClick={() => {
						if (!disabled) {
							setOpened(o => !o);
						}
					}}>
					{selectedUsers.length > 0 ? (
						selectedUsers.map(({ id, firstName, lastName }) => (
							<div className="selected-user" key={id}>
								{firstName} {lastName}
								<CloseIcon
									onClick={e => {
										e.stopPropagation();
										if (!disabled) {
											setSelectedUsers(selectedUsers.filter(u => u.id !== id));
										}
									}}
								/>
							</div>
						))
					) : (
						<div className="no-values">
							{t('standard.singleStandard.stageUpdateModal.additional.noValues')}
						</div>
					)}
					<ArrowIcon className="arrow" />
				</div>
				{opened && (
					<div className="popup">
						<div className="popup-body">
							{isDomainsLoading ? (
								<Flex $justify="center" className="loading">
									<Loading width={25} height={25} />
								</Flex>
							) : (
								!!domains &&
								domains.map(({ code, name }) => (
									<Domain
										key={code}
										code={code}
										name={name}
										selectedUsers={selectedUsers}
										onUserSelectedChange={handleUserSelectedChange}
										onAllUsersSelectedChange={handleAllUsersInDomainSelectedChange}
									/>
								))
							)}
						</div>
					</div>
				)}
			</div>
		</StyledUserSelect>
	);
};
