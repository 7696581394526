import {
	StandardTransactionHistoryListItem,
	StandardTransactionHistoryListItemDto,
} from '../domain/standard-stage.entity';

export const mapTransactionHistoryDto = (
	item: StandardTransactionHistoryListItemDto
): StandardTransactionHistoryListItem => ({
	id: item.id,
	code: item.stages.map(({ code }) => code).join(', '),
	name: item.stages.map(({ name }) => name).join(', '),
	completeDate: item.completeDate,
	startDate: item.startDate,
	targetDate: item.targetDate,
});
