import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { DomainMember } from '@asd-stan/domain/domain/domain-members.entity';
import {
	InfiniteRequestFetchFunction,
	useInfiniteRequest,
} from '@asd-stan/helpers/use-infinite-request';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { ReactComponent as EmptySearch } from '@assets/asd-stan-works/table/empty-search.svg';
import { Avatar } from '@components/avatar/avatar';
import { Button } from '@components/button/button';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { MarkedText } from '@components/utility/marked-text';

import { StyledExportLoading } from './standard-list.styled';

const useElementScrolledToBottom = (callback: () => void) => {
	const elementRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			const element = elementRef.current;
			if (!element) return;
			const { scrollHeight, scrollTop, clientHeight } = element;
			if (scrollTop + clientHeight >= scrollHeight) {
				callback();
			}
		};

		const element = elementRef.current;
		if (element) {
			element.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (element) {
				element.removeEventListener('scroll', handleScroll);
			}
		};
	}, [callback]);

	return elementRef;
};

const limit = 5;

export const Members = ({
	fetchFunction,
	isWg,
}: {
	fetchFunction: InfiniteRequestFetchFunction<DomainMember>;
	isWg: boolean;
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentUserService = getCurrentUserService();
	const {
		data: members,
		isLoading: membersLoading,
		loadMore: loadMoreMembers,
		hasMore: hasMoreMembers,
	} = useInfiniteRequest(
		async (limit: number, offset: number) => await fetchFunction(limit, offset),
		limit
	);

	const ref = useElementScrolledToBottom(
		useCallback(() => {
			if (!hasMoreMembers || membersLoading) {
				return;
			}
			loadMoreMembers();
		}, [loadMoreMembers, hasMoreMembers, membersLoading])
	);

	return (
		<div className="domain-member-list" ref={ref}>
			{membersLoading || members.length > 0 ? (
				<>
					{members.map(
						({ id, firstName, lastName, email, domainRoles, company, positions }: DomainMember) => {
							return (
								<div key={id} className="domain-member">
									<Flex $justify="space-between" $align="center">
										<Flex $align="center">
											<Avatar />
											<Flex className="user-info" $direction="column">
												<span className="name">
													{firstName} {lastName}
												</span>
												<span className="email">{email}</span>
											</Flex>
										</Flex>
										<div className="domain-roles">
											{domainRoles.map(role => (
												<div className="domain-role" key={role}>
													{role}
												</div>
											))}
										</div>
									</Flex>
									<Flex className="wrapper">
										<div className="company-container">
											<span className="label">{t('standard.standardList.company')}:</span>
											<span className="company">{company}</span>
										</div>
										{positions.length > 0 && (
											<div className="position-container">
												<span className="label">{t('standard.standardList.position')}:</span>
												<span className="position">{positions.join(', ')}</span>
											</div>
										)}
									</Flex>
								</div>
							);
						}
					)}
					{membersLoading && (
						<StyledExportLoading>
							<Loading height={48} width={48} />
						</StyledExportLoading>
					)}
				</>
			) : (
				<Flex $align="center" $direction="column" className="empty">
					<EmptySearch />
					<p>
						<MarkedText>{t('common.emptySearch')}</MarkedText>
					</p>
					<p className="empty-text">
						{t(`standard.standardList.${isWg ? 'noWgMembers' : 'noDomainMembers'}`)}
					</p>
					{currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES]) && (
						<Button
							className="button"
							title={t(`standard.standardList.${isWg ? 'addWgMember' : 'addDomainMember'}`)}
							onClick={() => navigate('/user-list/invite-users')}
						/>
					)}
				</Flex>
			)}
		</div>
	);
};
