export class Injector {
	// tslint:disable-next-line:no-any
	private map: Map<string | Symbol, any> = new Map();

	public get<T>(key: string | Symbol): T {
		return this.map.get(key);
	}

	public set<T>(key: string | Symbol, value: T): void {
		this.map.set(key, value);
	}
}

export const injector = new Injector();
