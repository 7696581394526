import { StandardStatusDTO } from '@asd-stan/standard/api/standard-status.dto';
import { StandardStatus } from '@asd-stan/standard/domain/standard-status.entity';

export const mapStandardStatusDTOToStandardStatus = (dto: StandardStatusDTO): StandardStatus => {
	const standardStatusMap: Record<StandardStatusDTO, StandardStatus> = {
		[StandardStatusDTO.WIP]: StandardStatus.WIP,
		[StandardStatusDTO.AN]: StandardStatus.AN,
		[StandardStatusDTO.PB]: StandardStatus.PB,
		[StandardStatusDTO.RT]: StandardStatus.RT,
		[StandardStatusDTO.DC]: StandardStatus.DC,
		[StandardStatusDTO.SD]: StandardStatus.SD,
		[StandardStatusDTO.ENWIP]: StandardStatus.ENWIP,
		[StandardStatusDTO.EN]: StandardStatus.EN,
		[StandardStatusDTO.SR]: StandardStatus.SR,
	};

	return standardStatusMap[dto];
};
