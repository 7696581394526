import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { CountryListDTO } from '@asd-stan/user/api/country/country.dto';
import { makeCountryFromCountryDTO } from '@asd-stan/user/api/country/country.factory';
import { Country } from '@asd-stan/user/domain/country.entity';

export const ALL_COUNTRIES = gql`
	query {
		allCountries {
			id
			name
		}
	}
`;

const COUNTRY_BY_COMPANY_ID = gql`
	query ($companyId: Int!) {
		countriesByCompanyId(companyId: $companyId) {
			id
			name
		}
	}
`;

export class CountryRepo {
	async getCountries(): Promise<Country[]> {
		const { data } = await stanWorksClient.query<CountryListDTO>({
			query: ALL_COUNTRIES,
		});

		return data.allCountries.map(dto => makeCountryFromCountryDTO(dto));
	}

	async getCountryByCompanyId(id: number): Promise<Country[]> {
		const { data } = await stanWorksClient.query<CountryListDTO>({
			query: COUNTRY_BY_COMPANY_ID,
			variables: {
				companyId: id,
			},
		});

		return data.countriesByCompanyId.map(dto => makeCountryFromCountryDTO(dto));
	}
}
