import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { BorderButton } from '@components/button/border-button';
import { ButtonClose } from '@components/button/button-close';
import { Flex } from '@components/utility/flex';

import { StyledErrorFormContainer } from './error-form-container.styled';

interface ErrorFormContainerProps {
	isError: boolean;
	title: string;
	description: string | JSX.Element;
	showClose?: boolean;
	handleApply: () => void;
	handleClose?: () => void;
}

export const ErrorFormContainer: React.FC<ErrorFormContainerProps> = ({
	isError,
	title,
	description,
	showClose,
	handleApply,
	handleClose,
}) => {
	const { t } = useTranslation();

	return (
		<StyledErrorFormContainer $isError={isError}>
			<div className="error-header">
				<div>
					<Warning />
					{title}
				</div>
				<span>{description}</span>
			</div>
			<Flex $direction="column" $align="flex-end">
				{showClose && <ButtonClose onClick={handleClose ? handleClose : () => {}} />}
				<BorderButton
					onClick={handleApply}
					title={t('standard.createNWP.originator.applyButton')}
				/>
			</Flex>
		</StyledErrorFormContainer>
	);
};
