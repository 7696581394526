import { DraftDetailed } from '../domain/draft-detailed.entity';
import { Draft } from '../domain/draft.entity';

import { DraftDTO, DraftDetailedDTO } from './draft.dto';

export const makeDraftFromDraftDTO = (dto: DraftDTO): Draft => {
	return new Draft({
		id: dto.id,
		generalData: {
			registrationNumber: dto.generalData ? dto.generalData.registrationNumber : null,
			form: dto.generalData ? dto.generalData.form : null,
			edition: dto.generalData ? dto.generalData.edition : null,
			cenWiNumber: dto.generalData ? dto.generalData.cenWiNumber : null,
		},
		titles: {
			en: dto.titles ? dto.titles.en : '',
		},
	});
};

export const makeDraftDetailedFromDraftDetailedDTO = (dto: DraftDetailedDTO): DraftDetailed => {
	return new DraftDetailed(dto);
};
