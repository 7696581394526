import React from 'react';

import { ReactComponent as LogoIcon } from '@assets/logo-black.svg';
import {StyledLogoHeader} from '@asd-stan/shell/components/logo-header/logo-header.styled';
import {NavLink} from 'react-router-dom';

export const LogoHeader: React.FC = () => {
	return (
		<StyledLogoHeader>
			<NavLink to={'/'}><LogoIcon /></NavLink>
		</StyledLogoHeader>
	);
};
