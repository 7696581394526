import styled from 'styled-components';

export const StyledModal = styled.div`
	width: 456px !important;
	align-items: stretch !important;

	h4 {
		text-align: center;
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 24px;
	}

	.body {
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		color: #abb3bb;
	}

	.buttons {
		display: flex;
		gap: 24px;
		margin-top: 24px;

		& > div {
			flex: 1;

			button {
				width: 100% !important;
			}
		}
	}
`;
