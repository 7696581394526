import { scrollStyles } from '@components/utility/shared-styles';
import { styled } from 'styled-components';

export const TitleWrapper = styled.div`
	margin: 22px 0 32px 0;
	max-width: 420px;
`;

export const StageSelectorTitle = styled.div`
	color: #abb3bb;
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
`;

export const StageSelectorTitleBlack = styled.span`
	color: #333336;
`;

export const StagePickerContainer = styled.div`
	border: solid 1px #dae4e8;
	border-radius: 8px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 300px;
	width: 100%;
	overflow-y: auto;
	${scrollStyles};
	scrollbar-width: thin;
	scrollbar-color: #0b93e3 #dae4e8;
`;

export const StageItem = styled.div`
	cursor: pointer;
	font-weight: 500;
	border: solid 1px #dae4e8;
	border-radius: 4px;
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #abb3bb;
	&:hover,
	&.active {
		color: #041630;
		border-color: #0b93e3;
		background-color: rgba(11, 147, 227, 0.08);
	}
`;
