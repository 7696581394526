import { useTranslation } from 'react-i18next';

import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormSelect } from '@components/form-select/form-select';
import { Field } from 'formik';

export const BallotResult = () => {
	const { t } = useTranslation();

	return (
		<InputGroup title={t('ballot.createBallot.result')}>
			<Field
				component={FormSelect}
				name="result"
				title={t('ballot.createBallot.result')}
				options={[
					{ label: t('ballot.ballotResult.pending'), value: 'pending' },
					{ label: t('ballot.ballotResult.approved'), value: 'approved' },
					{ label: t('ballot.ballotResult.disapproved'), value: 'disapproved' },
					{ label: t('ballot.ballotResult.notValid'), value: 'notValid' },
				]}
				isLoading={false}
				mandatory
				fullWidth
				showError
			/>
			<Field
				component={FormInputParagraph}
				name="resultComment"
				title={t('ballot.createBallot.comment')}
				fullWidth
				maxLength={5000}
				showError
			/>
		</InputGroup>
	);
};
