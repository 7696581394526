import {
	BallotResult,
	BallotStatus,
	SingleBallot,
	ValidatedVotingForm,
	VotingDecision,
} from '../domain/ballot.entity';

interface SingleBallotDto {
	ballot: {
		title: string;
		description: string;
		status: 'active' | 'pending';
		standard: {
			id: number;
			registrationNumber: string;
			form: string;
			edition: string;
			title: string;
		} | null;
		openingDate: string;
		closingDate: string;
		selectedVoters: {
			id: number;
			firstName: string;
			lastName: string;
		}[];
		attachments: {
			id: number;
			createdAt: string;
			createdBy: {
				firstName: string;
				lastName: string;
			};
			file: { id: number; name: string; path: string | null; size: number };
		}[];
		result: 'pending' | 'approved' | 'disapproved' | 'notValid';
		resultLastAction: {
			doneBy: {
				firstName: string;
				lastName: string;
			};
			doneAt: string;
		};
		resultComment: string | null;
		resultCommentLastAction: {
			doneBy: {
				firstName: string;
				lastName: string;
			};
			doneAt: string;
		};
		questions: { id: number; title: string; comment: string }[];
		createdBy: {
			id: number;
		};
	};

	myVote: null | {
		id: number;
		organization: {
			id: number;
			name: string;
		};
		attachments: {
			id: number;
			file: {
				id: number;
				name: string;
				path: string | null;
				size: number;
			};
		}[];
		decisions: {
			id: number;
			question: {
				id: number;
			};
			decision: 'approve' | 'abstain' | 'disapprove';
			comment: string;
		}[];
	};
}

export const mapSingleBallot = ({
	ballot: {
		status,
		title,
		description,
		standard,
		openingDate,
		closingDate,
		selectedVoters,
		result,
		resultComment,
		attachments,
		questions,
		resultCommentLastAction,
		resultLastAction,
		createdBy,
	},
	myVote,
}: SingleBallotDto): SingleBallot => ({
	title,
	status: status as BallotStatus,
	description,
	standard,
	openingDate: new Date(openingDate),
	closingDate: new Date(closingDate),
	attachments: attachments.map(({ id, createdAt, createdBy, file }) => ({
		value: {
			id,
			fileId: file.id,
			path: file.path || '',
			size: file.size,
			title: file.name,
		},
		doneAt: new Date(createdAt),
		doneBy: createdBy,
	})),
	result: {
		value: result as BallotResult,
		doneAt: new Date(resultLastAction.doneAt),
		doneBy: resultLastAction.doneBy,
	},
	resultComment: {
		value: resultComment || '',
		doneAt: new Date(resultCommentLastAction.doneAt),
		doneBy: resultCommentLastAction.doneBy,
	},
	selectedVotersIds: selectedVoters.map(({ id }) => id),
	questions,
	myVote: myVote && {
		id: myVote.id,
		form: {
			organization: {
				label: myVote.organization.name,
				value: myVote.organization.id,
			},
			decisions: myVote.decisions.map(({ id, comment, decision, question }) => ({
				id,
				questionId: question.id,
				comment,
				decision: decision as VotingDecision,
			})),
			attachments: myVote.attachments.map(({ file, id }) => ({
				id,
				fileId: file.id,
				path: file.path || '',
				size: file.size,
				title: file.name,
			})),
		},
	},
	createdById: createdBy.id,
});

interface CreateUpdateVoteDto {
	ballotId: number;
	organizationId: number;
	attachments: {
		id: null | number;
		fileId: number;
	}[];
	decisions: {
		id: null | number;
		questionId: number;
		decision: 'approve' | 'abstain' | 'disapprove';
		comment: string;
	}[];
}

export const mapCreateUpdateVoteDto = (
	ballotId: number,
	{ organization, attachments, decisions }: ValidatedVotingForm
): CreateUpdateVoteDto => ({
	ballotId,
	organizationId: organization.value,
	attachments: attachments.map(({ id, fileId }) => ({ id, fileId })),
	decisions,
});
