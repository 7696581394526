import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import {
	AppointerDTO,
	AppointerListDTO,
	CreatedAppointerDTO,
} from '@asd-stan/user/api/appointer/appointer.dto';
import { makeAppointerFromAppointerDTO } from '@asd-stan/user/api/appointer/appointer.factory';
import { Appointer } from '@asd-stan/user/domain/appointer.entity';

const ALL_APPOINTERS = gql`
	query {
		allAppointers {
			id
			name
		}
	}
`;

const CREATE_APPOINTER = gql`
	mutation ($name: String!) {
		createAppointer(name: $name) {
			id
			name
		}
	}
`;

export class AppointerRepo {
	async getAppointers(): Promise<Appointer[]> {
		const { data } = await stanWorksClient.query<AppointerListDTO>({
			query: ALL_APPOINTERS,
		});

		return data.allAppointers.map(dto => makeAppointerFromAppointerDTO(dto));
	}

	async createAppointer(name: string): Promise<AppointerDTO> {
		const { data } = await stanWorksClient.mutate<CreatedAppointerDTO>({
			mutation: CREATE_APPOINTER,
			variables: {
				name: name,
			},
		});

		return { id: data?.createAppointer.id, name: data?.createAppointer.name } as Appointer;
	}
}
