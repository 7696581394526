import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { AdditionalInformation } from '@asd-stan/standard/components/nwp/forms/additional-information';
import { Attachments } from '@asd-stan/standard/components/nwp/forms/attachments';
import { StandardDraft } from '@asd-stan/standard/components/nwp/forms/standard-draft';
import { Titles } from '@asd-stan/standard/components/nwp/forms/titles';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { NWPSchema, emptySchema } from '@asd-stan/standard/validation-schemas/nwp.schema';
import { BreadCrumbs } from '@asd-stan/ui-kit/components/bread-crumbs/bread-crumbs';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { Flex } from '@asd-stan/ui-kit/components/utility/flex';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { PageTitle } from '@components/page-title/page-title';
import { MarkedText } from '@components/utility/marked-text';
import { Form, Formik, FormikValues } from 'formik';

import { makeDraftNwpCreation } from './draft-edit-nwp';
import { DraftRemarks } from './forms/draft-remarks';
import { ENFields } from './forms/enFields';
import { Experts } from './forms/experts';
import { General } from './forms/general';
import { Justification } from './forms/justification';
import { Originator } from './forms/originator';

import { StyledButtonGroup } from '@asd-stan/ui-kit/components/utility/button-group.styled';
import { ContentContainer } from '@asd-stan/ui-kit/components/utility/content-container.styled';

const initialValues = {
	form: null,
	registrationNumber: '',
	revisionId: { label: '', value: '' },
	standard: '',
	stanNumber: '',
	edition: '',
	languages: null,
	typeIds: [],
	pages: '',
	domainCodes: [],
	workingGroupCodes: [],
	scope: '',
	originator: { label: '', value: '' },
	radioForm: true,
	isAsdOriginator: false,
	revisionJustification: '',
	technicalChanges: [],
	knownPatentIssue: false,
	patentReferences: '',
	tags: [],
	classification: [],
	standardTitleEN: '',
	remark: '',
	attachments: [],
	newOriginator: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		company: null,
		companyCountry: null,
		position: [],
	},
	existOriginator: null,
	existEmailOriginatorCredentials: null,
	existPhoneOriginatorCredentials: null,
	showReplaceStandard: false,
	enData: {
		name: '',
		publicationDate: null,
		title: '',
	},
};

export const CreateNwp: React.FC = observer(() => {
	const navigation = useNavigate();
	const { t } = useTranslation();

	const toasterService = getToasterService();
	const standardService = getStandardService();
	const draftService = getDraftService();

	const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
	const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

	useEffect(() => {
		const createDraft = async () => {
			draftService.remarks = null;
			draftService.singleDraft = null;
			draftService.draftId = null;
			await draftService.createDraft();
			draftService.getDraftsCount();
		};

		setIsSaveDisabled(true);
		createDraft();
		setIsSaveDisabled(false);

		if (standardService.singleStandard !== null) {
			standardService.clearSingleStandard();
		}
	}, [standardService]);

	const goToPreviousPage = () => {
		if (draftService.draftId) {
			draftService.singleDraft = null;
			standardService.clearSingleStandard();
			return navigation(`/standards/draft/${draftService.draftId}/general`);
		}
		navigation(-1);
	};

	const onSubmit = async (values: FormikValues) => {
		try {
			setIsSaveDisabled(true);

			if (draftService.draftId) {
				const creation = makeDraftNwpCreation(values, draftService);
				await standardService.updateDraft(draftService.draftId, creation);
			}

			if (isSaveClicked) {
				if (draftService.draftId) {
					await standardService.moveDraftToNWP(draftService.draftId);
				}
				toasterService.showToast(
					ToasterStatus.success,
					standardService.singleStandard
						? t('standard.createNWP.toaster.successfullUpdate')
						: t('standard.createNWP.toaster.successfullCreate')
				);
			}

			if (isSaveClicked) {
				navigation('/standards');
			}

			setIsSaveDisabled(false);
			setIsSaveClicked(false);
		} catch (error) {
			setIsSaveDisabled(false);
			setIsSaveClicked(false);
		}
	};

	return (
		<Formik
			validationSchema={isSaveClicked ? NWPSchema : emptySchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			validateOnChange
			validateOnBlur={false}>
			{({ handleSubmit, validateForm }) => {
				return (
					<ContentContainer>
						<BreadCrumbs />
						<Flex $justify="space-between" $align="center">
							<PageTitle title={t('standard.createNWP.title')} />
							<StyledButtonGroup>
								<Button
									disabled={isSaveDisabled}
									secondary
									title={t('standard.createNWP.buttons.cancel')}
									onClick={goToPreviousPage}
								/>
								<Button
									disabled={isSaveDisabled}
									title={t('standard.createNWP.buttons.save')}
									onClick={async () => {
										await setIsSaveClicked(prevState => true);
										validateForm().then(errors => {
											if (errors && !(Object.keys(errors).length === 0)) {
												toasterService.showToast(
													ToasterStatus.error,
													t('standard.createNWP.toaster.incorrectFields')
												);
												setIsSaveClicked(prevState => false);
											}
										});

										try {
											await handleSubmit();
										} catch (error) {
											setIsSaveClicked(prevState => false);
										}
									}}
								/>
							</StyledButtonGroup>
						</Flex>

						{standardService.singleStandard && (
							<>
								{standardService.singleStandard.stage && (
									<div className="description">
										<Trans
											i18nKey="standard.singleStandard.subTitle"
											values={{
												currentStage: `${standardService.singleStandard.stage?.code} ${standardService.singleStandard.stage.name}`,
											}}>
											Current stage:
											<MarkedText>
												{standardService.singleStandard.stage?.code}{' '}
												{standardService.singleStandard.stage?.name}
											</MarkedText>
										</Trans>
									</div>
								)}
							</>
						)}

						<Form>
							<InputGroup title={t('standard.createNWP.general.title')} children={<General />} />
							<InputGroup
								title={t('standard.createNWP.originator.title')}
								children={<Originator isSaveClicked={isSaveClicked} />}
							/>
							<InputGroup
								title={t('standard.createNWP.standardDraft.title')}
								children={<StandardDraft />}
							/>
							<InputGroup
								title={t('standard.createNWP.justification.title')}
								children={<Justification />}
							/>
							<InputGroup
								title={t('standard.createNWP.additionalInformation.title')}
								children={<AdditionalInformation />}
							/>
							<InputGroup title={t('standard.createNWP.titles.title')} children={<Titles />} />
							<InputGroup title={t('standard.createNWP.experts.title')} children={<Experts />} />
							{/*<InputGroup*/}
							{/*	title={t('standard.createNWP.suggestExpertsFromOutside.title')}*/}
							{/*	children={<></>}*/}
							{/*/>*/}
							<InputGroup
								title={t('standard.createNWP.attachments.title')}
								children={<Attachments />}
							/>
							{/*<InputGroup title={t('standard.createNWP.qualifications.title')} children={<></>} />*/}
							<InputGroup title={t('standard.createNWP.enFields.title')} children={<ENFields />} />
							<InputGroup
								title={t('standard.createNWP.remarks.title')}
								children={<DraftRemarks />}
							/>
						</Form>
					</ContentContainer>
				);
			}}
		</Formik>
	);
});
