import { getFileRepo } from '@asd-stan/file/infrastructure/getters';
import { Attachment } from '@asd-stan/standard/components/nwp/forms/attachments';
import { makeAutoObservable, runInAction } from 'mobx';

export interface UploadedFile {
	id?: number | null;
	fileId: number | null;
	uploadId?: string;
	title: string;
	path: string;
	size: number;
}

export class FileUploadController {
	private _uploadedFiles: UploadedFile[] = [];
	private _isLoading: boolean = false;

	constructor() {
		makeAutoObservable(this);

		this._uploadedFiles = [];
	}

	private get _fileRepo() {
		return getFileRepo();
	}

	/**
	 * @param file - File
	 * @param isPrivate - Declares if file should be accessible via permanent link. Default = false
	 **/

	public async uploadFile(file: File, isPrivate?: boolean): Promise<UploadedFile> {
		this._isLoading = true;
		const response = await this._fileRepo.uploadFile(file, isPrivate);

		runInAction(() => {
			this._uploadedFiles.push({
				id: response.id,
				title: response.title,
				path: response.path,
				fileId: response.fileId,
				size: response.size,
			});
			this._isLoading = false;
		});

		return response;
	}

	get uploadedFiles() {
		return this._uploadedFiles;
	}

	get isLoading() {
		return this._isLoading;
	}

	public setUploadedFiles(files: Attachment[]) {
		const convertedFiles = this._makeUploadedFilesFromSavedFiles(files);

		runInAction(() => {
			this._uploadedFiles = convertedFiles;
		});
	}

	public clearUploadedFiles() {
		runInAction(() => {
			this._uploadedFiles = [];
		});
	}

	private _makeUploadedFilesFromSavedFiles(files: Attachment[]): UploadedFile[] {
		const convertedFiles: UploadedFile[] = [];

		files.forEach(file => {
			convertedFiles.push({
				id: file.file.id,
				fileId: file.file.fileId,
				size: file.file.size,
				path: file.file.path,
				title: file.file.title,
			});
		});

		return convertedFiles;
	}
}
