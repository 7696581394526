import React, { HTMLInputTypeAttribute } from 'react';

import { generatePlaceholder } from '@asd-stan/helpers/app-utils';
import { ErrorMessage, FieldProps, getIn } from 'formik';

import { StyledFormInput } from './form-input.styled';

import { StyledFieldErrorMessage } from '@asd-stan/ui-kit/components/utility/field-error-message.styled';

interface InputProps {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	mandatory?: boolean;
	errors: string;
	title: string;
	name: string;
	fullWidth?: boolean;
	smallerWidth?: boolean;
	staticWidth?: boolean;
	showError?: boolean;
	disabled?: boolean;
	showCheckbox?: boolean;
	onlyDigits?: boolean;
	maxLength?: number;
	maxLengthWithError?: boolean;
	onChange?: (field: string, value: string) => void;
	onCustomChange?: (value: string) => void;
	useSubmitOnBlur?: boolean;
	setTouchedOnBlur?: boolean;
	isWarning?: boolean;
	disableMaxLength?: boolean;
	regExp?: RegExp;
}

export const FormInput: React.FC<InputProps & FieldProps> = ({
	title,
	placeholder,
	mandatory,
	field,
	form,
	type = 'text',
	fullWidth,
	smallerWidth,
	staticWidth,
	showError,
	disabled,
	showCheckbox = false,
	onlyDigits,
	maxLength,
	maxLengthWithError = false,
	onChange,
	onCustomChange,
	useSubmitOnBlur,
	setTouchedOnBlur = false,
	isWarning,
	disableMaxLength = false,
	regExp,
}) => {
	const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onCustomChange?.(event.target.value);
		if (onChange) {
			return onChange(field.name, event.target.value);
		}
		if (maxLength && !maxLengthWithError && event.target.value.length > maxLength) {
			return;
		}
		if (regExp) {
			const filteredValue = event.target.value.replace(regExp, '');
			form.setFieldValue(field.name, filteredValue);
			return;
		}
		if (onlyDigits) {
			const filteredValue = event.target.value.replace(/[^\d.]/gi, '');
			form.setFieldValue(field.name, filteredValue);
		} else {
			form.setFieldValue(field.name, event.target.value);
		}
	};

	const handleBlur = async () => {
		if (setTouchedOnBlur) {
			await form.setFieldTouched(field.name);
		}
		if (field.value && field.value.length > 0) {
			await form.setFieldValue(field.name, field.value.trim());
		}
		if (useSubmitOnBlur) {
			if (disableMaxLength) {
				return await form.handleSubmit();
			}
			if (maxLength && field.value && field.value?.length > maxLength) {
				return;
			}
			await form.handleSubmit();
		}
	};

	return (
		<StyledFormInput
			$error={!!(getIn(form.touched, field.name) && getIn(form.errors, field.name))}
			$warning={isWarning}
			$fullWidth={fullWidth}
			$showCheckbox={showCheckbox}
			$disabled={disabled}
			$smallerWidth={smallerWidth}
			$staticWidth={staticWidth}>
			<label title={title} className={disabled ? 'disabled' : ''}>
				{title}
				{mandatory && <span>*</span>}
			</label>
			<input
				type={type}
				disabled={disabled}
				placeholder={generatePlaceholder(title, placeholder)}
				{...field}
				onBlur={handleBlur}
				onChange={onValueChange}
			/>
			{maxLength && maxLengthWithError && field.value?.length > maxLength ? (
				<div className="error-message">
					{field.value?.length} / {maxLength}
				</div>
			) : null}
			{showError ? <ErrorMessage name={field.name} component={StyledFieldErrorMessage} /> : null}
		</StyledFormInput>
	);
};
