import { CountryDTO } from '@asd-stan/user/api/country/country.dto';
import { Country } from '@asd-stan/user/domain/country.entity';

export const makeCountryFromCountryDTO = (dto: CountryDTO | null): Country => {
	if (!dto) {
		return new Country({
			id: 0,
			name: '',
		});
	}

	return new Country({
		id: dto.id,
		name: dto.name,
	});
};
